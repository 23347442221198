import React, { useState } from "react"
import { IGCrawlerState } from "../../Model/IGCrawler"
import { Trans, useTranslation } from "react-i18next"
import classNames from "classnames"
import { addNeo4jJKOLRecord } from "../../HelpingFunction/Admin/AdminDBHelper"
import { DETAIL_TYPE } from "../../Utils/TypeList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEye } from "@fortawesome/free-solid-svg-icons"
import NewAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"

const AddKol: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState<{
    name: string
    type: string
    isLook4kolReg: boolean
    status: IGCrawlerState
  }>({
    name: "",
    type: "",
    isLook4kolReg: false,
    status: IGCrawlerState.PRIORITY,
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const addKOLToNeo4j = () => {
    if (data.name && data.type) {
      setIsLoading(true)
      addNeo4jJKOLRecord(data).then((result) => {
        setIsLoading(false)
        setIsLoaded(true)
        setTimeout(() => {
          setIsLoaded(false)
        }, 2000)
      })
    }
  }

  return (
    <div className="relative">
      {/* main content*/}
      <div className="relative max-w-7xl mx-auto pt-4 pb-36 relative px-4">
        <h1 className="text-grey-800 text-3xl font-bold leading-tight  ">
          <Trans>AddKOL.title</Trans>
        </h1>
        <hr className="mb-4" />

        <NewAlert
          title={t("Crawler_neo4j.warning-title")}
          alertType={ALERT_TYPE.ERROR}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          visible={true}
        />

        {/*  Crawler running */}
        <h2 className="text-grey-800 text-xl font-bold leading-tight  ">
          <Trans>AddKOL.warn</Trans>
        </h2>

        <div className="my-8">
          <p className="text-sm font-semibold my-2">
            <Trans>AddKOL.name</Trans>
          </p>
          <input
            type="text"
            placeholder="Enter your name"
            className="input input-bordered w-96 "
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
              })
            }}
            value={data.name}
          />
        </div>

        <div className="my-8">
          <p className="text-sm font-semibold my-2">
            <Trans>AddKOL.isLook4KOLReg</Trans>
          </p>
          <select className="select select-bordered  w-96" disabled={true}>
            <option disabled selected>
              Is this KOL Register in Look4KOL
            </option>
            <option value={"EN"}>True</option>
            <option value={"CHI"}>False</option>
          </select>
        </div>

        <div className="my-8">
          <p className="text-sm font-semibold my-2">
            <Trans>AddKOL.category</Trans>
          </p>
          <select
            className="select select-bordered  w-96"
            onChange={(e) => {
              setData({
                ...data,
                type: e.target.value,
              })
            }}>
            <option disabled>Choose KOL category</option>
            {DETAIL_TYPE.map((item, index) => {
              return (
                <option
                  selected={data.type === item.value}
                  key={item.value + index}
                  value={item.value}>
                  {item.labelCHI}
                </option>
              )
            })}
          </select>
        </div>

        <div className="my-8">
          <p className="text-sm font-semibold my-2">
            <Trans>AddKOL.crawler-status</Trans>
          </p>
          <select
            className="select select-bordered  w-96"
            value={data.status}
            onChange={(e) => {
              setData({
                ...data,
                status: e.target.value as unknown as IGCrawlerState,
              })
            }}>
            <option disabled selected>
              Choose Crawler Status
            </option>

            <option value={-1}>PRIORITY</option>
            <option value={0}>READY</option>
            <option disabled={true} value={1}>
              RUNNING
            </option>
            <option value={2}>FINISHED</option>
            <option value={3}>ERROR</option>
          </select>
        </div>

        <div className="my-8">
          <button
            className={classNames("btn", {
              loading: isLoading,
              "btn-success": isLoaded,
            })}
            disabled={isLoaded || isLoading}
            onClick={() => addKOLToNeo4j()}>
            {isLoaded && <FontAwesomeIcon icon={faCheck} />}
            {isLoaded ? "Done" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  )
}
export default AddKol
