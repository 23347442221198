import { Job } from "./Job"
import AdminRequest from "./AdminRequest"

export enum BankTransactionType {
  ADMIN_REQUEST = "ADMIN_REQUEST",
  JOB = "JOB",
}

export interface BankTransaction {
  id: string | undefined
  type: BankTransactionType
  amount: number
  date: number
  isFinish: boolean
  data: Job | AdminRequest
}
