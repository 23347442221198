import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import DivContentLoader from "../../Component/DivContentLoader"
import { getMyPayoutHistory } from "../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import {
  getJobList,
  jobListCondition,
} from "../../HelpingFunction/Job/JobDBHelper"
import { getKOLWallet } from "../../HelpingFunction/KOL/kolDBHelper"
import AdminRequest, { PAYOUT_REQUEST_DATA } from "../../Model/AdminRequest"
import { Balance, balanceDefault } from "../../Model/Bank"
import {
  BankTransaction,
  BankTransactionType,
} from "../../Model/BankTransaction"
import { Job, JobFilterDefault } from "../../Model/Job"
import KOL from "../../Model/KOL"
import { DateObj } from "../../Model/Utilities"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { isAuth } from "../../Utils/FirebaseUtils"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import CustomTable from "../../Component/NewTable"
import { Column } from "../../Model/Table"
import {
  adminTranscationTableSchema,
  defaultAdminTranscationTableSchema,
  ORDER_BY_DIRECTION,
} from "../../Utils/TableHelperFunction"

interface Props {
  profile: KOL
}

const KOLWalletProfiler = (props: Props) => {
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [profile, setProfile] = useState<KOL>()
  const [payoutHis, setPayoutHis] = useState<AdminRequest[]>([])
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [isLoadedPayoutHis, setIsLoadedPayoutHis] = useState<boolean>(false)
  const [isGettingWallet, setIsGettingWallet] = useState<boolean>(false)
  const [job_fulfilled, setJob_fulfilled] = useState<jobListCondition>({
    ...JobFilterDefault,
    completePayout: true,
    accepted: true,
  })
  const [condition, setCondition] = useState<{
    type: string
    startAfter: DateObj | "" | Date
  }>({
    type: "",
    startAfter: "",
  })
  const [bankTrans, setBankTrans] = useState<BankTransaction[]>([])
  const [jobHist, setJobHist] = useState<Job[]>([])
  const [balance, setBalance] = useState<Balance>(balanceDefault)
  let tempTrans: BankTransaction[] = []

  const [listWallet, setListWallet] = useState<Column[][]>([
    defaultAdminTranscationTableSchema(),
  ])

  const [orderByOption, setOrderByOption] = useState<{
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }>({
    fieldName: "lastUpdate",
    direction: ORDER_BY_DIRECTION.DESC,
  })

  useEffect(() => {
    setIsGettingWallet(true)
    if (Array.isArray(bankTrans) && isLoadedPayoutHis) {
      let tableSets: Column[][] = []

      bankTrans.map(async (bankData) => {
        tableSets.push(adminTranscationTableSchema(bankData))
      })
      setListWallet(tableSets)
      setIsGettingWallet(false)
    }
  }, [bankTrans])

  const openHistoryModal = (index: number) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.WALLET_HISTORY_MODAL,
      returnFn: () => {
        return null
      },
      metaData: bankTrans[index],
    })
  }

  useEffect(() => {
    setIsLoadedPayoutHis(false)
    setIsLoaded(false)

    if (props.profile.id && isAuth(auth)) {
      getMyPayoutHistory(condition, props.profile.id).then((result) => {
        if (result.success) {
          console.log(result.data)
          getBankTransaction(result.data, [])
          setPayoutHis(payoutHis.concat(result.data))
        }
      })
      getJobList(job_fulfilled, props.profile.id, orderByOption, 10).then(
        (result) => {
          if (result.success) {
            if (condition.startAfter === "") {
              setHasMore(result.hasMore)
              getBankTransaction([], result.data)
              setJobHist(result.data)
            } else {
              setHasMore(result.hasMore)
              setJobHist([...jobHist].concat(result.data))
            }
          } else {
            console.log("error")
          }
        }
      )
      getKOLWallet(props.profile.id).then((result: any) => {
        if (result.success) {
          setBalance(result.data)
        } else {
        }
      })
      setIsLoadedPayoutHis(true)
      setIsLoaded(true)
    }
  }, [props.profile])

  const getBankTransaction = (payoutHis: AdminRequest[], jobHist: Job[]) => {
    if (payoutHis.length !== 0) {
      payoutHis.map((p: AdminRequest) => {
        const payoutRequest = p.data as PAYOUT_REQUEST_DATA
        if ("seconds" in p.lastUpdate) {
          tempTrans.push({
            id: p.id,
            amount: payoutRequest.withdrawAmount,
            date: p.lastUpdate.seconds,
            isFinish: p.isFinish,
            type: BankTransactionType.ADMIN_REQUEST,
            data: { ...p },
          })
        }
      })
    }
    if (jobHist.length !== 0) {
      jobHist.map((j: Job) => {
        if ("seconds" in j.payDate) {
          tempTrans.push({
            id: j.id,
            amount: j.payoutAmount,
            date: j.payDate.seconds,
            isFinish: true,
            type: BankTransactionType.JOB,
            data: { ...j },
          })
        }
      })
    }
    setBankTrans([...bankTrans].concat(tempTrans))
  }

  if (isLoadedPayoutHis && isLoaded) {
    return (
      <div>
        <h1 className="text-3xl">
          <Trans>KOLProfiler.wallet</Trans>
        </h1>
        <div>
          <h2>
            <Trans>MyWallet.balance</Trans>:
          </h2>

          <div className="stats bg-primary text-primary-content">
            <div className="stat bg-primary">
              <div className="stat-title text-white">Account balance</div>
              <div className="stat-value text-white">
                {balance?.amount}{" "}
                <span className="uppercase">{balance?.currency}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <CustomTable
            cols={listWallet}
            clickAction={openHistoryModal}
            isFirstColStatic={false}
            isLoading={!isLoadedPayoutHis}
            rowIsLoading={isGettingWallet}
            notFoundDes={t("MyPayoutRequest.record-not-found")}
            isLastRecord={!hasMore}
          />

          {isLoadedPayoutHis && payoutHis.length >= 10 && hasMore && (
            <button
              className="w-full bg-gray-100 py-2 px-4 rounded my-2"
              disabled={!hasMore}
              onClick={() => {
                if (payoutHis.length > 0) {
                  setCondition({
                    ...condition,
                    startAfter: payoutHis[payoutHis.length - 1].lastUpdate,
                  })
                  setJob_fulfilled({
                    ...job_fulfilled,
                    paginationData: payoutHis[payoutHis.length - 1].lastUpdate,
                  })
                }
              }}>
              <Trans>MyPayoutRequest.more</Trans>
            </button>
          )}
        </div>
      </div>
    )
  }
  return (
    <div className="my-4">
      <DivContentLoader w={"100%"} h={200} r={20} />
    </div>
  )
}

export default KOLWalletProfiler
