export interface KOLType {
  id: string
  objectID?: string
  avatarUrl: string
  coverImages: string[]
  createDate: Date
  description: string
  email: string
  firstName: string
  followers: string[]
  isPublish: boolean
  isVerified: boolean
  jobScored: number
  lastModified: Date
  lastName: string
  nationality: string
  platform: string
  score: number
  serviceTag: string[]
  tags: string[]
  type: string[]
  url: string
  userName: string
  metaDesc?: string
}

export const KOLDefault: KOLType = {
  id: "",
  avatarUrl: "",
  coverImages: [],
  createDate: new Date(),
  description: "",
  email: "",
  firstName: "",
  followers: [],
  isPublish: false,
  isVerified: false,
  jobScored: 0,
  lastModified: new Date(),
  lastName: "",
  nationality: "",
  platform: "",
  score: 0,
  serviceTag: [],
  tags: [],
  type: [],
  url: "",
  userName: "",
}

class KOL {
  objectID?: string
  id: string
  avatarUrl: string
  coverImages: string[]
  createDate: Date
  description: string
  metaDesc?: string
  email: string
  firstName: string
  followers: string[]
  isPublish: boolean
  isVerified: boolean
  jobScored: number
  lastModified: Date
  lastName: string
  nationality: string
  platform: string
  score: number
  serviceTag: string[]
  tags: string[]
  type: string[]
  url: string
  userName: string

  constructor(data: KOLType) {
    this.id = data.id
    this.avatarUrl = data.avatarUrl
    this.coverImages = data.coverImages
    this.createDate = data.createDate
    this.description = data.description
    this.email = data.email
    this.firstName = data.firstName
    this.followers = data.followers
    this.isPublish = data.isPublish
    this.isVerified = data.isVerified
    this.jobScored = data.jobScored
    this.lastModified = data.lastModified
    this.lastName = data.lastName
    this.nationality = data.nationality
    this.platform = data.platform
    this.score = data.score
    this.serviceTag = data.serviceTag
    this.tags = data.tags
    this.type = data.type
    this.url = data.url
    this.userName = data.userName
    this.metaDesc = data.metaDesc
  }
}

export default KOL
