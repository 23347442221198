import { Reducer } from "react"
import * as List from "../../Utils/ModalType"
import Modal from "../../Model/Modal"
import { MODAL_TYPE } from "../../Enum/MODAL_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { APP_TYPE } from "../../Enum/APP_TYPE"

interface actionTypes {
  type: string
  Modal: Modal
  typeOfModal: MODAL_TYPE
  returnFn: () => void | null
  metaData: null
  typeLimit?: APP_TYPE
  uploadPath?: string
  limit?: number
}

export interface modalInitStateType {
  Modal: Modal
  returnFn: (() => void) | null
  metaData: null
  typeLimit?: APP_TYPE
  uploadPath?: string
  limit?: number
}

const initialState: modalInitStateType = {
  Modal: new Modal("", false),
  returnFn: null,
  metaData: null,
  typeLimit: APP_TYPE.NONE,
  uploadPath: "",
  limit: 1,
}

const ModalController: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL) {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden"
    if (action.typeOfModal === List.SUPPORTED_MODAL_TYPE.UPLOAD_FILE_MODAL) {
      return {
        ...state,
        Modal: new Modal(action.typeOfModal, true),
        returnFn: action.returnFn,
        metaData: action.metaData,
        uploadPath: action.uploadPath,
        typeLimit: action.typeLimit,
        limit: action.limit,
      }
    } else if (
      action.typeOfModal ===
        List.SUPPORTED_MODAL_TYPE.ADMIN_CREATE_CHATROOM_MODAL ||
      action.typeOfModal === List.SUPPORTED_MODAL_TYPE.VIEW_IMAGE_MODAL ||
      action.typeOfModal ===
        List.SUPPORTED_MODAL_TYPE.VIEW_KOL_PERCENTAGE_MODAL ||
      action.typeOfModal ===
        List.SUPPORTED_MODAL_TYPE.ADMIN_EDIT_REQUEST_MODAL ||
      action.typeOfModal === List.SUPPORTED_MODAL_TYPE.WALLET_HISTORY_MODAL ||
      action.typeOfModal ===
        List.SUPPORTED_MODAL_TYPE.COMPARE_GROUP_TABLE_MODAL ||
      action.typeOfModal === List.SUPPORTED_MODAL_TYPE.KOL_FILTER_MODAL ||
      action.typeOfModal === List.SUPPORTED_MODAL_TYPE.KOL_PROFILE_MODAL ||
      action.typeOfModal === List.SUPPORTED_MODAL_TYPE.EDIT_CRAWLER_STATE ||
      action.typeOfModal ===
        List.SUPPORTED_MODAL_TYPE.ADD_TO_COMPARE_GROUP_MODAL
    ) {
      return {
        ...state,
        Modal: new Modal(action.typeOfModal, true),
        returnFn: action.returnFn,
        metaData: action.metaData,
      }
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.CLOSE_MODAL) {
    document.getElementsByTagName("body")[0].style.overflowY = "scroll"
    return {
      ...state,
      Modal: new Modal("", false),
    }
  }

  return state
}

export default ModalController
