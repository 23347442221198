import { Capacitor } from "@capacitor/core"
import { isLocalhost } from "./utiltyHelper"

export function getAPIPath(path: string) {
  const isMobileDevice =
    Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios"

  const dnsUrl = process.env.REACT_APP_HOST_NAME + path
  const testUrl = "http://localhost:" + process.env.REACT_APP_TEST_PORT + path

  return isMobileDevice ? dnsUrl : isLocalhost() ? testUrl : path
}

export function getNeo4jServer(path: string) {
  return isLocalhost()
    ? "https://neo4jtest.look4kol.com" + path
    : "https://neo4j.look4kol.com" + path
}

export function isWeb() {
  return (
    Capacitor.getPlatform() !== "android" && Capacitor.getPlatform() !== "ios"
  )
}

export function getCumulativeOffset(element: any) {
  let top = 0,
    left = 0
  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0

    element = element.offsetParent
  } while (element)

  return {
    top: top,
    left: left,
  }
}
