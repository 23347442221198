import { getAPIPath, isWeb } from "./HelpingFunction"
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import firebaseApp, { analytics } from "../config/firebase"
import { logEvent } from "firebase/analytics"
import { getMessaging, getToken } from "firebase/messaging"
import { getUserLang } from "./utiltyHelper"
import { useHistory } from "react-router-dom"

export const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    new GoogleAuthProvider(),
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccess: () => {
      if (isHost()) {
        logEvent(analytics, "login")
      }
      // this.props.history.push({pathname: "/login"})
    },
  },
}

export function isAuth(authObj) {
  return (
    authObj !== null &&
    typeof authObj !== "undefined" &&
    authObj.uid !== "" &&
    authObj.uid
  )
}

export function logout() {
  const auth = getAuth(firebaseApp)
  auth
    .signOut()
    .then(function () {
      // Sign-out successful.
      window.location.reload(false)
      localStorage.setItem("look4kol-require-logout", false)
      localStorage.setItem("look4kol-auth", null)
    })
    .catch(function (error) {
      // An error happened
    })
}

export function returnIdTokenResult() {
  return new Promise((resolve, reject) => {
    const auth = getAuth(firebaseApp)
    if (auth.currentUser) {
      auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        return resolve({
          success: true,
          email: auth.currentUser.email,
          uid: auth.currentUser.uid,
          photoURL: auth.currentUser.photoURL,
          displayName: auth.currentUser.displayName,
          token: idTokenResult.token,
          role: idTokenResult.claims.role,
          expirationTime: idTokenResult.expirationTime,
          custID: idTokenResult.claims.custID
            ? idTokenResult.claims.custID
            : null,
        })
      })
    }
  })
}

export function isHost() {
  return window.location.host === "look4kol.com"
}

export function requestNotificationAccess(auth) {
  try {
    const messaging = getMessaging(firebaseApp)
    return new Promise((resolve, reject) => {
      // Let's check whether notification permissions have already been granted
      if (isWeb()) {
        if (Notification.permission === "granted") {
          // If it's okay let's create a notification

          getToken(messaging, {
            vapidKey:
              "BALiUneYw0V4b-U5QvtpU5G2SvcgqlWsJnzZfXEdPDjcqmND01geOkjaoBn7WgZy6THFSjKiWVyZgVgfToBfcLY",
          })
            .then((currentToken) => {
              // console.log("currentToken: ")
              // console.log(currentToken)
              if (currentToken) {
                if (
                  localStorage.getItem("look4kol-gcm-id" + "-" + auth.uid) !==
                  currentToken
                ) {
                  // console.log("updating new token to firebase")
                  localStorage.setItem(
                    "look4kol-gcm-id" + "-" + auth.uid,
                    currentToken
                  )
                  return new Promise(async (resolve, reject) => {
                    await fetch(getAPIPath("/api/gcm/addID"), {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        uid: auth.uid,
                        id: currentToken,
                      }),
                    })
                      .then((res) => res.json())
                      .then((response) => {
                        return returnIdTokenResult(firebaseApp).then(
                          async (res) => {
                            await fetch(
                              getAPIPath("/api/gcm/" + auth.uid + "/test"),
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  idToken: res.token,
                                },
                                body: JSON.stringify({
                                  token: [currentToken],
                                  isWeb: isWeb(),
                                }),
                              }
                            )
                              .then((res) => res.json())
                              .then((response) => {
                                if (response.success) {
                                  return resolve({ success: true })
                                }
                              })
                              .catch((err) => {
                                console.log(err)
                                return resolve({
                                  success: false,
                                  message: err,
                                })
                              })
                          }
                        )
                      })
                  })
                } else {
                  // console.log(
                  //   "already requested successfully, sending notification..."
                  // )

                  returnIdTokenResult(firebaseApp).then(async (res) => {
                    await fetch(getAPIPath("/api/gcm/" + auth.uid + "/test"), {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        idToken: res.token,
                      },
                      body: JSON.stringify({
                        token: [currentToken],
                        isWeb: isWeb(),
                      }),
                    })
                      .then((res) => res.json())
                      .then((response) => {
                        if (response.success) {
                          return resolve({ success: true })
                        }
                      })
                      .catch((err) => {
                        console.log(err)
                        return resolve({
                          success: false,
                          message: err,
                        })
                      })
                  })
                }
              } else {
                // Show permission request.
                console.log(
                  "No registration token available. Request permission to generate one."
                )
                // Show permission UI.
                resolve({ success: false })
              }
            })
            .catch((err) => console.log(err))
          //
          // logEvent(analytics, "messaging_permission_accepted")
          // var notification = new Notification("Hi 我地之後會係呢到通知你")
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
          localStorage.removeItem("look4kol-gcm-id" + "-" + auth.uid)
          logEvent(analytics, "messaging_permission_rejected")

          Notification.requestPermission().then(async function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              getToken(messaging, {
                vapidKey:
                  "BE7FT7GwqwzVasd6Htn8qLEWHaAR3hi-cdHGe7vBR_sj8khdWeUIbwqdii_rVQR0JVFX5l4i2p6mAFkrNaGP1V8",
              })
                .then((currentToken) => {
                  if (currentToken) {
                    if (
                      localStorage.getItem(
                        "look4kol-gcm-id" + "-" + auth.uid
                      ) !== currentToken
                    ) {
                      console.log("updating new token to firebase")

                      localStorage.setItem(
                        "look4kol-gcm-id" + "-" + auth.uid,
                        currentToken
                      )
                      return new Promise(async (resolve, reject) => {
                        await fetch(getAPIPath("/api/gcm/addID"), {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            uid: auth.uid,
                            id: currentToken,
                          }),
                        })
                          .then((res) => res.json())
                          .then((response) => {
                            if (response.success) {
                              resolve({ success: true })
                            } else {
                              resolve({ success: false })
                            }
                          })
                      })
                    } else {
                      console.log(
                        "already requested successfully, sending notification..."
                      )

                      returnIdTokenResult(firebaseApp).then(async (res) => {
                        await fetch(
                          getAPIPath("/api/gcm/" + auth.uid + "/test"),
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              idToken: res.token,
                            },
                            body: JSON.stringify({
                              token: [currentToken],
                              isWeb: isWeb(),
                            }),
                          }
                        )
                          .then((res) => res.json())
                          .then((response) => {
                            if (response.success) {
                              return resolve({ success: true })
                            }
                          })
                          .catch((err) => {
                            console.log(err)
                            return resolve({
                              success: false,
                              message: err,
                            })
                          })
                      })
                    }
                  } else {
                    // Show permission request.
                    console.log(
                      "No registration token available. Request permission to generate one."
                    )
                    // Show permission UI.
                    resolve({ success: false })
                  }
                })
                .catch((err) => {
                  console.log("An error occurred while retrieving token. ", err)
                })
              // logEvent(analytics, "messaging_permission_accepted")
              // var notification = new Notification("Hi 我地之後會係呢到通知你")
            }
          })
        }
      } else {
        console.log("its not web app! replacing location!")
        const currentToken = localStorage.getItem(
          "look4kol-gcm-id" + "-" + auth.uid
        )

        console.log("currentToken")
        console.log(currentToken)
        console.log("auth.uid")
        console.log(auth.uid)
        return returnIdTokenResult(firebaseApp).then(async (res) => {
          console.log("sending msg test request...")
          console.log("res data:")
          console.log(res.token)
          localStorage.setItem("firebase-auth" + "-" + auth.uid, res.token)

          window.location.replace("/" + getUserLang() + "/setting/sendMsg")
        })
      }
    })
  } catch (e) {
    console.log("error occurred in firebaseUtil!")
    console.log(e)
  }
}
