import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobeAsia, faStar, faUser } from "@fortawesome/free-solid-svg-icons"
import { Carousel } from "react-responsive-carousel"
import DivContentLoader from "./DivContentLoader"
import { returnSelectedItems } from "../Utils/TypeList"
import KOL, { KOLDefault } from "../Model/KOL"
import { getKOL } from "../HelpingFunction/KOL/kolDBHelper"
import { useDispatch, useSelector } from "react-redux"
import { isAuth } from "../Utils/FirebaseUtils"
import { getCountry } from "../Utils/Countries"
import { getUserLang, returnThumbnailUrl } from "../Utils/utiltyHelper"

type PropsType =
  | {
      data: KOL
      isHomePage?: boolean
      isContentLoader?: boolean
      onSelectKOL: (data: KOL) => void
    }
  | {
      isContentLoader: true
      isHomePage?: boolean
    }
const KOLSearchCard: React.FC<PropsType> = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const [kol, setKOL] = useState<KOL>(
    props.isContentLoader ? KOLDefault : props.data
  )
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false)

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const bookmarkList: string[] = useSelector((state: any) => {
    return state.MemberManager.bookMarkedKOLs
  })

  const checkMember = async () => {
    if (bookmarkList.length) {
      if (bookmarkList.includes(kol.id)) {
        setIsBookmarked(true)
      } else {
        setIsBookmarked(false)
      }
    }
  }
  useEffect(() => {
    if (kol && kol.objectID) {
      getKOL(kol.objectID as string).then((result) => {
        if (result.success) {
          setKOL(result.data)
        }
      })
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
  }, [props?.data])
  useEffect(() => {
    if (isAuth(auth)) {
      checkMember()
    }
  }, [auth, bookmarkList])

  if (props.isContentLoader) {
    return (
      <div className=" w-full cursor-pointer hover:bg-gray-50 rounded-2xl my-2 bg-white md:px-2 md:py-2  ">
        <div
          className={[
            props.isHomePage ? " " : "md:grid",
            " w-full gap-3 md:gap-4",
          ].join(" ")}
          style={{ gridTemplateColumns: "50% 50%" }}>
          <DivContentLoader w={"100%"} h={235} r={8} />

          <div className="py-2 pl-3 md:pl-1 py-1 md:pr-4">
            <DivContentLoader w={"100%"} h={30} r={2} />
            <div
              className="inline-block w-full my-2 text-sm overflow-y-hidden text-gray-700"
              style={{ height: "40px" }}>
              <DivContentLoader w={"100%"} h={35} r={2} />
              <div className="relative float-right mt-20 right-0 bottom-0">
                ...
              </div>
            </div>
            <div className="inline-block">
              <div className="md:flex gap-4 my-2">
                <div className="block my-2 md:my-0 md:flex text-sm text-gray-500">
                  <FontAwesomeIcon
                    icon={faGlobeAsia}
                    className="mr-2 text-red-default"
                  />
                  <DivContentLoader w={60} h={20} r={2} />
                </div>
                <div className="block my-2 md:my-0 md:flex text-sm text-gray-500">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="mr-2 text-red-default"
                  />
                  <DivContentLoader w={10} h={20} r={2} />
                  <span>
                    <DivContentLoader w={20} h={20} r={2} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className=" w-full cursor-pointer hover:bg-gray-50 rounded-2xl my-2 bg-white md:px-2 md:py-2  ">
      <div
        onClick={() => props.onSelectKOL(kol)}
        className=" md:grid w-full gap-3 md:gap-4"
        style={{ gridTemplateColumns: "50% 50%" }}>
        <div className="h-72 md:h-56  w-full rounded-xl">
          {Array.isArray(kol.coverImages) && (
            <div className="w-full" onClick={() => props.onSelectKOL(kol)}>
              <Carousel
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                className="h-72 md:h-56  w-full rounded-xl"
                showStatus={false}
                showThumbs={false}>
                {kol.coverImages.map((e, index) => {
                  if (e !== "") {
                    return (
                      <div
                        key={"lk" + kol.id + index}
                        className="w-full rounded-xl">
                        <img
                          alt="find kol"
                          className="rounded-xl object-cover lazyload h-72 md:h-56"
                          src={returnThumbnailUrl(e as string)}
                        />
                      </div>
                    )
                  }
                  return <div></div>
                })}
              </Carousel>
            </div>
          )}
        </div>

        <div className="py-2 pl-3 md:pl-1 py-1 md:pr-4">
          <div
            className="grid"
            style={{ gridTemplateColumns: "50px auto auto" }}>
            <div
              onClick={() => props.onSelectKOL(kol)}
              className={
                "overflow-hidden h-10 w-10 bg-red-50 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono"
              }>
              {kol.avatarUrl !== "" ? (
                <img src={kol.avatarUrl} />
              ) : (
                <FontAwesomeIcon icon={faUser} className="text-red-200" />
              )}
            </div>
            <div>
              <a
                className="hover:underline font-semibold text-lg md:text-sm xl:text-lg text-gray-700 w-24 md:w-28 lg:w-24 xl:w-36 overflow-hidden overflow-ellipsis"
                href={`/${getUserLang()}/p/${kol.userName}`}>
                {kol.userName}
              </a>
            </div>
          </div>
          <div
            className="inline-block w-full my-2 text-sm overflow-y-hidden text-gray-700"
            style={{ height: "40px" }}
            onClick={() => props.onSelectKOL(kol)}>
            {kol.description}
            <div className="relative float-right mt-20 right-0 bottom-0">
              ...
            </div>
          </div>

          {/* icon container*/}
          <div className="inline-block">
            <div className="md:flex gap-4 my-2">
              <div className="block my-2 md:my-0 md:flex text-sm text-gray-500">
                <FontAwesomeIcon
                  icon={faGlobeAsia}
                  className="mr-2 text-red-default"
                />
                {getCountry(kol.nationality).label}
              </div>
              <div className="block my-2 md:my-0 md:flex text-sm text-gray-500">
                <FontAwesomeIcon
                  icon={faStar}
                  className="mr-2 text-red-default"
                />
                {kol.score}
                <span>({kol.jobScored})</span>
              </div>
            </div>
          </div>

          {/* KOL cate */}
          <div className="my-1 h-10 overflow-y-hidden">
            {Array.isArray(kol.type) &&
              returnSelectedItems(kol.type).map((t) => {
                return (
                  <button
                    key={t.value}
                    className="bg-white hover:bg-gray-50 inline-block px-2 py-2 rounded-2xl text-sm rounded-lg my-0.5 mr-2 border border-gray-200 text-red-default"
                    onClick={() => {
                      history.push(
                        "/" +
                          (localStorage.getItem("i18n-lang") === "en"
                            ? "en"
                            : "hk") +
                          "/s/?&t=" +
                          t.value
                      )
                    }}>
                    {t.label}
                  </button>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default KOLSearchCard
