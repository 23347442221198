import React from "react"

import { Switch } from "react-router-dom"
import { NEW_CUSTOMER } from "../../Enum/LOGIN_PAGE_TYPE"
import CustomerJobProfiler from "./CustomerJobProfiler"
import AdminEditCustomerProfile from "./AdminEditCustomerProfile"

enum selectedCustomerEnum {
  CUSTOMER_PROFILE = "profile",
  JOB = "job",
}

interface Props {
  auth: any
  selectedCustomer: NEW_CUSTOMER
  type: selectedCustomerEnum
}

const CustomerProfiler = (props: Props) => {
  return (
    <>
      <span>{props.selectedCustomer.objectID}</span>

      <Switch>
        {/*<Route path={"/:lang/admin/list-kol/:kolID/profile"} exact>*/}
        {props.type === selectedCustomerEnum.CUSTOMER_PROFILE && (
          <AdminEditCustomerProfile
            auth={props.auth}
            profile={props.selectedCustomer}
          />
        )}

        {props.type === selectedCustomerEnum.JOB && (
          <CustomerJobProfiler
            auth={props.auth}
            profile={props.selectedCustomer}
          />
        )}
      </Switch>
    </>
  )
}
export default CustomerProfiler
