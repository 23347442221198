import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { useDispatch, useSelector } from "react-redux"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CsvDownload from "react-json-to-csv"
import { CompareItem } from "../../redux/reducer/ToolsManager"
import { compareKOLAlgolia } from "../../HelpingFunction/KOL/kolDBHelper"
import { KOLCombine } from "../../Model/KOLCombine"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import classNames from "classnames"

interface PropsType {
  data: CompareItem
}

export const CompareGroupCard: React.FC<PropsType> = ({ data }) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [isDeleting, setIsDeleting] = useState(false)
  const [confirmCode, setConfirmCode] = useState("")
  const [tableData, setTableData] = useState<
    {
      success: true
      userName: string
      data: KOLCombine
    }[]
  >([])
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const removeKOLFromCompareGroup = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.REMOVE_COMPARE_GROUP,
      data: state,
    })

  useEffect(() => {
    if (isLoaded && tableData.length) {
      toggleTableCompareGroup()
    }
  }, [tableData])

  const toggleTableCompareGroup = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.COMPARE_GROUP_TABLE_MODAL,
      returnFn: null,
      metaData: {
        kolData: tableData,
        dataLoaded: isLoaded,
        disableOutbounds: true,
      },
    })
  }

  const onSearchStart = (searchUserIDs: string[]) => {
    setIsTableLoading(true)
    setIsLoaded(false)
    if (Array.isArray(searchUserIDs) && searchUserIDs.filter((n) => n)) {
      compareKOLAlgolia(
        searchUserIDs.filter((n) => n).join(","),
        auth.uid
      ).then((result) => {
        if (result.success) {
          setIsLoaded(true)
          setTableData(result.data)
        }

        setIsTableLoading(false)
      })
    }
  }

  return (
    <div
      className="col-span-1 rounded-lg p-4 border border-gray-100 bg-white shadow grid gap-2"
      style={{ gridTemplateRows: "30px 120px auto" }}>
      <div className="relative font-bold text-lg">
        {data.title}

        <button
          className="absolute -top-1 right-2 rounded-full p-1 "
          onClick={() => {
            setIsDeleting(!isDeleting)
          }}>
          <FontAwesomeIcon icon={faTrash} className="text-gray-400" />
        </button>
      </div>

      <div className="overflow-x-hidden overflow-y-scroll">
        {data.data.map((kolID) => {
          return (
            <span
              key={kolID}
              className="text-sm inline-block mx-1 py-1 px-2 text-center rounded bg-gray-50">
              {kolID}
            </span>
          )
        })}
      </div>

      {isDeleting ? (
        <div className="">
          <div className="text-red-700 mb-2">
            <Trans>CompareGroupCard.confirm-delete-code</Trans> :
            <span className="bg-gray-50 rounded mx-2 p-1 text-red-900">
              {data.title}
            </span>
          </div>
          <div className="grid" style={{ gridTemplateColumns: "auto 80px" }}>
            <input
              type="text"
              className="p-0.5 w-full  rounded text-sm  border focus:ring-gray-500 focus:border-gray-900 "
              value={confirmCode}
              onChange={(e) => {
                setConfirmCode(e.target.value)
              }}
            />
            <button
              className="rounded-lg py-2 px-4 text-center"
              disabled={confirmCode !== data.title}
              onClick={() => {
                removeKOLFromCompareGroup(data.id)
              }}>
              <Trans>CompareGroupCard.confirm-delete</Trans>
            </button>
          </div>
        </div>
      ) : (
        <div>
          <button
            className={classNames("btn btn-primary my-2", {
              loading: isTableLoading,
            })}
            disabled={isTableLoading || data.data.length === 0}
            onClick={(e) => {
              onSearchStart(data.data)
            }}>
            <Trans>CompareKOL.direct-compare</Trans>
          </button>
        </div>
      )}
    </div>
  )
}

export default CompareGroupCard
