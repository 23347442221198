import KOLProfiler from "../admin/KOLProfiler"
import React, { useEffect, useState } from "react"
import { selectedKOLEnum } from "../../Enum/PROFILE_TYPE"
import {
  returnSearchConditionParam,
  searchConditionType,
  searchPageQuery,
} from "../../Utils/QueryHelper"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import KOL, { KOLDefault } from "../../Model/KOL"
import { getKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import { isAuth } from "../../Utils/FirebaseUtils"
import { isAdminChatroomExists } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { getUserLang } from "../../Utils/utiltyHelper"
import { useHistory } from "react-router-dom"
import KOLProfileTypeBtnGroup from "./KOLProfileTypeBtnGroup"

interface profileProps {
  queryPushFn: (query: any) => void
  metaData?: any
}

const KOLProfileComponent: React.FC<profileProps> = ({
  queryPushFn,
  metaData,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  let params = searchPageQuery(paramList)
  const [selectedKOL, setSelectedKOL] = useState<KOL>(KOLDefault)
  const [isSelectedKOL, setIsSelectedKOL] = useState<boolean>(false)

  const [type, setType] = useState<selectedKOLEnum>(selectedKOLEnum.PROFILE)

  const [existingChatRoomID, setExistingChatRoomID] = useState<string>("")
  const createNewChatroom = (): void => {
    if (params.kolID) {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
        typeOfModal: SUPPORTED_MODAL_TYPE.ADMIN_CREATE_CHATROOM_MODAL,
        metaData: { userID: params.kolID },
      })
    }
  }
  useEffect(() => {
    if (isAuth(auth) && params.kolID) {
      const queryInfo = params.type
      onPageUpdate(queryInfo ? queryInfo : "profile", params.kolID)
    }
  }, [auth, params.kolID, params.type])

  const checkChatroomExist = (uid: string) => {
    isAdminChatroomExists(uid).then((result) => {
      if (result.success) {
        setExistingChatRoomID(result.chatroomID)
      }
    })
  }
  const onPageUpdate = (pageType: string, kolID: string) => {
    returnTypeEnum(pageType)
    checkChatroomExist(kolID)
    if (kolID !== selectedKOL.id) {
      getKOL(kolID).then((result) => {
        if (result.success) {
          setIsSelectedKOL(false)
          setSelectedKOL({
            ...result.data,
            id: result.data.id,
          } as KOL)
          setTimeout(() => {
            setIsSelectedKOL(true)
          }, 250)
        }
      })
    }
  }

  const returnTypeEnum = (pageTypeStr: string) => {
    switch (pageTypeStr) {
      case "profile":
        setType(selectedKOLEnum.PROFILE)
        break
      case "follower":
        setType(selectedKOLEnum.FOLLOWER)
        break
      case "job":
        setType(selectedKOLEnum.JOB)
        break
      case "wallet":
        setType(selectedKOLEnum.WALLET)
        break
      default:
        break
    }
  }
  const queryLoader = (query: searchConditionType) => {
    history.push({
      pathname: "/" + getUserLang() + "/admin/list-kol",
      search: "?" + returnSearchConditionParam(query, paramList),
    })
  }

  const returnQueryFn = metaData ? queryLoader : queryPushFn
  const onSelectPageType = (pageType: selectedKOLEnum) => {
    if (params.kolID) {
      returnQueryFn({ kolID: params.kolID, type: pageType })
    }
  }

  if (isSelectedKOL && typeof selectedKOL.id !== "undefined") {
    return (
      <div className="rounded-md shadow w-full p-4 bg-white my-4 h-full mb-12 lg:mb-0 relative">
        <div className="sticky top-0 z-50 border-b border-gray-100 rounded-b-2xl bg-white py-3 p-2">
          <KOLProfileTypeBtnGroup
            createNewChatroom={createNewChatroom}
            existingChatRoomID={existingChatRoomID}
            onSelectPageType={onSelectPageType}
            type={type}
          />
        </div>
        <KOLProfiler
          type={type}
          auth={auth}
          history={history}
          selectedKOL={selectedKOL}
        />
      </div>
    )
  }
  return <div></div>
}

export default KOLProfileComponent
