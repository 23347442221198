import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Trans } from "react-i18next"

import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth"
import { useDispatch } from "react-redux"
import { ROLE } from "../Enum/APP_TYPE"
import { LOGIN_CREDENTIALS_TYPE } from "../Enum/LOGIN_PAGE_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../redux/SUPPORTED_REDUX_FUNCTION"
import { t } from "i18next"
import ResetPasswordForm from "../page-components/login/ResetPasswordForm"
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { ALERT_TYPE, BUTTON_TYPE } from "../Enum/ALERT_SYSYEM"
import CustomAlert from "../Component/NewAlert"
import { getUserLang } from "../Utils/utiltyHelper"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../Assets/logo_top-removebg-preview.png"
import { getAdminRole } from "../Model/Role"

const auth = getAuth()

const Login: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [loginCredentials, setLoginCredentials] =
    useState<LOGIN_CREDENTIALS_TYPE>({
      email: "",
      password: "",
    })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string>("")

  const [loggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [forgotPassword, setForgotPassword] = useState<boolean>(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        history.push("/" + getUserLang() + "/login")
      }
    })
  }, [auth])

  const loginHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setIsLoading(true)

    if (loginCredentials.email === "" || loginCredentials.password === "") {
      setIsLoading(false)
      setStatus("required-all-fields")
    } else {
      signInWithEmailAndPassword(
        auth,
        loginCredentials.email,
        loginCredentials.password
      )
        .then((userCredential) => {
          if (auth.currentUser) {
            localStorage.setItem("look4kol-require-login", "false")
            auth.currentUser
              .getIdTokenResult()
              .then((idTokenResult) => {
                setRedirect(idTokenResult.claims.role as string)
                localStorage.setItem(
                  "look4kol-auth-uid",
                  userCredential.user.uid
                )
                dispatch({
                  type: SUPPORTED_REDUX_FUNCTIONS.LOGIN,
                  data: {
                    email: userCredential.user.email,
                    uid: userCredential.user.uid,
                    photoURL: userCredential.user.photoURL,
                    displayName: userCredential.user.displayName,
                    role: getAdminRole(idTokenResult.claims.role as ROLE),
                    expirationTime: idTokenResult.expirationTime,
                    custID: idTokenResult.claims.custID
                      ? idTokenResult.claims.custID
                      : null,
                  },
                })
              })
              .catch((err) => console.log(err))
          }

          // Signed in
          setIsLoading(false)
          setIsLoggedIn(true)
          //For Login Modal

          // ...
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            setIsLoading(false)
            setStatus(error.code)
          }
          if (error.code === "auth/wrong-password") {
            setIsLoading(false)
            setStatus(error.code)
          }
          if (error.code === "auth/too-many-requests") {
            setIsLoading(false)
            setStatus(error.code)
          }
          if (error.code === "auth/invalid-email") {
            setIsLoading(false)
            setStatus(error.code)
          }
          let errorCode = error.code
          let errorMessage = error.message
        })
    }
  }

  const setRedirect = (role: string) => {
    setTimeout(() => {
      if (role === ROLE.KOL) {
        window.open(
          process.env.REACT_APP_HOST_NAME +
            "/" +
            (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
            "/p/" +
            auth.currentUser?.uid
        )
      } else if (role === ROLE.CUSTOMER) {
        window.open(
          process.env.REACT_APP_HOST_NAME +
            "/" +
            (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
            "/s"
        )
      } else {
        history.push(
          (localStorage.getItem("i18n-lang") === "en" ? "/en" : "/hk") +
            "/admin"
        )
      }
    }, 2000)
  }

  const requiredLogin =
    localStorage.getItem("look4kol-require-login") === "true"

  const errorStatus = () => {
    switch (status) {
      case "required-all-fields":
        return "ErrorList.fill-in-the-field"
        break
      case "auth/wrong-password":
        return "RegForm.error-wrong-password"
        break
      case "auth/user-not-found":
        return "RegForm.error-user-not-found"
        break
      default:
        return ""
        break
    }
  }

  return (
    <div className="h-full">
      <div className="flex justify-center h-full">
        {loggedIn ? (
          <div className="  px-6 lg:px-12 xl:px-20 pt-40 mb-10">
            <div className="relative w-full">
              <div>
                <div className="max-w-full mx-auto px-4 lg:p-6">
                  <div className="block font-semibold text-xl self-start text-gray-700">
                    <h2 className="leading-relaxed text-center">
                      <Trans>RegForm.login-intro</Trans>
                    </h2>
                    <p className="text-sm text-gray-500 font-normal leading-relaxed text-center">
                      <Trans>RegForm.login-intro-des</Trans>
                    </p>
                    <div className="w-full md:pl-0 my-6">
                      <button
                        id={"LoginForm_LoginButton"}
                        className="btn btn-primary rounded-lg w-full py-2 px-4 text-center loading"
                        disabled={isLoading}>
                        <div>
                          <Trans>RegForm.login-loading</Trans>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="md:px-12 lg:px-20 px-4 md:py-12 md:mb-10 w-full md:max-w-3xl">
            {forgotPassword ? (
              <ResetPasswordForm setForgotPassword={setForgotPassword} />
            ) : (
              <>
                <img className="w-28 mx-auto mb-4 md:hidden block" src={logo} />
                <div>
                  {/* <img className="md:hidden block w-28 mx-auto mb-4" src={logo} /> */}
                  <h2 className="text-3xl font-semibold mb-4">
                    <Trans>RegForm.login</Trans>
                  </h2>
                  <p className="text-sm text-gray-400">
                    <Trans>RegForm.welcome-message</Trans> 👋🏻
                  </p>
                </div>
                <form className="py-8 text-base leading-6 space-y-4 text-gray-600 text-md sm:text-lg sm:leading-7">
                  {requiredLogin && (
                    //RequireLoginAlert
                    <CustomAlert
                      title={"RequireLoginAlert.title"}
                      alertType={ALERT_TYPE.INFO}
                      buttonType={{
                        type: BUTTON_TYPE.NONE,
                      }}
                      description={"RequireLoginAlert.des"}
                      icon={faCheck}
                    />
                  )}

                  <div className="flex flex-col">
                    <label className="leading-loose font-medium">
                      <Trans>RegForm.email</Trans>
                    </label>
                    <input
                      id={"LoginForm_Email"}
                      type="text"
                      className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                      placeholder={t("RegForm.email-placeholder")}
                      disabled={isLoading}
                      value={loginCredentials.email}
                      onChange={(e) =>
                        setLoginCredentials({
                          ...loginCredentials,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="leading-loose font-medium">
                      <Trans>RegForm.password</Trans>
                    </label>
                    <input
                      id={"LoginForm_Password"}
                      type="password"
                      className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                      placeholder={t("RegForm.password-placeholder")}
                      disabled={isLoading}
                      value={loginCredentials.password}
                      onChange={(e) =>
                        setLoginCredentials({
                          ...loginCredentials,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>

                  {!window.location.toString().includes("link") && (
                    <div>
                      <a
                        id={"LoginForm_ForgotPassword"}
                        className="text-primary font-medium px-2 text-xs text-right float-right py-2 cursor-pointer"
                        onClick={() => setForgotPassword(true)}>
                        <Trans>RegForm.forget-password</Trans>
                      </a>
                    </div>
                  )}

                  <button
                    id={"LoginForm_LoginButton"}
                    className={[
                      "btn btn-primary rounded-lg w-full py-2 px-4 text-center normal-case",
                      isLoading ? " loading" : "",
                    ].join("")}
                    disabled={isLoading}
                    onClick={(e) => loginHandler(e)}>
                    {isLoading ? (
                      <div>
                        <Trans>RegForm.login-loading</Trans>
                      </div>
                    ) : (
                      <div>
                        <Trans>RegForm.login</Trans>
                      </div>
                    )}
                  </button>

                  {status !== "" && (
                    //ErrorAlerts
                    <CustomAlert
                      title={errorStatus()}
                      alertType={ALERT_TYPE.ERROR}
                      buttonType={{
                        type: BUTTON_TYPE.NONE,
                      }}
                      icon={faCircleXmark}
                      visible={true}
                    />
                  )}
                </form>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Login
