import { doc, getFirestore, setDoc } from "firebase/firestore"
import firebaseApp from "../config/firebase"
const db = getFirestore(firebaseApp)

export function createNewJob(jobID, data) {
  return new Promise(async (resolve, reject) => {
    await setDoc(doc(db, "Job", jobID), data)
      .then((res) => {
        console.log(res)
        resolve({
          success: true,
        })
      })
      .catch((err) => {
        resolve({
          success: false,
        })
      })
  })
}
