import React from "react"
import FollowersCountSlider from "./FollowersCountSlider"
import IsVerifiedToggle from "./IsVerifiedToggle"
import EngagementRateSlider from "./EngagementRateSlider"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import IsPublishToggle from "./IsPublishToggle"
import {
  returnSearchConditionParam,
  searchConditionType,
  searchPageQuery,
} from "../../Utils/QueryHelper"
import { getUserLang } from "../../Utils/utiltyHelper"
import { useHistory } from "react-router-dom"

type PropsType = {
  metaData?: any
  queryPushFn: (query: any) => void
}

const SearchConditionBox: React.FC<PropsType> = ({ metaData, queryPushFn }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const setCondition = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: state.key,
        value: state.value,
      },
    })
  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })
  const queryLoader = (query: searchConditionType) => {
    history.push({
      pathname: "/" + getUserLang() + "/admin/list-kol",
      search: "?" + returnSearchConditionParam(query, paramList),
    })
  }
  let paramsQuery = searchPageQuery(paramList)
  const returnQueryFn = metaData ? queryLoader : queryPushFn
  return (
    <div>
      <FollowersCountSlider
        followersMin={paramsQuery.followersMin}
        followersMax={paramsQuery.followersMax}
        queryPushFn={returnQueryFn}
        setCondition={setCondition}
      />
      <EngagementRateSlider
        engagementMin={paramsQuery.engagementMin}
        engagementMax={paramsQuery.engagementMax}
        queryPushFn={returnQueryFn}
        setCondition={setCondition}
      />
      <IsVerifiedToggle
        verified={paramsQuery.verified}
        queryPushFn={returnQueryFn}
        setCondition={setCondition}
      />
      <IsPublishToggle
        published={paramsQuery.published}
        queryPushFn={returnQueryFn}
        setCondition={setCondition}
      />
    </div>
  )
}
export default SearchConditionBox
