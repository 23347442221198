export function timeStampToString(ts) {
  const date = new Date(ts * 1000)
  return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
}

export function dateObjToString(date) {
  return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
}

export function timeStampToDateTimeString(ts) {
  const date = new Date(ts * 1000)
  return (
    date.getFullYear() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getDate() +
    ", " +
    (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) +
    ":" +
    (date.getMinutes() === 0 ? "00" : date.getMinutes()) +
    (date.getHours() > 12 ? " PM" : " AM")
  )
}

export function timeStampToDateString(ts) {
  const date = new Date(ts * 1000)
  return date.getDate() + "/" + (date.getMonth() + 1)
}

export function timestraptoIOStime(st_second) {
  return new Date(st_second * 1000).toISOString()
}

export function timeStampToDisplayTimeString(ts) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const date = new Date(
    ts.hasOwnProperty("seconds") ? ts.seconds * 1000 : ts * 1000
  )
  return (
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    ", " +
    date.getFullYear() +
    " | " +
    (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) +
    ":" +
    (date.getMinutes() === 0
      ? "00"
      : date.getMinutes() < 10
      ? "0" + date.getMinutes().toString()
      : date.getMinutes()) +
    (date.getHours() > 12 ? " PM" : " AM")
  )
}

export function titleLangConverter(article, lang) {
  if (lang === "en" || article.titleCHI === "") {
    return article.title
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .replace(/(([^\s]+\s\s*){80})(.*)/, "$1…")
  } else {
    return article.titleCHI
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .replace(/(([^\s]+\s\s*){80})(.*)/, "$1…")
  }
}

export function contentLangConverter(article, lang) {
  if (lang === "en" || article.contentCHI === "") {
    return article.content
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .substring(1, 500)
  } else {
    return article.contentCHI
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .substring(1, 210)
  }
}

export function descriptionLangConverter(article, lang) {
  if (lang === "en" || article.titleCHI === "") {
    return article.content
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .substring(1, 200)
  } else {
    return article.contentCHI
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .substring(1, 80)
  }
}

export function restructureContent(content, tags, isEditArticle) {
  let newSeoKeywords = []
  if (Array.isArray(tags)) {
    tags.map((kw) => {
      if (!kw.hasOwnProperty("value")) {
        if (kw.includes("/")) {
          if (kw.split("/")[0].includes("-")) {
            newSeoKeywords.push(kw.split("/")[0])
            newSeoKeywords.push(kw.split("/")[0].split("-").join(""))
          } else {
            newSeoKeywords.push(kw.split("/")[0])
          }
        } else {
          newSeoKeywords.push(kw)
        }
      } else {
        newSeoKeywords.push(kw.value)
      }
    })
  }

  let newContent = content
  const matchPArr = newContent.match(/<\s*p[^>]*>(.*?)<\/p>/g)
  if (Array.isArray(matchPArr)) {
    matchPArr.map((pContent) => {
      let pContentCopy = pContent
      const matchImg = pContent.match(/<img [^>]*src="[^"]*"[^>]*>/gm)
      if (Array.isArray(matchImg)) {
        matchImg.map((imgContent) => {
          pContentCopy = pContentCopy.replace(imgContent, "")
        })
      }
      let newPString = ""
      if (Array.isArray(matchImg)) {
        matchImg.map((imgContent) => {
          newPString +=
            "<div>" +
            imgContent.replace(
              "<img src",
              '<img  class="lazyload" alt=\'' +
                newSeoKeywords.join(",") +
                (isEditArticle ? "' src" : "' data-src")
            ) +
            "</div>"
        })
      }
      newPString += pContentCopy
      newContent = newContent.replace(pContent, newPString)
    })
  }
  return newContent
}

export function timeAgo(date) {
  if (typeof date !== "undefined") {
    const dateAgo = (new Date().valueOf() / 1000 - date) / (60 * 60 * 24)
    let resStr = ""
    if (dateAgo > 30 && dateAgo <= 365) {
      resStr =
        Math.floor(parseInt(dateAgo / 30)).toString() +
        (Math.floor(parseInt(dateAgo / 30)) === 1 ? " month" : " months") +
        " ago"
    } else if (dateAgo > 365) {
      resStr =
        Math.floor(parseInt(dateAgo / 365)).toString() +
        (Math.floor(parseInt(dateAgo / 365)) === 1 ? " year" : " years") +
        " ago"
    } else if (dateAgo > 0 && dateAgo < 1) {
      if (new Date().valueOf() / 1000 - date < 3600) {
        resStr = Math.floor(
          (new Date().valueOf() / 1000 - date) / 60
        ).toString()
        resStr +=
          Math.floor((new Date().valueOf() / 1000 - date) / 60) === 1
            ? " minute"
            : " minutes" + " ago"
      } else {
        resStr =
          Math.floor((new Date().valueOf() / 1000 - date) / 3600).toString() +
          (Math.floor((new Date().valueOf() / 1000 - date) / 3600) === 1
            ? " hour"
            : " hours") +
          " ago"
      }
    } else {
      resStr =
        Math.floor(dateAgo).toString() +
        (Math.floor(parseInt(dateAgo)) === 1 ? " day" : " days") +
        " ago"
    }
    return resStr
  } else {
    return "Just Now"
  }
}

export function sizeConverter(sizeByte) {
  if (sizeByte > 1073741824) {
    return Math.round((sizeByte / 1024 / 1024 / 1024) * 100) / 100 + "GB"
  } else if (sizeByte > 1048576) {
    return Math.round((sizeByte / 1024 / 1024) * 100) / 100 + "MB"
  } else if (sizeByte > 1024) {
    return Math.round((sizeByte / 1024) * 100) / 100 + "KB"
  } else {
    return Math.round(sizeByte * 100) / 100 + "B"
  }
}

export function servicePlanToSelectObj(sp) {
  let newSp = []
  if (Array.isArray(sp)) {
    sp.map((e) => {
      newSp.push({
        label: e.title + "(~ HKD " + e.price + ")",
        value: e.id,
      })
    })
  }
  newSp.push({
    label: "[自定 Custom]",
    value: "custom",
  })
  return newSp
}

export function intToFloat(num, decPlaces) {
  const number = num.toFixed(decPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  return number
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function jsxComponentToString(jsx) {
  if (typeof jsx !== "string") {
    if (Array.isArray(jsx)) {
      let el = ""
      jsx.map((e) => {
        el = el += jsxComponentToString(e)
      })
      return el
    } else if (
      jsx.props.children !== null &&
      (jsx.type === "h2" || jsx.type === "h3")
    ) {
      return jsxComponentToString(jsx.props.children)
    } else {
      return ""
    }
  } else {
    return jsx
  }
}

export const followersCount = (fCount) => {
  if (fCount > 9999 && fCount < 100000) {
    let num = fCount / 1000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 1)
    return num + "K"
  } else if (fCount >= 100000 && fCount < 1000000) {
    let num = fCount / 1000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 3)
    return num + "K"
  } else if (fCount >= 1000000 && fCount < 10000000) {
    let num = fCount / 1000000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 1)
    return num + "M"
  } else if (fCount >= 10000000) {
    let num = fCount / 1000000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 1)

    return num + "M"
  } else {
    return fCount
  }
}
