import { combineReducers } from "redux"
import LoginManager from "./LoginManager"
import SystemManager from "./SystemManager"
import setModalController from "./ModalController"
import ToolsManager from "./ToolsManager"
import AdminControlManager from "./AdminControlManager"
import MemberManager from "./MemberManager"
import ArticleManager from "./ArticleManager"
import QueryManager from "./QueryManager"

const rootReducer = combineReducers({
  firebase: LoginManager,
  setModalController: setModalController,
  SystemManager: SystemManager,
  ToolsManager: ToolsManager,
  AdminControlManager: AdminControlManager,
  MemberManager: MemberManager,
  ArticleManager: ArticleManager,
  QueryManager: QueryManager,
})
export default rootReducer
