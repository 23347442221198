import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { CompareItem } from "../../../redux/reducer/ToolsManager"
import { CompareGroupCard } from "../../../page-components/tools/CompareGroupCard"
import { getUserLang } from "../../../Utils/utiltyHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"

export const CompareKOL: React.FC = () => {
  const dispatch = useDispatch()

  const isLoadedCompareGroup = useSelector((state: any) => {
    return state.ToolsManager.isLoadedCompareGroup
  })

  const compareGroup = useSelector((state: any) => {
    return state.ToolsManager.compareGroup
  })

  const createCompareGroup = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CREATE_COMPARE_GROUP_MODAL,
      returnFn: null,
      metaData: {},
    })
  }
  return (
    <div className="relative max-w-7xl mx-auto pt-16 md:pt-4 pb-36 relative px-4">
      <div className="hidden md:inline">
        <h1 className="text-grey-800 text-3xl font-bold leading-tight  ">
          <Trans>SearchKOL.compare-kol-statistics</Trans>
        </h1>
        <hr className="mb-4" />
      </div>

      <section className="my-8">
        <div className="text-lg font-bold text-gray-800">
          <Trans>CompareKOL.selected-group</Trans>
        </div>
        {isLoadedCompareGroup ? (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 mt-4">
            {compareGroup.map((cg: CompareItem, index: number) => {
              const nonEmptyCG = { ...cg, data: cg.data.filter((n: any) => n) }
              return (
                <CompareGroupCard
                  key={"cg" + nonEmptyCG.id}
                  data={nonEmptyCG}
                />
              )
            })}
          </div>
        ) : (
          <Link to={"/" + getUserLang() + "/tools?type=search"}>
            <div className="p-2 rounded-lg bg-red-default shadow my-4 text-white">
              <Trans>CompareKOL.group-not-found</Trans>
              <Trans>CompareKOL.create-your-own-group</Trans>
            </div>
          </Link>
        )}

        <button
          className="btn btn-primary w-full my-4"
          onClick={() => createCompareGroup()}>
          <Trans>CreateNewCompareGroup.title</Trans>
        </button>
      </section>
    </div>
  )
}
