import React, { useEffect, useState } from "react"
import KOL, { KOLDefault } from "../Model/KOL"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { Trans, useTranslation } from "react-i18next"
import { returnSelectedItem, returnSelectedItems } from "../Utils/TypeList"
import { useHistory } from "react-router-dom"
import { KOLCombine } from "../Model/KOLCombine"
import { getKOL } from "../HelpingFunction/KOL/kolDBHelper"

interface RecommendProps {
  data: KOLCombine
  topCateItem: singlePercentageItem[]
}

interface singlePercentageItem {
  item: string
  percentage: number
}

interface singlePercentageItemPage {
  count: number
  item: string
  percentages: number
}

const KOLRecommendationsProfile: React.FC<RecommendProps> = ({
  data,
  topCateItem,
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [kol, setKOL] = useState<KOL>(KOLDefault)

  useEffect(() => {
    getKOL(data.id).then((result) => {
      if (result.success) {
        setKOL(result.data)
      }
    })
  }, [])

  const getTopThreeCateItemPercentage = (
    percentages: singlePercentageItemPage[],
    topCateItem: singlePercentageItem[]
  ): { label: string; percentage: number }[] => {
    let data: { label: string; percentage: number }[] = []
    topCateItem.map((cate, index) => {
      if (index < 2) {
        const foundObj = percentages.find((e) => e.item === cate.item)
        if (foundObj) {
          data.push({
            /*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
            /*@ts-ignore*/
            label: returnSelectedItem(cate.item).label,
            percentage: parseFloat(foundObj.percentages.toFixed(2)) * 100,
          })
        }
      }
    })

    return data
  }

  if (isLoaded) {
    return (
      <section className="mx-4 w-full my-2 bg-white">
        {/* KOL Information*/}
        <div className="indicator indicator-end indicator-top">
          <section className="w-96  bg-white shadow-md border border-gray-100 rounded-xl p-4">
            <div className="mx-auto inline-block w-full mb-4">
              <div className="w-24 h-24 xl:w-28 xl:h-28 rounded-full mx-auto text-center">
                <img
                  className="object-cover w-24 h-24 xl:w-28 xl:h-28 rounded-full"
                  alt={data.userName + "avatar image"}
                  src={
                    kol.avatarUrl
                      ? kol.avatarUrl
                      : "https://look4kol.com/logo.png"
                  }
                />
              </div>
            </div>

            {/*  KOL username and score*/}
            <div className="text-center mx-auto">
              <div className="flex justify-center">
                <h1 className="w-36	 inline-block font-semibold text-lg lg:text-2xl p-0 my-2 text-ellipsis overflow-hidden">
                  {data.userName}
                </h1>
                <span
                  className="inline-block cursor-pointer mt-2"
                  onClick={() =>
                    window.open("https://instagram.com/" + data.userName)
                  }>
                  <svg
                    className="svg-inline--fa fa-instagram  w-5 h-5 ml-2"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg="">
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                  </svg>
                </span>
              </div>
            </div>

            {/* KOL category tag*/}
            {getTopThreeCateItemPercentage(data.percentages, topCateItem).map(
              (e, index) => {
                return (
                  <div
                    className="rounded-xl bg-gray-100 text-sm p-2 my-2"
                    key={index}>
                    {e.label} ( {e.percentage} % )
                    <progress
                      className="progress progress-warning w-full"
                      value={e.percentage}
                      max="100"></progress>
                  </div>
                )
              }
            )}

            <button
              className="btn btn-primary w-full rounded-2xl mt-4"
              onClick={() =>
                window.open("https://instagram.com/" + data.userName)
              }>
              <Trans>SimilarKOL.visit</Trans>
            </button>
          </section>
        </div>
      </section>
    )
  }
  return <div>Loading...</div>
}

export default KOLRecommendationsProfile
