import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath } from "../../Utils/HelpingFunction"

export interface ContactList {
  name: string
  id: string
  contact_count: number
}

export interface DesignList {
  id: string
  name: string
  thumbnail_url: string
  created_at: string
}

const sendEmailToList = async (
  designID: string,
  listID: string,
  name: string
): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/mail"), {
        method: "POST",
        body: JSON.stringify({
          name: name,
          list_id: [listID],
          design_id: designID,
        }),
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    })
  })
}

const getContactList = async (): Promise<
  { success: true; contacts: ContactList[] } | { success: false }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/mail/contacts"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve({
            success: true,
            contacts: finalResponse.data.result,
          })
        })
        .catch((e) => {
          console.log(e)
          return resolve({
            success: false,
          })
        })
    })
  })
}

const getDesignList = async (): Promise<
  { success: true; designs: DesignList[] } | { success: false }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/mail/designs"), {
        method: "GET",
        headers: { "Content-Type": "application/json", idToken: res.token },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve({
            success: true,
            designs: finalResponse.data.result,
          })
        })
        .catch((e) => {
          console.log(e)
          return resolve({
            success: false,
          })
        })
    })
  })
}

export { getContactList, getDesignList, sendEmailToList }
