import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  KOLSearchDefault,
  KOLSearchType,
} from "../../redux/reducer/ToolsManager"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import {
  CONDITION_BADGES_TYPE,
  returnConditionBadgesType,
} from "../../Model/ConditionBadge"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "react-i18next"
import { KOL_GENDER } from "../../Utils/KOLDefaultData"
import { getAllCountries, getCountry } from "../../Utils/Countries"
import Select from "react-select"
import MultiRangeSlider from "../../Component/MultiRangeSlider/MultiRangeSlider"
import { generalType, genreGroupData } from "../../Utils/TypeList"
import { queryType } from "../../Utils/QueryHelper"
import { getUserLang } from "../../Utils/utiltyHelper"
import { compareConditionType } from "../../pages/admin/tools/kol"

interface PropsType {
  toggleConditionDropDown: Dispatch<SetStateAction<boolean>>
  queryPushFn: (query: compareConditionType) => void
}

export const SearchSetting: React.FC<PropsType> = ({
  toggleConditionDropDown,
  queryPushFn,
}) => {
  const dispatch = useDispatch()

  const [tempCondition, setTempCondition] =
    useState<KOLSearchType>(KOLSearchDefault)

  const step: CONDITION_BADGES_TYPE = useSelector((state: any) => {
    return state.ToolsManager.step
  })

  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })

  useEffect(() => {
    console.log("searchKOLCondition", searchKOLCondition)
    setTempCondition(searchKOLCondition)
  }, [searchKOLCondition])

  const setCondition = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: state.key,
        value: state.value,
      },
    })

  const setStep = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_STEP,
      data: state,
    })

  // main page
  switch (step) {
    case CONDITION_BADGES_TYPE.USER:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.name</Trans>
          </div>
          <input
            type="text"
            className="input input-bordered w-full max-w-xs"
            placeholder={"Enter..."}
            value={tempCondition.userName}
            onChange={(e) => {
              setTempCondition({
                ...tempCondition,
                userName: e.target.value,
              })
            }}
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              const regex = / /gi
              setCondition({
                key: "searchStr",
                value: tempCondition.userName.replaceAll(regex, ""),
              })
              queryPushFn({
                searchID: tempCondition.userName.replaceAll(regex, ""),
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.GENDER:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.gender</Trans>
          </div>
          <select
            className="select select-bordered w-full max-w-xs"
            onChange={(event: any) => {
              setTempCondition({
                ...tempCondition,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                gender: KOL_GENDER[event.target.selectedIndex].value,
              })
            }}>
            {KOL_GENDER.map((e, index) => {
              return <option key={"index" + index}>{e.label}</option>
            })}
          </select>
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "gender",
                value: tempCondition.gender,
              })
              queryPushFn({
                gender: tempCondition.gender,
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.TYPE || CONDITION_BADGES_TYPE.GENRE:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.category-and-genre</Trans>
          </div>

          {/*
            Category
          */}
          <label>
            <Trans>SearchKOLConditionSetting.category</Trans>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            onChange={(event: any) => {
              setTempCondition({
                ...tempCondition,
                genreCategory:
                  generalType[event.target.selectedIndex - 1].value,
              })
            }}>
            <option
              disabled
              selected={!tempCondition.genreCategory}
              key={"index-default"}>
              ---
            </option>
            {generalType.map((e, index) => {
              return (
                <option
                  key={"index" + index}
                  selected={e.value === tempCondition.genreCategory}>
                  {getUserLang() === "hk" ? e.labelCHI : e.label}
                </option>
              )
            })}
          </select>

          {/*
            Genre
          */}

          {tempCondition.genreCategory !== "" && (
            <>
              <label>
                <Trans>SearchKOLConditionSetting.genre</Trans>
              </label>
              <select
                className="select select-bordered w-full max-w-xs"
                onChange={(event: any) => {
                  setTempCondition({
                    ...tempCondition,
                    genre:
                      genreGroupData()[
                        parseInt(tempCondition.genreCategory) - 1
                      ].options[event.target.selectedIndex - 1].value,
                  })
                }}>
                <option
                  disabled
                  selected={!tempCondition.genre}
                  key={"index-default"}>
                  ---
                </option>
                {genreGroupData()[
                  parseInt(tempCondition.genreCategory) - 1
                ].options.map((e, index) => {
                  return (
                    <option
                      key={"index" + index}
                      selected={e.value === tempCondition.genre}>
                      {e.label}
                    </option>
                  )
                })}
              </select>
            </>
          )}

          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "genreCategory",
                value: tempCondition.genreCategory,
              })

              setCondition({
                key: "genre",
                value: tempCondition.genre,
              })
              queryPushFn({
                genreCategory: tempCondition.genreCategory,
                genre: tempCondition.genre,
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.NATIONALITY:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.area</Trans>
          </div>
          <Select
            className=" m-2 mt-4"
            options={getAllCountries()}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={{
              value: tempCondition.nationality,
              label: getCountry(tempCondition.nationality).label,
            }}
            onChange={(e) => {
              if (e) {
                setTempCondition({
                  ...tempCondition,
                  nationality: e.value as string,
                })
              }
            }}
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "nationality",
                value: tempCondition.nationality,
              })
              queryPushFn({
                nationality: tempCondition.nationality,
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.IS_FACE:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.is-face</Trans>
          </div>
          <input
            type="checkbox"
            checked={tempCondition.isFace === "" ? false : tempCondition.isFace}
            className="checkbox"
            onChange={(e) => {
              setTempCondition({
                ...tempCondition,
                isFace: e.target.checked,
              })
            }}
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "isFace",
                value: tempCondition.isFace,
              })
              // queryPushFn({
              //   isFace: tempCondition.isFace ? "true" : "false",
              // })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.IS_LOOK4KOL_REG:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.is-look4kol-reg</Trans>
          </div>
          <input
            type="checkbox"
            checked={
              tempCondition.isLook4kolReg === ""
                ? false
                : tempCondition.isLook4kolReg
            }
            className="checkbox"
            onChange={(e) => {
              setTempCondition({
                ...tempCondition,
                isLook4kolReg: e.target.checked,
              })
            }}
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "isLook4kolReg",
                value: tempCondition.isLook4kolReg,
              })
              queryPushFn({
                isLook4kolReg: tempCondition.isLook4kolReg ? "true" : "false",
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.HAS_DATA:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.has-data</Trans>
          </div>
          <input
            type="checkbox"
            checked={
              tempCondition.hasData === "" ? false : tempCondition.hasData
            }
            className="checkbox"
            onChange={(e) => {
              setTempCondition({
                ...tempCondition,
                hasData: e.target.checked,
              })
            }}
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "hasData",
                value: tempCondition.hasData ? true : "",
              })
              queryPushFn({
                hasData: tempCondition.hasData ? "true" : "",
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.FOCUS_PERCENTAGE:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.focus-percentage</Trans>
          </div>
          <input
            type="text"
            className="input input-bordered w-full max-w-xs"
            placeholder={"Enter..."}
            value={tempCondition.focusPercentage}
            onChange={(e) =>
              setTempCondition({
                ...tempCondition,
                focusPercentage: e.target.value,
              })
            }
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "focusPercentage",
                value: tempCondition.focusPercentage,
              })
              queryPushFn({
                focusPercentage: tempCondition.focusPercentage,
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.PRICE_RANGE:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.price-range</Trans>
          </div>
          <MultiRangeSlider
            min={0}
            max={1000000}
            onUpdate={({ min, max }: { min: number; max: number }) => {
              setTempCondition({
                ...tempCondition,
                priceMin: min,
                priceMax: max,
              })
            }}
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "priceMin",
                value: tempCondition.priceMin,
              })
              setCondition({
                key: "priceMax",
                value: tempCondition.priceMax,
              })

              queryPushFn({
                priceMin: tempCondition.priceMin.toString(),
                priceMax: tempCondition.priceMax.toString(),
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    case CONDITION_BADGES_TYPE.FOLLOWER_COUNT:
      return (
        <div>
          <div className="my-2 text-gray-400 text-md">
            <Trans>SearchKOLConditionSetting.no-of-follower</Trans>
          </div>
          <MultiRangeSlider
            min={0}
            max={1000000}
            onUpdate={({ min, max }: { min: number; max: number }) => {
              setTempCondition({
                ...tempCondition,
                followerMin: min,
                followerMax: max,
              })
            }}
          />
          <button
            className="btn btn-sm block my-2"
            onClick={(e) => {
              setCondition({
                key: "followerMin",
                value: tempCondition.followerMin,
              })
              setCondition({
                key: "followerMax",
                value: tempCondition.followerMax,
              })
              queryPushFn({
                followerMin: tempCondition.followerMin.toString(),
                followerMax: tempCondition.followerMax.toString(),
              })
              toggleConditionDropDown(false)
            }}>
            <Trans>SearchKOLConditionSetting.apply</Trans>
          </button>
        </div>
      )
    default:
      return (
        <div className="menu bg-base-100 w-full rounded-box">
          {returnConditionBadgesType().map((item, index) => {
            return (
              <button
                key={index + "bady"}
                className="justify-start btn btn-md btn-ghost gap-2"
                onClick={() => setStep(item.type)}>
                <FontAwesomeIcon icon={item.icon} />
                {item.label}
              </button>
            )
          })}
        </div>
      )
  }
}
