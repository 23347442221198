import {
  IG_USER_DATA,
  IG_USER_PROFILE_DATA,
  NEW_KOL,
} from "../../Enum/LOGIN_PAGE_TYPE"
import { getAPIPath } from "../../Utils/HelpingFunction"
import KOL, { KOLType } from "../../Model/KOL"
import { CaseStudy } from "../../Model/CaseStudy"
import { Plan } from "../../Model/Plan"
import { Balance, Bank } from "../../Model/Bank"
import {
  createNewProduct,
  updateProduct,
} from "../EditKOLProfile/EditKOLProfile"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../config/firebase"
import { KOLFollowersPercentage } from "../../Model/KOLFollowersPercentage"
import { KOLSearchType } from "../../redux/reducer/ToolsManager"

const db = getFirestore(firebaseApp)

export interface kolCrawlerFollowerData {
  numOfPosts: number
  numOfFollowers: number
  numOfFollowing: number
  averageEngagementRate: number
  imagesList: []
}

export const kolCrawlerFollowerDataDefault: kolCrawlerFollowerData = {
  numOfPosts: 0,
  numOfFollowers: 0,
  numOfFollowing: 0,
  averageEngagementRate: 0,
  imagesList: [],
}

const setPayoutRequest = (
  uid: string,
  withdrawAmount: number,
  bankDetail: Bank
): Promise<
  | {
      success: true
      message: string
    }
  | {
      success: false
      message: string
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getAPIPath("/api/kol/" + uid + "/payout"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          withdrawAmount: withdrawAmount,
          bankDetail: bankDetail,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: finalResponse.success,
              message: "",
            })
          }
          return resolve({
            success: finalResponse.success,
            message: finalResponse.message,
          })
        })
    })
  })
}

const getKOLPlan = (
  uid: string,
  planID: string
): Promise<
  | {
      success: true
      data: Plan
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "KOL", uid, "Plan", planID))
      .then((doc: any) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            },
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getKOLPlans = (
  uid: string,
  isKOL: boolean
): Promise<
  | {
      success: true
      data: Plan[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "KOL", uid, "Plan")

    // if (!isKOL) {
    //   dbRef = query(dbRef, where("isVisible", "==", true))
    // }

    await getDocs(query(dbRef, limit(3)))
      .then((docs: any) => {
        if (!docs.empty) {
          let allPlan: Plan[] = []
          docs.forEach((doc: any) => {
            allPlan.push({
              id: doc.id,
              ...doc.data(),
            } as Plan)
          })
          return resolve({
            success: true,
            data: allPlan,
          })
        }
        return resolve({
          success: true,
          data: [],
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getKOLWallet = (
  uid: string
): Promise<
  | {
      success: true
      data: Balance
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/kol/" + uid + "/balance"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const setWalletBank = (
  uid: string,
  bankDetail: Bank
): Promise<
  | {
      success: true
      data: Balance
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/kol/" + uid + "/bank"), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          bankDetail: bankDetail,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const getWalletBank = (
  uid: string
): Promise<
  | {
      success: true
      data: Bank
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/kol/" + uid + "/bank"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data as Bank,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const setCaseStudy = (
  kolID: string,
  cs: CaseStudy
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await setDoc(doc(db, "KOL", kolID, "CaseStudy", cs.id), cs)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const updateCaseStudy = (
  kolID: string,
  cs: CaseStudy
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "KOL", kolID, "CaseStudy", cs.id), { cs })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const deleteCaseStudy = (
  kolID: string,
  cs: CaseStudy
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await deleteDoc(doc(db, "KOL", kolID, "CaseStudy", cs.id))
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const editKOLPlan = async (
  kolID: string,
  plan: Plan
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    let id = plan.id
    let isPriceChange = plan.isPriceChange
    delete plan.isPriceChange

    const updateProductState = await updateProduct(plan, kolID, isPriceChange)
    if (updateProductState.success) {
      await updateDoc(doc(db, "KOL", kolID, "Plan", id), {
        ...plan,
        priceID: isPriceChange
          ? updateProductState.data.newPrice.id
          : plan.priceID,
      })
        .then((res) => {
          return resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    }
  })
}

const removeKOLPlan = (
  kolID: string,
  planID: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await deleteDoc(doc(db, "KOL", kolID, "Plan", planID))
      .then((re) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const setKOLPlan = (
  kolID: string,
  plan: Plan
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    let p = plan
    let id = p.id
    delete p.isNew
    delete p.isPriceChange

    const createProductState = await createNewProduct(plan, kolID)
    if (createProductState.success) {
      await setDoc(doc(db, "KOL", kolID, "Plan", id), {
        ...p,
        priceID: createProductState.data.newPrice.id,
      })
        .then((res) => {
          return resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    }
  })
}

const saveKOL = (
  requestUID: string,
  kolProfile: KOLType,
  plans?: Plan[],
  caseStudies?: CaseStudy[],
  igUserData?: IG_USER_DATA
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    try {
      // Save KOL Plan
      const planPromise: { success: boolean } = await new Promise(
        (resolve1) => {
          let hasPlanError =
            typeof plans?.find((e) => e.hasError) !== "undefined"

          if (Array.isArray(plans) && !hasPlanError) {
            let allPromise: any = []
            plans.map(async (e: Plan) => {
              if (e.isNew) {
                allPromise.push(await setKOLPlan(kolProfile.id, e))
              } else if (e.isRemoved) {
                allPromise.push(await removeKOLPlan(kolProfile.id, e.id))
              } else {
                allPromise.push(await editKOLPlan(kolProfile.id, e))
              }
            })

            Promise.all(allPromise).then((res) => {
              resolve1({
                success: true,
              })
            })
          } else {
            resolve1({
              success: false,
            })
          }
        }
      )

      //case study
      const caseStudyPromise: { success: boolean } = await new Promise(
        (resolve1) => {
          if (Array.isArray(caseStudies)) {
            let allPromise: any = []
            caseStudies.map(async (e: CaseStudy) => {
              let eAction = e.action
              let cs = { ...e }
              delete cs.action
              if (eAction === "add") {
                allPromise.push(await setCaseStudy(kolProfile.id, cs))
              } else if (eAction === "update") {
                allPromise.push(await updateCaseStudy(kolProfile.id, cs))
              } else if (eAction === "delete") {
                allPromise.push(await deleteCaseStudy(kolProfile.id, cs))
              }
            })

            Promise.all(allPromise).then((res) => {
              return resolve1({
                success: true,
              })
            })
          } else {
            return resolve1({
              success: true,
            })
          }
        }
      )

      if (planPromise.success && caseStudyPromise.success) {
        returnIdTokenResult().then(async (res) => {
          await fetch(getAPIPath("/api/kol/" + kolProfile.id), {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              idToken: res.token,
            },
            body: JSON.stringify({
              ...kolProfile,
              igUserData: igUserData,
            }),
          })
            .then((res) => res.json())
            .then((finalResponse) => {
              if (finalResponse.success) {
                return resolve({
                  success: true,
                })
              }
              return resolve({
                success: false,
              })
            })
            .catch((err) => console.log(err))
        })
      }
    } catch (e) {
      return resolve({
        success: false,
      })
    }
  })
}

/**
 * Get kol profile by kolID
 * @param kolID , allow uid or username
 */
const getKOL = (
  kolID: string
): Promise<
  | {
      success: true
      data: KOLType
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "KOL", kolID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: new KOL({
              id: doc.id,
              ...doc.data(),
            } as unknown as KOL),
          })
        }

        getDocs(
          query(collection(db, "KOL"), where("userName", "==", kolID), limit(1))
        ).then((docs) => {
          if (!docs.empty) {
            return resolve({
              success: true,
              data: new KOL({
                id: docs.docs[0].id,
                ...docs.docs[0].data(),
              } as KOL),
            })
          }
          //if profile not found or not yet published
          return resolve({
            success: false,
          })
        })
      })
      .catch((err) => console.error(err))
  })
}

/**
 * Set KOL for registration
 * @param {string} uid - firebase uid
 * @param {NEW_KOL} data - data will be store in KOL/{:uid}
 */

const setKOL = (
  uid: string,
  data: NEW_KOL
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await setDoc(doc(db, "KOL", uid), data)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * Create a new KOL account using email and password
 * @param {NEW_KOL} newKOLUser - data for new kol user
 * @param {string} password - user password
 * @param {boolean} isVerified - whether this user is isVerified by ig login
 * @param {IG_USER_DATA} igUserData : [Optional] only while the kol connected the account with FB api
 */

const createKOL = (
  newKOLUser: NEW_KOL,
  password: string,
  isVerified: boolean,
  igUserData?: IG_USER_DATA
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/kol"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...newKOLUser,
        password: password,
        igUserData: igUserData,
        isVerified: isVerified,
      }),
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}

/**
 * Check whether this id is exists or not
 * @param {string} id - kol id
 * @return {Promise} - whether the kol is exists
 *
 */

const checkKOLIDExist = (
  id: string
): Promise<{
  success: boolean
  exists: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    await fetch(getAPIPath("/api/kol/" + id + "/username"), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        if (finalResponse.success) {
          if (finalResponse.userName !== "") {
            return resolve({
              success: true,
              exists: true,
            })
          }
          return resolve({
            success: true,
            exists: false,
          })
        }
        return resolve({
          success: false,
          exists: false,
        })
      })
  })
}

const getKOLFollowerGroup = async (
  kolID: string
): Promise<{
  success: boolean
  data: IG_USER_PROFILE_DATA
}> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/statistics/kol/" + kolID + "/igPreview"), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}
const getKOLUserName = (
  uid: string
): Promise<
  | {
      success: true
      userName: string
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    fetch(getAPIPath("/api/kol/" + uid + "/username"), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        if (finalResponse.success) {
          resolve({
            success: true,
            userName: finalResponse.userName,
          })
        } else {
          resolve({
            success: false,
          })
        }
      })
      .catch((err) => console.log(err))
  })
}

const getKOLDataFromCrawler = (
  uid: string,
  username: string
): Promise<
  | {
      success: true
      data: kolCrawlerFollowerData
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    if (username.length > 0) {
      returnIdTokenResult().then(async (res) => {
        fetch(
          getAPIPath(
            "/api/admin/crawler/kol/" +
              uid +
              "?" +
              new URLSearchParams({
                username: username,
              })
          ),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              idToken: res.token,
            },
          }
        )
          .then((res) => res.json())
          .then((finalResponse) => {
            if (finalResponse.success) {
              resolve({
                success: true,
                data: finalResponse.data,
              })
            } else {
              resolve({
                success: false,
              })
            }
          })
      })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}

/**
 * get case studies from KOL
 * @param {string} uid - firebase uid
 * @param {boolean} isKOL - whether the request user is the the kol same as the uid or not
 */

const getCaseStudies = (
  uid: string,
  isKOL: boolean
): Promise<
  | {
      success: true
      data: CaseStudy[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "KOL", uid, "CaseStudy")
    // if (isKOL) {
    //   dbRef = dbRef.where("isVisible", "==", true)
    // }

    await getDocs(dbRef)
      .then((docs: any) => {
        if (!docs.empty) {
          let caseStudies: CaseStudy[] = []
          docs.forEach((doc: any) => {
            caseStudies.push({
              id: doc.id,
              ...doc.data(),
            } as CaseStudy)
          })
          return resolve({
            success: true,
            data: caseStudies,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => console.log(err))
  })
}

interface KOLSearchCondition {
  uid: string | ""
  limit: string
  searchStr: string
  nationality: string | ""
  userName: string | ""
  focusPercentage: ""
  hasData: "true" | "false" | ""
  isFace: "true" | "false" | ""
  gender: "" | "1" | "2" | "3"
  type: string | ""
  genreCategory: string | ""
  genre: string | ""
  min: number | ""
  max: number | ""
  orderBy:
    | "desc(userName)"
    | "asc(userName)"
    | "desc(noOfFollowers)"
    | "asc(noOfFollowers)"
    | ""
  filters: ""
  page: ""
}

export const defaultKOLFollowerPercentageCondition: KOLSearchCondition = {
  uid: "",
  limit: "",
  searchStr: "",
  nationality: "",
  userName: "",
  focusPercentage: "",
  hasData: "",
  isFace: "",
  gender: "",
  type: "",
  genreCategory: "",
  genre: "",
  min: 0,
  max: 0,
  orderBy: "",
  filters: "",
  page: "",
}

const addToConditionArr = (con: object): Record<any, any> => {
  let condition = {}
  Object.keys(con).map((k: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (con[k] !== "") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      condition[k] = con[k]
    }
  })
  return condition
}

const getKOLFollowerPercentage = (
  userName: string
): Promise<
  | {
      success: true
      data: KOLFollowersPercentage[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/kol/percentage?" +
            new URLSearchParams({
              uid: "",
              limit: "",
              userName: userName as string,
              isFace: "",
              gender: "",
              targetArea: "",
              jobType: "",
              genreCategory: "",
              genre: "",
              min: "",
              max: "",
              orderBy: "",
              hasData: "",
              focusPercentage: "",
              filters: "",
              page: "",
            })
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data[0].percentages,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
    })
  })
}

const getKOLs = (
  isVerified: boolean,
  isPublished: boolean
): Promise<{ success: true; kolList: KOL[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "KOL")

    if (isVerified) {
      dbRef = query(dbRef, where("userName", "!=", ""))
    }
    if (isPublished) {
      dbRef = query(dbRef, where("isPublish", "==", true))
    }
    let kolList: KOL[] = []
    await getDocs(query(dbRef)).then((docs: any) => {
      if (!docs.empty) {
        docs.forEach((doc: any) => {
          kolList.push({ id: doc.id, ...doc.data() })
        })
        return resolve({
          success: true,
          kolList: kolList,
        })
      }
      return resolve({
        success: true,
        kolList: [],
      })
    })
  })
}

const updateKOL = (kolProfile: KOL): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/kol/" + kolProfile.id), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          ...kolProfile,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            console.log(finalResponse.message)
            return resolve({
              success: true,
            })
          }
          return resolve({
            success: false,
          })
        })

        .catch((err) => console.log(err))
    })
  })
}

const searchKOLAlgolia = (
  condition: KOLSearchType
): Promise<
  | {
      success: true
      data: any[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/kol?" + new URLSearchParams(addToConditionArr(condition))
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
              hasMore: finalResponse.hasMore,
            })
          }
          return resolve({
            success: true,
            data: [],
            hasMore: false,
          })
        })
    })
  })
}

const compareKOLAlgolia = (
  searchUserIDs: string,
  uid: string
): Promise<
  | {
      success: true
      data: any[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/kol/compare?" +
            new URLSearchParams({
              userNames: searchUserIDs,
              uid: uid,
            })
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
    })
  })
}

const generateKOLMetaDescription = (
  kolID: string
): Promise<{ success: true; desc: string } | { success: false }> => {
  return new Promise(async (resolve) => {
    const result = await getKOLFollowerGroup(kolID)
    if (result.success) {
      let data = {
        ...result.data,
        score: 0,
      }

      await fetch(getAPIPath("/api/admin/gpt/" + kolID), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              desc: finalResponse.desc,
            })
          }
          return resolve({
            success: false,
          })
        })
        .catch((err) => {
          console.log("catch:", err)
          return resolve({
            success: false,
          })
        })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}

export {
  setPayoutRequest,
  getKOLWallet,
  setWalletBank,
  getWalletBank,
  saveKOL,
  getKOL,
  getKOLPlan,
  getKOLPlans,
  setKOL,
  createKOL,
  getKOLFollowerGroup,
  checkKOLIDExist,
  getKOLUserName,
  getKOLDataFromCrawler,
  getCaseStudies,
  getKOLFollowerPercentage,
  getKOLs,
  updateKOL,
  searchKOLAlgolia,
  compareKOLAlgolia,
  generateKOLMetaDescription,
}
