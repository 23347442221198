export enum ADMIN_ROLE {
  UNKNOWN,
  ALL,
  SUPER,
  ACCOUNTS,
  MARKETING,
  BUSINESS,
  FRONTEND,
  BACKEND,
  QA,
  CRAWLER,
}

interface HomeFunction {
  title: string
  url: string
  role: ADMIN_ROLE[]
}

const AllHomeFunction: HomeFunction[] = [
  {
    title: "New Article",
    url: "/new-article",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.BUSINESS, ADMIN_ROLE.MARKETING],
  },
  {
    title: "List Article",
    url: "/list-article",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.BUSINESS, ADMIN_ROLE.MARKETING],
  },
  {
    title: "List KOL",
    url: "/list-kol",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.BUSINESS, ADMIN_ROLE.MARKETING],
  },
  {
    title: "List Customer",
    url: "/list-customer",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.BUSINESS, ADMIN_ROLE.MARKETING],
  },
  {
    title: "Email System",
    url: "/email-system",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.BUSINESS, ADMIN_ROLE.MARKETING],
  },
  {
    title: "Job Status",
    url: "/job",
    role: [ADMIN_ROLE.SUPER],
  },
  {
    title: "Stripe Status",
    url: "/stripe",
    role: [ADMIN_ROLE.SUPER],
  },
  {
    title: "Add KOL",
    url: "/add-kol",
    role: [ADMIN_ROLE.SUPER],
  },
  {
    title: "Upload follower CSV",
    url: "/add-follower",
    role: [ADMIN_ROLE.SUPER],
  },
  // {
  //   title: "Cloud Dashboard",
  //   url: "/cloud",
  //   role: [ADMIN_ROLE.SUPER],
  // },
  {
    title: "Search KOL stat",
    url: "/tools?type=search",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.MARKETING, ADMIN_ROLE.BUSINESS],
  },
  {
    title: "Compare KOLs",
    url: "/tools?type=compare",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.MARKETING, ADMIN_ROLE.BUSINESS],
  },
  {
    title: "Search Follower Stat",
    url: "/search-follower",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.MARKETING, ADMIN_ROLE.BUSINESS],
  },
  {
    title: "Admin Request",
    url: "/request",
    role: [ADMIN_ROLE.SUPER],
  },
  {
    title: "Registration Count",
    url: "/register",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.BUSINESS],
  },
  {
    title: "Admin Setting",
    url: "/setting",
    role: [ADMIN_ROLE.SUPER],
  },
  {
    title: "Crwaler and Neo4j",
    url: "/crawler",
    role: [ADMIN_ROLE.SUPER, ADMIN_ROLE.CRAWLER],
  },
  {
    title: "OPEN AI ChatGPT [TESTING]",
    url: "/chatgpt",
    role: [ADMIN_ROLE.ALL],
  },
  {
    title: "OPENAI Image Generate [TESTING]",
    url: "/image-generate",
    role: [ADMIN_ROLE.ALL],
  },
]

const returnHomeFn = (r: ADMIN_ROLE): HomeFunction[] => {
  return AllHomeFunction.filter(
    (e) => e.role.includes(r) || e.role.includes(ADMIN_ROLE.ALL)
  )
}

const getAdminRole = (roleString: string): ADMIN_ROLE => {
  switch (roleString) {
    case "administrator":
      return ADMIN_ROLE.SUPER
    case "accounts":
      return ADMIN_ROLE.ACCOUNTS
    case "marketing":
      return ADMIN_ROLE.MARKETING
    case "business":
      return ADMIN_ROLE.BUSINESS
    case "frontend":
      return ADMIN_ROLE.FRONTEND
    case "backend":
      return ADMIN_ROLE.BACKEND
    case "qa":
      return ADMIN_ROLE.QA
    case "crawler":
      return ADMIN_ROLE.CRAWLER
    default:
      return ADMIN_ROLE.UNKNOWN
  }
}

export { returnHomeFn, getAdminRole }
