import React, { useEffect, useState } from "react"
import KOLMiniCard from "../../../Component/KOLMiniCard"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { searchKOLAlgolia } from "../../../HelpingFunction/KOL/kolDBHelper"
import {
  getPromotedKOLIDs,
  setPromotedKOLIDs,
} from "../../../HelpingFunction/Admin/AdminDBHelper"
import { isAuth } from "../../../Utils/FirebaseUtils"
import {
  KOLSearchDefault,
  KOLSearchType,
} from "../../../redux/reducer/ToolsManager"
import { KOLCombine } from "../../../Model/KOLCombine"
import { DEFAULT_MAX_CONDITION } from "../../../Enum/KOL_SEARCH_TYPE"
import { getUserLang } from "../../../Utils/utiltyHelper"
import {
  returnSearchConditionParam,
  searchConditionType,
  searchPageQuery,
  searchParamsToCondition,
  verifiedUpdateDetector,
} from "../../../Utils/QueryHelper"
import {
  faChevronLeft,
  faChevronRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import KOLProfileComponent from "../../../page-components/list-kol/KOLProfileComponent"
import classNames from "classnames"

// eslint-disable-next-line max-statements
const KolID: React.FC = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const promotedKOLsID = useSelector((state: any) => {
    return state.AdminControlManager.promotedKOLsID
  })

  const isLoadedPromotedKOLsID = useSelector((state: any) => {
    return state.AdminControlManager.isLoadedPromotedKOLsID
  })

  const currentPage = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition.page
  })
  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const [isRefreshed, setIsRefreshed] = useState<boolean>(false)

  const [KOLList, setKOLList] = useState<KOLCombine[]>([])
  const [isLoadedKOLList, setIsLoadedKOLList] = useState<boolean>(false)

  const [hasMore, setHasMore] = useState<boolean>(false)

  useEffect(() => {
    if (KOLList.length === 0) {
      kolSelection()
    }
    getPromotedKOLIDs().then((result) => {
      if (result.success) {
        setPromotedKOLList(result.data)
      }
    })
  }, [])

  useEffect(() => {
    if (promotedKOLsID && isLoadedPromotedKOLsID) {
      setPromotedKOLIDs(promotedKOLsID)
    }
  }, [promotedKOLsID])

  let params = searchPageQuery(paramList)

  const setPromotedKOLList = (data: string[]): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROMOTED_KOL_LIST,
      data: data,
    })
  }

  const setCondition = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: state.key,
        value: state.value,
      },
    })

  const resetPageByRefresh = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.RESET_SEARCH_KOL,
      data: null,
    })
  }
  const setAllConditions = (state: KOLSearchType) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERIES,
      data: state,
    })

  const toggleFilterModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.KOL_FILTER_MODAL,
      metaData: { params: params, queryPushFn: queryLoader },
    })
  }

  const togglePorfileModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.KOL_PROFILE_MODAL,
      metaData: { queryPushFn: queryLoader },
    })
  }

  const resetCondition = () =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.RESET_SEARCH_KOL,
    })

  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })

  const setCurrentPage = (page: number) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: "page",
        value: page,
      },
    })
  }

  useEffect(() => {
    const isParamEmpty = Object.entries(params).every((e) => e[1] === null)
    // check if all params in query url are null, refresh kol result search if so
    if (!isRefreshed && isParamEmpty) {
      resetPageByRefresh()
      setIsRefreshed(true)
    } else {
      const paramsArr = Object.entries(params).filter((e) => e[1] !== null)
      const search = searchParamsToCondition(paramsArr, searchKOLCondition)
      if (search) {
        let newCondition: KOLSearchType = {
          ...KOLSearchDefault,
          followerMax: DEFAULT_MAX_CONDITION.FOLLOWER,
          basicScoreMax: DEFAULT_MAX_CONDITION.BASIC_SCORE,
          ...search,
        }
        setAllConditions(newCondition)
      }
      setIsRefreshed(true)
    }
  }, [params])

  useEffect(() => {
    if (isAuth(auth)) {
      //reset current when search query changed
      kolSelection()
    }
  }, [auth, searchKOLCondition])

  useEffect(() => {
    if (
      params.pageNum &&
      searchKOLCondition.page !== parseInt(params.pageNum) - 1
    ) {
      setCurrentPage(parseInt(params.pageNum) - 1)
    }
  }, [params.pageNum])

  useEffect(() => {
    if (
      params.searchID &&
      verifiedUpdateDetector(params.searchID, searchKOLCondition.searchStr)
    ) {
      setCondition({
        key: "searchStr",
        value: params.searchID,
      })
    }
  }, [params.searchID])
  const kolSelection = async () => {
    setIsLoadedKOLList(false)

    searchKOLAlgolia({
      ...KOLSearchDefault,
      followerMax: DEFAULT_MAX_CONDITION.FOLLOWER,
      basicScoreMax: DEFAULT_MAX_CONDITION.BASIC_SCORE,
      ...searchKOLCondition,
      ...{
        isLook4kolReg: true,
        page: currentPage,
      },
    })
      .then((response) => {
        if (response.success) {
          setKOLList(response.data as KOLCombine[])
          setHasMore(response.hasMore)
        } else {
          setHasMore(false)
        }
        setIsLoadedKOLList(true)
      })
      .catch((err) => console.log(err))
  }

  const onSelectKOL = (KOLInfo: KOLCombine) => {
    queryLoader({ kolID: KOLInfo.id, type: "profile" })
    if (screenWidth < 1024) {
      togglePorfileModal()
    }
  }

  const queryLoader = (query: searchConditionType) => {
    history.push({
      pathname: "/" + getUserLang() + "/admin/list-kol",
      search: "?" + returnSearchConditionParam(query, paramList),
    })
  }

  return (
    <div className="w-full p-4 lg:p-2 lg:grid lg:grid-cols-12 gap-4">
      <div className="lg:col-span-5 xl:col-span-4 w-full relative">
        <h2 className="font-bold text-xl">KOL Profile</h2>
        <div className="my-4">
          <label className="font-sm text-gray-500">Search KOL</label>
          <div className="flex gap-4 ">
            <div className="relative w-full grow">
              <input
                type="text"
                className="input input-bordered w-full rounded-3xl mt-2"
                placeholder={"search kol..."}
                value={params.searchID ? params.searchID : ""}
                onChange={(e) => {
                  setCondition({
                    key: "searchStr",
                    value: e.target.value,
                  })

                  queryLoader({ searchID: e.target.value })
                }}
              />

              <button
                className="absolute right-1 bottom-1 pt-2 inline-block btn btn-ghost w-auto"
                onClick={() => toggleFilterModal()}>
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </div>
            <button
              onClick={() => {
                resetCondition()
                history.push("/" + getUserLang() + "/admin/list-kol")
              }}
              className="btn btn-primary btn-outline btn-sm my-auto">
              RESET
            </button>
          </div>
        </div>

        {isLoadedKOLList ? (
          <>
            <div className="grid lg:block grid-cols-2 gap-3">
              {KOLList.map((kol, index) => {
                return (
                  <div className="md:col-span-1 col-span-2">
                    <KOLMiniCard
                      key={"kol" + kol.objectID}
                      onSelectKOL={onSelectKOL}
                      data={kol}
                    />
                  </div>
                )
              })}
            </div>
            <div className="sticky bottom-20 z-50 mb-16">
              <div className="w-full h-12">
                <div className="flex flex-row gap-2 justify-center w-40 text-center mx-auto mt-6">
                  <div
                    className={classNames(
                      "w-10 h-10 rounded-full bg-gray-200 text-black",
                      {
                        "cursor-pointer": currentPage > 0,
                        "text-gray-300 cursor-not-allowed": currentPage <= 0,
                      }
                    )}
                    onClick={() => {
                      if (currentPage > 0) {
                        let cp = currentPage
                        setCurrentPage(--cp)
                        queryLoader({
                          pageNum: params.pageNum
                            ? (parseInt(params.pageNum) - 1).toString()
                            : "1",
                        })
                      }
                    }}>
                    <FontAwesomeIcon className="mt-3" icon={faChevronLeft} />
                  </div>

                  <div className="w-10 h-10 rounded-full mx-auto bg-primary text-white flex items-center justify-center text-lg">
                    <p>{currentPage + 1}</p>
                  </div>
                  <div
                    className={classNames(
                      "w-10 h-10 rounded-full bg-gray-200 text-black",
                      {
                        "cursor-pointer": hasMore,
                        "text-gray-300 cursor-not-allowed": !hasMore,
                      }
                    )}
                    onClick={() => {
                      if (hasMore) {
                        let cp = currentPage
                        setCurrentPage(++cp)
                        queryLoader({
                          pageNum: params.pageNum
                            ? (parseInt(params.pageNum) + 1).toString()
                            : "2",
                        })
                      }
                    }}>
                    <FontAwesomeIcon className="mt-3" icon={faChevronRight} />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>LOADING...</div>
        )}
      </div>

      {screenWidth >= 1024 && (
        <div className="lg:col-span-7 xl:col-span-6 w-full">
          <KOLProfileComponent queryPushFn={queryLoader} />
        </div>
      )}
    </div>
  )
}

export default KolID
