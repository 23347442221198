import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"
import { faAdd, faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Article } from "../../Model/Article"

interface PropsType {
  updateKeywords: (newKeywords: string[]) => void
}

export const KeywordsTools: React.FC<PropsType> = ({ updateKeywords }) => {
  const [keywords, setKeywords] = useState<string[]>([])
  const [newKeywords, setNewKeywords] = useState<string>("")

  const article: Article = useSelector((state: any) => {
    return state.ArticleManager.article
  })

  useEffect(() => {
    setKeywords(article.keywords)
  }, [article])

  return (
    <section className="rounded-2xl p-4 shadow-md my-4 border border-gray-200">
      <h2 className="text-lg text-left font-medium  text-gray-700">
        <Trans>NewArticle.keyword-tools</Trans>
      </h2>

      {/* input group*/}
      <div className="form-control">
        <div className="input-group">
          <input
            type="text"
            placeholder="Add…"
            className="input input-bordered w-full"
            value={newKeywords}
            onChange={(e) => {
              setNewKeywords(e.target.value)
            }}
          />
          <button
            className="btn btn-square"
            onClick={() => {
              updateKeywords([...keywords.concat(newKeywords)])
              setNewKeywords("")
            }}>
            <FontAwesomeIcon icon={faAdd} />
          </button>
        </div>
      </div>

      {keywords.map((word, index) => {
        return (
          <span
            key={index}
            className="grid badge m-1"
            style={{ gridTemplateColumns: "auto 30px" }}>
            <div>{word}</div>
            <div>
              <button
                className="ml-4"
                onClick={() => {
                  updateKeywords([...keywords.filter((e) => e !== word)])
                }}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </span>
        )
      })}
    </section>
  )
}
