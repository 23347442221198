import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleUser,
  faFire,
  faHeart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { returnSelectedCategories } from "../Utils/TypeList"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../redux/SUPPORTED_REDUX_FUNCTION"
import { KOLCombine } from "../Model/KOLCombine"
import { getKOL } from "../HelpingFunction/KOL/kolDBHelper"
import KOL from "../Model/KOL"
import { followersCount } from "../Utils/Converter"

interface PropsType {
  onSelectKOL: (el: KOLCombine) => void
  data: KOLCombine
}

const KOLMiniCard: React.FC<PropsType> = (props) => {
  const dispatch = useDispatch()

  const promotedKOLsID = useSelector((state: any) => {
    return state.AdminControlManager.promotedKOLsID
  })

  const onPromoteKOL = (uid: string) => {
    if (promotedKOLsID.includes(uid)) {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.REMOVE_FROM_PROMOTED_KOL_LIST,
        data: uid,
      })
    } else {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.ADD_TO_PROMOTED_KOL_LIST,
        data: uid,
      })
    }
  }

  const [gotProfileData, setGotProfileData] = useState<boolean>(false)
  const [kolData, setKOLData] = useState<KOL>()

  useEffect(() => {
    onProfileUpdate(props.data.id)
  }, [props.data])

  const onProfileUpdate = (kolID: string) => {
    setGotProfileData(false)
    if (kolID) {
      getKOL(kolID).then((result) => {
        if (result.success) {
          setKOLData({
            ...result.data,
            id: result.data.id,
          } as KOL)

          setGotProfileData(true)
        }
      })
    }
  }

  return (
    <div className="relative">
      <button
        className="z-10 w-10 h-10 px-2 absolute right-2 top-2 rounded-full hover:bg-gray-200"
        onClick={() => onPromoteKOL(props.data.objectID as string)}>
        <FontAwesomeIcon
          icon={faHeart}
          className={
            promotedKOLsID.includes(props.data.objectID)
              ? "text-red-default"
              : "tesxt-gray-400"
          }
        />
      </button>

      <div
        className=" relative cursor-pointer hover:bg-gray-50 rounded-2xl shadow-md md:my-1 lg:my-4 p-2 lg:p-4 py-4 bg-white"
        onClick={() => props.onSelectKOL(props.data)}>
        <div className="grid grid-cols-5  items-center">
          <div
            onClick={() => {
              props.onSelectKOL(props.data)
            }}
            className="col-span-4 grid w-full"
            style={{ gridTemplateColumns: "50px auto" }}>
            <div className="self-center ">
              {gotProfileData && kolData ? (
                <img
                  className="w-10 h-10 rounded-full"
                  src={kolData.avatarUrl}
                />
              ) : (
                <FontAwesomeIcon
                  className="text-primary text-4xl"
                  icon={faCircleUser}
                />
              )}
            </div>

            <div className="font-normal text-lg  grid grid-rows-2">
              <div>{props.data.userName}</div>

              <div className="flex flex-row items-center gap-2">
                {props.data.topCateID &&
                  returnSelectedCategories([props.data.topCateID]).map((t) => {
                    return (
                      <div
                        key={t.value}
                        className=" flex items-center w-auto md:max-w-[170px] max-w-[120px] border p-1 bg-white  text-xs   text-red-default rounded-xl">
                        <FontAwesomeIcon
                          icon={faFire}
                          className="mr-2 text-red-default my-auto"
                        />
                        <p className="truncate">{t.label}</p>
                      </div>
                    )
                  })}

                <div className="flex text-xs text-gray-500 items-center">
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  {followersCount(props.data.noOfFollowers)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default KOLMiniCard
