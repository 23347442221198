import { useEffect, useState } from "react"
import {
  ContactList,
  DesignList,
  getContactList,
  getDesignList,
  sendEmailToList,
} from "../../HelpingFunction/EmailSystem/EmailSystemDBHelper"
import classNames from "classnames"

const ContactTab = ({
  contacts,
  setContactID,
  contactID,
}: {
  contacts: ContactList[]
  setContactID: any
  contactID: string
}) => {
  return (
    <div className="max-w-xl shadow rounded-lg border p-3">
      <div className="grid grid-cols-7 border-b py-1 mb-6">
        <div className="col-span-1" />
        <div className="col-span-4">
          <p className="text-sm font-medium">List Name</p>
        </div>
        <div className="col-span-2">
          <p className="text-sm font-medium text-right">No. Contacts</p>
        </div>
      </div>
      {contacts.map((contact) => {
        return (
          <div className="grid grid-cols-7 my-1">
            <div className="col-span-1">
              <input
                type="checkbox"
                checked={contact.id === contactID}
                onClick={() => {
                  setContactID(contact.id)
                }}
                className="checkbox rounded-full"
              />
            </div>
            <div className="col-span-5">
              <p>{contact.name}</p>
            </div>
            <div className="col-span-1">
              <p className="text-center">{contact.contact_count}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const DesignCard = ({
  designs,
  setDesignID,
  designID,
}: {
  designs: DesignList[]
  setDesignID: any
  designID: string
}) => {
  return (
    <div className="flex overflow-auto  w-full mb-8">
      <div className="flex flex-nowrap  gap-4">
        {designs.map((design) => {
          return (
            <div className="shadow p-3 bg-white rounded-lg w-64 my-4 ">
              <div className="flex flex-row items-center justify-between my-2">
                <p>{design.name}</p>
                <input
                  type="checkbox"
                  checked={design.id === designID}
                  onClick={() => {
                    setDesignID(design.id)
                  }}
                  className="checkbox rounded-full"
                />
              </div>
              <div>
                <img
                  className="w-full object-cover"
                  src={design.thumbnail_url}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const EmailSystem = () => {
  const [contactID, setContactID] = useState<string>("")
  const [contacts, setContacts] = useState<ContactList[]>([])
  const [designID, setDesignID] = useState<string>("")
  const [designs, setDesigns] = useState<DesignList[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [campaignName, setCampaignName] = useState<string>("")
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSent, setIsSent] = useState<boolean>(false)

  useEffect(() => {
    getContactLists()
    getDesignLists()
  }, [])
  const getContactLists = async () => {
    await getContactList().then((res) => {
      if (res.success) {
        setContacts(res.contacts)
      }
    })
  }
  const getDesignLists = async () => {
    await getDesignList().then((res) => {
      if (res.success) {
        setDesigns(res.designs)
      }
      setIsLoaded(true)
    })
  }

  const sendEmail = async () => {
    setIsSending(true)
    await sendEmailToList(designID, contactID, campaignName).then((res) => {
      if (res.success) {
        setIsSending(false)
        setIsSent(true)
        setTimeout(() => {
          setIsSent(false)
        }, 3000)
      }
    })
  }

  return (
    <div className="md:px-0 px-4 md:pb-8 pb-12">
      <h1 className="text-3xl font-bold mt-6">Send Bulk Email</h1>
      <p className="my-4 max-w-md text-gray-500 font-medium">
        Use this tool to send marketing emails to contact lists by choosing
        preffered design choice.
      </p>
      <section>
        <h2 className="my-4 text-xl font-semibold">1. Select Contact List</h2>
        {isLoaded && (
          <ContactTab
            contactID={contactID}
            contacts={contacts}
            setContactID={setContactID}
          />
        )}
      </section>
      <section className="my-8">
        <h2 className="my-4 text-xl font-semibold">2. Select Email Design</h2>
        {isLoaded && (
          <DesignCard
            designID={designID}
            designs={designs}
            setDesignID={setDesignID}
          />
        )}
      </section>
      <section>
        <input
          type="text"
          placeholder="Campaign Name"
          onChange={(e) => setCampaignName(e.target.value)}
          value={campaignName}
          className="input input-bordered w-full max-w-xs"
        />
        <button
          onClick={() => sendEmail()}
          className={classNames("btn btn-outline btn-primary ml-4", {
            loading: isSending,
          })}>
          {isSent ? "Sent" : "Send Email"}
        </button>
      </section>
    </div>
  )
}

export default EmailSystem
