import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import PromotedKOLSetting from "../../page-components/admin/PromotedKOLSetting"

enum AdminSettingType {
  PROMOTED_KOL,
}

const Setting = () => {
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [settingType, setSettingType] = useState<AdminSettingType>(
    AdminSettingType.PROMOTED_KOL
  )

  if (settingType === AdminSettingType.PROMOTED_KOL) {
    return <PromotedKOLSetting />
  }
  return <div></div>
}

export default withRouter(Setting)
