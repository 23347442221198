import { Article } from "../../Model/Article"
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../config/firebase"
import {
  getDirectionSign,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
  SORT_OPTION,
} from "../../Utils/TableHelperFunction"

const db = getFirestore(firebaseApp)

/**
 * get Article from aid - article id
 * @param {string} aid - firebase uid
 */

const getArticle = (
  aid: string
): Promise<
  | {
      success: true
      data: Article
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Article", aid))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Article,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * get all Articles from db -> admin functions
 */

export interface articleCondition {
  paginationData: any | ""
  paginationDirection: PAGINATION_DIRECTION | ""
}

const getArticles = (
  condition: articleCondition,
  orderByOption: SORT_OPTION
): Promise<
  | {
      success: true
      data: Article[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef = query(collection(db, "Article"))
    if (condition.paginationData !== "" && orderByOption.direction !== false) {
      dbRef = query(
        dbRef,
        where(
          orderByOption.fieldName,
          getDirectionSign(
            orderByOption.direction,
            condition.paginationDirection
          ),
          condition.paginationData
        )
      )
    }
    //Previous page exclusive:
    //For reversing the order of data depending on the direction
    // (so that we can get the closest datasets)
    const reverseDirection =
      orderByOption.direction === ORDER_BY_DIRECTION.DESC
        ? ORDER_BY_DIRECTION.ASC
        : ORDER_BY_DIRECTION.DESC

    const orderByDirection =
      condition.paginationDirection === PAGINATION_DIRECTION.PREV
        ? reverseDirection
        : orderByOption.direction

    if (orderByDirection !== false) {
      dbRef = query(dbRef, orderBy(orderByOption.fieldName, orderByDirection))
    }

    await getDocs(query(dbRef, limit(11)))
      .then((docs) => {
        if (!docs.empty) {
          let articles: Article[] = []
          let index = 0
          docs.forEach((doc) => {
            if (index < 10) {
              articles.push({
                id: doc.id,
                ...doc.data(),
              } as Article)
            }
            index += 1
          })
          return resolve({
            success: true,
            data: articles,
            hasMore: docs.docs.length === 11,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * remove Article from aid - article id -> admin function
 * @param {string} aid - firebase uid
 */

const removeArticle = (
  aid: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await deleteDoc(doc(db, "Article", aid))
      .then((doc) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * update Article from aid - article id
 * @param {string} aid - firebase uid
 * @param {Article} article - article
 */

const updateArticle = (
  aid: string,
  article: Article
): Promise<
  | {
      success: true
    }
  | {
      success: false
      message: string
    }
> => {
  return new Promise(async (resolve) => {
    if (
      article.featureImage === "" ||
      article.authorName === "" ||
      article.urlTitle === "" ||
      article.title === "" ||
      article.content === ""
    ) {
      return resolve({
        success: false,
        message: "Please fill in everything to proceed",
      })
    }
    const exist = await checkArticleExist(article.urlTitle, aid)
    const inValid = /\s/
    const specialChars = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/
    if (exist.exist) {
      return resolve({
        success: false,
        message: "The URL Title already exists",
      })
    } else if (
      inValid.test(article.urlTitle) ||
      specialChars.test(article.urlTitle)
    ) {
      return resolve({
        success: false,
        message: "URL title should not have any spaces or special characters",
      })
    }
    let a: Article = {
      ...article,
      lastModified: new Date(),
    }

    if (a.isPublish && a.publishDate === new Date()) {
      a.publishDate = new Date()
    }

    await setDoc(doc(db, "Article", aid), {
      ...article,
      lastModified: new Date(),
    })
      .then((doc) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

const checkArticleExist = (
  articleUrl: string,
  articleID: string
): Promise<{ exist: boolean }> => {
  return new Promise(async (resolve) => {
    let dbRef = query(collection(db, "Article"))
    dbRef = query(dbRef, where(documentId(), "!=", articleID))
    dbRef = query(dbRef, where("urlTitle", "==", articleUrl))
    await getDocs(dbRef)
      .then((docs) => {
        if (!docs.empty) {
          return resolve({
            exist: true,
          })
        }
        return resolve({
          exist: false,
        })
      })
      .catch((e) => console.log(e))
  })
}

export { getArticle, getArticles, updateArticle, removeArticle }
