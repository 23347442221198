import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
  doc,
} from "firebase/firestore"
import firebaseApp from "../config/firebase"
import { IGCrawler, IGCrawlerCondition } from "../Model/IGCrawler"
import {
  getDirectionSign,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../Utils/TableHelperFunction"
import { returnIdTokenResult } from "../Utils/FirebaseUtils"
import { getNeo4jServer } from "../Utils/HelpingFunction"

const db = getFirestore(firebaseApp)

const updateCrawlerState = (
  data: IGCrawler
): Promise<{
  success: boolean
}> => {
  return new Promise((resolve) => {
    let newData = { ...data }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete newData.id
    console.log(data)
    updateDoc(doc(db, "IGCrawler", data.id), newData)
      .then((doc: any) => {
        return resolve({
          success: true,
        })
      })
      .catch((err: any) => {
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * get crawler state from firebase
 * @param {IGCrawlerCondition} condition - condition
 * @param {
 *     fieldName: string
 *     direction: "DESC" | "ASC"
 *   } orderByOption
 */

const getCrawlerDataList = (
  condition: IGCrawlerCondition,
  orderByOption: {
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }
): Promise<
  | {
      success: true
      data: IGCrawler[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef = query(collection(db, "IGCrawler"))

    if (condition.status !== "") {
      dbRef = query(dbRef, where("status", "==", condition.status))
    }

    if (condition.userName !== "") {
      dbRef = query(dbRef, where("userName", "==", condition.userName))
    }

    if (condition.noOfFollowers !== "") {
      dbRef = query(dbRef, where("noOfFollowers", "<", condition.noOfFollowers))
    }

    if (condition.isLook4kolReg !== "") {
      dbRef = query(
        dbRef,
        where("isLook4kolReg", "==", condition.isLook4kolReg)
      )
    }

    if (condition.noOfFollowers !== "") {
      dbRef = query(dbRef, where("noOfFollowers", "<", condition.noOfFollowers))
    }

    if (condition.paginationData !== "") {
      dbRef = query(
        dbRef,
        where(
          orderByOption.fieldName,
          getDirectionSign(
            orderByOption.direction,
            condition.paginationDirection
          ),
          condition.paginationData
        )
      )
    }

    //Previous page exclusive:
    //For reversing the order of data depending on the direction
    // (so that we can get the closest datasets)
    const reverseDirection =
      orderByOption.direction === ORDER_BY_DIRECTION.DESC
        ? ORDER_BY_DIRECTION.ASC
        : ORDER_BY_DIRECTION.DESC

    const orderByDirection =
      condition.paginationDirection === PAGINATION_DIRECTION.PREV
        ? reverseDirection
        : orderByOption.direction

    await getDocs(
      query(
        dbRef,
        orderBy(orderByOption.fieldName, orderByDirection),
        limit(11)
      )
    )
      .then(async (docs: any) => {
        let data: IGCrawler[] = []
        if (!docs.empty) {
          let docLength = 0
          docs.docs.forEach((doc: any) => {
            if (docLength < 10) {
              data.push({
                id: doc.id,
                ...doc.data(),
              } as IGCrawler)
            }
            docLength += 1
          })
          return resolve({
            success: true,
            data: data,
            hasMore: docs.docs.length === 11,
          })
        }
        return resolve({
          success: true,
          data: data,
          hasMore: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * get follower count by this kol through neo4j
 * @param {string} userName - ig username
 */

const getNeo4jFollowerCounts = (userName: string): Promise<number> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getNeo4jServer(`/v1/kols/${userName}/followers`), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
          UID: res.uid,
        },
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          if (finalResponse.success) {
            return resolve(finalResponse.data)
          }
          return resolve(0)
        })
    })
  })
}

export { getCrawlerDataList, updateCrawlerState, getNeo4jFollowerCounts }
