import React from "react"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import classNames from "classnames"
import { ALERT_TYPE, BUTTON_TYPE } from "../Enum/ALERT_SYSYEM"

interface PropsType {
  title: string
  alertType: ALERT_TYPE
  buttonType: {
    type: BUTTON_TYPE
    buttonText?: string
    buttonDisabledText?: string
    buttonIcon?: IconDefinition
  }
  description?: string
  buttonFn?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  icon?: IconDefinition
  isLoading?: boolean
  disabled?: boolean
  visible?: boolean
}

const CustomAlert: React.FC<PropsType> = ({
  title,
  alertType,
  buttonType,
  description,
  buttonFn,
  onMouseEnter,
  onMouseLeave,
  icon,
  isLoading,
  disabled,
  visible,
}) => {
  const alertTypeStyle = () => {
    switch (alertType) {
      case ALERT_TYPE.ERROR:
        return isAlertError
      case ALERT_TYPE.INFO:
        return isAlertInfo
      case ALERT_TYPE.WARNING:
        return isAlertWarning
      case ALERT_TYPE.REQUEST:
        return isAlertRequest
      case ALERT_TYPE.PAYMENT:
        return isAlertPayment
      default:
        break
    }
  }
  const isButtonless =
    buttonType.type === BUTTON_TYPE.NONE
      ? "50px auto auto 20px"
      : "50px auto 150px 20px 20px"
  const isAlertError = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec rgb(239 68 68)",
    borderWidth: "1px 1px 1px 8px",
    backgroundColor: "rgb(254 226 226)",
    gridTemplateColumns: isButtonless,
    color: "#b91c1c",
  }
  const isAlertInfo = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec rgb(2 132 199)",
    borderWidth: "1px 1px 1px 8px",
    backgroundColor: "rgb(224 242 254)",
    gridTemplateColumns: isButtonless,
    color: "#1d4ed8",
  }
  const isAlertWarning = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec  rgb(245 158 11)",
    borderWidth: "1px 1px 1px 8px",
    backgroundColor: "rgb(254 243 199)",
    gridTemplateColumns: isButtonless,
    color: "#a16207",
  }
  const isAlertRequest = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec #a16207",
    borderWidth: "1px 1px 1px 8px",
    gridTemplateColumns: "50px auto 100px",
    color: "#a16207",
  }
  const isAlertPayment = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec rgb(16 185 129)",
    backgroundColor: "rgb(209 250 229)",
    borderWidth: "1px 1px 1px 8px",
    gridTemplateColumns: isButtonless,
    color: "#15803d",
  }
  const iconExisit = () => {
    if (icon) {
      return <FontAwesomeIcon icon={icon} />
    }
  }
  const btnIconExisit = () => {
    if (buttonType.buttonIcon) {
      return <FontAwesomeIcon icon={buttonType.buttonIcon} className="mr-2" />
    }
  }
  const helpIconExisit = () => {
    if (buttonType.buttonIcon) {
      return <FontAwesomeIcon icon={buttonType.buttonIcon} />
    }
  }
  return (
    <div
      className={classNames(
        " py-2 pl-4 md:pl-1 relative w-full items-center mb-5 font-bold py-1 px-2 bg-white hover:bg-gray-50 rounded-tl rounded-bl rounded-tr-xl rounded-br-xl shadow-md",
        "border-l-8 border-red-700",
        {
          hidden: !visible && visible !== undefined,
          "lg:grid": alertType === ALERT_TYPE.REQUEST,
          "md:grid": alertType !== ALERT_TYPE.REQUEST,
        }
      )}
      style={alertTypeStyle()}>
      <div
        slot="avatar"
        className={classNames("hidden mx-5 text-xl", {
          "lg:block": alertType === ALERT_TYPE.REQUEST,
          "md:block": alertType !== ALERT_TYPE.REQUEST,
        })}>
        {iconExisit()}
      </div>
      <div className="flex">
        <div
          slot="avatar"
          className={classNames(
            "ml-1 mx-3 md:mx-5 self-center text-2xl block",
            {
              "lg:hidden": alertType === ALERT_TYPE.REQUEST,
              "md:hidden": alertType !== ALERT_TYPE.REQUEST,
            }
          )}>
          {iconExisit()}
        </div>
        <div
          className={classNames("max-w-full flex-initial", {
            "text-sm font-bold text-gray-500": alertType === ALERT_TYPE.REQUEST,
            "text-sm font-bold md:font-normal ":
              alertType !== ALERT_TYPE.REQUEST,
          })}>
          {alertType === ALERT_TYPE.ERROR && (
            <p className="font-bold">
              <Trans>NewAlert.error</Trans>
            </p>
          )}
          {alertType === ALERT_TYPE.INFO && (
            <p className="font-bold">
              {" "}
              <Trans>NewAlert.information</Trans>
            </p>
          )}
          {alertType === ALERT_TYPE.WARNING && (
            <p className="font-bold">
              {" "}
              <Trans>NewAlert.warning</Trans>
            </p>
          )}
          {alertType === ALERT_TYPE.PAYMENT && (
            <p className="font-bold">
              {" "}
              <Trans>NewAlert.success</Trans>
            </p>
          )}
          <div>
            <Trans>{title}</Trans>
            <div className="text-xs md:text-sm font-normal">
              <Trans>{description}</Trans>
            </div>
          </div>
        </div>
      </div>
      <div>
        {buttonType.type === BUTTON_TYPE.TEXT_ONLY && (
          <div>
            <button
              className={classNames(
                "btn bg-gray-200 hover:bg-gray-300 text-neutral w-full border-inherit hover:border-inherit",
                {
                  "btn-sm": alertType === ALERT_TYPE.REQUEST,
                  loading: isLoading,
                }
              )}
              onClick={buttonFn}
              disabled={disabled}>
              {disabled && !isLoading ? (
                <Trans>{buttonType.buttonDisabledText}</Trans>
              ) : (
                <Trans>{buttonType.buttonText}</Trans>
              )}
            </button>
          </div>
        )}
        {buttonType.type === BUTTON_TYPE.WITH_ICON && (
          <div>
            <button
              className={classNames(
                "btn bg-gray-200 hover:bg-gray-300 text-neutral w-full border-inherit hover:border-inherit",
                {
                  loading: isLoading,
                }
              )}
              onClick={buttonFn}
              disabled={disabled}>
              {btnIconExisit()}
              {disabled && !isLoading ? (
                <Trans>{buttonType.buttonDisabledText}</Trans>
              ) : (
                <Trans>{buttonType.buttonText}</Trans>
              )}
            </button>
          </div>
        )}

        {buttonType.type === BUTTON_TYPE.HELP_BTN && (
          <div className="flex justify-end">
            <button
              className={classNames(
                "btn md:btn-circle btn-sm bg-gray-200 hover:bg-gray-300 text-neutral w-full md:w-8 border-inherit hover:border-inherit",
                {
                  loading: isLoading,
                }
              )}
              onClick={buttonFn}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              disabled={disabled}>
              {helpIconExisit()}
              {disabled && !isLoading ? (
                <Trans>{buttonType.buttonDisabledText}</Trans>
              ) : (
                <Trans>{buttonType.buttonText}</Trans>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default CustomAlert
