import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRefresh, faUpload } from "@fortawesome/free-solid-svg-icons"
import { Trans, useTranslation } from "react-i18next"
import Select from "react-select"
import { getAllCountries, getCountry } from "../../Utils/Countries"
import { onUploadFile } from "../../HelpingFunction/FirebaseStorage"
import Spinner from "../../Component/Spinner"
import { getAllDetailType, returnSelectedItems } from "../../Utils/TypeList"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Coverimg1 from "../../Assets/446x385.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Coverimg2 from "../../Assets/300x193.png"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { getFilePath, SUPPORTED_FILE_TYPE } from "../../Utils/FilePath"

import KOL, { KOLDefault } from "../../../src/Model/KOL"
import {
  generateKOLMetaDescription,
  updateKOL,
} from "../../HelpingFunction/KOL/kolDBHelper"
import CustomTagSelect from "../../Component/NewTagSelect"
import { uploadImageFileToImgur } from "../../Utils/utiltyHelper"

interface Props {
  profile: KOL
}

const AdminEditKOLProfile = (props: Props) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [profile, setProfile] = useState<KOL>(KOLDefault)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isPublish, setIsPublish] = useState<boolean>(props.profile.isPublish)
  const [isVerified, setIsVerified] = useState<boolean>(
    props.profile.isVerified
  )
  const [isGenDesc, setIsGenDesc] = useState<boolean>(false)

  useEffect(() => {
    setIsLoaded(false)
    if (props.profile.id) {
      setProfile(props.profile)
      setIsLoaded(true)
    }
  }, [props.profile])

  const uploadImage = async (e: any) => {
    let allFile: File[] = []
    Array.from(e.target.files).forEach((file: any) => allFile.push(file))

    const uploadToImgur = await uploadImageFileToImgur(allFile[0])
    if (uploadToImgur.success) {
      setProfile({
        ...profile,
        avatarUrl: uploadToImgur.data[0],
      })
    }
  }

  const updateKOLProfile = async (kolProfile: KOL) => {
    setIsSubmitting(true)

    updateKOL(kolProfile).then((finalResponse) => {
      if (finalResponse.success) {
        setIsSubmitting(false)
      } else {
        setIsSubmitting(false)
      }
    })
  }

  const returnUploadedImage = (metaData: any, files: any[]) => {
    if (typeof metaData !== "undefined" && Array.isArray(files)) {
      if (files.length > 0) {
        let newCoverImages = profile.coverImages
        newCoverImages[metaData.position] = files[0].thumbnailUrl
        let newProfile = {
          ...profile,
          coverImages: newCoverImages,
        }

        setProfile(newProfile)
      }
    }
  }
  const setUploadFileModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.UPLOAD_FILE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
      uploadPath: state.uploadPath,
      typeLimit: state.typeLimit,
      limit: state.limit,
    })
  }
  const setProfilePublished = () => {
    let publish = !isPublish

    let newProfile = {
      ...profile,
      isPublish: publish,
    }
    setProfile(newProfile)
    setIsPublish(publish)
  }
  const setProfileVerified = () => {
    let verified = !isVerified
    let newProfile = {
      ...profile,
      isVerified: verified,
    }
    setProfile(newProfile)
    setIsVerified(verified)
  }

  const categoryFn = (e: any[]) => {
    let allIDs: string[] = []
    if (Array.isArray(e)) {
      e.map((i) => {
        allIDs.push(i.value)
      })
    }
    console.log("allIDs", allIDs)
    setProfile({ ...profile, type: allIDs })
  }

  const genMetaDesc = async () => {
    setIsGenDesc(true)
    const result = await generateKOLMetaDescription(props.profile.id)
    if (result.success) {
      setIsGenDesc(false)
      setProfile({
        ...props.profile,
        metaDesc: result.desc,
      })
    } else {
      setIsGenDesc(false)
    }
  }

  if (isLoaded) {
    return (
      <div className="relative py-3 w-full">
        <div>
          <div className="divide-y divide-gray-200">
            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
              <div className="flex flex-row">
                <div className="flex flex-row ml-4 mr-auto">
                  <p className="text-base mr-2">
                    <Trans>EditKOLProfile.is-publish</Trans>
                  </p>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={isPublish}
                    onClick={() => setProfilePublished()}
                  />
                </div>
                <div className="flex flex-row mr-4">
                  <p className="text-base mr-2">
                    <Trans>RegForm.verified</Trans>:
                  </p>
                  <input
                    type="checkbox"
                    className="toggle toggle-accent"
                    checked={isVerified}
                    onClick={() => setProfileVerified()}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2 h-56">
                <div
                  className="border-2"
                  onClick={() =>
                    setUploadFileModal({
                      returnFn: returnUploadedImage,
                      limit: 1,
                      uploadPath: getFilePath(
                        SUPPORTED_FILE_TYPE.UPLOAD_PROFILE_COVER_IMAGE,
                        auth.uid
                      ),
                      typeLimit: "image",
                      metaData: { position: 0 },
                    })
                  }>
                  <img
                    className={
                      "rounded-l-lg h-56 object-cover hover:opacity-75"
                    }
                    src={
                      typeof profile.coverImages[0] !== "undefined" &&
                      profile.coverImages[0] !== ""
                        ? profile.coverImages[0]
                        : Coverimg1
                    }
                  />
                </div>
                <div className="grid grid-rows-2 gap-2">
                  <div
                    className="border-2"
                    onClick={() =>
                      setUploadFileModal({
                        returnFn: returnUploadedImage,
                        limit: 1,
                        uploadPath: getFilePath(
                          SUPPORTED_FILE_TYPE.UPLOAD_PROFILE_COVER_IMAGE,
                          auth.uid
                        ),
                        typeLimit: "image",
                        metaData: { position: 1 },
                      })
                    }>
                    <img
                      className={"w-full object-cover hover:opacity-75"}
                      style={{ height: "108px" }}
                      src={
                        typeof profile.coverImages[1] !== "undefined" &&
                        profile.coverImages[1] !== ""
                          ? profile.coverImages[1]
                          : Coverimg2
                      }
                    />
                  </div>
                  <div
                    className="border-2"
                    onClick={() =>
                      setUploadFileModal({
                        returnFn: returnUploadedImage,
                        limit: 1,
                        uploadPath: getFilePath(
                          SUPPORTED_FILE_TYPE.UPLOAD_PROFILE_COVER_IMAGE,
                          auth.uid
                        ),
                        typeLimit: "image",
                        metaData: { position: 2 },
                      })
                    }>
                    <img
                      className={"w-full object-cover hover:opacity-75"}
                      style={{ height: "108px" }}
                      src={
                        typeof profile.coverImages[2] !== "undefined" &&
                        profile.coverImages[2] !== ""
                          ? profile.coverImages[2]
                          : Coverimg2
                      }
                    />
                  </div>
                </div>
                <div
                  className="border-2"
                  onClick={() =>
                    setUploadFileModal({
                      returnFn: returnUploadedImage,
                      limit: 1,
                      uploadPath: getFilePath(
                        SUPPORTED_FILE_TYPE.UPLOAD_PROFILE_COVER_IMAGE,
                        auth.uid
                      ),
                      typeLimit: "image",
                      metaData: { position: 3 },
                    })
                  }>
                  <img
                    className={
                      "rounded-r-lg h-56 object-cover hover:opacity-75 "
                    }
                    src={
                      typeof profile.coverImages[3] !== "undefined" &&
                      profile.coverImages[3] !== ""
                        ? profile.coverImages[3]
                        : Coverimg1
                    }
                  />
                </div>
              </div>
              <div className="table m-auto items-center space-x-5">
                <div>
                  {profile.avatarUrl !== "" ? (
                    <div className={"relative  cursor-pointer"}>
                      <img
                        className={
                          "object-cover md:w-20 md:h-20 rounded-full w-12 h-12 relative opacity-60"
                        }
                        src={profile.avatarUrl}
                        alt={"avatar"}
                      />
                      <label
                        className={
                          "md:absolute md:top-3 md:left-3 absolute top-2 left-2"
                        }
                        htmlFor={"file-input"}>
                        <FontAwesomeIcon
                          className={
                            "md:text-2xl text-xl md:ml-4 md:mt-3 mt-1 ml-1.5  cursor-pointer"
                          }
                          icon={faUpload}
                        />
                      </label>
                      <input
                        className={"hidden"}
                        id="file-input"
                        type="file"
                        accept=".png,.jpg,.svg"
                        multiple={false}
                        onChange={async (e) => uploadImage(e)}
                      />
                    </div>
                  ) : (
                    <>
                      <label htmlFor={"file-input"}>
                        <div
                          className={
                            "border-2 text-xs md:w-20 md:h-20 rounded-full w-12 h-12 pl-0.5  cursor-pointer hover:bg-gray-50"
                          }>
                          <FontAwesomeIcon
                            icon={faUpload}
                            className={
                              "md:text-2xl text-xl ml-2.5 mt-2 md:ml-6 md:mt-5"
                            }
                          />
                        </div>
                      </label>
                      <input
                        className={"hidden"}
                        id="file-input"
                        type="file"
                        accept=".png,.jpg,.svg"
                        multiple={false}
                        onChange={async (e) => uploadImage(e)}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.email</Trans>
                </label>
                <input
                  type="text"
                  className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  placeholder={t("RegForm.email")}
                  value={profile.email}
                  disabled={true}
                />
              </div>

              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.user-name</Trans>
                  <span className="block text-xs text-gray-500 mb-2">
                    <Trans>RegForm.user-name-des</Trans>
                  </span>
                </label>
                <input
                  type="text"
                  className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  placeholder={t("RegForm.user-name")}
                  pattern="[a-zA-Z0-9]+"
                  required
                  value={profile.userName}
                  onChange={(e) => {
                    let un = e.target.value.replace(/\s+/g, "")
                    setProfile({ ...profile, userName: un })
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.last-name</Trans>
                </label>
                <input
                  type="text"
                  className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  placeholder={t("RegForm.last-name")}
                  value={profile.lastName}
                  onChange={(e) =>
                    setProfile({ ...profile, lastName: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.first-name</Trans>
                </label>
                <input
                  type="text"
                  className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  placeholder={t("RegForm.first-name")}
                  value={profile.firstName}
                  onChange={(e) =>
                    setProfile({ ...profile, firstName: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.description</Trans>
                </label>
                <textarea
                  className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  placeholder="Optional"
                  value={profile.description}
                  onChange={(e) =>
                    setProfile({ ...profile, description: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  <p className="leading-loose">
                    <Trans>Meta Description</Trans>
                  </p>
                  <button
                    onClick={() => {
                      if (!isGenDesc) {
                        genMetaDesc()
                      }
                    }}>
                    <FontAwesomeIcon
                      className={
                        isGenDesc
                          ? "animate-spin cursor-wait"
                          : "cursor-pointer"
                      }
                      icon={faRefresh}
                    />
                  </button>
                </div>
                <textarea
                  disabled={isGenDesc}
                  className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 h-[150px]"
                  placeholder="Optional"
                  value={profile.metaDesc ? profile.metaDesc : ""}
                  onChange={(e) =>
                    setProfile({ ...profile, metaDesc: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>EditKOLProfile.nationality</Trans>
                </label>
                <Select
                  className="text-md"
                  name="nationality"
                  closeMenuOnSelect={true}
                  options={getAllCountries()}
                  defaultValue={getCountry(profile.nationality)}
                  onChange={(e: any) =>
                    setProfile({
                      ...profile,
                      nationality: e.value,
                    })
                  }
                />
              </div>

              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.type</Trans>
                </label>
                <CustomTagSelect
                  tagValueType={profile.type}
                  onChangeFn={categoryFn}
                  disabled={false}
                />
              </div>
            </div>
            <div className="pt-4 flex items-center space-x-4">
              <button
                className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"
                disabled={isSubmitting}
                onClick={() => {
                  updateKOLProfile(profile)
                }}>
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <Trans>KOLProfiler.update-profile</Trans>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <div>loading...</div>
}
export default AdminEditKOLProfile
