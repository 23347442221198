import React, { useState, useEffect } from "react"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const Recommendation = (props) => {
  return (
    <>
      <div className="relative max-w-7xl mx-auto  relative h-full">
        <h1 className="text-grey-800 text-3xl font-bold leading-tight  ">
          Recommendation
        </h1>
        <hr />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12"></div>
        </div>

        <div className="col-span-12 grid md:grid-cols-2 gap-4"></div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    screenWidth: state.SystemManager.screenWidth,
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(withRouter(Recommendation))
