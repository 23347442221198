import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

const initialState = {
  isConfirmEmail: false,
  screenWidth: 0,
  screenHeight: 0,
  forceSignUp: false,
}

const SystemManager = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_WIDTH) {
    return {
      ...state,
      screenWidth: action.screenWidth,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_HEIGHT) {
    return {
      ...state,
      screenHeight: action.screenHeight,
    }
  }

  return state
}

export default SystemManager
