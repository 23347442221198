import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import * as List from "../../Utils/ModalType"
import { v4 as uuidv4 } from "uuid"

import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"

import CustomModal from "../CustomModal"
import ViewImageModalComponent from "../ModalComponents/ViewImageModalComponent"
import WalletHistoryModalComponent from "../ModalComponents/WalletHistoryModalComponent"
import UploadFileModalComponent from "../ModalComponents/UploadFileModalComponent"
import { onUploadFile } from "../../HelpingFunction/FirebaseStorage"

import AdminViewKOLPercentageModalComponent from "../../Component/ModalComponents/AdminViewKOLPercentageModalComponent"
import AdminEditRequestModalComponent from "../ModalComponents/AdminEditRequestModalComponent"
import { createNewChatRoom } from "../../Utils/ChatroomHelpingFunction"
import AdminCreateChatroomModalComponent from "../ModalComponents/AdminCreateChatroomModalComponent"
import EditCrawlerStateModal from "../ModalComponents/EditCrawlerStateModal"
import AddToCompareGroup from "../ModalComponents/AddToCompareGroup"
import { updateCrawlerState } from "../../HelpingFunction/crawlerDBHelper"
import SearchConditionBox from "../../page-components/list-kol/SearchConditionBox"
import { searchParamsToCondition } from "../../Utils/QueryHelper"
import {
  KOLSearchDefault,
  KOLSearchType,
} from "../../redux/reducer/ToolsManager"
import { DEFAULT_MAX_CONDITION } from "../../Enum/KOL_SEARCH_TYPE"
import KOLProfileComponent from "../../page-components/list-kol/KOLProfileComponent"
import { KOLCompareTable } from "../../page-components/tools/KOLCompareTable"

const ModalController = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState("")

  let history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen !== props.isModalOpen) {
      setIsOpen(props.isModalOpen)
    }

    if (type !== props.typeOfModal) {
      setType(props.typeOfModal)
    }
  }, [props.isModalOpen, props.typeOfModal])

  const setAllConditions = (state) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERIES,
      data: state,
    })

  if (type === List.SUPPORTED_MODAL_TYPE.UPLOAD_FILE_MODAL) {
    const uploadFile = async (itemsRef) => {
      let allFile = []
      if (Array.isArray(itemsRef)) {
        itemsRef.forEach((ref) => {
          if (ref !== null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            allFile.push(ref.returnFile())
          }
        })

        const uploadState = await onUploadFile(
          allFile,
          props.auth.uid,
          props.uploadPath
        )

        if (uploadState.success) {
          props.returnFn(props.metaData, uploadState.data)
          props.closeModal()
        }
      }
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "Upload",
            color: "info",
            size: "32",
            onClick: uploadFile,
          },
        ]}
        title={"UploadFileModal.title"}
        modalSize={"3xl"}
        component={UploadFileModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.ADMIN_CREATE_CHATROOM_MODAL) {
    const createAdminChatroom = async () => {
      const chatRoomObject = {
        title: "Look4KOL團隊",
        type: "admin",
        participateUID: [props.auth.uid, props.metaData.userID],
      }

      const chatroomID = uuidv4()
      const createChatroomState = await createNewChatRoom(
        chatroomID,
        chatRoomObject
      )
      if (createChatroomState.success) {
        window.open(
          process.env.REACT_APP_HOST_NAME +
            "/" +
            (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
            "/c?chatroomID=" +
            chatroomID
        )
        props.closeModal()
      }
    }
    const onClickFunc = () => {
      createAdminChatroom()
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "AdminCreateChatroomModal.create",
            color: "success",
            size: "32",
            onClick: onClickFunc,
          },
        ]}
        title={"AdminCreateChatroomModal.title"}
        modalSize={"2xl"}
        component={AdminCreateChatroomModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.VIEW_IMAGE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"ViewImageModal.title"}
        modalSize={"3xl"}
        component={ViewImageModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.ADMIN_EDIT_REQUEST_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={() => {
          history.replace("/hk/admin/request")
          props.closeModal()
        }}
        buttons={[]}
        title={"AdminEditRequestModal.title"}
        modalSize={"3xl"}
        component={AdminEditRequestModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.VIEW_KOL_PERCENTAGE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"AdminViewKOLPercentageModal.title"}
        modalSize={"7xl"}
        component={AdminViewKOLPercentageModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.WALLET_HISTORY_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"2xl"}
        component={WalletHistoryModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.EDIT_CRAWLER_STATE) {
    const updateState = (ref) => {
      updateCrawlerState(ref).then((result) => {
        if (result.success) {
          props.closeModal()
          window.location.reload()
        }
      })
    }

    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "EditCrawlerStateModal.update",
            color: "success",
            size: "32",
            onClick: updateState,
          },
        ]}
        title={""}
        modalSize={"2xl"}
        component={EditCrawlerStateModal}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.ADD_TO_COMPARE_GROUP_MODAL) {
    const confirm = (ref) => {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.COMPARE_GROUP_NEED_UPDATE,
      })

      props.closeModal()
    }

    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "Save",
            color: "",
            size: "32",
            onClick: confirm,
          },
        ]}
        title={"AddToCompareGroup.title"}
        modalSize={"2xl"}
        component={AddToCompareGroup}
        metaData={props.metaData}
        noCancelButton={false}
        haveLoading={false}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.KOL_FILTER_MODAL) {
    const confirm = (ref) => {
      const paramsArr = Object.entries(props.metaData.params).filter(
        (e) => e[1] !== null
      )
      const search = searchParamsToCondition(paramsArr)
      setAllConditions({
        ...KOLSearchDefault,
        followerMax: DEFAULT_MAX_CONDITION.FOLLOWER,
        basicScoreMax: DEFAULT_MAX_CONDITION.BASIC_SCORE,
        ...search,
      })

      props.closeModal()
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "Confirm",
            color: "",
            size: "32",
            onClick: confirm,
          },
        ]}
        noCancelButton={true}
        title={"Search Conditions"}
        modalSize={"7xl"}
        component={SearchConditionBox}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.KOL_PROFILE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        noCancelButton={true}
        title={"Profile"}
        modalSize={"7xl"}
        modalHeight={"full"}
        component={KOLProfileComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.COMPARE_GROUP_TABLE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"7xl"}
        component={KOLCompareTable}
        metaData={props.metaData}
        noCancelButton={true}
        disableOutbounds={props.metaData.disableOutbounds}
      />
    )
  }
  return <div></div>
}
const mapDispatchToProps = (dispatch) => {
  return {
    setModalController: (state) =>
      dispatch({
        type: state.type,
        typeOfModal: state.typeOfModal,
        isModalOpen: state.isModalOpen,
      }),
    closeModal: (state) =>
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.CLOSE_MODAL,
      }),
  }
}

const mapStateToProps = (state) => {
  return {
    typeOfModal: state.setModalController.Modal.name,
    isModalOpen: state.setModalController.Modal.isOpen,
    returnFn: state.setModalController.returnFn,
    metaData: state.setModalController.metaData,
    uploadPath: state.setModalController.uploadPath,
    typeLimit: state.setModalController.typeLimit,
    limit: state.setModalController.limit,

    auth: state.firebase.auth,
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(withRouter(ModalController))
