export const SUPPORTED_MODAL_TYPE = {
  UPLOAD_FILE_MODAL: "UPLOAD_FILE_MODAL",

  ADMIN_CREATE_CHATROOM_MODAL: "ADMIN_CREATE_CHATROOM_MODAL",
  ADMIN_EDIT_REQUEST_MODAL: "ADMIN_EDIT_REQUEST_MODAL",

  VIEW_IMAGE_MODAL: "VIEW_IMAGE_MODAL",

  VIEW_KOL_PERCENTAGE_MODAL: "VIEW_KOL_PERCENTAGE_MODAL",

  WALLET_HISTORY_MODAL: "WALLET_HISTORY_MODAL",

  ADD_TO_COMPARE_GROUP_MODAL: "ADD_TO_COMPARE_GROUP_MODAL",

  EDIT_CRAWLER_STATE: "EDIT_CRAWLER_STATE",

  CREATE_COMPARE_GROUP_MODAL: "CREATE_COMPARE_GROUP_MODAL",
  COMPARE_GROUP_TABLE_MODAL: "COMPARE_GROUP_TABLE_MODAL",

  KOL_FILTER_MODAL: "KOL_FILTER_MODAL",
  KOL_PROFILE_MODAL: "KOL_PROFILE_MODAL",
}
