import React, { useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Carousel } from "react-responsive-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobeAsia, faStar, faUser } from "@fortawesome/free-solid-svg-icons"
import { getCountry } from "../Utils/Countries"
import LoadingPlaceLoader from "../Assets/LoadingPlaceLoader.png"
import { returnSelectedItems } from "../Utils/TypeList"
import { analytics } from "../config/firebase"
import { logEvent } from "firebase/analytics"
import { IMGUR_THUMBNAIL_TYPE, returnThumbnailUrl } from "../Utils/utiltyHelper"

const KOLSmallCard = (props) => {
  return (
    <div className="inline-block rounded-md  w-full">
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        className="w-full rounded-xl mb-4"
        showStatus={false}
        showThumbs={false}>
        {Array.isArray(props.data.coverImages) &&
        props.data.coverImages.length > 0 &&
        typeof props.data.coverImages.find((e) => e !== "") !== "undefined" ? (
          props.data.coverImages
            .filter((img) => img !== "")
            .map((e, index) => {
              if (e !== "") {
                return (
                  <Link
                    key={"lk" + props.data.id + index}
                    to={{
                      pathname:
                        "/" +
                        (localStorage.getItem("i18n-lang") === "en"
                          ? "en"
                          : "hk") +
                        "/p/" +
                        props.data.id,
                      state: { kol: props.data },
                    }}>
                    <div
                      onClick={() => {
                        logEvent(analytics, "kolProfile_visit", {
                          kolID: props.data.id,
                        })
                      }}
                      className="w-full rounded-xl border border-gray-200">
                      <img
                        alt="find kol"
                        className="rounded-xl object-cover lazyload h-72"
                        // style={{height: "240px"}}
                        src={returnThumbnailUrl(e)}
                      />
                    </div>
                  </Link>
                )
              }
            })
        ) : (
          <Link
            to={{
              pathname:
                "/" +
                (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                "/p/" +
                props.data.id,
              state: { kol: props.data },
            }}>
            <div
              onClick={() => {
                logEvent(analytics, "kolProfile_visit", {
                  kolID: props.data.id,
                })
              }}
              className="w-full">
              <img
                alt="find kol"
                className="rounded-3xl object-cover lazyload h-72"
                src={LoadingPlaceLoader}
              />
            </div>
          </Link>
        )}
      </Carousel>

      <div>
        <div className="grid" style={{ gridTemplateColumns: "50px auto" }}>
          <div
            className={
              "overflow-hidden h-10 w-10 bg-red-50 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono"
            }>
            {props.data.avatarUrl !== "" ? (
              <img
                src={returnThumbnailUrl(
                  props.data.avatarUrl,
                  IMGUR_THUMBNAIL_TYPE.s
                )}
              />
            ) : (
              <FontAwesomeIcon icon={faUser} className="text-red-200" />
            )}
          </div>
          <div
            onClick={() => {
              logEvent(analytics, "kolProfile_visit", {
                kolID: props.data.id,
              })
            }}>
            <Link
              className="block"
              to={{
                pathname:
                  "/" +
                  (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                  "/p/" +
                  props.data.id,
                state: { kol: props.data },
              }}>
              <h3 className="font-semibold text-lg text-gray-700 overflow-hidden overflow-ellipsis">
                {props.data.userName}
                {/*    • {props.data.type}*/}
              </h3>
            </Link>
          </div>
        </div>
        <hr className="w-8 text-gray-500 my-2" />
        <p className="text-gray-500 mt-4 my-6 text-sm h-10 overflow-y-hidden overflow-hidden overflow-ellipsis">
          {props.data.description}
        </p>

        <div className="flex gap-2 my-2">
          <div className="flex text-sm font-light text-gray-500">
            <FontAwesomeIcon
              icon={faGlobeAsia}
              className="mr-2 text-red-default"
            />{" "}
            {getCountry(props.data.nationality).label}
          </div>
          <div className="flex text-sm font-light text-gray-500">
            <FontAwesomeIcon icon={faStar} className="mr-2 text-red-default" />
            {typeof props.data.score === "number"
              ? Math.round(props.data.score * 10) / 10
              : ""}
            ({props.data.jobScored})
          </div>
        </div>

        {/* KOL cate */}
        <div className="my-1 h-10 overflow-y-hidden">
          {Array.isArray(props.data.type) &&
            returnSelectedItems(props.data.type).map((t) => {
              return (
                <button
                  key={t.value}
                  className="bg-white hover:bg-gray-50 inline-block px-2 py-2 rounded-2xl text-sm rounded-lg my-0.5 mr-2 border border-gray-200 text-red-default"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_HOST_NAME}/` +
                        (localStorage.getItem("i18n-lang") === "en"
                          ? "en"
                          : "hk") +
                        "/s/?&t=" +
                        t.value
                    )
                  }}>
                  {t.label}
                </button>
              )
            })}
        </div>
      </div>
    </div>
  )
}
export default withRouter(KOLSmallCard)
