import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import NewAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import {
  getCrawlerDataList,
  getNeo4jFollowerCounts,
} from "../../HelpingFunction/crawlerDBHelper"
import {
  IGCrawler,
  IGCrawlerCondition,
  IGCrawlerConditionDefault,
  IGCrawlerOption,
  IGCrawlerState,
  returnIGCrawlerOnSelection,
} from "../../Model/IGCrawler"
import { Column, tableFunctions } from "../../Model/Table"
import {
  adminCrawlerTableSchema,
  defaultAdminCrawlerTableSchema,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../../Utils/TableHelperFunction"
import CustomTable from "../../Component/NewTable"
import { TABLE_FUNCTIONS, WIDTH_SIZE } from "../../Enum/TABLE_SYSTEM"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"

export const Crawler_neo4j: React.FC = (props) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const [data, setData] = useState<IGCrawler[]>([])
  const [tableCol, setTableCol] = useState<Column[][]>([
    defaultAdminCrawlerTableSchema(),
  ])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const [selectionValue, setSelectionValue] = useState<string>("")
  const [condition, setCondition] = useState<IGCrawlerCondition>({
    ...IGCrawlerConditionDefault,
  })

  const [page, setPage] = useState<number>(0)
  const [orderByOption, setOrderByOption] = useState<{
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }>({
    fieldName: "userName",
    direction: ORDER_BY_DIRECTION.ASC,
  })

  const [hasMore, setHasMore] = useState<boolean>(false)

  useEffect(() => {
    getCrawlerDataList(condition, orderByOption).then((result) => {
      let tableSets: Column[][] = []
      let igData: IGCrawler[] = []
      if (result.success) {
        let finalData = result.data

        //always return hasMore (for nextBtn) as true when loading prev page, otherwise nextBtn might not work
        if (condition.paginationDirection === PAGINATION_DIRECTION.PREV) {
          finalData = finalData.reverse()
          setHasMore(true)
        } else {
          setHasMore(result.hasMore)
        }
        igData = finalData
        let allPromise: Promise<any>[] = []
        finalData.map((e) => {
          allPromise.push(
            new Promise(async (resolve) => {
              tableSets.push(adminCrawlerTableSchema(e))
              resolve(null)
            })
          )
        })
        Promise.all(allPromise).then((result) => {
          setData(igData)
          setTableCol(tableSets)
          setIsLoaded(true)
        })
      }
    })
  }, [condition, orderByOption])

  const onClickRow = (rowIndex: number): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.EDIT_CRAWLER_STATE,
      returnFn: null,
      metaData: {
        data: data[rowIndex] as IGCrawler,
      },
    })
  }

  const labelFunction = (index: number) => {
    setPage(0)
    setIsLoaded(false)
    setData([])

    let val: any = IGCrawlerOption[index]
    let val2 = isNaN(parseInt(val.value)) ? "" : parseInt(val.value)

    setCondition(returnIGCrawlerOnSelection(val2 as IGCrawlerState | ""))
  }

  const onPrevPage = () => {
    let cp = page
    setPage(--cp)
    if (data.length > 0) {
      const t = [...data]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const val = t[0][orderByOption.fieldName]
      console.log("prev", val)
      setCondition({
        ...condition,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        paginationData: val,
        paginationDirection: PAGINATION_DIRECTION.PREV,
      })
    }
  }
  const onNextPage = () => {
    let cp = page
    setPage(++cp)
    if (data.length > 0) {
      const t = [...data]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const val = t.pop()[orderByOption.fieldName]
      console.log("next", val)
      setCondition({
        ...condition,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        paginationData: val,
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
    }
  }

  const tableFn: tableFunctions[] = [
    {
      title: t("ListContract.type-of-job"),
      data: {
        type: TABLE_FUNCTIONS.SELECT,
        onChange: (e) => {
          labelFunction(e.target.selectedIndex - 1)
        },
        labeledValue: selectionValue,
        selectData: IGCrawlerOption,
        width: WIDTH_SIZE.XL,
      },
    },
    {
      title: "Job ID",
      data: {
        type: TABLE_FUNCTIONS.INPUT,
        placeHolder: "Ig Username",
        textValue: condition.userName ? condition.userName : "",
        width: WIDTH_SIZE.XXL,
        onChange: (e) => {
          setCondition({
            ...IGCrawlerConditionDefault,
            userName: e.target.value,
          })
        },
      },
    },
  ]

  return (
    <div className="relative">
      {/* main content*/}
      <div className="relative max-w-7xl mx-auto pt-4 pb-36 relative px-4">
        <h1 className="text-grey-800 text-3xl font-bold leading-tight  ">
          <Trans>Crawler_neo4j.title</Trans>
        </h1>
        <hr className="mb-4" />

        <NewAlert
          title={t("Crawler_neo4j.warning-title")}
          alertType={ALERT_TYPE.ERROR}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          visible={true}
        />

        {/*  Crawler running */}
        <h2 className="text-grey-800 text-xl font-bold leading-tight  ">
          <Trans>Crawler_neo4j.crawler-result</Trans>
        </h2>

        <div className="my-4">
          <CustomTable
            cols={tableCol}
            clickAction={onClickRow}
            tableFn={tableFn}
            isFirstColStatic={false}
            notFoundDes={t("MyPayoutRequest.record-not-found")}
            isLoading={!isLoaded}
            rowIsLoading={!isLoaded}
            prevBtn={{
              disable: page === 0,
              onClick: () => onPrevPage(),
            }}
            pageNum={page}
            nextBtn={{
              disable: !hasMore,
              onClick: () => onNextPage(),
            }}
            isLastRecord={!hasMore}
          />
        </div>
      </div>
    </div>
  )
}
