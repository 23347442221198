import React, { useEffect, useState } from "react"
import { NEW_CUSTOMER } from "../../Enum/LOGIN_PAGE_TYPE"
import {
  getJobList,
  jobListCondition,
} from "../../HelpingFunction/Job/JobDBHelper"
import { Job, JobFilterDefault } from "../../Model/Job"
import { Column } from "../../Model/Table"
import {
  adminProfileJobTableSchema,
  defaultAdminProfileJobTableSchema,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../../Utils/TableHelperFunction"
import { isChatroomExists } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import CustomTable from "../../../src/Component/NewTable"
import { useTranslation } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"

interface Props {
  auth: any
  profile: NEW_CUSTOMER
}

const CustomerJobProfiler = (props: Props) => {
  const { t } = useTranslation()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [profile, setProfile] = useState({})

  const [jobs, setJobs] = useState<Job[]>([])
  const [isLoadedJobs, setIsLoadedJobs] = useState<boolean>(false)
  const [condition, setCondition] = useState<jobListCondition>(JobFilterDefault)

  const [listJobs, setListJobs] = useState<Column[][]>([
    defaultAdminProfileJobTableSchema(),
  ])

  const [isGettingJob, setIsGettingJob] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [pageNum, setPageNum] = useState<number>(0)

  const [orderByOption, setOrderByOption] = useState<{
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }>({
    fieldName: "lastUpdate",
    direction: ORDER_BY_DIRECTION.DESC,
  })

  useEffect(() => {
    setIsGettingJob(true)
    if (Array.isArray(jobs) && isLoadedJobs) {
      let tableSets: Column[][] = []

      jobs.map(async (jobData, index) => {
        tableSets.push(adminProfileJobTableSchema(jobData, index))
      })
      setListJobs(tableSets)
      setIsGettingJob(false)
    }
  }, [jobs])

  const onClickRow = async (index: number) => {
    if (jobs) {
      await isChatroomExists(jobs[index].CustomerID, jobs[index].KOLID).then(
        (res) => {
          if (res.success) {
            window.open(
              process.env.REACT_APP_HOST_NAME +
                "/" +
                getUserLang() +
                "/c?chatroomID=" +
                res.chatroomID +
                "&type=contract&metaPath=" +
                jobs[index].id
            )
          } else {
            window.open(
              process.env.REACT_APP_HOST_NAME +
                "/" +
                getUserLang() +
                "/c?chatroomID=" +
                "undefined" +
                "&type=contract&metaPath=" +
                jobs[index].id
            )
          }
        }
      )
    }
  }

  useEffect(() => {
    console.log(props.profile)
    setIsLoadedJobs(false)
    setIsLoaded(false)

    if (typeof props.profile.objectID !== "undefined") {
      getCustomerJobs(props.profile.objectID)
      setProfile(props.profile)
      setIsLoaded(true)
    }
  }, [props.profile, condition])
  const getCustomerJobs = (customerID: string) => {
    getJobList(condition, customerID, orderByOption, 11)
      .then((result) => {
        if (result.success) {
          let finalData = result.data

          //always return hasMore (for nextBtn) as true when loading prev page, otherwise nextBtn might not work
          if (condition.paginationDirection === PAGINATION_DIRECTION.PREV) {
            finalData = finalData.reverse()
            setHasMore(true)
          } else {
            setHasMore(result.hasMore)
          }
          setIsLoadedJobs(true)
          setJobs(finalData)
        } else {
          setIsLoadedJobs(true)
          setJobs(jobs)
          setHasMore(false)
        }
      })
      .catch((err) => console.log(err))
  }

  const previousPagAction = () => {
    if (pageNum >= 1 && jobs.length > 0) {
      setIsLoaded(false)
      setCondition({
        ...condition,
        paginationData: jobs[0].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.PREV,
      })
      setPageNum(pageNum - 1)
    }
  }
  const nextPagAction = () => {
    if (hasMore && jobs.length > 0) {
      setIsLoaded(false)
      setCondition({
        ...condition,
        paginationData: jobs[9].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
      setPageNum(pageNum + 1)
    }
  }

  if (isLoadedJobs && isLoaded && typeof jobs !== "undefined") {
    return (
      <div>
        <ul>
          <CustomTable
            cols={listJobs}
            clickAction={onClickRow}
            isFirstColStatic={false}
            isLoading={!isLoadedJobs}
            rowIsLoading={isGettingJob}
            prevBtn={{
              disable: false,
              onClick: previousPagAction,
            }}
            pageNum={pageNum}
            nextBtn={{
              disable: !hasMore,
              onClick: nextPagAction,
            }}
            notFoundDes={t("MyTransaction.record-not-found")}
            isLastRecord={!hasMore}
          />
        </ul>
      </div>
    )
  }
  return <div>Loading</div>
}
export default CustomerJobProfiler
