import { DateObj } from "./Utilities"
import { v4 as uuidv4 } from "uuid"

export interface Article {
  id: string
  title: string
  content: string
  featureImage: string
  isPublish: boolean
  createDate: Date | DateObj
  lastModified: Date | DateObj
  publishDate: Date | DateObj
  language: string
  urlTitle: string
  keywords: string[]
  authorName: string
  canonicalLink?: string
}

export const articleDefault: Article = {
  id: uuidv4(),
  title: "",
  content: "",
  featureImage: "",
  isPublish: false,
  createDate: new Date(),
  lastModified: new Date(),
  publishDate: new Date(),
  language: "EN",
  urlTitle: "",
  keywords: [],
  authorName: "",
  canonicalLink: "",
}
