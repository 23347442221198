/* eslint-disable max-params */
import { Job } from "../Model/Job"
import { Column } from "../Model/Table"
import {
  BADGE_TYPE,
  BUTTON_TYPE,
  ICON_TYPE,
  TABLE_TYPE,
  WIDTH_SIZE,
} from "../Enum/TABLE_SYSTEM"
import {
  faArrowUpRightFromSquare,
  faChartPie,
  faCircleCheck,
  faCircleXmark,
  faEye,
  faHeart,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons"
import { BankTransaction } from "../Model/BankTransaction"
import { intToFloat } from "./Converter"
import AdminRequest, { ADMIN_REQUEST } from "../Model/AdminRequest"
import { Article } from "../Model/Article"
import { returnJobBadgeSetting } from "../HelpingFunction/JobUtility"
import { getCountry } from "./Countries"
import { KOLCombine } from "../Model/KOLCombine"
import { returnKOLRecomText } from "./SearchKOLUtils"
import { t } from "i18next"
import { IGCrawler, returnIGCrawlerStateBadge } from "../Model/IGCrawler"
import { DateObj } from "../Model/Utilities"
import {
  returnCategoryName,
  returnSelectedItem,
  tagTypeSelection,
} from "./TypeList"
import {
  returnAudienceAgeData,
  returnAudienceCityData,
  returnAudienceGenderData,
} from "../HelpingFunction/Followers/FollowerHelper"
import { getUserLang } from "./utiltyHelper"
import { ADMIN_REQUEST_STATUS_TYPE } from "../Enum/APP_TYPE"

export enum ORDER_BY_DIRECTION {
  DESC = "desc",
  ASC = "asc",
}

export enum PAGINATION_DIRECTION {
  PREV,
  NEXT,
}

export interface SORT_OPTION {
  isDefault?: boolean
  fieldName: string
  direction: ORDER_BY_DIRECTION | false
}

//add default sorting direction to the soon be sorted type
// and reverse direction if sort again
export const sortDirectionFn = (
  order: ORDER_BY_DIRECTION | false
): ORDER_BY_DIRECTION => {
  if (order === false) {
    return ORDER_BY_DIRECTION.DESC
  }
  return order === ORDER_BY_DIRECTION.DESC
    ? ORDER_BY_DIRECTION.ASC
    : ORDER_BY_DIRECTION.DESC
}

export const getDirectionSign = (
  o: ORDER_BY_DIRECTION,
  p: PAGINATION_DIRECTION | ""
): "<" | ">" => {
  if (o === ORDER_BY_DIRECTION.DESC && p === PAGINATION_DIRECTION.NEXT) {
    // if order by desc and pagination is next, the pagination data should get the `last` item on the list and `smaller` than
    return "<"
  } else if (o === ORDER_BY_DIRECTION.DESC && p === PAGINATION_DIRECTION.PREV) {
    // if order by desc and pagination is prev, the pagination data should get the `first` item on the list and `larger` than
    return ">"
  } else if (o === ORDER_BY_DIRECTION.ASC && p === PAGINATION_DIRECTION.NEXT) {
    // if order by asc and pagination is next, the pagination data should get the `last` item on the list and `larger` than
    return ">"
  } else if (o === ORDER_BY_DIRECTION.ASC && p === PAGINATION_DIRECTION.PREV) {
    // if order by asc and pagination is prev, the pagination data should get the `first` item on the list and `smaller` than
    return "<"
  }
  return "<"
}

export const getNewSortDirection = (
  arr: SORT_OPTION[],
  fieldName: string
): ORDER_BY_DIRECTION | false => {
  const val = arr.find((e) => e.fieldName === fieldName)
  if (val) {
    return sortDirectionFn(val.direction)
  }
  return false
}

const getSortDirection = (
  arr: SORT_OPTION[],
  fieldName: string
): ORDER_BY_DIRECTION | false => {
  const val = arr.find((e) => e.fieldName === fieldName)
  if (val) {
    return val.direction
  }
  return false
}

export const getMySort = (arr: SORT_OPTION[]): SORT_OPTION => {
  if (Array.isArray(arr)) {
    const result = arr.find((e) => e.direction !== false)
    if (result) {
      return result
    }

    const defaultOption = arr.find((e) => e.isDefault === true)
    if (defaultOption) {
      return {
        ...defaultOption,
        direction: ORDER_BY_DIRECTION.DESC,
      }
    }

    return {
      fieldName: "",
      direction: false,
    }
  }
  return {
    fieldName: "",
    direction: false,
  }
}

export const onSort = (
  sortOption: SORT_OPTION[],
  fieldName: string
): SORT_OPTION[] => {
  const newDir = getNewSortDirection(sortOption, fieldName)

  let newSortOption: SORT_OPTION[] = []
  sortOption.forEach((e) => {
    if (e.fieldName === fieldName) {
      newSortOption.push({
        ...e,
        direction: newDir,
      })
    } else {
      newSortOption.push({
        ...e,
        direction: false,
      })
    }
  })
  return newSortOption
}

export const defaultArticleTableSort: SORT_OPTION[] = [
  {
    isDefault: true,
    direction: false,
    fieldName: "lastModified",
  },
]

// for displaying empty data results
const nullResultChecker = (res: string) => {
  if (res && res !== "-") {
    return res
  }
  return getUserLang() === "en" ? "No results" : "沒有結果"
}

const statusBadgeStyling = (
  status: ADMIN_REQUEST_STATUS_TYPE,
  text: string[]
): {
  type: BADGE_TYPE
  text: string
} => {
  if (status === ADMIN_REQUEST_STATUS_TYPE.FINISHED) {
    return { type: BADGE_TYPE.SUCCESS, text: text[0] }
  } else if (status === ADMIN_REQUEST_STATUS_TYPE.REJECT) {
    return { type: BADGE_TYPE.FAIL, text: text[2] }
  }
  return { type: BADGE_TYPE.PENDING, text: text[1] }
}

export const defaultAdminJobTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-price",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-transaction-fee",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-payout-date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-status",
    },
  ]
}

export const adminJobTableSchema = (job: Job): Column[] => {
  const badgeElement = returnJobBadgeSetting(job)
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-name",
      value: job.ServicePlanName,
    },
    {
      type: TABLE_TYPE.PRICE,
      title: "MyTransaction.job-price",
      currency: job.currency,
      value: job.price,
    },
    {
      type: TABLE_TYPE.ROW,
      title: "MyTransaction.job-price",
      component: [
        {
          type: TABLE_TYPE.PRICE,
          title: "",
          currency: job.currency,
          value:
            Math.round(
              job.price *
                (parseInt(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  typeof job.fee !== "undefined"
                    ? job.fee
                    : process.env.REACT_APP_TRANSACTION_FEE
                ) /
                  100) *
                100
            ) / 100,
        },
        {
          type: TABLE_TYPE.BADGE,
          badgeType: BADGE_TYPE.TAG,
          title: "",
          value:
            (typeof job.fee !== "undefined"
              ? job.fee
              : process.env.REACT_APP_TRANSACTION_FEE) + " %",
        },
      ],
    },
    {
      type: TABLE_TYPE.DATE,
      title: "MyTransaction.job-payout-date",
      value: job.completePayout ? job.payoutDate : "",
    },
    {
      type: TABLE_TYPE.BADGE,
      badgeType: badgeElement.type,
      title: "MyTransaction.job-status",
      value: badgeElement.label,
    },
  ]
}

export const defaultAdminRequestTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "AdminRequest.request-id",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "AdminRequest.job-id",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "AdminRequest.request-type",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "AdminRequest.create-date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "AdminRequest.status",
    },
  ]
}

export const adminRequestTableSchema = (request: ADMIN_REQUEST): Column[] => {
  let badgeText: string[] = [
    "AdminRequest.finished",
    "AdminRequest.not-yet-finished",
    "AdminRequest.reject",
  ]
  const badgeElement = statusBadgeStyling(request.status, badgeText)

  return [
    {
      type: TABLE_TYPE.STRING,
      title: "AdminRequest.request-id",
      value: request.id ? request.id : "",
      fixedWidth: WIDTH_SIZE.MD,
    },
    {
      type: TABLE_TYPE.STRING,
      title: "AdminRequest.job-id",
      value: request.jobID ? request.jobID : "",
      fixedWidth: WIDTH_SIZE.MD,
    },
    {
      type: TABLE_TYPE.BADGE,
      badgeType: BADGE_TYPE.TAG,
      title: "AdminRequest.request-type",
      value: request.type,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "AdminRequest.create-date",
      value: request.createDate,
    },
    {
      type: TABLE_TYPE.BADGE,
      badgeType: badgeElement.type,
      title: "AdminRequest.status",
      value: badgeElement.text,
    },
  ]
}

export const defaultAdminProfileJobTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "No. #",
    },

    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-status",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.paid",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.status",
    },
  ]
}

export const adminProfileJobTableSchema = (
  job: Job,
  index: number
): Column[] => {
  const badgeElement = returnJobBadgeSetting(job)

  return [
    {
      type: TABLE_TYPE.STRING,
      title: "No. #",
      value: String(index),
    },
    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-name",
      value: job.ServicePlanName,
    },
    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-price",
      value: "$" + job.price,
    },
    {
      type: TABLE_TYPE.BADGE,
      badgeType: badgeElement.type,
      title: "MyTransaction.job-status",
      value: badgeElement.label,
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.paid ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ListContract.paid",
      value: job.paid ? faCircleCheck : faCircleXmark,
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.isFinish ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ListContract.status",
      value: job.isFinish ? faCircleCheck : faCircleXmark,
    },
  ]
}

export const defaultAdminArticleTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListArticle.article-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "Create Date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListArticle.functions",
    },
  ]
}

export const adminArticleTableSchema = (
  article: Article,
  sortFn: (type: string) => void,
  orderList: any,
  editArticleFn: () => void,
  previewArticleFn: () => void,
  toArticleFn: () => void,
  deleteArticleFn: () => void
): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "ListArticle.article-name",
      value: article.title,
      fixedWidth: WIDTH_SIZE.XL,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "Last Modified",
      value: article.lastModified,
      canSort: {
        order: getSortDirection(orderList, "lastModified"),
        sortFn: () => sortFn("lastModified"),
      },
    },
    {
      type: TABLE_TYPE.ROW,
      title: "ListArticle.functions",
      fixedWidth: WIDTH_SIZE.MD,
      component: [
        {
          type: TABLE_TYPE.BUTTON,
          buttonType: BUTTON_TYPE.GHOST,
          title: "",
          icon: faPenToSquare,
          onClick: editArticleFn,
          value: "",
        },
        {
          type: TABLE_TYPE.BUTTON,
          buttonType: BUTTON_TYPE.GHOST,
          title: "",
          icon: faEye,
          onClick: previewArticleFn,
          value: "",
        },
        {
          type: TABLE_TYPE.BUTTON,
          buttonType: BUTTON_TYPE.GHOST,
          title: "",
          icon: faArrowUpRightFromSquare,
          onClick: toArticleFn,
          value: "",
        },
        {
          type: TABLE_TYPE.BUTTON,
          buttonType: BUTTON_TYPE.GHOST,
          title: "",
          icon: faTrashCan,
          onClick: deleteArticleFn,
          value: "",
        },
      ],
    },
  ]
}

export const defaultAdminTranscationTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-name",
    },

    {
      type: TABLE_TYPE.DEFAULT,
      title: "Date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "Amount Cr/Dr",
    },
  ]
}

export const adminTranscationTableSchema = (
  bankTrans: BankTransaction
): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-name",
      value: !(bankTrans.data instanceof AdminRequest)
        ? bankTrans.data.ServicePlanName
        : "",
    },
    {
      type: TABLE_TYPE.DATE,
      title: "Date",
      value: new Date(bankTrans.date * 1000),
    },
    {
      type: TABLE_TYPE.COL,
      title: "Amount Cr/Dr",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value:
            (bankTrans.type === "ADMIN_REQUEST" ? "- " : "+ ") +
            intToFloat((1 * bankTrans.amount) / 100, 2),
          fixedWidth: WIDTH_SIZE.MD,
        },
        {
          type: TABLE_TYPE.BADGE,
          badgeType: bankTrans.isFinish
            ? BADGE_TYPE.JOB_DONE
            : BADGE_TYPE.WORKING,
          title: "MyTransaction.job-status",
          value: bankTrans.isFinish
            ? "MyPayoutRequest.finished"
            : "MyPayoutRequest.in-progress",
        },
      ],
    },
  ]
}

export const searchKOLTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.userID",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.top-cate",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.top-genre",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-age-group",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-gender",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-country",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
  ]
}

export const searchKOLTableSchema = (
  kol: KOLCombine,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setViewKOLPercentageModal: (metaData: { metaData: KOLCombine }) => void,
  // whether user toggle to show recommendation
  showHelpText: boolean,
  setAddToCompareGroup: (metaData: { metaData: string }) => void
): Column[] => {
  const topCateStat = kol.topCatePercentage
    ? (kol.topCatePercentage * 100).toFixed(2)
    : "-"
  const topGenreStat = kol.topGenrePercentage
    ? (kol.topGenrePercentage * 100).toFixed(2)
    : "-"
  const largeAgeItem = returnAudienceAgeData(
    kol.audience_gender_age,
    true
  ).sort((a, b) => b[1] - a[1])

  const largeGenderItem = returnAudienceGenderData(
    kol.audience_gender_age,
    true
  ).sort((a, b) => b[1] - a[1])
  const largeCountryItem = returnAudienceCityData(
    kol.audience_country,
    20
  ).sort((a, b) => b[1] - a[1])

  const gender = largeGenderItem[0][1] > 0 ? largeGenderItem[0] : ""
  const age = largeAgeItem[0][1] > 0 ? largeAgeItem[0] : ""

  const totalCountryCount = largeCountryItem.length
    ? largeCountryItem.map((e) => e[1]).reduce((a, b) => a + b)
    : 0
  const country: {
    name: string
    percentage: number
  } = {
    name: largeCountryItem[0] ? largeCountryItem[0][0] : "",
    percentage: largeCountryItem[0]
      ? (largeCountryItem[0][1] / totalCountryCount) * 100
      : 0,
  }
  return [
    {
      type: TABLE_TYPE.COL,
      title: "",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "SearchKOL.userID",
          value: kol.userName,
        },
        {
          type: TABLE_TYPE.BUTTON,
          buttonType: BUTTON_TYPE.GHOST,
          title: "",
          icon: faChartPie,
          onClick: () => {
            setViewKOLPercentageModal({
              metaData: kol,
            })
          },
          hoverActive: false,
          value: t("SearchKOL.data-analysis"),
          hidden: kol.percentages.length === 0,
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          badgeType: BADGE_TYPE.SUCCESS,
          value: returnKOLRecomText(kol.percentages),
          hidden:
            !showHelpText ||
            kol.percentages.length === 0 ||
            returnKOLRecomText(kol.percentages) === "",
        },
      ],
    },
    {
      type: TABLE_TYPE.STRING,
      title: "SearchKOL.followers",
      value: kol.noOfFollowers.toString(),
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.top-cate",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: topCateStat + "%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(returnCategoryName(kol.topCateID)),
          badgeType:
            returnCategoryName(kol.topCateID) !== "-"
              ? BADGE_TYPE.TAG
              : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.top-genre",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: topGenreStat + "%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(returnSelectedItem(kol.topGenreID)!.label),
          badgeType:
            returnSelectedItem(kol.topGenreID)!.label !== "-"
              ? BADGE_TYPE.TAG
              : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-age-group",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: age ? age[1].toFixed(2) + "%" : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(age[0]),
          badgeType: age ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-gender",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: gender ? gender[1].toFixed(2) + "%" : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(gender[0]),
          badgeType: gender ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-country",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: country.percentage
            ? country.percentage.toFixed(2) + "%"
            : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(country.name),
          badgeType: country.name ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.BUTTON,
      buttonType: BUTTON_TYPE.INFO,
      title: "MyTransaction.functions",
      icon: faHeart,
      onClick: () =>
        setAddToCompareGroup({
          metaData: kol.userName,
        }),
      value: "SearchKOL.save",
      fixedWidth: WIDTH_SIZE.MD,
    },
  ]
}

export const defaultAdminCrawlerTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "Crawler_neo4j.username",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "Crawler_neo4j.status",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "Crawler_neo4j.look4kol-reg",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "Crawler_neo4j.complement",
    },

    {
      type: TABLE_TYPE.DEFAULT,
      title: "Crawler_neo4j.last-update",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "Crawler_neo4j.action",
    },
  ]
}

export const adminCrawlerTableSchema = (igCrawler: IGCrawler): Column[] => {
  const lastUpdate: DateObj = igCrawler.lastUpdate as DateObj

  return [
    {
      type: TABLE_TYPE.STRING,
      title: "Crawler_neo4j.username",
      value: igCrawler.userName,
    },
    {
      type: TABLE_TYPE.BADGE,
      badgeType: returnIGCrawlerStateBadge(igCrawler.status).type,
      title: "Crawler_neo4j.status",
      value: returnIGCrawlerStateBadge(igCrawler.status).label,
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: igCrawler.isLook4kolReg ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "Crawler_neo4j.look4kol-reg",
      value: igCrawler.isLook4kolReg ? faCircleCheck : faCircleXmark,
    },
    {
      type: TABLE_TYPE.STRING,
      title: "Crawler_neo4j.complement",
      value: igCrawler.noOfFollowersCrawled + " / " + igCrawler.noOfFollowers,
    },
    {
      type: TABLE_TYPE.STRING,
      title: "Crawler_neo4j.last-update",
      value: new Date((lastUpdate.seconds as number) * 1000).toString(),
    },
    // {
    //   type: TABLE_TYPE.BUTTON,
    //   buttonType: BUTTON_TYPE.GHOST,
    //   title: "Crawler_neo4j.action",
    //   icon: faPencil,
    //   onClick: () => {
    //     console.log("hi")
    //   },
    //   hoverActive: false,
    //   value: t("Crawler_neo4j.edit"),
    // },
  ]
}

export const defaultIGPercentageTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      fixedWidth: WIDTH_SIZE.LG,
      title: "AdminViewKOLPercentageModal.item",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      fixedWidth: WIDTH_SIZE.MD,
      title: "AdminViewKOLPercentageModal.percentage",
    },
  ]
}

export const getIGPercentageTableSchema = (
  total: number,
  data: {
    count: number
    item: string
    percentages: number
  }
): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "AdminViewKOLPercentageModal.item",
      fixedWidth: WIDTH_SIZE.XL,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: returnSelectedItem(data.item).label as string,
    },
    {
      type: TABLE_TYPE.STRING,
      fixedWidth: WIDTH_SIZE.MD,
      title: "AdminViewKOLPercentageModal.percentage",
      value: ((data.count / total) * 100).toFixed(2).toString() + "%",
    },
  ]
}

export const getIGFollowerPercentageTableSchema = (data: {
  item: string
  percentage: number
}): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "AdminViewKOLPercentageModal.item",
      fixedWidth: WIDTH_SIZE.XL,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: returnSelectedItem(data.item).label as string,
    },
    {
      type: TABLE_TYPE.STRING,
      fixedWidth: WIDTH_SIZE.MD,
      title: "AdminViewKOLPercentageModal.percentage",
      value: (data.percentage * 100).toFixed(2).toString() + "%",
    },
  ]
}
export const compareKOLTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.userID",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.top-cate",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.top-genre",
    },
  ]
}
export const compareKOLTableSchema = (
  kol: KOLCombine,
  filterData: tagTypeSelection[],
  orderList: SORT_OPTION[],
  sortFn: (type: string) => void
): Column[] => {
  const topCateStat = kol.topCatePercentage
    ? (kol.topCatePercentage * 100).toFixed(2)
    : "-"
  const topGenreStat = kol.topGenrePercentage
    ? (kol.topGenrePercentage * 100).toFixed(2)
    : "-"
  let groupColumns: Column[] = []
  filterData.forEach((data) => {
    const itemCount = kol.percentages.find((k) => {
      return k.item === data.value
    })
    groupColumns.push({
      type: TABLE_TYPE.STRING,
      title: data.label + "(%)",
      value: itemCount ? (itemCount.percentages * 100).toFixed(2) + "%" : "-%",
      canSort: {
        order: getSortDirection(orderList, data.value),
        sortFn: () => sortFn(data.value),
      },
    })
  })
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "SearchKOL.userID",
      value: kol.userName,
      canSort: {
        order: getSortDirection(orderList, "userName"),
        sortFn: () => sortFn("userName"),
      },
    },
    {
      type: TABLE_TYPE.STRING,
      title: "SearchKOL.followers",
      value: kol.noOfFollowers.toString(),
      canSort: {
        order: getSortDirection(orderList, "noOfFollowers"),
        sortFn: () => sortFn("noOfFollowers"),
      },
    },
    {
      type: TABLE_TYPE.HEAD,
      title: "SearchKOL.top-cate",
      component: {
        type: TABLE_TYPE.COL,
        title: "",
        component: [
          {
            type: TABLE_TYPE.STRING,
            title: "topCatePercentage",
            value: topCateStat + "%",
            canSort: {
              order: getSortDirection(orderList, "topCatePercentage"),
              sortFn: () => sortFn("topCatePercentage"),
            },
          },
          {
            type: TABLE_TYPE.BADGE,
            title: "",
            value: nullResultChecker(returnCategoryName(kol.topCateID)),
            badgeType:
              returnCategoryName(kol.topCateID) !== "-"
                ? BADGE_TYPE.TAG
                : BADGE_TYPE.FAIL,
          },
        ],
      },
      canSort: "topCatePercentage",
    },
    {
      type: TABLE_TYPE.HEAD,
      title: "SearchKOL.top-genre",
      component: {
        type: TABLE_TYPE.COL,
        title: "",
        component: [
          {
            type: TABLE_TYPE.STRING,
            title: "topGenrePercentage",
            value: topGenreStat + "%",
            canSort: {
              order: getSortDirection(orderList, "topGenrePercentage"),
              sortFn: () => sortFn("topGenrePercentage"),
            },
          },
          {
            type: TABLE_TYPE.BADGE,
            title: "",
            value: nullResultChecker(returnSelectedItem(kol.topGenreID)!.label),
            badgeType:
              returnSelectedItem(kol.topGenreID)!.label !== "-"
                ? BADGE_TYPE.TAG
                : BADGE_TYPE.FAIL,
          },
        ],
      },
      canSort: "topGenrePercentage",
    },

    ...groupColumns,
  ]
}
