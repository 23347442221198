import React, { useState } from "react"
import { Trans } from "react-i18next"
import { returnSelectedItems } from "../../../../Utils/TypeList"
import Spinner from "../../../Spinner"
import { markAdminRequestAsFinished } from "../../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"

const RecommendedRequest = (props) => {
  const [isLoading, setIsLoading] = useState(false)

  const markAsFinished = () => {
    markAdminRequestAsFinished(props.request.id)
      .then((result) => {
        setIsLoading(false)
        props.closeModal()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      {/* Q1*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q1</Trans>
        </div>
        <div>{props.request.data.title}</div>
      </section>

      {/* Q2*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q2</Trans>
        </div>
        <div>
          <button
            className={[
              "block w-full xl:w-96 mx-auto rounded-2xl my-4 py-3 px-2 md:px-4 text-center",
              "bg-red-default text-white",
            ].join(" ")}>
            {props.request.data.type}
          </button>
        </div>
      </section>

      {/* Q3*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q3</Trans>
        </div>
        <div>
          {returnSelectedItems(props.request.data.categories).map((el) => {
            return (
              <div
                className="mx-2 rounded-full py-2 px-4"
                key={"cate" + el.value}>
                {el.label}
              </div>
            )
          })}
        </div>
      </section>

      {/* Q4*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q4</Trans>
        </div>
        <div>
          {props.request.data.interestPreference.map((el, index) => {
            return (
              <div
                key={"interest" + index}
                className=" w-full xl:w-96 mx-auto grid rounded-2xl bg-white border border-red-default p-3 my-2"
                style={{ gridTemplateColumns: "30px auto" }}>
                <div className="text-center text-gray-400">3</div>
                <div>
                  <input
                    type="text"
                    className=" border-b w-full"
                    value={el}
                    disabled={true}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </section>

      {/*    Q5*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q5</Trans>
        </div>
        <div>
          <input
            type="number"
            className=" border-b w-full"
            value={props.request.data.budget}
            min={0}
            max={9999999}
          />
        </div>
      </section>

      {/*    Q6*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q6</Trans>
        </div>
        <div>
          <input
            type="number"
            className=" border-b w-full"
            value={props.request.data.numberOfKOL}
          />
        </div>
      </section>

      {/*    Q7*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q7</Trans>
        </div>
        <div>
          {props.request.data.kolRequirement.map((e, index) => {
            return (
              <button
                key={"requ" + index}
                className={[
                  "block w-full xl:w-96 mx-auto rounded-2xl py-3 px-2 md:px-4 text-center",
                  "bg-red-default text-white",
                ].join(" ")}>
                {e === "1" && <Trans>RecommendQuestion.q7-1</Trans>}
                {e === "2" && <Trans>RecommendQuestion.q7-2</Trans>}
                {e === "3" && <Trans>RecommendQuestion.q7-3</Trans>}
                {e === "4" && <Trans>RecommendQuestion.q7-4</Trans>}
                {e === "5" && <Trans>RecommendQuestion.q7-5</Trans>}
                {e === "6" && <Trans>RecommendQuestion.q7-6</Trans>}
              </button>
            )
          })}
        </div>
      </section>

      {/*    Q8*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q8</Trans>
        </div>
        <div>
          <button
            className={[
              "block w-full xl:w-96 mx-auto rounded-2xl py-3 px-2 md:px-4 text-center",
              "bg-red-default text-white",
            ].join(" ")}>
            {props.request.data.numberOfFLsPreference}
          </button>
        </div>
      </section>

      {/*    Q9*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q9</Trans>
        </div>
        <div>
          <button
            className={[
              "block w-full xl:w-96 mx-auto rounded-2xl my-4 py-3 px-2 md:px-4 text-center",
              "bg-red-default text-white",
            ].join(" ")}>
            {props.request.data.goal === "reach" && (
              <Trans>RecommendQuestion.q9-reach</Trans>
            )}
            {props.request.data.goal === "conversion" && (
              <Trans>RecommendQuestion.q9-conversion</Trans>
            )}
            {props.request.data.goal === "brand" && (
              <Trans>RecommendQuestion.q9-brand</Trans>
            )}
          </button>
        </div>
      </section>

      {/*    Q10*/}
      <section className="my-2  py-4 border-b border-gray-300">
        <div className="text-left text-lg font-bold text-gray-800 mt-8 mb-2">
          <Trans>RecommendQuestion.q10</Trans>
        </div>
        <div>
          <input
            className="block mx-auto  w-full xl:w-96  mt-6 mb-2  text-xl border border-red-default p-3 rounded-2xl text-red-default"
            value={props.request.data.email}
          />
        </div>
      </section>

      {!props.request.isFinish && (
        <>
          <div className="">
            <button
              className="rounded-2xl bg-green-500 hover:bg-green-600 text-white w-full p-2"
              onClick={() => markAsFinished()}>
              {isLoading ? (
                <Spinner />
              ) : (
                <Trans>AdminEditRequestModal.mark-as-finished</Trans>
              )}
            </button>
          </div>
          <div className="mt-1 text-sm text-gray-400 text-right">
            when press this button, the item will be marked as "FINISHED"
          </div>
        </>
      )}
    </div>
  )
}
export default RecommendedRequest
