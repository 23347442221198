import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"

type PropsType = {
  verified: string | null
  queryPushFn: (query: { verified?: string }) => void
  setCondition: (state: any) => void
}

const IsVerifiedToggle: React.FC<PropsType> = ({
  verified,
  queryPushFn,
  setCondition,
}) => {
  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })

  const [isToggled, setIsToggled] = useState<boolean>(
    searchKOLCondition.isVerified
  )
  const [isFirstRefresh, setIsFirstRefresh] = useState<boolean>(true)

  const verifiedUpdateDetector = (
    value: string | null,
    searchCondition: boolean
  ) => {
    if (value !== "") {
      if (searchCondition) {
        return value !== "true"
      }
      return value !== "false"
    }
    return false
  }

  useEffect(() => {
    if (
      verified &&
      searchKOLCondition.isVerified !== ((verified === "true") as boolean)
    ) {
      setCondition({
        key: "isVerified",
        value: (verified === "true") as boolean,
      })
    }
  }, [verified, searchKOLCondition])

  return (
    <>
      <div
        className="md:mx-4 mb-10 xl:grid gap-4"
        style={{ gridTemplateColumns: "50px auto" }}>
        <div className="hidden xl:inline bg-red-default w-10 h-10 text-white p-2 text-center text-lg rounded-2xl">
          <FontAwesomeIcon icon={faLock} />
        </div>

        <div className="form-control">
          <label className=" label cursor-pointer">
            <h2 className="font-bold text-lg ">
              <Trans>IsVerifiedToggle.title</Trans>
            </h2>
            <input
              type="checkbox"
              className="toggle"
              checked={isToggled}
              onChange={() => {
                setIsToggled(!isToggled)
                console.log("pressed isVerified: ", !isToggled)
                setCondition({
                  key: "isVerified",
                  value: !isToggled as boolean,
                })
                queryPushFn({ verified: (!isToggled).toString() })
              }}
            />
          </label>
        </div>
      </div>
      <hr className="mb-4 border-gray-200 w-11/12 mx-auto" />
    </>
  )
}
export default IsVerifiedToggle
