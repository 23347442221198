import { Member } from "../../Model/Member"
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore"
import firebaseApp from "../../config/firebase"

const db = getFirestore(firebaseApp)

/**
 * get member object from firebase
 * @param {string} uid - firebase uid
 */

const getMember = (
  uid: string
): Promise<
  | {
      success: true
      data: Member
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Member", uid))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Member,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * update admin compare group, since customer should be the collection "Customer", only admin use "Member" collection
 * @param {string} uid - firebase uid
 * @param {object} compareGroup
 */

const updateAdminCompareGroup = (
  uid: string,
  compareGroup: [
    {
      data: string[]
      id: string
      title: string
      type: string
    }
  ]
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Member", uid), {
      kolCompareGroup: compareGroup,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * set bookmark list of a member
 * @param {string} uid - firebase uid
 * @param { string } bookmarkedKOLs - list of kolIDs
 */
const setMemberBookMarkList = (
  uid: string,
  bookmarkedKOLs: string[]
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Member", uid), {
      bookmark: bookmarkedKOLs,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export { getMember, updateAdminCompareGroup, setMemberBookMarkList }
