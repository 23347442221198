import { NEW_CUSTOMER } from "../../Enum/LOGIN_PAGE_TYPE"
import { getAPIPath } from "../../Utils/HelpingFunction"
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import firebaseApp from "../../config/firebase"
import Customer, { CustomerType } from "../../Model/Customer"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"

const db = getFirestore(firebaseApp)

/**
 * Set Customer for registration
 * @param {string} uid - firebase uid
 * @param {NEW_CUSTOMER} data - data will be store in Customer/{:uid}
 */

const setCustomer = (
  uid: string,
  data: NEW_CUSTOMER
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await setDoc(doc(db, "Customer", uid), data)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * Create a new Customer account using email and password
 * @param {NEW_CUSTOMER} newCustomer - data for new customer user
 * @param {string} password - user password
 */

const createCustomer = (
  newCustomer: NEW_CUSTOMER,
  password: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/customer"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...newCustomer,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}

/**
 * Get Customer profile by CustomerID
 * @param CustomerID , allow uid
 */
const getCustomer = (
  CustomerID: string
): Promise<
  | {
      success: true
      data: CustomerType
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Customer", CustomerID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              uid: doc.id,
              ...doc.data(),
            } as Customer,
          })
        }

        return resolve({
          success: false,
        })
      })
      .catch((err) => console.error(err))
  })
}

/**
 * Create a new Customer account using email and password
 * @param {string} customerID - customerID
 */

const getCustomerCompanyName = (
  customerID: string
): Promise<
  | {
      success: true
      customerCompanyName: string
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/customer/" + customerID + "/company"), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
      .catch((err) => console.log(err))
  })
}

/**
 * updateCompareGroup
 * @param {string} customerID - customerID
 * @param {object} compareGroup
 */
const updateCompareGroup = (
  customerID: string,
  compareGroup: [
    {
      data: string[]
      id: string
      title: string
      type: string
    }
  ]
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Customer", customerID), {
      kolCompareGroup: compareGroup,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * getKOLCompareGroup
 * @param {string} customerID - customerID
 * @param {object} compareGroup
 */
const getKOLCompareGroup = (
  customerID: string
): Promise<
  | {
      success: true
      data: [
        {
          data: string[]
          id: string
          title: string
          type: string
        }
      ]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Customer", customerID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data: doc.data().kolCompareGroup ? doc.data().kolCompareGroup : [],
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

const searchCustomerAlgolia = (
  uid: string,
  searchQuery: string
): Promise<
  | {
      success: true
      data: any[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/customer?" +
            new URLSearchParams({
              uid: uid,
              searchStr: searchQuery,
            })
        ),
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            return resolve({
              success: true,
              data: response.data,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

export {
  setCustomer,
  createCustomer,
  getCustomer,
  getCustomerCompanyName,
  updateCompareGroup,
  getKOLCompareGroup,
  searchCustomerAlgolia,
}
