import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import BankAccountCard from "../../../../page-components/kol/BankAccountCard"
import { Trans } from "react-i18next"
import Spinner from "../../../Spinner"
import { intToFloat } from "../../../../Utils/Converter"
import {
  markAdminRequestAsFinished,
  returnStatusString,
} from "../../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import { ADMIN_REQUEST_STATUS_TYPE } from "../../../../Enum/APP_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../../redux/SUPPORTED_REDUX_FUNCTION"
import KOL from "../../../../Model/KOL"
import { getKOL } from "../../../../HelpingFunction/KOL/kolDBHelper"
import { isAuth } from "../../../../Utils/FirebaseUtils"
import {
  getJob,
  getJobList,
  jobListCondition,
} from "../../../../HelpingFunction/Job/JobDBHelper"
import { Job, JobFilterDefault } from "../../../../Model/Job"
import {
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
  adminProfileJobTableSchema,
  defaultAdminProfileJobTableSchema,
} from "../../../../Utils/TableHelperFunction"
import CustomTable from "../../../NewTable"
import { Column } from "../../../../Model/Table"
import { t } from "i18next"
import classNames from "classnames"

const PayoutRequest = (props: any) => {
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const setRequestStateChanged = (status: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_REQUEST_STATUS_CHANGED,
      data: status,
    })
  }
  const [isFinishLoading, setIsFinishLoading] = useState(false)
  const [kol, setKOL] = useState<KOL>()
  const [jobs, setJobs] = useState<Job[]>([])
  const [newJobList, setNewJobList] = useState<Job[]>([])
  const [condition, setCondition] = useState<jobListCondition>(JobFilterDefault)
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [isLoadedJobs, setIsLoadedJobs] = useState<boolean>(false)
  const [isGettingJob, setIsGettingJob] = useState<boolean>(false)
  const [pageNum, setPageNum] = useState<number>(0)
  const [listJobs, setListJobs] = useState<Column[][]>([
    defaultAdminProfileJobTableSchema(),
  ])

  useEffect(() => {
    if (isAuth(auth)) {
      getKOLDetails()
    }
  }, [])

  useEffect(() => {
    setIsGettingJob(true)
    if (Array.isArray(jobs) && isLoadedJobs) {
      let tableSets: Column[][] = []
      let newJobOrder: Job[] = []

      let allPromise: Promise<any>[] = []

      jobs.forEach(async (jobData, index) => {
        allPromise.push(
          new Promise(async (resolve) => {
            tableSets.push(
              adminProfileJobTableSchema(jobData, pageNum * 10 + index + 1)
            )
            newJobOrder.push(jobData)
            resolve(null)
          })
        )
      })

      Promise.all(allPromise).then((result) => {
        setListJobs(tableSets)
        setNewJobList(newJobOrder)
        setIsGettingJob(false)
      })
    }
  }, [jobs])

  const getKOLDetails = async () => {
    const result = await getKOL(props.request.requestUID)

    if (result.success) {
      setKOL(result.data)
    }
  }

  const markAsFinished = () => {
    setIsFinishLoading(true)
    markAdminRequestAsFinished(props.request.id)
      .then((result) => {
        setRequestStateChanged(true)
        setIsFinishLoading(false)
        props.closeModal()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (isAuth(auth)) {
      getJobData()
    }
  }, [auth])

  const getJobData = async () => {
    const result = await getJobList(
      JobFilterDefault,
      props.request.requestUID,
      { fieldName: "lastUpdate", direction: ORDER_BY_DIRECTION.DESC },
      10
    )

    if (result.success) {
      setIsLoadedJobs(true)
      setJobs(result.data)
      setHasMore(result.hasMore)
    }
  }
  const previousPagAction = () => {
    if (pageNum >= 1 && newJobList.length > 0) {
      setCondition({
        ...condition,
        paginationData: newJobList[0].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.PREV,
      })
      setPageNum(pageNum - 1)
    }
  }
  const nextPagAction = () => {
    if (hasMore && newJobList.length > 0) {
      setCondition({
        ...condition,
        paginationData: jobs[9].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
      setPageNum(pageNum + 1)
    }
  }

  const getTotalAmount = (amt: number) => {
    if (props.request.data.isFive) {
      const increase = amt * 0.05

      const newAmount = amt + increase
      return newAmount
    }
    return amt
  }

  return (
    <div className="w-full">
      <div className="md:grid md:grid-cols-2">
        <section className="md:border-r md:pr-3">
          <div>Status: {returnStatusString(props.request.status)}</div>
          {kol !== undefined && (
            <div className="flex flex-row gap-6 items-center mt-2 md:hidden">
              <div className=" text-gray-800 text-lg font-bold">
                KOL Details
              </div>
              <div className="flex flex-row gap-4 items-center justify-center mt-4">
                <div>
                  <img
                    className="w-12 h-12 rounded-full object-cover"
                    src={kol.avatarUrl}
                  />
                </div>
                <div>
                  <a
                    href={"https://look4kol.com/en/p/" + kol.userName}
                    target="_blank"
                    className="text-gray-700 font-semibold text-xl hover:text-primary">
                    @{kol.userName}
                  </a>
                  <p className="text-sm text-gray-500 font-medium">#{kol.id}</p>
                </div>
              </div>
            </div>
          )}
          <div
            className="w-full grid my-8 items-center"
            style={{ gridTemplateColumns: "120px auto" }}>
            <div className=" text-gray-800 text-lg font-bold">
              <Trans>BankWithdrawModal.to-account</Trans>
            </div>
            <BankAccountCard
              key={"bk" + props.index}
              bank={props.request.data.bankDetail}
              displayOnly={true}
            />
          </div>

          <div
            className="w-full grid my-8 items-center"
            style={{ gridTemplateColumns: "120px auto" }}>
            <div className=" text-gray-800 text-lg font-bold">
              <Trans>BankWithdrawModal.amount</Trans>
            </div>
            <div>
              <input
                type="text"
                className="w-full  border-gray-300 rounded-xl p-2 border "
                value={
                  intToFloat((1 * props.request.data.withdrawAmount) / 100, 2) +
                  " / " +
                  props.request.data.bankDetail.currency
                }
                disabled={true}
              />
            </div>
          </div>
          {props.request.data.hasOwnProperty("isFive") && (
            <>
              <div
                className="w-full grid items-center my-8"
                style={{ gridTemplateColumns: "120px auto" }}>
                <div className="text-amber-500 text-lg font-bold">
                  {" "}
                  <Trans>BankWithdrawModal.5%-extra</Trans>
                </div>
                <div
                  className={classNames("w-fit  rounded-lg px-3", {
                    "bg-green-100 text-green-500": props.request.data.isFive,
                    "bg-red-100 text-red-500": !props.request.data.isFive,
                  })}>
                  {props.request.data.isFive ? (
                    <Trans>BankWithdrawModal.applied</Trans>
                  ) : (
                    <Trans>BankWithdrawModal.not-applied</Trans>
                  )}
                </div>
              </div>
              <div
                className="w-full grid items-center my-8"
                style={{ gridTemplateColumns: "120px auto" }}>
                <div className="text-gray-800 text-lg font-bold">
                  <Trans>BankWithdrawModal.total</Trans>
                </div>
                <div>
                  {getTotalAmount(props.request.data.withdrawAmount / 100)} /
                  hkd
                </div>
              </div>
            </>
          )}

          {props.request.status === ADMIN_REQUEST_STATUS_TYPE.PENDING && (
            <>
              <div className=" flex flex-row gap-8 md:mx-4">
                <button
                  className="rounded-2xl bg-green-500 hover:bg-green-600 text-white w-full p-2"
                  onClick={() => markAsFinished()}>
                  {isFinishLoading ? (
                    <Spinner />
                  ) : (
                    <Trans>AdminEditRequestModal.mark-as-finished</Trans>
                  )}
                </button>
              </div>
              {/* <div className="mt-1 text-sm text-gray-400 text-right">
            when press this button, the item will be marked as "FINISHED"
          </div> */}
            </>
          )}
        </section>

        {kol !== undefined && (
          <section className="mt-2 p-4 md:block hidden">
            <div className="flex flex-row gap-4 items-center justify-center">
              <div>
                <img
                  className="w-16 h-16 rounded-full object-cover"
                  src={kol.avatarUrl}
                />
              </div>
              <div>
                <a
                  href={"https://look4kol.com/en/p/" + kol.userName}
                  target="_blank"
                  className="text-gray-700 font-semibold text-xl hover:text-primary">
                  @{kol.userName}
                </a>
                <p className="text-sm text-gray-500 font-medium">#{kol.id}</p>
              </div>
            </div>
            <div className="mt-8 overflow-auto">
              {isLoadedJobs && (
                <CustomTable
                  cols={listJobs}
                  isFirstColStatic={false}
                  isLoading={!isLoadedJobs}
                  rowIsLoading={isGettingJob}
                  prevBtn={{
                    disable: false,
                    onClick: previousPagAction,
                  }}
                  pageNum={pageNum}
                  nextBtn={{
                    disable: !hasMore,
                    onClick: nextPagAction,
                  }}
                  notFoundDes={t("MyTransaction.record-not-found")}
                  isLastRecord={!hasMore}
                />
              )}
            </div>
          </section>
        )}
      </div>
    </div>
  )
}

export default PayoutRequest
