import React from "react"
import { REGISTER_STAT_TYPE } from "../../../../Enum/ADMIN_ACTION_TYPE"

interface registeredAccountInfo {
  registerTime: Date
  registerName: string
  registerEmail: string
  registerIsVerified: boolean
  registerID: string
  isKOL: boolean
}

const monthToWeek = (timeData: registeredAccountInfo[]) => {
  const weeklyLimit = new Date(new Date().setHours(0, 0, 0, 0))
  weeklyLimit.setDate(weeklyLimit.getDate() - 6)
  let weeklyData: registeredAccountInfo[] = []
  timeData.forEach((monthData) => {
    if (monthData.registerTime.getTime() >= weeklyLimit.getTime()) {
      weeklyData.push(monthData)
      // console.log("weeklyData get: " + monthData)
    }
  })
  return weeklyData
}

const monthToDaily = (timeData: registeredAccountInfo[]) => {
  const dailyLimit = new Date(new Date().setHours(0, 0, 0, 0))
  dailyLimit.setDate(dailyLimit.getDate())
  let dailyData: registeredAccountInfo[] = []
  timeData.forEach((monthData) => {
    if (monthData.registerTime.getTime() >= dailyLimit.getTime()) {
      dailyData.push(monthData)
      // console.log("dailyData get: " + monthData)
    }
  })
  return dailyData
}

const registrationHelper = (
  timeData: registeredAccountInfo[],
  type: REGISTER_STAT_TYPE
) => {
  // console.log("splitting time data:")
  // console.log(timeData)
  const midnight = new Date(new Date().setHours(0, 0, 0, 0))

  switch (type) {
    case REGISTER_STAT_TYPE.WEEKLY:
      let registerPerWeek: registeredAccountInfo[][] = [...Array(7)].fill([])

      for (let day = 0; day < 7; day++) {
        const previousDay = new Date(
          midnight.getTime() - (7 - day - 1) * 24 * 60 * 60 * 1000
        )
        const currentDay = new Date(
          midnight.getTime() - (7 - day - 2) * 24 * 60 * 60 * 1000
        )
        let daySlot: registeredAccountInfo[] = []
        timeData.forEach((dataElement) => {
          if (
            previousDay.getTime() <= dataElement.registerTime.getTime() &&
            currentDay.getTime() >= dataElement.registerTime.getTime()
          ) {
            daySlot.push(dataElement)
          }
        })

        registerPerWeek[day] = [...daySlot]
        // console.log(registerPerWeek)
      }

      return registerPerWeek

    case REGISTER_STAT_TYPE.MONTHLY:
      let registerPerMonth: registeredAccountInfo[][] = [...Array(30)].fill([])

      for (let day = 0; day < 30; day++) {
        const previousDay = new Date(
          midnight.getTime() - (30 - day - 1) * 24 * 60 * 60 * 1000
        )
        const currentDay = new Date(
          midnight.getTime() - (30 - day - 2) * 24 * 60 * 60 * 1000
        )

        let daySlot: registeredAccountInfo[] = []
        timeData.forEach((dataElement) => {
          if (
            previousDay.getTime() <= dataElement.registerTime.getTime() &&
            currentDay.getTime() >= dataElement.registerTime.getTime()
          ) {
            daySlot.push(dataElement)
          }
        })

        registerPerMonth[day] = [...daySlot]
        // console.log(registerPerMonth)
      }

      return registerPerMonth
    case REGISTER_STAT_TYPE.CUSTOM:
      return []

    default:
      return []
  }
}

const dateGetter = (type: REGISTER_STAT_TYPE) => {
  let xAxisData = []

  const dateTemplate = new Date()

  switch (type) {
    case REGISTER_STAT_TYPE.MONTHLY:
      for (let day = 29; day >= 0; day--) {
        dateTemplate.setTime(new Date().getTime() - day * 24 * 60 * 60 * 1000)
        xAxisData[29 - day] =
          dateTemplate.getDate() + "/" + (dateTemplate.getMonth() + 1)
      }
      return xAxisData

    case REGISTER_STAT_TYPE.WEEKLY:
      for (let day = 6; day >= 0; day--) {
        dateTemplate.setTime(new Date().getTime() - day * 24 * 60 * 60 * 1000)
        xAxisData[6 - day] =
          dateTemplate.getDate() + "/" + (dateTemplate.getMonth() + 1)
      }
      return xAxisData

    case REGISTER_STAT_TYPE.CUSTOM:

    default:
  }

  return []
}

export { registrationHelper, dateGetter, monthToWeek, monthToDaily }
