import React, { useEffect, useState } from "react"
import { searchKOLAlgolia } from "../../HelpingFunction/KOL/kolDBHelper"
import { useDispatch, useSelector } from "react-redux"
import { isAuth } from "../../Utils/FirebaseUtils"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { Column } from "../../Model/Table"
import {
  searchKOLTableSchema,
  searchKOLTableSchemaDefault,
} from "../../Utils/TableHelperFunction"
import { t } from "i18next"
import { KOLCombine } from "../../Model/KOLCombine"
import { KOLSearchDefault } from "../../redux/reducer/ToolsManager"
import CustomTable from "../../Component/NewTable"
import { useLocation } from "react-router-dom"
import { compareQueryType, queryType } from "../../Utils/QueryHelper"
import { compareConditionType } from "../../pages/admin/tools/kol"

type PropsType = {
  params: compareQueryType
  queryPushFn: (query: compareConditionType) => void
}
export const SearchKOLTable: React.FC<PropsType> = ({
  params,
  queryPushFn,
}) => {
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })

  const showHelpText = useSelector((state: any) => {
    return state.ToolsManager.showHelpText
  })

  const currentPage = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition.page
  })

  const [data, setData] = useState<Column[][]>([searchKOLTableSchemaDefault()])
  const [kols, setKOLs] = useState<KOLCombine[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(false)

  useEffect(() => {
    if (isAuth(auth)) {
      getSearchResult()
    }
  }, [auth, searchKOLCondition])

  useEffect(() => {
    if (Array.isArray(kols)) {
      buildTable(kols)
    }
  }, [kols, showHelpText])
  const setCurrentPage = (page: number) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: "page",
        value: page,
      },
    })
  }

  const setViewKOLPercentageModal = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.VIEW_KOL_PERCENTAGE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })

  const toggleCompareGroupModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.ADD_TO_COMPARE_GROUP_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })
  }

  const buildTable = (kols: KOLCombine[]) => {
    setIsLoaded(false)
    let tableSets: Column[][] = []
    kols.map((kol) => {
      let col: Column[] = [
        ...searchKOLTableSchema(
          kol,
          setViewKOLPercentageModal,
          showHelpText,
          toggleCompareGroupModal
        ),
      ]
      tableSets.push(col)
    })

    setData(tableSets)
    setIsLoaded(true)
  }

  const getSearchResult = () => {
    setIsLoading(true)

    searchKOLAlgolia({
      ...KOLSearchDefault,
      ...searchKOLCondition,
    })
      .then((response) => {
        if (response.success) {
          setKOLs(response.data as KOLCombine[])
          setHasMore(response.hasMore)
        }
      })
      .catch((err) => console.log(err))
  }

  if (isLoaded) {
    return (
      <div>
        {data.length > 0 && (
          <CustomTable
            cols={data}
            isFirstColStatic={false}
            isLoading={!isLoaded}
            rowIsLoading={false}
            notFoundDes={t("SearchKOL.no-result")}
            prevBtn={{
              disable: false,
              onClick: () => {
                let cp = currentPage
                setCurrentPage(--cp)
                queryPushFn({
                  pageNum: params.pageNum
                    ? (parseInt(params.pageNum) - 1).toString()
                    : "1",
                })
              },
            }}
            pageNum={currentPage}
            nextBtn={{
              disable: !hasMore,
              onClick: () => {
                let cp = currentPage
                setCurrentPage(++cp)
                queryPushFn({
                  pageNum: params.pageNum
                    ? (parseInt(params.pageNum) + 1).toString()
                    : "2",
                })
              },
            }}
            isLastRecord={!hasMore}
          />
        )}
      </div>
    )
  }
  return <div />
}
