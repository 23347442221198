import React, { useEffect, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import Select from "react-select"
import Spinner from "../../Component/Spinner"
import { CompanySize } from "../../Utils/RegFormData"
import { getAPIPath } from "../../Utils/HelpingFunction"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { NEW_CUSTOMER } from "../../Enum/LOGIN_PAGE_TYPE"

interface Props {
  profile: NEW_CUSTOMER
  auth: any
}

const AdminEditCustomerProfile = (props: Props) => {
  const { t, i18n } = useTranslation()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [profile, setProfile] = useState<NEW_CUSTOMER>({
    contactEmail: "",
    avatarUrl: "",
    companyName: "",
    companySize: "",
    roleInCompany: "",
    companyType: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    setIsLoaded(false)
    if (typeof props.profile.objectID !== "undefined") {
      setProfile(props.profile)
      setIsLoaded(true)
    }
  }, [props.profile])
  console.log(profile)

  const updateCustomerProfile = async () => {
    setIsSubmitting(true)

    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/customer/" + profile.objectID), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          reqUID: props.auth.uid,
          editUID: profile.objectID,
          ...profile,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            setIsSubmitting(false)
          } else {
            setIsSubmitting(false)
            console.log(finalResponse.message)
          }
        })
    })
  }

  return (
    <div className="relative py-3 w-full">
      <div>
        <div className="divide-y divide-gray-200">
          <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.email</Trans>
              </label>
              <input
                type="text"
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder={t("RegForm.email")}
                value={profile.contactEmail}
                disabled={true}
              />
            </div>

            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.last-name</Trans>
              </label>
              <input
                type="text"
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder={t("RegForm.last-name")}
                value={profile.lastName}
                onChange={(e) =>
                  setProfile({ ...profile, lastName: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.first-name</Trans>
              </label>
              <input
                type="text"
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder={t("RegForm.first-name")}
                value={profile.firstName}
                onChange={(e) =>
                  setProfile({ ...profile, firstName: e.target.value })
                }
              />
            </div>

            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.contact-number</Trans>
              </label>
              <input
                type="text"
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder={t("RegForm.contact-number")}
                value={profile.contactNumber}
                onChange={(e) =>
                  setProfile({
                    ...profile,
                    contactNumber: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.company-name</Trans>
              </label>
              <input
                type="text"
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder={t("RegForm.company-name")}
                value={profile.companyName}
                onChange={(e) =>
                  setProfile({
                    ...profile,
                    companyName: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.company-website</Trans>
              </label>
              <input
                type="text"
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder={t("RegForm.company-name")}
                value={profile.website}
                onChange={(e) =>
                  setProfile({
                    ...profile,
                    website: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.role-in-company</Trans>
              </label>
              <input
                type="text"
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder={t("RegForm.role-in-company")}
                value={profile.roleInCompany}
                onChange={(e) =>
                  setProfile({
                    ...profile,
                    roleInCompany: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.company-size</Trans>
              </label>
              <Select
                isMulti={false}
                name="colors"
                value={CompanySize.find((e) => e.value === profile.companySize)}
                options={CompanySize}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e: any) =>
                  setProfile({ ...profile, companySize: e.value })
                }
              />
            </div>

            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>RegForm.description</Trans>
              </label>
              <textarea
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder="Optional"
                value={profile.description}
                onChange={(e) =>
                  setProfile({ ...profile, description: e.target.value })
                }
              />
            </div>
          </div>
          <div className="pt-4 flex items-center space-x-4">
            <button
              className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"
              disabled={isSubmitting}
              onClick={() => {
                updateCustomerProfile()
              }}>
              {isSubmitting ? (
                <Spinner />
              ) : (
                <Trans>KOLProfiler.update-profile</Trans>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdminEditCustomerProfile
