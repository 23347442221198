import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { analytics } from "../../../../config/firebase"
import { isAuth, returnIdTokenResult } from "../../../../Utils/FirebaseUtils"
import {
  timeStampToDisplayTimeString,
  timestraptoIOStime,
} from "../../../../Utils/Converter"
import Spinner from "../../../Spinner"
import { getAPIPath } from "../../../../Utils/HelpingFunction"
import { useTranslation } from "react-i18next"
import { getJob } from "../../../../HelpingFunction/Job/JobDBHelper"
import { logEvent } from "firebase/analytics"
import { ADMIN_REQUEST } from "../../../../Model/AdminRequest"
import { Job, JOB_DEFAULT } from "../../../../Model/Job"
import { getUserLang } from "../../../../Utils/utiltyHelper"
import { getCustomerCompanyName } from "../../../../HelpingFunction/Customer/customerDBHelper"
import { getKOLUserName } from "../../../../HelpingFunction/KOL/kolDBHelper"
import { ADMIN_REQUEST_STATUS_TYPE } from "../../../../Enum/APP_TYPE"
import {
  returnStatusString,
  setAdminRequest,
} from "../../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../../redux/SUPPORTED_REDUX_FUNCTION"

interface PropsType {
  request: ADMIN_REQUEST
  closeModal: () => void
}

export const PayLaterRequest: React.FC<PropsType> = ({
  request,
  closeModal,
}) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const setRequestStateChanged = (status: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_REQUEST_STATUS_CHANGED,
      data: status,
    })
  }
  const [job, setJob] = useState<Job>(JOB_DEFAULT)
  const [isLoadedJob, setIsLoadedJob] = useState(false)
  const [kolName, setKOLName] = useState("")
  const [customerCompany, setCustomerCompany] = useState("")
  const [isAcceptLoading, setIsAcceptLoading] = useState(false)
  const [isRejectLoading, setIsRejectLoading] = useState(false)

  const [error, setError] = useState("")

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (
      isAuth(auth) &&
      request.jobID !== "" &&
      typeof request.jobID !== "undefined"
    )
      getJob(request.jobID).then((result) => {
        if (result.success) {
          setJob(result.job)
          setIsLoadedJob(true)
          getNames(result.job)
        }
      })
  }, [auth, request.jobID])

  const confirmPayment = () => {
    setIsAcceptLoading(true)

    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/admin/job/" + request.jobID + "/payLater/" + request.id
        ),
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            setRequestStateChanged(true)
            logEvent(analytics, "contract_conversion", {
              value: job.price,
            })
            setTimeout(() => {
              setIsAcceptLoading(false)
              closeModal()
            }, 1200)
          } else {
            setError(finalResponse.error)
          }
        })
    })
  }

  const rejectCheckout = async () => {
    setIsRejectLoading(true)
    let newRequest: ADMIN_REQUEST = {
      ...request,
      status: ADMIN_REQUEST_STATUS_TYPE.REJECT,
    }
    await setAdminRequest(request.id as string, newRequest).then((res) => {
      if (res.success) {
        setRequestStateChanged(true)
        setIsRejectLoading(false)
        closeModal()
      }
    })
  }

  const getNames = async (job: Job) => {
    await getKOLUserName(job.KOLID).then((res) => {
      if (res.success) {
        setKOLName(res.userName)
        console.log(res.userName)
      }
    })
    await getCustomerCompanyName(job.CustomerID).then((res) => {
      if (res.success) {
        setCustomerCompany(res.customerCompanyName)
      }
    })
  }

  if (isLoadedJob) {
    return (
      <div className="w-full pb-20">
        <h1 className="text-lg font-semibold mt-0 mb-2">
          Pay Later Request - {returnStatusString(request.status)}
        </h1>
        <p className="text-sm font-medium text-gray-500">{job.id}</p>
        <section className="my-2">
          <p className="text-sm my-3">
            <span className="font-medium mr-4">Job Name:</span>
            {job.ServicePlanName}
          </p>
          <p className="text-sm my-3">
            <span className="font-medium mr-4">KOL Name: </span>
            {kolName}
          </p>
          <p className="text-sm my-3">
            <span className="font-medium mr-4">Customer Company Name: </span>
            {customerCompany}
          </p>
          <p className="text-sm my-3">
            <span className="font-medium mr-4">Last Update: </span>
            {timeStampToDisplayTimeString(job.lastUpdate)}
          </p>

          <button
            className="rounded-2xl bg-gray-50 hover:bg-gray-100 border border-gray-500 text-gray-700 py-1 px-2"
            onClick={() => {
              window.open(
                process.env.REACT_APP_HOST_NAME +
                  "/" +
                  getUserLang() +
                  "/c?chatroomID=" +
                  job.id
              )
              closeModal()
            }}>
            Go to Job
          </button>

          <div className="my-4">
            <div className="text-md my-2 text-gray-600">Job Amount</div>
            <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
              HKD {job.price}
            </div>
          </div>
        </section>

        <section>
          <div className="w-full p-2 rounded-lg my-4 border border-gray-100">
            <div className="block mb-2 text-gray-400">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore*/}
              {timestraptoIOStime(request.createDate.seconds)}
              <textarea
                className="textarea textarea-bordered w-full h-32"
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore*/
                value={request.data.content}></textarea>
            </div>
          </div>
        </section>

        <section>
          {request.status === ADMIN_REQUEST_STATUS_TYPE.PENDING && (
            <>
              <div className="flex flex-row gap-8">
                <button
                  className="rounded-2xl bg-green-500 hover:bg-green-600 text-white w-full p-2 mb-20"
                  disabled={job.paid}
                  onClick={() => confirmPayment()}>
                  {isAcceptLoading ? <Spinner /> : "Confirm Request"}
                </button>
                <button
                  className="rounded-2xl bg-red-500 hover:bg-red-600 text-white w-full p-2 mb-20"
                  disabled={job.paid}
                  onClick={() => rejectCheckout()}>
                  {isRejectLoading ? <Spinner /> : "Reject"}
                </button>
              </div>
              <div className="mt-1 text-sm text-gray-400 text-right">
                when this button is pressed, the job will be marked as "PAID"
              </div>
            </>
          )}

          {error !== "" && (
            <div className="text-md text-red-700 my-2">{t(error)}</div>
          )}
        </section>
      </div>
    )
  }
  return <div>LOADING...</div>
}
