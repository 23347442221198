import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"

type PropsType = {
  published: string | null
  queryPushFn: (query: { published?: string }) => void
  setCondition: (state: any) => void
}

const IsPublishToggle: React.FC<PropsType> = ({
  published,
  queryPushFn,
  setCondition,
}) => {
  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })

  const [isToggled, setIsToggled] = useState<boolean>(
    searchKOLCondition.isPublish
  )
  const [isFirstRefresh, setIsFirstRefresh] = useState<boolean>(true)

  const publishedUpdateDetector = (
    value: string | null,
    searchCondition: boolean
  ) => {
    if (published !== "") {
      if (searchCondition) {
        return published !== "true"
      }
      return published !== "false"
    }
    return false
  }

  useEffect(() => {
    const paramData = published ? published : "true"
    if (
      paramData &&
      publishedUpdateDetector(paramData, searchKOLCondition.isPublish)
    ) {
      setIsFirstRefresh(false)
      setIsToggled(paramData === "true")
      setCondition({
        key: "isPublish",
        value: (paramData === "true") as boolean,
      })
    }
  }, [published, isFirstRefresh])

  useEffect(() => {
    if (
      published &&
      searchKOLCondition.isPublish !== ((published === "true") as boolean)
    ) {
      setCondition({
        key: "isPublish",
        value: (published === "true") as boolean,
      })
    }
  }, [published, searchKOLCondition])

  return (
    <>
      <div
        className="md:mx-4 mb-10 xl:grid gap-4"
        style={{ gridTemplateColumns: "50px auto" }}>
        <div className="hidden xl:inline bg-red-default w-10 h-10 text-white p-2 text-center text-lg rounded-2xl">
          <FontAwesomeIcon icon={faLock} />
        </div>

        <div className="form-control">
          <label className=" label cursor-pointer">
            <h2 className="font-bold text-lg ">
              <Trans>IsPublishToggle.title</Trans>
            </h2>
            <input
              type="checkbox"
              className="toggle"
              checked={isToggled}
              onChange={() => {
                setIsToggled(!isToggled)
                setCondition({
                  key: "isPublish",
                  value: !isToggled,
                })
                queryPushFn({ published: (!isToggled).toString() })
              }}
            />
          </label>
        </div>
      </div>
      <hr className="mb-4 border-gray-200 w-11/12 mx-auto" />
    </>
  )
}
export default IsPublishToggle
