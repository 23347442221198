import React from "react"
import { WalletHistoryModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"
import { intToFloat, timeStampToDisplayTimeString } from "../../Utils/Converter"
import { getHKBankName } from "../../Utils/HKBankList"
import { PAYOUT_REQUEST_DATA } from "../../Model/AdminRequest"
import { Job } from "../../Model/Job"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { isChatroomExists } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { getUserLang } from "../../Utils/utiltyHelper"

const WalletHistoryModalComponent = (props: WalletHistoryModalData) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const payout_data = props.metaData.data.data as PAYOUT_REQUEST_DATA
  const job_data = props.metaData.data as Job

  const onClickToChat = async () => {
    if (job_data) {
      await isChatroomExists(job_data.CustomerID, job_data.KOLID).then(
        (res) => {
          if (res.success) {
            window.open(
              `${process.env.REACT_APP_HOST_NAME}/` +
                getUserLang() +
                "/c?chatroomID=" +
                res.chatroomID +
                "&type=contract&metaPath=" +
                job_data.id
            )
          } else {
            window.open(
              `${process.env.REACT_APP_HOST_NAME}/` +
                getUserLang() +
                "/c?chatroomID=" +
                "undefined" +
                "&type=contract&metaPath=" +
                job_data.id
            )
          }
        }
      )
    }
  }

  if (props.metaData.type === "ADMIN_REQUEST") {
    return (
      <div>
        <div className={"text-center text-sm mb-8"}>
          <p>
            <Trans>WalletHistoryModal.payout-history</Trans>
          </p>
          <p className={"mb-2 opacity-60"}>
            {timeStampToDisplayTimeString(props.metaData.date)}
          </p>
          {props.metaData.data.isFinish ? (
            <span className="badge bg-green-500">
              <Trans>MyPayoutRequest.finished</Trans>
            </span>
          ) : (
            <span className="badge bg-orange-400">
              <Trans>MyPayoutRequest.in-progress</Trans>
            </span>
          )}
        </div>

        <h3 className={"text-4xl text-md text-center mb-4"}>
          <span className={"text-red-500"}>
            {"-"}
            {payout_data.bankDetail.currency.toLocaleUpperCase()}{" "}
            {intToFloat((1 * props.metaData.amount) / 100, 2)}
          </span>
          <p className={"text-sm mt-4"}>
            <span>
              <Trans>WalletHistoryModal.handling-fee</Trans>:{" "}
              {intToFloat(payout_data.handlingFee / 100, 2)}
            </span>
          </p>
        </h3>
        <div className={"text-center mb-6"}>
          <p className={"text-xl"}>
            <Trans>WalletHistoryModal.payout</Trans>
          </p>
          <p className={"text-sm opacity-50"}>#{props.metaData.id}</p>
        </div>
        <hr />
        <div className={"mt-8 grid grid-cols-1 md:grid-cols-2 md:gap-4"}>
          <div>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.card-holder</Trans>:{" "}
              </span>
              {payout_data.bankDetail.cardHolder}
            </h3>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.account-number</Trans>:{" "}
              </span>
              {payout_data.bankDetail.accNum}
            </h3>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>WalletHistoryModal.apply-date</Trans>:{" "}
              </span>
              {timeStampToDisplayTimeString(props.metaData.date)}
            </h3>
          </div>
          <div>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.bank-location</Trans>:{" "}
              </span>
              {payout_data.bankDetail.bankLocation}
            </h3>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.receiving-bank</Trans>:{" "}
              </span>
              {getHKBankName(payout_data.bankDetail.bankID)}
            </h3>
          </div>
        </div>
      </div>
    )
  } else if (props.metaData.type === "JOB") {
    const jobData = props.metaData.data as Job
    return (
      <div>
        <div className={"text-center text-sm mb-8"}>
          <p>
            <Trans>WalletHistoryModal.job-history</Trans>
          </p>
          <p className={"mb-2 opacity-60"}>
            {timeStampToDisplayTimeString(props.metaData.date)}
          </p>
          <span className="badge bg-green-500">
            <Trans>MyPayoutRequest.finished</Trans>
          </span>
        </div>

        <h3 className={"text-4xl text-md text-center mb-4"}>
          <span className={"text-green-500"}>
            {"+"}
            {job_data.currency.toLocaleUpperCase()}{" "}
            {intToFloat((1 * props.metaData.amount) / 100, 2)}
          </span>
        </h3>
        <div className={"text-center mb-6"}>
          <p className={"text-xl"}>
            <Trans>MyPayoutRequest.job</Trans>
          </p>
          <p className={"text-sm opacity-50"}>#{props.metaData.id}</p>
        </div>
        <hr />
        <div className={"grid grid-cols-1 md:grid-cols-2 md:gap-4"}>
          <div>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>WalletHistoryModal.start-date</Trans>:{" "}
              </span>
              {timeStampToDisplayTimeString(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                jobData.createDate.seconds
              )}
            </h3>
          </div>
          <div>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>WalletHistoryModal.pay-date</Trans>:{" "}
              </span>
              {timeStampToDisplayTimeString(props.metaData.date)}
            </h3>
          </div>
        </div>
        <div className={"w-full block"}>
          <button
            className="rounded-3xl p-2 w-24 bg-gray-100 ml-auto"
            onClick={() => {
              onClickToChat()
              props.closeModal()
            }}>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
            <Trans>MyTransaction.open</Trans>
          </button>
        </div>
      </div>
    )
  }
}

export default WalletHistoryModalComponent
