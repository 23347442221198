import React, { useEffect, useState } from "react"

import { KOLCombine } from "../../Model/KOLCombine"
import CustomTable from "../../Component/NewTable"
import { t } from "i18next"
import { Column, multiSelectGroup, tableFunctions } from "../../Model/Table"
import {
  compareKOLTableSchema,
  compareKOLTableSchemaDefault,
  onSort,
  ORDER_BY_DIRECTION,
  SORT_OPTION,
} from "../../Utils/TableHelperFunction"
import {
  BUTTON_TYPE,
  MULTISELECT_TYPE,
  TABLE_FUNCTIONS,
} from "../../Enum/TABLE_SYSTEM"
import {
  genreGroupData,
  returnSelectedItems,
  tagTypeSelection,
} from "../../Utils/TypeList"
import { sortInfo } from "../../Utils/CompareKOLUtils"

interface PropsType {
  metaData: {
    kolData: {
      success: boolean
      userName: string
      data: KOLCombine
    }[]
    dataLoaded: boolean
  }
}

export const KOLCompareTable: React.FC<PropsType> = ({ metaData }) => {
  const [kolCompare, setKOLCompare] = useState<Column[][]>([
    compareKOLTableSchemaDefault(),
  ])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [genreFilterData, setGenreFilterData] = useState<tagTypeSelection[]>([])

  const [sortOptions, setSortOptions] = useState<SORT_OPTION[]>([])
  const buildTable = (kols: KOLCombine[]) => {
    setIsLoaded(false)
    let tableSets: Column[][] = []

    const sortEnabled = sortOptions.find((e) => e.direction !== false)
    if (sortEnabled) {
      kols.sort((a, b) => {
        const { sortDataA, sortDataB } = sortInfo(a, b, sortEnabled)
        if (
          (sortDataA < sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.ASC) ||
          (sortDataA > sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.DESC)
        ) {
          return -1
        }
        if (
          (sortDataA > sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.ASC) ||
          (sortDataA < sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.DESC)
        ) {
          return 1
        }

        // names must be equal
        return 0
      })
    }
    kols.map((kol) => {
      let col: Column[] = [
        ...compareKOLTableSchema(kol, genreFilterData, sortOptions, sortFn),
      ]
      tableSets.push(col)
    })

    setKOLCompare(tableSets)
    setIsLoaded(true)
  }

  useEffect(() => {
    if (metaData.kolData.every((kC) => kC.success)) {
      buildTable(metaData.kolData.map((kC) => kC.data))
    }
  }, [metaData.kolData, sortOptions])
  const sortFn = (fieldName: string) => {
    const isExistedSortOption = sortOptions.find(
      (e) => e.fieldName === fieldName
    )
    let newSortOption: SORT_OPTION[]
    if (isExistedSortOption) {
      newSortOption = onSort(sortOptions, fieldName)
    } else {
      newSortOption = onSort(
        [
          ...sortOptions,
          {
            direction: false,
            fieldName: fieldName,
          },
        ],
        fieldName
      )
    }
    setSortOptions(newSortOption)
  }

  const formatGroupLabel = (data: multiSelectGroup) => {
    return (
      <div className="flex justify-between m-3">
        <span>{data.label}</span>
        <span className="badge badge-primary p-2 my-auto">
          {data.options.length}
        </span>
      </div>
    )
  }

  const tableFn: tableFunctions[] = [
    {
      title: t("SearchKOL.genre"),
      data: {
        type: TABLE_FUNCTIONS.MULTISELECT,
        dataType: MULTISELECT_TYPE.GENRE,
        buttonType: BUTTON_TYPE.INFO,
        selectData: genreGroupData(),
        labeledValue: genreFilterData,
        onClick: (data) => {
          if (Array.isArray(genreFilterData) && genreFilterData.length <= 5) {
            let allIDs: string[] = [...genreFilterData.map((e) => e.value)]
            let sortIDs: SORT_OPTION[] = [...sortOptions]
            const existgenreID = genreFilterData.findIndex(
              (e) => e.value === data.value
            )
            const existSortID = sortOptions.findIndex(
              (e) => e.fieldName === data.value
            )
            if (!(existgenreID > -1) && !(existSortID > -1)) {
              if (genreFilterData.length < 5) {
                allIDs.push(data.value)
                sortIDs.push({
                  direction: false,
                  fieldName: data.value,
                })
              }
            } else {
              allIDs.splice(existgenreID, 1)
              sortIDs.splice(existSortID, 1)
            }
            setGenreFilterData(returnSelectedItems(allIDs))
            setSortOptions(sortIDs)
          } else if (data === null) {
            setGenreFilterData([])
            setSortOptions([])
          }
        },
        groupFormat: formatGroupLabel,
        disableButton: genreFilterData.length >= 5,
      },
    },
  ]

  return (
    <CustomTable
      cols={kolCompare}
      tableFn={tableFn}
      isFirstColStatic={false}
      isLoading={!metaData.dataLoaded || !isLoaded}
      rowIsLoading={false}
      notFoundDes={"No Record"}
      isLastRecord={true}
    />
  )
}
