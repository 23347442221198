import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleUser } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"

import {
  AvatarType,
  BadgeType,
  ButtonType,
  CheckBoxType,
  ColType,
  CustomType,
  DateType,
  EmptyType,
  HeaderType,
  IconType,
  PriceType,
  RowType,
  ScoreType,
  StringType,
  TogglerType,
} from "../Model/Table"
import {
  BADGE_TYPE,
  BUTTON_TYPE,
  ICON_TYPE,
  TABLE_TYPE,
  WIDTH_SIZE,
} from "../Enum/TABLE_SYSTEM"
import { timeStampToDisplayTimeString } from "../Utils/Converter"
import { Rating } from "./Rating"
import classNames from "classnames"

interface Column {
  data:
    | IconType
    | ScoreType
    | CheckBoxType
    | TogglerType
    | BadgeType
    | DateType
    | StringType
    | ButtonType
    | PriceType
    | AvatarType
    | CustomType
    | EmptyType
    | ColType
    | RowType
    | HeaderType
  colIndex: number
}

const Columns: React.FC<Column> = ({ data, colIndex }) => {
  const widthStyle = (data: RowType | ColType | StringType | ButtonType) => {
    switch (data.fixedWidth) {
      case WIDTH_SIZE.SM:
        return "w-12"
      case WIDTH_SIZE.MD:
        return "w-24"
      case WIDTH_SIZE.LG:
        return "w-36"
      case WIDTH_SIZE.XL:
        return "w-48"
      case WIDTH_SIZE.XXL:
        return "w-64"
      case WIDTH_SIZE.XXXL:
        return "w-96"
      default:
        break
    }
  }
  const iconTypeStyle = (data: IconType) => {
    switch (data.iconType) {
      case ICON_TYPE.FAIL:
        return "text-red-600"
      case ICON_TYPE.WARNING:
        return "text-yellow-600"
      case ICON_TYPE.SUCCESS:
        return "text-green-600"
      case ICON_TYPE.GHOST:
        return "text-gray-500"
      default:
        break
    }
  }

  const btnIconTypeStyle = (data: ButtonType) => {
    switch (data.buttonType) {
      case BUTTON_TYPE.FAIL:
        return "text-red-600"
      case BUTTON_TYPE.WARNING:
        return "text-yellow-600"
      case BUTTON_TYPE.SUCCESS:
        return "text-green-600"
      case BUTTON_TYPE.INFO:
        return "text-gray-100"
      case BUTTON_TYPE.GHOST:
        return "text-gray-500"
      default:
        break
    }
  }

  const btnTypeStyle = (data: ButtonType) => {
    switch (data.buttonType) {
      case BUTTON_TYPE.FAIL:
        return "btn-error"
      case BUTTON_TYPE.WARNING:
        return "btn-warning"
      case BUTTON_TYPE.SUCCESS:
        return "btn-success"
      case BUTTON_TYPE.INFO:
        return ""
      case BUTTON_TYPE.GHOST:
        return "btn-ghost text-gray-500"
      default:
        break
    }
  }

  const btnIconExisit = (data: ButtonType) => {
    if (data.icon) {
      return (
        <FontAwesomeIcon
          className={["text-sm", btnIconTypeStyle(data)].join(" ")}
          icon={data.icon}
        />
      )
    }
  }

  switch (data.type) {
    case TABLE_TYPE.STRING:
      return (
        <div className="font-medium text-stone-800 text-sm">
          <div
            className={["text-ellipsis overflow-hidden", widthStyle(data)].join(
              " "
            )}>
            {data.value}
          </div>
        </div>
      )
    case TABLE_TYPE.PRICE:
      return (
        <div
          className={[
            "font-medium text-stone-800 text-sm",
            data.value === 0 ? "text-gray-400" : "",
          ].join(" ")}>
          {data.value === 0
            ? "NA"
            : data.value + " " + data.currency.toUpperCase()}
        </div>
      )
    case TABLE_TYPE.ICON:
      return (
        <div>
          <FontAwesomeIcon
            className={["ml-2 text-lg", iconTypeStyle(data)].join(" ")}
            icon={data.value}
          />
        </div>
      )
    case TABLE_TYPE.DATE:
      return (
        <div className="font-medium text-stone-800 text-sm">
          {typeof data.value !== "string" ? (
            timeStampToDisplayTimeString(
              "getTime" in data.value ? data.value.getTime() / 1000 : data.value
            )
          ) : (
            <div className="text-base-400">------</div>
          )}
        </div>
      )
    case TABLE_TYPE.BADGE:
      return (
        <div
          className={classNames("h-full grid grid-rows-1 gap-2", {
            hidden: data.hidden,
          })}>
          <div className="row-span-3">
            <span
              className={[
                "relative inline-block px-2 py-1  text-2xs font-semibold leading-tight",
                data.badgeType === BADGE_TYPE.WORKING && "text-fuchsia-900",
                data.badgeType === BADGE_TYPE.JOB_DONE && "text-green-900",
                data.badgeType === BADGE_TYPE.JOB_ACCEPTED && "text-yellow-900",
                data.badgeType === BADGE_TYPE.QUOTATION && "text-blue-900",
                data.badgeType === BADGE_TYPE.PENDING && "text-red-900",
                data.badgeType === BADGE_TYPE.TAG && "text-gray-900",
                data.badgeType === BADGE_TYPE.SUCCESS && "text-green-900",
                data.badgeType === BADGE_TYPE.FAIL && "text-red-900",
              ].join(" ")}>
              <span
                aria-hidden
                className={[
                  "absolute inset-0 opacity-50 rounded-full",
                  data.badgeType === BADGE_TYPE.WORKING && "bg-fuchsia-200",
                  data.badgeType === BADGE_TYPE.JOB_DONE && "bg-green-200 ",
                  data.badgeType === BADGE_TYPE.JOB_ACCEPTED &&
                    "bg-yellow-200 ",
                  data.badgeType === BADGE_TYPE.QUOTATION && "bg-blue-200",
                  data.badgeType === BADGE_TYPE.PENDING && "bg-orange-200",
                  data.badgeType === BADGE_TYPE.TAG && "bg-gray-200",
                  data.badgeType === BADGE_TYPE.SUCCESS && "bg-green-100",
                  data.badgeType === BADGE_TYPE.FAIL && "bg-red-100",
                ].join(" ")}
              />
              <span className="relative">
                <Trans>{data.value}</Trans>
              </span>
            </span>
          </div>
        </div>
      )
    case TABLE_TYPE.BUTTON:
      return (
        <div>
          <button
            className={classNames(
              "btn btn-sm flex p-1 gap-2",
              btnTypeStyle(data),
              data.fixedWidth ? widthStyle(data) : "w-auto",
              {
                "group-hover:visible invisible": data.hoverActive,
                hidden: data.hidden,
              }
            )}
            disabled={data.disable}
            onClick={(e) => {
              e.stopPropagation()
              data.onClick()
            }}>
            {btnIconExisit(data)}
            <Trans>{data.value}</Trans>
          </button>
        </div>
      )
    case TABLE_TYPE.CHECK_BOX:
      return (
        <div>
          <input
            type="checkbox"
            className="checkbox"
            onChange={data.onChange}
            checked={data.checked}
          />
        </div>
      )
    case TABLE_TYPE.TOGGLE:
      return (
        <div>
          <input
            type="checkbox"
            className="toggle"
            onChange={data.onChange}
            checked={data.checked}
          />
        </div>
      )
    case TABLE_TYPE.SCORE:
      return (
        <div>
          <Rating score={data.value} size={"sm"} index={colIndex} />
        </div>
      )
    case TABLE_TYPE.AVATAR:
      return (
        <div className="avatar">
          <div className="w-10 h-10 rounded-full">
            {data.value !== "" ? (
              <img src={data.value} />
            ) : (
              <FontAwesomeIcon
                className="text-primary w-10 h-10"
                icon={faCircleUser}
              />
            )}
          </div>
        </div>
      )
    case TABLE_TYPE.CUSTOM:
      return <div>{data.component}</div>
    case TABLE_TYPE.ROW:
      return (
        <div
          className={[
            "flex gap-2 lg:gap-4 ",
            data.fixedWidth ? widthStyle(data) : "",
          ].join(" ")}>
          {data.component.map((item, index) => {
            if (item.type !== TABLE_TYPE.DEFAULT) {
              return <Columns data={item} colIndex={index} />
            }
            return <div />
          })}
        </div>
      )
    case TABLE_TYPE.COL:
      return (
        <div
          className={[
            "flex-1 gap-2 w-full",
            data.fixedWidth ? widthStyle(data) : "",
          ].join(" ")}>
          {data.component.map((item, index) => {
            if (item.type !== TABLE_TYPE.DEFAULT) {
              return <Columns data={item} colIndex={index} />
            }
            return <div />
          })}
        </div>
      )
    case TABLE_TYPE.HEAD:
      if (data.component.type !== TABLE_TYPE.DEFAULT) {
        return <Columns data={data.component} colIndex={colIndex} />
      }
      return <div />

    default:
      return <div />
  }
}
export default Columns
