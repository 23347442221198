import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const AdminAppeal = (props) => {
  return <div></div>
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}
const enhance = connect(
  mapStateToProps
  // mapDispatchToProps
)
export default enhance(withRouter(AdminAppeal))
