import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGlobeAsia,
  faSitemap,
  faStar,
  faUsers,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons"
import { CompanySize } from "../Utils/RegFormData"
import { Trans } from "react-i18next"

const CustomerMiniCard = (props) => {
  return (
    <div
      className="cursor-pointer hover:bg-gray-50 rounded-2xl shadow-md  my-4 py-4 bg-white px-2"
      onClick={() => props.onSelectCustomer(props.data)}>
      <div className="grid" style={{ gridTemplateColumns: "60px auto" }}>
        <div className="hidden md:inline-block w-16">
          <div className="inline-block m-auto items-center space-x-5">
            <div
              className={
                "overflow-hidden h-14 w-14 bg-red-200 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono"
              }>
              <img src={props.data.avatarUrl} alt={""} />
            </div>
          </div>
        </div>

        <div>
          <div className="relative block">
            <h3 className="hover:underline font-semibold text-lg text-gray-700 overflow-hidden overflow-ellipsis">
              {props.data.companyName}
            </h3>
          </div>

          {/* Customer detail*/}
          <div className="block">
            <div className="flex gap-4 my-2">
              <div className="flex text-sm text-gray-600">
                <FontAwesomeIcon icon={faUserTag} className="mr-2" />{" "}
                {props.data.roleInCompany}
              </div>
              <div className="flex text-sm text-gray-600">
                <FontAwesomeIcon icon={faSitemap} className="mr-2" />
                {props.data.companySize === ""
                  ? ""
                  : CompanySize.find((e) => e.value === props.data.companySize)
                      .label}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomerMiniCard
