import React from "react"
import {
  DETAIL_TYPE,
  generalType,
  returnSelectedItems,
} from "../Utils/TypeList"
import Select from "react-select"

interface PropsType {
  tagValueType: string[]
  onChangeFn: (e: any) => void
  disabled: boolean
}

interface GroupData {
  label: string
  options: any[]
}

const CustomTagSelect: React.FC<PropsType> = ({
  tagValueType,
  onChangeFn,
  disabled,
}) => {
  let dataSet: GroupData[] = []
  const groupData = () => {
    const generalData = [...generalType]
    const categoryData = [...DETAIL_TYPE]
    if (Array.isArray(categoryData)) {
      generalData.forEach((group) => {
        const optionsData: { value: string; label: string }[] = []

        //filter category by Groups
        categoryData
          .filter((data) => data.subset === group.value)
          .map((item) => {
            optionsData.push({
              value: item.value,
              label:
                localStorage.getItem("i18n-lang") === "en"
                  ? item.label
                  : item.labelCHI,
            })
          })

        //add group data with category array as options
        dataSet.push({
          label:
            localStorage.getItem("i18n-lang") === "en"
              ? group.label
              : group.labelCHI,
          options: optionsData,
        })
      })
    }
    return dataSet
  }

  const formatGroupLabel = (data: GroupData) => {
    return (
      <div className="flex justify-between">
        <span>{data.label}</span>
        <span className="badge badge-primary p-2">{data.options.length}</span>
      </div>
    )
  }

  return (
    <Select
      value={returnSelectedItems(tagValueType)}
      isMulti={true}
      name="colors"
      options={groupData()}
      formatGroupLabel={formatGroupLabel}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(e) => {
        onChangeFn(e)
      }}
      isDisabled={disabled}
    />
  )
}
export default CustomTagSelect
