import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { isAuth, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { timeStampToDisplayTimeString } from "../../Utils/Converter"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getAPIPath } from "../../Utils/HelpingFunction"
import DivContentLoader from "../../../src/Component/DivContentLoader"

const ListStripeCharges = (props) => {
  const { t, i18n } = useTranslation()

  const [charge, setCharge] = useState([])
  const [isLoadedCharge, setIsLoadedCharge] = useState(false)
  const [condition, setCondition] = useState({
    limit: 10,
    starting_after: "",
    ending_before: "",
    transfer_group: "",
  })
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    if (isAuth(props.auth) && !isLoadedCharge) {
      getLatestCharge()
    }
  }, [props.auth])

  useEffect(() => {
    if (isLoadedCharge) {
      getLatestCharge()
    }
  }, [condition])

  const getLatestCharge = async () => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/admin/stripe/charge"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          limit: condition.limit,
          starting_after: condition.starting_after,
          ending_before: condition.ending_before,
          transfer_group: condition.transfer_group,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            let newCharge = [...charge].concat(finalResponse.data)
            setCharge(newCharge)
            setHasMore(finalResponse.hasMore)
            setIsLoadedCharge(true)
          }
        })
    })
  }

  if (isLoadedCharge) {
    return (
      <div className="mb-4">
        <div className="rounded-2xl bg-gray-100 border border-gray-200 shadow overflow-x-scroll">
          <div
            className="w-full grid border-b border-gray-100 px-4 py-6 text-left gap-4 font-bold text-gray-700"
            style={{
              gridTemplateColumns: "150px 150px 150px 150px 100px auto",
              width: "fit-content",
              minWidth: "100%",
            }}>
            <div className="uppercase">amount</div>
            <div className="uppercase">job</div>
            <div className="uppercase">created</div>
            <div className="uppercase">status</div>
            <div className="uppercase">functions</div>
          </div>
          {charge.length !== 0 ? (
            charge.map((j, index) => {
              return (
                <div className="border-b border-gray-100" key={j.id}>
                  <div
                    className="pl-4 py-2 w-full bg-white hover:bg-gray-50   last:rounded-br-2xl last:rounded-bl-2xl"
                    key={"item" + j.id}>
                    <span className="py-1 px-2 bg-gray-100 rounded">
                      {" "}
                      {j.id}
                    </span>
                  </div>
                  <div
                    className="grid px-4 py-2 pb-6 bg-white gap-4"
                    style={{
                      gridTemplateColumns: "150px 150px 150px 150px 100px auto",
                      width: "fit-content",
                      minWidth: "100%",
                    }}>
                    <div>
                      <span className="mx-1 p-1 rounded">
                        {j.currency.toUpperCase()}
                      </span>
                      {(j.amount / 100)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div>
                      {j.metadata.hasOwnProperty("jobID") && (
                        <Link
                          to={
                            "/" +
                            (localStorage.getItem("i18n-lang") === "en"
                              ? "en"
                              : "hk") +
                            "/c?chatroomID=" +
                            j.metadata.jobID +
                            "&type=contract"
                          }>
                          <button className="rounded-3xl p-2 w-24 bg-gray-100">
                            <FontAwesomeIcon
                              icon={faExternalLinkAlt}
                              className="mr-2"
                            />
                            Job
                          </button>
                        </Link>
                      )}
                    </div>
                    <div>
                      {timeStampToDisplayTimeString(new Date(j.created))}
                    </div>
                    <div>
                      <span
                        className={[
                          "relative inline-block p-2 font-semibold leading-tight",
                          j.status === "succeeded" && "text-green-900",
                          j.status === "refunded" && "text-yellow-900",
                          j.status === "cancelled" && "text-red-900",
                        ].join(" ")}>
                        <span
                          aria-hidden
                          className={[
                            "absolute inset-0 opacity-50 rounded-full",
                            j.status === "succeeded" && "bg-green-200 ",
                            j.status === "refunded" && "bg-yellow-200 ",
                            j.status === "cancelled" && "bg--red-900",
                          ].join(" ")}></span>
                        <span className="relative">
                          {j.status === "succeeded" && "succeeded"}
                          {j.status === "refunded" && "refunded"}
                          {j.status === "cancelled" && "cancelled"}
                        </span>
                      </span>
                    </div>
                    <div>
                      <Link
                        to={
                          "/" +
                          (localStorage.getItem("i18n-lang") === "en"
                            ? "en"
                            : "hk") +
                          "/c?chatroomID=" +
                          j.id
                        }>
                        <button className="rounded-3xl p-2 w-24 text-red-100 bg-red-700">
                          REFUND
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div
              className="w-full px-4 py-6 bg-white hover:bg-gray-50 border-b border-gray-100 gap-4 last:rounded-br-2xl last:rounded-bl-2xl"
              style={{ width: "fit-content", minWidth: "100%" }}>
              <div className="text-center">Record Not Found</div>
            </div>
          )}
        </div>

        <div className="mx-auto w-64 my-2">
          <button
            className="w-full rounded-2xl py-2 px-2 bg-indigo-100 text-indigo-700"
            disabled={!hasMore}
            onClick={() => {
              if (hasMore) {
                setCondition({
                  ...condition,
                  starting_after: charge[charge.length - 1].id,
                })
              }
            }}>
            {hasMore ? "More" : "No More Record"}
          </button>
        </div>
      </div>
    )
  } else {
    return (
      <div className="mb-4">
        <DivContentLoader w={"100%"} h={800} r={35} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

const enhance = connect(
  mapStateToProps
  // mapDispatchToProps
)

export default enhance(withRouter(ListStripeCharges))
