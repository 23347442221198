import React, { useEffect, useState } from "react"
import {
  getJobList,
  jobListCondition,
} from "../../HelpingFunction/Job/JobDBHelper"
import KOL from "../../../src/Model/KOL"
import { Job, JobFilterDefault } from "../../Model/Job"
import CustomTable from "../../../src/Component/NewTable"
import { Column } from "../../Model/Table"
import {
  adminProfileJobTableSchema,
  defaultAdminProfileJobTableSchema,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../../Utils/TableHelperFunction"
import { isChatroomExists } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { useTranslation } from "react-i18next"

interface Props {
  profile: KOL
}

const KOLJobProfiler = (props: Props) => {
  const { t } = useTranslation()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [profile, setProfile] = useState<KOL>()

  const [jobs, setJobs] = useState<Job[]>([])
  //for updating the order of Jobs after calling the TableSchema, used for onClick Actions
  const [newJobList, setNewJobList] = useState<Job[]>([])

  const [isLoadedJobs, setIsLoadedJobs] = useState<boolean>(false)
  const [condition, setCondition] = useState<jobListCondition>(JobFilterDefault)

  const [listJobs, setListJobs] = useState<Column[][]>([
    defaultAdminProfileJobTableSchema(),
  ])

  const [isGettingJob, setIsGettingJob] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [pageNum, setPageNum] = useState<number>(0)

  const [orderByOption, setOrderByOption] = useState<{
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }>({
    fieldName: "lastUpdate",
    direction: ORDER_BY_DIRECTION.DESC,
  })

  useEffect(() => {
    setIsGettingJob(true)
    if (Array.isArray(jobs) && isLoadedJobs) {
      let tableSets: Column[][] = []
      let newJobOrder: Job[] = []

      let allPromise: Promise<any>[] = []

      jobs.forEach(async (jobData, index) => {
        allPromise.push(
          new Promise(async (resolve) => {
            tableSets.push(
              adminProfileJobTableSchema(jobData, pageNum * 10 + index + 1)
            )
            newJobOrder.push(jobData)
            resolve(null)
          })
        )
      })
      Promise.all(allPromise).then((result) => {
        setListJobs(tableSets)
        setNewJobList(newJobOrder)
        setIsGettingJob(false)
      })
    }
  }, [jobs])

  const onClickRow = async (index: number) => {
    if (newJobList) {
      await isChatroomExists(
        newJobList[index].CustomerID,
        newJobList[index].KOLID
      ).then((res) => {
        if (res.success) {
          window.open(
            process.env.REACT_APP_HOST_NAME +
              "/" +
              (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
              "/c?chatroomID=" +
              res.chatroomID +
              "&type=contract&metaPath=" +
              newJobList[index].id
          )
        } else {
          window.open(
            process.env.REACT_APP_HOST_NAME +
              "/" +
              (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
              "/c?chatroomID=" +
              "undefined" +
              "&type=contract&metaPath=" +
              newJobList[index].id
          )
        }
      })
    }
  }

  useEffect(() => {
    setIsLoadedJobs(false)
    setIsLoaded(false)

    if (props.profile.id) {
      getKOLJobs(props.profile.id)
      setProfile(props.profile)
      setIsLoaded(true)
    }
  }, [props.profile, condition])

  const getKOLJobs = (kolID: string) => {
    getJobList(condition, kolID, orderByOption, 11)
      .then((result) => {
        if (result.success) {
          let finalData = result.data

          //always return hasMore (for nextBtn) as true when loading prev page, otherwise nextBtn might not work
          if (condition.paginationDirection === PAGINATION_DIRECTION.PREV) {
            finalData = finalData.reverse()
            setHasMore(true)
          } else {
            setHasMore(result.hasMore)
          }
          setIsLoadedJobs(true)
          setJobs(finalData)
        } else {
          setIsLoadedJobs(true)
          setJobs(jobs)
        }
      })
      .catch((err) => console.log(err))
  }

  const previousPagAction = () => {
    if (pageNum >= 1 && newJobList.length > 0) {
      setIsLoaded(false)
      setCondition({
        ...condition,
        paginationData: newJobList[0].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.PREV,
      })
      setPageNum(pageNum - 1)
    }
  }
  const nextPagAction = () => {
    if (hasMore && newJobList.length > 0) {
      setIsLoaded(false)
      setCondition({
        ...condition,
        paginationData: jobs[9].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
      setPageNum(pageNum + 1)
    }
  }

  if (isLoadedJobs && isLoaded) {
    return (
      <div>
        <ul>
          <CustomTable
            cols={listJobs}
            clickAction={onClickRow}
            isFirstColStatic={false}
            isLoading={!isLoadedJobs}
            rowIsLoading={isGettingJob}
            prevBtn={{
              disable: false,
              onClick: previousPagAction,
            }}
            pageNum={pageNum}
            nextBtn={{
              disable: !hasMore,
              onClick: nextPagAction,
            }}
            notFoundDes={t("MyTransaction.record-not-found")}
            isLastRecord={!hasMore}
          />
        </ul>
      </div>
    )
  }
  return <div>Loading</div>
}
export default KOLJobProfiler
