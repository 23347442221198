import React, { useEffect, useState } from "react"
import DivContentLoader from "../../Component/DivContentLoader"
import { useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Article, articleDefault } from "../../Model/Article"
import { getArticle } from "../../HelpingFunction/Article/ArticleDBHelper"
import { getUserLang } from "../../Utils/utiltyHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "react-i18next"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const PreviewArticle = () => {
  const history = useHistory()
  const params = useParams<{ lang: string; id: string }>()

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [article, setArticle] = useState<Article>(articleDefault)

  useEffect(() => {
    if (params.id) {
      getBlog()
    }
  }, [params])

  const getBlog = async () => {
    await getArticle(params.id).then((res) => {
      if (res.success) {
        setArticle(res.data)
        setIsLoaded(true)
      } else {
        history.push("/" + getUserLang())
      }
    })
  }
  if (isLoaded) {
    return (
      <>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-18 md:py-12 py-4">
          <Link to={"/" + getUserLang() + "/admin/list-article"}>
            <p className="mb-8">
              <span className="text-sm text-primary font-semibold">
                <FontAwesomeIcon className="mx-2" icon={faChevronLeft} />
                <Trans>All Articles</Trans>
              </span>
            </p>
          </Link>
          <section className="my-6 md:px-0 ">
            <h1 className="md:text-4xl text-2xl font-bold leading-tight mb-8">
              {article?.title}
            </h1>
            <p className="uppercase font-bold text-sm mb-1">
              {article?.authorName}
            </p>
            <p className="uppercase font-bold text-gray-400 text-sm">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore*/}
              {new Date(article?.publishDate.seconds * 1000).toDateString()}
            </p>
          </section>
          <section className="mb-12">
            <img
              className="shadow rounded-md object-cover w-full h-96"
              src={article?.featureImage}
              alt={article?.title}
            />
          </section>

          <section className="my-6 md:px-0 ">
            <hr />
          </section>
          <section className="my-12 md:px-12">
            <div
              className="md:blog mobile-blog"
              dangerouslySetInnerHTML={{
                __html: article?.content as string,
              }}
            />
          </section>
          <hr />
        </div>
      </>
    )
  }

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-18 md:py-12 py-4">
      <section className="my-6 md:px-0 px-4">
        <h1 className="md:text-4xl text-2xl font-bold leading-tight mb-8">
          {screenWidth > 768 ? (
            <DivContentLoader w={800} h={50} r={80} />
          ) : (
            <>
              <DivContentLoader w={"100%"} h={30} r={80} />
              <DivContentLoader w={"70%"} h={30} r={80} />
            </>
          )}
        </h1>
        <p className="uppercase font-bold text-sm mb-1">
          <DivContentLoader w={120} h={20} r={80} />
        </p>
        <p className="uppercase font-bold text-gray-400 text-sm">
          <DivContentLoader w={120} h={20} r={80} />
        </p>
      </section>
      <section className="mb-12">
        <DivContentLoader w={"100%"} h={screenWidth < 768 ? 350 : 380} r={20} />
      </section>

      <section className="my-6 md:px-0 px-4">
        <hr />
      </section>
    </div>
  )
}

export default PreviewArticle
