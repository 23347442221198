import {
  Chatroom,
  ChatroomJob,
  ChatroomWithLastMessage,
} from "../../Model/Chatroom"
import { DateObj } from "../../Model/Utilities"
import { CHATROOM_TYPE, UNREAD_MESSAGE_TYPE } from "../../Enum/APP_TYPE"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath } from "../../Utils/HelpingFunction"
import { ChatMessage } from "../../Model/ChatMessage"
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import firebaseApp, { analytics } from "../../config/firebase"
import { getCustomerCompanyName } from "../Customer/customerDBHelper"
import { getKOL, getKOLUserName } from "../KOL/kolDBHelper"
import { logEvent } from "firebase/analytics"
import { v4 as uuidv4 } from "uuid"
import { createNewChatRoom } from "../../Utils/ChatroomHelpingFunction"
import { NotiMessage } from "../../Model/Message"

const db = getFirestore(firebaseApp)

/**
 * get chatroom by chatroom ID
 * @param {string} id - chatroom ID
 */

const getChatroom = (
  id: string
): Promise<
  | {
      success: true
      data: Chatroom
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "ChatRoom", id))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Chatroom,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        return resolve({
          success: false,
        })
      })
  })
}

export const getChatroomsTitle = (
  uid: string,
  chatrooms: ChatroomJob[]
): Promise<ChatroomJob[]> => {
  return new Promise((resolve) => {
    if (Array.isArray(chatrooms)) {
      let newChatrooms: Promise<ChatroomJob>[] = []
      let tempChatrooms = [...chatrooms]
      tempChatrooms.map((cr: ChatroomJob, index: number) => {
        newChatrooms.push(
          new Promise(async (resolve) => {
            let role = ""
            let pid = ""
            let type = cr.type
            const kolUid: string = cr.participateUID[1]
            if (uid === kolUid) {
              role = "kol"
              pid = cr.participateUID[0]
            } else {
              role = "customer"
              pid = cr.participateUID[1]
            }

            resolve({
              ...cr,
              title: await getChatroomTitle(pid, role, type).then(
                (res) => res.title
              ),
            })
          })
        )
      })

      Promise.all(newChatrooms).then((result) => {
        return resolve(result)
      })
    } else {
      return resolve([])
    }
  })
}

export const getChatroomTitle = (
  uid: string,
  role: string,
  type: string
): Promise<{
  title: string
}> => {
  return new Promise(async (resolve) => {
    if (role === "kol") {
      if (type === "admin") {
        return resolve({
          title: "Admin",
        })
      } else if (type !== "admin") {
        await getCustomerCompanyName(uid).then((res) => {
          if (res.success) {
            return resolve({
              title: res.customerCompanyName,
            })
          }
          return resolve({
            title: "",
          })
        })
      }
    } else {
      await getKOLUserName(uid).then((res) => {
        if (res.success) {
          return resolve({
            title: res.userName,
          })
        }
        return resolve({
          title: "",
        })
      })
    }
  })
}

export const getChatLastMessage = (
  uid: string,
  chatroom: Chatroom
): Promise<
  | {
      success: true
      lastMessage: ChatMessage
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDocs(
      query(
        collection(db, "ChatRoom", chatroom.id, "Message"),
        orderBy("createDate", "desc"),
        limit(1)
      )
    ).then(async (docs) => {
      if (!docs.empty) {
        resolve({
          success: true,
          lastMessage: {
            ...(docs.docs[0].data() as ChatMessage),
          },
        })
      } else {
        resolve({
          success: false,
        })
      }
    })
  })
}

const getChatroomWithCondition = (
  uid: string,
  condition: {
    type: CHATROOM_TYPE | ""
    startAfter: string | DateObj | Date
  }
): Promise<
  | {
      success: true
      chatrooms: ChatroomJob[]
      hasMore: boolean
    }
  | {
      success: false
      chatrooms: ChatroomJob[]
    }
> => {
  return new Promise((resolve) => {
    let dbRef = query(
      collection(db, "ChatRoom"),
      where("participateUID", "array-contains", uid)
    )
    if (condition.startAfter !== "") {
      dbRef = query(dbRef, where("lastUpdate", "<", condition.startAfter))
    }

    if (condition.type !== "") {
      dbRef = query(dbRef, where("type", "==", condition.type))
    }
    getDocs(query(dbRef, limit(10), orderBy("lastUpdate", "desc")))
      .then((docs) => {
        let allChatRoom: ChatroomJob[] = []
        if (!docs.empty) {
          docs.docs.forEach(async (doc: any) => {
            allChatRoom.push({
              id: doc.id,
              ...doc.data(),
            })
          })
        }
        return resolve({
          success: true,
          chatrooms: allChatRoom,
          hasMore: allChatRoom.length === 10,
        })
      })
      .catch((err) => {
        return resolve({
          success: false,
          chatrooms: [],
        })
      })
  })
}

const getUserChatRoomWithLastChat = (
  uid: string,
  condition: {
    type: CHATROOM_TYPE
    startAfter: string | DateObj
  }
): Promise<
  | {
      success: true
      chatrooms: ChatroomWithLastMessage[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    let dbRef = query(
      collection(db, "ChatRoom"),
      where("participateUID", "array-contains", uid)
    )

    if (condition.startAfter !== "") {
      dbRef = query(dbRef, where("lastUpdate", "<", condition.startAfter))
    }

    if (condition.type !== "") {
      dbRef = query(dbRef, where("type", "==", condition.type))
    }

    getDocs(query(dbRef, limit(10), orderBy("lastUpdate", "desc")))
      .then((docs) => {
        if (!docs.empty) {
          let allPromise: Promise<any>[] = []
          docs.forEach(async (doc) => {
            allPromise.push(
              new Promise(async (resolve1, reject) => {
                //get last message
                await getDocs(
                  query(
                    collection(db, "ChatRoom", doc.id, "Message"),
                    orderBy("createDate", "desc"),
                    limit(1)
                  )
                )
                  .then(async (docs) => {
                    if (!docs.empty) {
                      resolve1({
                        id: doc.id,
                        ...doc.data(),
                        hasLastMessage: true,
                        lastMessage: {
                          id: docs.docs[0].id,
                          ...docs.docs[0].data(),
                        },
                      })
                    } else {
                      resolve1({
                        id: doc.id,
                        ...doc.data(),
                        hasLastMessage: false,
                        lastMessage: {},
                      })
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    resolve1(null)
                  })
              })
            )
          })

          Promise.all(allPromise).then((res) => {
            return resolve({
              success: true,
              chatrooms: res,
              hasMore: res.length === 10,
            })
          })
        } else {
          return resolve({
            success: false,
          })
        }
      })
      .catch((err) => console.log(err))
  })
}

/**
 * check whether the chatroom with [kol, customer] is exists, 1:1 relationship
 * @param {string} person1 - firebase uid
 * @param {string} person2 - firebase uid
 */

const isChatroomExists = (
  person1: string,
  person2: string
): Promise<
  | {
      success: true
      chatroomID: string
      exists: true
    }
  | {
      success: false
      exists: boolean
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getAPIPath("/api/chatroom/exists"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          person1: person1,
          person2: person2,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          return resolve(finalResponse)
        })
    })
  })
}

/**
 * check whether the chatroom with Admin is exists, 1:1 relationship
 * @param {string} uid - firebase uid
 */

const isAdminChatroomExists = (
  uid: string
): Promise<
  | {
      success: true
      chatroomID: string
      exists: true
    }
  | {
      success: false
      exists: boolean
    }
> => {
  return new Promise(async (resolve) => {
    await getDocs(
      query(
        collection(db, "ChatRoom"),
        where("participateUID", "array-contains", uid),
        where("type", "==", "admin"),
        limit(1)
      )
    )
      .then((docs) => {
        if (!docs.empty) {
          return resolve({
            success: true,
            chatroomID: docs.docs[0].id,
            exists: true,
          })
        }
        return resolve({
          success: false,
          exists: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * get messages from a member
 * @param {allMessage: boolean; isRead: boolean} condition - condition of searching msg
 * @param {boolean} isReadAll - is reading all msg
 * @param {string} uid - the user uid who sent this message
 */

const getMessage = (
  condition: { allMessage: boolean; isRead: boolean },
  isReadAll: boolean,
  uid: string,
  msgType: UNREAD_MESSAGE_TYPE
): Promise<{ data: NotiMessage[]; hasMore: boolean; success: boolean }> => {
  return new Promise(async (resolve) => {
    let dbRef = query(collection(db, "Member", uid, "Message"))

    if (!condition.allMessage) {
      dbRef = query(dbRef, where("isRead", "==", condition.isRead))
    }

    //get newest 25 msg
    // (May have bug of not being able to fetch other msg from other chatroom beyond the 25 newest msg)
    // Possible bug: when setting read all, not all msgs are setting read if chat unread msgs in that chatroom are 25 msgs more
    if (!isReadAll) {
      dbRef = query(dbRef, orderBy("issueDate", "desc"), limit(25))
    }

    switch (msgType) {
      case UNREAD_MESSAGE_TYPE.JUST_CHAT:
        dbRef = query(
          dbRef,
          where("type", "in", ["text", "image", "file", "file-ref"])
        )
        break
      case UNREAD_MESSAGE_TYPE.JUST_CONTRACT:
        dbRef = query(dbRef, where("type", "==", "contract"))
        break
      default:
        break
    }

    getDocs(dbRef).then((docs) => {
      let allMessages: NotiMessage[] = []
      if (!docs.empty) {
        docs.docs.forEach(async (doc: any) => {
          allMessages.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        if (!isReadAll && allMessages.length === 20) {
          resolve({ data: allMessages, hasMore: true, success: true })
        } else {
          resolve({ data: allMessages, hasMore: false, success: true })
        }
      } else {
        resolve({ data: [], hasMore: false, success: true })
      }
    })
  })
}

/**
 * send message from NewMessageContainer
 * @param {string} chatroomID - chatroomID
 * @param {ChatMessage} data - data of Message
 * @param {string} uid - the user uid who sent this message
 */

const sendChatroomMessage = (
  chatroomID: string,
  data: ChatMessage,
  uid: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await addDoc(collection(db, "ChatRoom", chatroomID, "Message"), {
      ...data,
      createUserID: uid,
      createDate: new Date(),
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

const getChatroomAvatar = (
  uid: string,
  chatroom: Chatroom
): Promise<{
  success: boolean
  avatarURL: string
}> => {
  return new Promise(async (resolve) => {
    if (uid !== chatroom.participateUID[1]) {
      await getKOL(chatroom.participateUID[1]).then((res) => {
        if (res.success) {
          return resolve({
            success: true,
            avatarURL: res.data.avatarUrl,
          })
        }
        return resolve({
          success: false,
          avatarURL: "",
        })
      })
    } else {
      return resolve({
        success: false,
        avatarURL: "",
      })
    }
  })
}

const createChatroom = (
  uid: string,
  kolID: string,
  kolUsername: string,
  customerUsername: string
): Promise<
  | {
      success: true
      chatroomID: string
      // whether this chatroom is new created or not
      isNew: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    //check whether the 1:1 chatroom is exists
    const isChatroomExistsRes = await isChatroomExists(uid, kolID)

    if (isChatroomExistsRes.success && !isChatroomExistsRes.exists) {
      // if chatroom not exists, create new chatroom

      const chatRoomObject = {
        // adminUID: ""
        // avatar:"",
        // createDate: new Date(),
        title: customerUsername + " X " + kolUsername,
        type: "job",
        participateUID: [uid, kolID],
        customTitle: [uid + "/" + customerUsername, kolID + "/" + kolUsername],
        //    more detail will be added by cloud functions
      }

      logEvent(analytics, "contact_KOL", {
        kolID: kolID,
        customerID: uid,
      })

      //generate chatroom id
      const chatroomID = uuidv4()
      return resolve({
        success: await createNewChatRoom(chatroomID, chatRoomObject),
        chatroomID: chatroomID,
        isNew: true,
      })
    } else if (isChatroomExistsRes.success && isChatroomExistsRes.exists) {
      // if the chatroom already exists
      return resolve({
        success: true,
        chatroomID: isChatroomExistsRes.chatroomID,
        isNew: false,
      })
    }
    // if error
    return resolve({
      success: false,
    })
  })
}

export {
  getChatroom,
  getUserChatRoomWithLastChat,
  isChatroomExists,
  isAdminChatroomExists,
  getMessage,
  sendChatroomMessage,
  getChatroomWithCondition,
  getChatroomAvatar,
  createChatroom,
}
