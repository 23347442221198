import classNames from "classnames"
import { selectedKOLEnum } from "../../Enum/PROFILE_TYPE"
import { Trans } from "react-i18next"
import React from "react"

interface btnGroupProps {
  type: selectedKOLEnum
  existingChatRoomID: string
  createNewChatroom: () => void
  onSelectPageType: (pageType: selectedKOLEnum) => void
}

const KOLProfileTypeBtnGroup: React.FC<btnGroupProps> = ({
  type,
  existingChatRoomID,
  createNewChatroom,
  onSelectPageType,
}) => {
  return (
    <div className="grid grid-cols-5 gap-2 ">
      <div className="col-span-1">
        {/*<Link to={"/" + (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") + "/admin/list-kol/" + selectedKOL.objectID +"/profile"}>*/}
        <button
          className={classNames(
            "py-2 px-4 w-full rounded md:text-base text-xs",
            type === selectedKOLEnum.PROFILE
              ? "bg-blue-200 text-blue-900"
              : "bg-blue-50 text-blue-900"
          )}
          onClick={() => {
            if (type !== selectedKOLEnum.PROFILE)
              onSelectPageType(selectedKOLEnum.PROFILE)
          }}>
          <Trans>KOLProfiler.basic-info</Trans>
        </button>
        {/*</Link>*/}
      </div>
      <div className="col-span-1">
        {/*<Link to={"/" + (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") + "/admin/list-kol/" + selectedKOL.objectID +"/follower"}>*/}
        <button
          className={classNames(
            "py-2 px-4 w-full rounded md:text-base text-xs",
            type === selectedKOLEnum.FOLLOWER
              ? "bg-blue-200 text-blue-900"
              : "bg-blue-50 text-blue-900"
          )}
          onClick={() => {
            if (type !== selectedKOLEnum.FOLLOWER)
              onSelectPageType(selectedKOLEnum.FOLLOWER)
          }}>
          <Trans>KOLProfiler.followers</Trans>
        </button>
        {/*</Link>*/}
      </div>
      <div className="col-span-1">
        {/*<Link to={"/" + (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") + "/admin/list-kol/" + selectedKOL.objectID +"/job"}>*/}
        <button
          className={classNames(
            "py-2 px-4 w-full rounded md:text-base text-xs",
            type === selectedKOLEnum.JOB
              ? "bg-blue-200 text-blue-900"
              : "bg-blue-50 text-blue-900"
          )}
          onClick={() => {
            if (type !== selectedKOLEnum.JOB)
              onSelectPageType(selectedKOLEnum.JOB)
          }}>
          <Trans>KOLProfiler.job</Trans>
        </button>
        {/*</Link>*/}
      </div>
      <div className="col-span-1">
        {/*<Link to={"/" + (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") + "/admin/list-kol/" + selectedKOL.objectID +"/profile"}>*/}
        <button
          className={classNames(
            "py-2 px-4 w-full rounded md:text-base text-xs",
            type === selectedKOLEnum.WALLET
              ? "bg-blue-200 text-blue-900"
              : "bg-blue-50 text-blue-900"
          )}
          onClick={() => {
            if (type !== selectedKOLEnum.WALLET)
              onSelectPageType(selectedKOLEnum.WALLET)
          }}>
          <Trans>KOLProfiler.wallet</Trans>
        </button>
        {/*</Link>*/}
      </div>

      <div className="col-span-1">
        <button
          className={classNames(
            "py-2 px-4 w-full rounded md:text-base text-xs",
            existingChatRoomID !== ""
              ? "bg-green-50 text-green-900"
              : "bg-red-50 text-red-900"
          )}
          onClick={() => {
            if (existingChatRoomID !== "") {
              window.open(
                process.env.REACT_APP_HOST_NAME +
                  "/" +
                  (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                  "/c?chatroomID=" +
                  existingChatRoomID
              )
            } else {
              createNewChatroom()
            }
          }}>
          {existingChatRoomID !== "" ? (
            <Trans>KOLProfiler.go-to-chatroom</Trans>
          ) : (
            <Trans>KOLProfiler.create-chatroom</Trans>
          )}
        </button>
      </div>
    </div>
  )
}

export default KOLProfileTypeBtnGroup
