import React from "react"
import "./App.css"
import { Provider } from "react-redux"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { getReduxStore, getRrfProp } from "./config/firebase-redux"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import RouterManager from "./Layout/RouterManager/_app"

function App() {
  return (
    <div className={["App"].join(" ")}>
      <Provider store={getReduxStore()}>
        <ReactReduxFirebaseProvider {...getRrfProp()}>
          <BrowserRouter>
            <Switch>
              <RouterManager />
            </Switch>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </div>
  )
}

export default App
