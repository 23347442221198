import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import "./Assets/i18n/i18n"
import "lazysizes"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

serviceWorkerRegistration.register()

serviceWorkerRegistration.register({
  onUpdate: () => {
    const shouldReload = window.confirm("已有新版本, 按確定重新載入頁面")
    shouldReload && window.location.reload()
    shouldReload && registration.waiting.postMessage({ type: "SKIP_WAITING" })
  },
})
