import React, { useEffect, useState } from "react"
import CustomerMiniCard from "../../../Component/CustomerMiniCard"
import { useHistory, useLocation } from "react-router-dom"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { Trans } from "react-i18next"
import CustomerProfiler from "../../../page-components/admin/CustomerProfiler"
import { isAdminChatroomExists } from "../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { useDispatch, useSelector } from "react-redux"
import Customer, { CustomerDefault } from "../../../Model/Customer"
import {
  getCustomer,
  searchCustomerAlgolia,
} from "../../../HelpingFunction/Customer/customerDBHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import classNames from "classnames"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"

const CustomerID: React.FC = (props) => {
  let history = useHistory()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const dispatch = useDispatch()

  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()

  enum selectedCustomerEnum {
    CUSTOMER_PROFILE = "profile",
    JOB = "job",
  }

  const [customerList, setCustomerList] = useState<Customer[]>([])
  const [isLoadedCustomerList, setIsLoadedCustomerList] =
    useState<boolean>(false)
  const [selectedCustomer, setSelectedCustomer] =
    useState<Customer>(CustomerDefault)
  const [isSelectedCustomer, setIsSelectedCustomer] = useState<boolean>(false)

  const [customerExist, setCustomerExist] = useState<boolean>(false)

  const [type, setType] = useState<selectedCustomerEnum>(
    selectedCustomerEnum.CUSTOMER_PROFILE
  )

  const [existingChatRoomID, setExistingChatRoomID] = useState<string>("")

  useEffect(() => {
    if (customerList.length === 0 && isAuth(auth)) {
      getCustomerList()
    }
  }, [])

  useEffect(() => {
    //used query path to avoid page from refreshing
    let queryStr = query.get("CustomerID")
    let queryInfo = queryStr?.split("?type=")

    if (queryInfo && queryInfo !== null) {
      const CustomerID = queryInfo[0]
      const pageType = queryInfo[1]

      const pageTypeNum = returnTypeEnum(pageType)

      if (pageTypeNum !== undefined) {
        setType(pageTypeNum)
        checkChatroomExist(CustomerID)
        if (CustomerID !== selectedCustomer.objectID) {
          setIsSelectedCustomer(false)
          setCustomerExist(true)
          getCustomer(CustomerID).then((result) => {
            if (result.success) {
              setSelectedCustomer({
                ...result.data,
                objectID: result.data.uid,
              } as Customer)
              setTimeout(() => {
                setIsSelectedCustomer(true)
                setCustomerExist(true)
              }, 250)
            } else {
              setSelectedCustomer(CustomerDefault)
              setIsSelectedCustomer(true)
              setCustomerExist(false)
            }
          })
        }
      }
    }
  }, [query])

  const checkChatroomExist = async (uid: string) => {
    isAdminChatroomExists(uid).then((result) => {
      if (result.success) {
        setExistingChatRoomID(result.chatroomID)
      }
    })
  }

  const getCustomerList = async (searchQuery?: string) => {
    searchCustomerAlgolia(auth.uid, searchQuery ? searchQuery : "").then(
      (response) => {
        if (response.success) {
          setCustomerList(response.data)
          setIsLoadedCustomerList(true)
        }
      }
    )
  }

  const onSelectCustomer = (cInfo: Customer) => {
    history.push(
      "/" +
        (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
        "/admin/list-customer?CustomerID=" +
        cInfo.objectID +
        "?type=profile"
    )
  }

  const onSelectPageType = (pageType: selectedCustomerEnum) => {
    let queryStr = query.get("CustomerID")
    let queryInfo = queryStr?.split("?type=")
    if (queryInfo) {
      history.push(
        "/" +
          (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
          "/admin/list-customer?CustomerID=" +
          queryInfo[0] +
          "?type=" +
          pageType
      )
    }
  }

  const returnTypeEnum = (pageTypeStr: string) => {
    switch (pageTypeStr) {
      case "profile":
        return selectedCustomerEnum.CUSTOMER_PROFILE
      case "job":
        return selectedCustomerEnum.JOB
      default:
        return undefined
    }
  }

  const createNewChatroom = (): void => {
    let queryStr = query.get("CustomerID")
    let queryInfo = queryStr?.split("?type=")
    if (queryInfo) {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
        typeOfModal: SUPPORTED_MODAL_TYPE.ADMIN_CREATE_CHATROOM_MODAL,
        metaData: { userID: queryInfo[0] },
      })
    }
  }

  return (
    <div className="w-full grid grid-cols-12 gap-4">
      <div className="col-span-4">
        <h2 className="font-bold text-xl">Customer Profile</h2>
        <div className="my-4">
          <label className="mb-2 font-sm text-gray-500">Search Customer</label>
          <input
            className="w-full p-2 rounded bg-gray-50 text-gray-900 border border-gray-200"
            placeholder={"search customer..."}
            onChange={(e) => {
              getCustomerList(e.target.value)
            }}
          />
        </div>

        {isLoadedCustomerList ? (
          customerList.map((customer, index) => {
            return (
              <CustomerMiniCard
                key={"cu" + customer.objectID}
                onSelectCustomer={onSelectCustomer}
                data={customer}
              />
            )
          })
        ) : (
          <div>LOADING...</div>
        )}
      </div>
      <div className="col-span-8">
        {isSelectedCustomer && selectedCustomer.objectID && customerExist && (
          <div className="rounded-md shadow w-full p-4 bg-white my-4">
            <div className="grid grid-cols-3 gap-2">
              <div className="col-span-1">
                <button
                  className={classNames("py-2 px-4 w-full rounded", {
                    "bg-blue-200 text-blue-900":
                      type === selectedCustomerEnum.CUSTOMER_PROFILE,
                    "bg-blue-50 text-blue-900":
                      type !== selectedCustomerEnum.CUSTOMER_PROFILE,
                  })}
                  onClick={() =>
                    onSelectPageType(selectedCustomerEnum.CUSTOMER_PROFILE)
                  }>
                  <Trans>CustomerProfiler.basic-info</Trans>
                </button>
              </div>
              <div className="col-span-1">
                <button
                  className={classNames("py-2 px-4 w-full rounded", {
                    "bg-blue-200 text-blue-900":
                      type === selectedCustomerEnum.JOB,
                    "bg-blue-50 text-blue-900":
                      type !== selectedCustomerEnum.JOB,
                  })}
                  onClick={() => onSelectPageType(selectedCustomerEnum.JOB)}>
                  <Trans>CustomerProfiler.job</Trans>
                </button>
              </div>
              <div className="col-span-1">
                <button
                  className={classNames("py-2 px-4 w-full rounded", {
                    "bg-green-50 text-green-900": existingChatRoomID !== "",
                    "bg-red-50 text-red-900": existingChatRoomID === "",
                  })}
                  onClick={() => {
                    if (existingChatRoomID !== "") {
                      window.open(
                        process.env.REACT_APP_HOST_NAME +
                          "/" +
                          (localStorage.getItem("i18n-lang") === "en"
                            ? "en"
                            : "hk") +
                          "/c?chatroomID=" +
                          existingChatRoomID
                      )
                    } else {
                      createNewChatroom()
                    }
                  }}>
                  {existingChatRoomID !== "" ? (
                    <Trans>KOLProfiler.go-to-chatroom</Trans>
                  ) : (
                    <Trans>KOLProfiler.create-chatroom</Trans>
                  )}
                </button>
              </div>
            </div>
            <hr className="my-4" />
            <CustomerProfiler
              type={type}
              auth={auth}
              selectedCustomer={selectedCustomer}
            />
          </div>
        )}
        {!customerExist && isSelectedCustomer && (
          <div className="m-2 mt-6">
            <CustomAlert
              title={"Error, this user does not exist"}
              alertType={ALERT_TYPE.ERROR}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomerID
