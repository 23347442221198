import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../redux/SUPPORTED_REDUX_FUNCTION"
import { Trans } from "react-i18next"

import { SearchKOLTable } from "../../../page-components/tools/SearchKOLTable"
import { SearchCondition } from "../../../page-components/tools/SearchCondition"
import { useHistory, useLocation } from "react-router-dom"
import {
  compareKOLQuery,
  compareQueryType,
  queryGenerator,
  queryType,
  returnSearchConditionParam,
  searchParamsToCondition,
} from "../../../Utils/QueryHelper"
import { getUserLang } from "../../../Utils/utiltyHelper"
import {
  KOLSearchDefault,
  KOLSearchType,
} from "../../../redux/reducer/ToolsManager"
import { DEFAULT_MAX_CONDITION } from "../../../Enum/KOL_SEARCH_TYPE"

export interface compareConditionType {
  pageNum?: string
  searchID?: string
  gender?: string
  nationality?: string
  isLook4kolReg?: string
  genreCategory?: string
  genre?: string
  hasData?: string
  focusPercentage?: string
  priceMin?: string
  priceMax?: string
  followerMin?: string
  followerMax?: string
}

export const Kol: React.FC = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })
  const showHelpText = useSelector((state: any) => {
    return state.ToolsManager.showHelpText
  })
  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })
  let params: compareQueryType = compareKOLQuery(paramList)

  const [isRefreshed, setIsRefreshed] = useState<boolean>(false)

  const setCondition = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: state.key,
        value: state.value,
      },
    })

  const toggleHelpText = (state: boolean) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SHOW_HELP_TEXT,
      data: state,
    })

  const setAllConditions = (state: KOLSearchType) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERIES,
      data: state,
    })
  const setCurrentPage = (page: number) => {
    console.log(page)
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: "page",
        value: page,
      },
    })
  }
  const resetPageByRefresh = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.RESET_SEARCH_KOL,
      data: null,
    })
  }
  useEffect(() => {
    const isParamEmpty = Object.entries(params).every(
      (e) => e[1] === null && e[0] !== "type"
    )
    const paramsArr = Object.entries(params).filter(
      (e) => e[1] !== null && e[0] !== "type"
    )
    // check if all params in query url are null, refresh kol result search if so
    if (!isRefreshed && isParamEmpty) {
      resetPageByRefresh()
      setIsRefreshed(true)
    } else if (
      !isRefreshed &&
      paramsArr.some((e) => {
        const changedCondition = Object.entries(searchKOLCondition).find(
          (s) => e[0] === s[0]
        )
        return changedCondition ? e[1] !== changedCondition[1] : true
      })
    ) {
      const search = searchParamsToCondition(paramsArr)
      let newCondition: KOLSearchType = {
        ...KOLSearchDefault,
        followerMax: DEFAULT_MAX_CONDITION.FOLLOWER,
        basicScoreMax: DEFAULT_MAX_CONDITION.BASIC_SCORE,
        isLook4kolReg: true,
        ...search,
      }
      setAllConditions(newCondition)
      setIsRefreshed(true)
    }
  }, [params])

  useEffect(() => {
    if (
      params.pageNum &&
      searchKOLCondition.page !== parseInt(params.pageNum) - 1
    ) {
      setCurrentPage(parseInt(params.pageNum) - 1)
    }
  }, [params.pageNum])

  const queryLoader = (query: compareConditionType) => {
    history.push({
      pathname: "/" + getUserLang() + "/admin/tools",
      search: "?" + returnSearchConditionParam(query, paramList),
    })
  }

  return (
    <div className="relative">
      {/* sticky floating bar*/}
      <div className="px-2 w-full z-10 bg-white shadow h-14 md:h-28 text-gray-700 sticky top-0">
        <SearchCondition queryPushFn={queryLoader} />
      </div>

      {/* main content*/}
      <div className="relative max-w-7xl mx-auto pt-4 pb-36 px-4">
        {/* Recommendation group */}

        <h2 className="text-grey-800 text-xl font-bold leading-tight  ">
          <Trans>SearchKOL.try-those</Trans>
        </h2>

        <section className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-4 mb-8">
          <div className="rounded-lg bg-red-default text-white p-4 w-full h-full">
            <div className="text-lg font-bold mb-2">
              <Trans>SearchKOL.focus-group</Trans>
            </div>
            <div className="text-gray-200 text-md">
              <Trans>SearchKOL.focus-group-des</Trans>
            </div>

            <button
              id={"SearchKOL_FocusGroupTryNow"}
              className="mt-5 float-right rounded-2xl bg-white text-red-default py-2 px-4 text-center"
              onClick={() => {
                setCondition({
                  key: "hasData",
                  value: true,
                })
                setCondition({
                  key: "focusPercentage",
                  value: 0.5,
                })
                queryLoader({ hasData: "true", focusPercentage: "0.5" })
              }}>
              <Trans>SearchKOL.try-now</Trans>
            </button>
          </div>
        </section>

        <h2 className="text-grey-800 text-xl font-bold leading-tight  ">
          <Trans>SearchKOL.search-result</Trans>
        </h2>

        <div className="my-4">
          <input
            type="checkbox"
            className="toggle"
            checked={showHelpText}
            onChange={(e) => {
              toggleHelpText(!showHelpText)
            }}
          />

          <span className="mx-2 text-md text-gray-500">
            <Trans>SearchKOL.recommendation</Trans>
          </span>
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12 lg:col-span-12">
            <SearchKOLTable params={params} queryPushFn={queryLoader} />
          </div>
        </div>
      </div>
    </div>
  )
}
