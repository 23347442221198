import React, { useEffect, useState } from "react"
import { isAuth } from "../../Utils/FirebaseUtils"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {
  getJob,
  getJobList,
  jobListCondition,
} from "../../HelpingFunction/Job/JobDBHelper"
import {
  Job,
  JOB_LIST_CONDITION_TYPE,
  JobFilter,
  JobFilterDefault,
  JobFilterOption,
} from "../../Model/Job"
import CustomTable from "../../Component/NewTable"
import { Column, tableFunctions } from "../../Model/Table"
import {
  adminJobTableSchema,
  defaultAdminJobTableSchema,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../../Utils/TableHelperFunction"
import { isChatroomExists } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import {
  BUTTON_TYPE,
  TABLE_FUNCTIONS,
  WIDTH_SIZE,
} from "../../Enum/TABLE_SYSTEM"
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons"

const AdminJob: React.FC = (props) => {
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const { t } = useTranslation()

  const jobOption = JobFilterOption

  const [job, setJob] = useState<Job[]>([])
  //for updating the order of Jobs after calling the TableSchema, used for onClick Actions
  const [newJobList, setNewJobList] = useState<Job[]>([])

  const [isLoadedJob, setIsLoadedJob] = useState<boolean>(false)

  const [orderByOption, setOrderByOption] = useState<{
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }>({
    fieldName: "lastUpdate",
    direction: ORDER_BY_DIRECTION.DESC,
  })

  const [isGettingMoreJobs, setIsGettingMoreJobs] = useState<boolean>(false)
  const [condition, setCondition] = useState<jobListCondition>({
    ...JobFilterDefault,
    jobID: "",
    uid: "",
  })

  const [pageNum, setPageNum] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(false)

  const [selectionValue, setSelectionValue] = useState<string>("")

  const [contractList, setContractList] = useState<Column[][]>([
    defaultAdminJobTableSchema(),
  ])

  useEffect(() => {
    if (isAuth(auth) && !isLoadedJob) {
      getJobData()
    }
  }, [auth, condition])

  const getJobData = () => {
    if (condition.jobID === "") {
      getJobList(
        condition,
        condition.uid ? condition.uid : "",
        orderByOption,
        11
      )
        .then((result) => {
          if (result.success) {
            let finalData = result.data

            //always return hasMore (for nextBtn) as true when loading prev page, otherwise nextBtn might not work
            if (condition.paginationDirection === PAGINATION_DIRECTION.PREV) {
              finalData = finalData.reverse()
              setHasMore(true)
            } else {
              setHasMore(result.hasMore)
            }
            setIsLoadedJob(true)
            setJob(finalData)
          }
        })
        .catch((err) => console.log(err))
    } else if (condition.jobID) {
      getJob(condition.jobID).then((result) => {
        if (result.success) {
          setHasMore(false)
          setIsLoadedJob(true)
          setJob([result.job])
        }
      })
    }
  }

  const reset = () => {
    setIsLoadedJob(false)
    setSelectionValue("0")
    setPageNum(0)
    setHasMore(false)
    setCondition({
      ...JobFilterDefault,
      jobID: "",
    })
  }

  useEffect(() => {
    if (Array.isArray(job) && isLoadedJob) {
      schemaCaller()
    }
  }, [job])

  const schemaCaller = () => {
    if (Array.isArray(job) && isLoadedJob) {
      setIsGettingMoreJobs(true)

      let tableSets: Column[][] = []
      let newJobOrder: Job[] = []

      let allPromise: Promise<any>[] = []

      job.forEach(async (jobData) => {
        allPromise.push(
          new Promise(async (resolve) => {
            tableSets.push(adminJobTableSchema(jobData))
            newJobOrder.push(jobData)
            resolve(null)
          })
        )
      })
      Promise.all(allPromise).then((result) => {
        setContractList(tableSets)
        setNewJobList(newJobOrder)
        setIsGettingMoreJobs(false)
      })
    }
  }

  const getChatroomId = async (index: number) => {
    await isChatroomExists(
      newJobList[index].CustomerID,
      newJobList[index].KOLID
    ).then((res) => {
      if (res.success) {
        window.open(
          process.env.REACT_APP_HOST_NAME +
            "/" +
            (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
            "/c?chatroomID=" +
            res.chatroomID +
            "&type=contract&metaPath=" +
            newJobList[index].id
        )
      } else {
        window.open(
          process.env.REACT_APP_HOST_NAME +
            "/" +
            (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
            "/c?chatroomID=" +
            "undefined" +
            "&type=contract&metaPath=" +
            newJobList[index].id
        )
      }
    })
  }

  const previousPagAction = () => {
    if (pageNum >= 1 && newJobList.length > 0) {
      setIsLoadedJob(false)
      setCondition({
        ...condition,
        paginationData: newJobList[0].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.PREV,
      })
      setPageNum(pageNum - 1)
    }
  }
  const nextPagAction = () => {
    if (hasMore && newJobList.length > 0) {
      setIsLoadedJob(false)
      setCondition({
        ...condition,
        paginationData: newJobList[9].lastUpdate,
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
      setPageNum(pageNum + 1)
    }
  }

  const conditionControl = (e: any) => {
    setIsLoadedJob(false)
    setPageNum(0)
    setJob([])

    const type: JOB_LIST_CONDITION_TYPE = e.value
    setCondition({
      ...JobFilter[type],
      jobID: "",
    })
  }

  const labelFunction = (event: { target: { selectedIndex: number } }) => {
    const ets: number = event.target.selectedIndex
    conditionControl(jobOption[ets - 1])
  }

  const tableFn: tableFunctions[] = [
    {
      title: t("ListContract.type-of-job"),
      data: {
        type: TABLE_FUNCTIONS.SELECT,
        onChange: (e) => {
          labelFunction(e)
          setSelectionValue((e.target.selectedIndex - 1).toString())
        },
        labeledValue: selectionValue,
        selectData: jobOption,
        width: WIDTH_SIZE.XL,
      },
    },
    {
      title: "AdminRequest.reset",
      data: {
        type: TABLE_FUNCTIONS.BUTTON,
        buttonType: BUTTON_TYPE.SUCCESS,
        icon: faRotateLeft,
        onClick: reset,
        text: "AdminRequest.reset",
      },
    },
    {
      title: "Job ID",
      data: {
        type: TABLE_FUNCTIONS.INPUT,
        placeHolder: "Job UID",
        textValue: condition.jobID ? condition.jobID : "",
        width: WIDTH_SIZE.XXL,
        onChange: (e) => {
          setPageNum(0)
          setIsLoadedJob(false)
          setCondition({
            ...condition,
            jobID: e.target.value,
          })
        },
      },
    },
    {
      title: "Participate UID",
      data: {
        type: TABLE_FUNCTIONS.INPUT,
        placeHolder: "Participate UID",
        textValue: condition.uid ? condition.uid : "",
        width: WIDTH_SIZE.XXL,
        onChange: (e) => {
          setPageNum(0)
          setIsLoadedJob(false)
          setCondition({
            ...JobFilterDefault,
            uid: e.target.value,
            jobID: "",
          })
        },
      },
    },
  ]

  return (
    <div>
      <h2 className="font-bold text-xl p-1 m-2">Job Status</h2>
      <label className="font-sm text-gray-500 p-1 mx-4">Job Filter</label>
      <div>
        <CustomTable
          cols={contractList}
          tableFn={tableFn}
          clickAction={getChatroomId}
          prevBtn={{
            disable: false,
            onClick: previousPagAction,
          }}
          pageNum={pageNum}
          nextBtn={{
            disable: !hasMore,
            onClick: nextPagAction,
          }}
          isFirstColStatic={false}
          isLoading={!isLoadedJob}
          rowIsLoading={isGettingMoreJobs}
          notFoundDes={"Record Not Found"}
          isLastRecord={!hasMore}
        />
      </div>
    </div>
  )
}
export default AdminJob
