import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import firebase, { analytics } from "../../../../config/firebase"
import { isAuth, returnIdTokenResult } from "../../../../Utils/FirebaseUtils"
import {
  timeStampToDateString,
  timeStampToDateTimeString,
  timeStampToDisplayTimeString,
  timestraptoIOStime,
} from "../../../../Utils/Converter"
import classes from "../../ContactKOLModal/ContactKOLModal.module.css"
import Spinner from "../../../Spinner"
import { getAPIPath } from "../../../../Utils/HelpingFunction"
import { useTranslation } from "react-i18next"
import { getJob } from "../../../../HelpingFunction/Job/JobDBHelper"
import { logEvent } from "firebase/analytics"
import { isChatroomExists } from "../../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { getKOLUserName } from "../../../../HelpingFunction/KOL/kolDBHelper"
import { getCustomerCompanyName } from "../../../../HelpingFunction/Customer/customerDBHelper"
import {
  returnStatusString,
  setAdminRequest,
} from "../../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import { ADMIN_REQUEST_STATUS_TYPE } from "../../../../Enum/APP_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../../redux/SUPPORTED_REDUX_FUNCTION"

const BankCheckoutRequest = (props) => {
  const dispatch = useDispatch()
  //TODO Admin finishJobPayment : i.e. : stripeRouter.js /charge/create, use Customer.finishJobPayment only

  const setRequestStateChanged = (status) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_REQUEST_STATUS_CHANGED,
      data: status,
    })
  }

  const [job, setJob] = useState({})
  const [isLoadedJob, setIsLoadedJob] = useState(false)
  const [kolName, setKOLName] = useState("")
  const [customerCompany, setCustomerCompany] = useState("")

  const [isAcceptLoading, setIsAcceptLoading] = useState(false)
  const [isRejectLoading, setIsRejectLoading] = useState(false)

  const [error, setError] = useState("")

  const { t, i18n } = useTranslation()

  const request = props.request

  useEffect(() => {
    if (
      isAuth(props.auth) &&
      props.request.jobID !== "" &&
      typeof props.request.jobID !== "undefined"
    )
      getJob(props.request.jobID).then((result) => {
        if (result.success) {
          setJob(result.job)
          setIsLoadedJob(true)
          getNames(result.job)
        }
      })
  }, [props.auth, props.request.jobID])

  const confirmPayment = () => {
    setIsAcceptLoading(true)

    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/admin/job/" +
            props.request.jobID +
            "/bankCheckout/" +
            props.request.id
        ),
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            setRequestStateChanged(true)
            logEvent(analytics, "contract_conversion", {
              value: job.price,
            })
            setTimeout(() => {
              setIsAcceptLoading(false)
              props.closeModal()
            }, 1200)
          } else {
            setError(finalResponse.error)
          }
        })
    })
  }

  const rejectCheckout = async () => {
    setIsRejectLoading(true)
    let newRequest = {
      ...request,
      status: ADMIN_REQUEST_STATUS_TYPE.REJECT,
    }

    await setAdminRequest(request.id, newRequest).then((res) => {
      if (res.success) {
        setRequestStateChanged(true)
        setIsRejectLoading(false)
        props.closeModal()
      }
    })
  }

  const getNames = async (job) => {
    await getKOLUserName(job.KOLID).then((res) => {
      if (res.success) {
        setKOLName(res.userName)
      }
    })
    await getCustomerCompanyName(job.CustomerID).then((res) => {
      if (res.success) {
        setCustomerCompany(res.customerCompanyName)
      }
    })
  }

  const screenWidth = useSelector((state) => {
    return state.SystemManager.screenWidth
  })

  if (isLoadedJob) {
    return (
      <div className="w-full">
        <div className="md:grid md:grid-cols-2 gap-2">
          <div className="md:border-r md:pr-3">
            <h1 className="text-lg text-gray-600 mt-0 mb-2">
              Bank Checkout Request - {returnStatusString(request.status)}
            </h1>

            <section className="my-3 text-3xl font-medium">
              HKD {job.price}
            </section>

            <section className="my-2 md:hidden block">
              <p className="text-sm font-medium text-gray-500">{job.id}</p>
              <p className="text-sm my-3">
                <span className="font-medium mr-4">Job Name:</span>
                {job.ServicePlanName}
              </p>
              <p className="text-sm my-3">
                <span className="font-medium mr-4">KOL Name: </span>
                {kolName}
              </p>
              <p className="text-sm my-3">
                <span className="font-medium mr-4">Customer Company: </span>
                {customerCompany}
              </p>
              <p className="text-sm my-3">
                <span className="font-medium mr-4">Last Update: </span>
                {timeStampToDisplayTimeString(job.lastUpdate)}
              </p>
              <button
                className="rounded-2xl bg-gray-50 hover:bg-gray-100 border border-gray-500 text-gray-700 py-1 px-2 my-2"
                onClick={async () => {
                  const chatroom = await isChatroomExists(
                    job.CustomerID,
                    job.KOLID
                  )
                  if (chatroom.success) {
                    const chatroomID = chatroom.chatroomID
                    window.open(
                      `${process.env.REACT_APP_HOST_NAME}/` +
                        (localStorage.getItem("i18n-lang") === "en"
                          ? "en"
                          : "hk") +
                        "/c?chatroomID=" +
                        chatroomID +
                        "&type=contract&metaPath=" +
                        job.id
                    )
                  }

                  props.closeModal()
                }}>
                Go to Job
              </button>

              <hr />

              <div className="my-4">
                <div className="text-md my-2 text-gray-600">Job Amount</div>
                <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
                  HKD {job.price}
                </div>
              </div>
            </section>

            <section>
              <div className="w-full p-2 rounded-lg my-4 border border-gray-100">
                <div>
                  {Array.isArray(props.request.data) &&
                    props.request.data.map((file) => {
                      return (
                        <div
                          key={file.fileKey}
                          className={classes.ImageContainer}>
                          <img
                            onClick={() => {
                              window.open(file.url)
                            }}
                            className={classes.ImageItem}
                            src={file.thumbnailUrl}
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
            </section>

            <section>
              {request.status === ADMIN_REQUEST_STATUS_TYPE.PENDING && (
                <>
                  <div className="flex flex-row gap-8">
                    <button
                      className="rounded-2xl bg-green-500 hover:bg-green-600 text-white w-full p-2 mb-20"
                      disabled={job.paid}
                      onClick={() => confirmPayment()}>
                      {isAcceptLoading ? <Spinner /> : "Confirm Request"}
                    </button>
                    <button
                      className="rounded-2xl bg-red-500 hover:bg-red-600 text-white w-full p-2 mb-20"
                      disabled={job.paid}
                      onClick={() => rejectCheckout()}>
                      {isRejectLoading ? <Spinner /> : "Reject"}
                    </button>
                  </div>
                  <div className="mt-1 text-sm text-gray-400 text-right">
                    when press this button, the job will be marked as "PAID"
                  </div>
                </>
              )}
              {error !== "" && (
                <div className="text-md text-red-700 my-2">{t(error)}</div>
              )}
            </section>
          </div>
          <div className="md:block hidden p-2">
            <p className="text-2xl font-bold">{job.ServicePlanName}</p>
            <p className="text-xs text-gray-500 my-2">{job.id}</p>
            <section className="my-4 grid grid-cols-2 w-fit mt-8">
              <p className=" text-gray-500">KOL: </p>
              <p className="font-medium">{kolName}</p>
              <p className=" text-gray-500">Customer: </p>
              <p className="font-medium">{customerCompany}</p>
            </section>
            <hr />
            <section className="my-2">
              <p className="font-medium text-lg">Job Details</p>
              <div className="p-2 border my-2 rounded-lg h-[180px] overflow-auto">
                <p dangerouslySetInnerHTML={{ __html: job.quotationDetail }} />
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
  return <div>LOADING...</div>
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}
const enhance = connect(
  mapStateToProps
  // mapDispatchToProps
)

export default enhance(withRouter(BankCheckoutRequest))
