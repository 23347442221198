import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts"
import { isAuth } from "../../Utils/FirebaseUtils"
import { getRegisterStats } from "../../HelpingFunction/Admin/AdminDBHelper"
import {
  dateGetter,
  monthToDaily,
  monthToWeek,
  registrationHelper,
} from "../../Layout/AdminRouter/RegistrationStats/RegistrationHelper/RegistrationHelper"
import {
  REGISTER_KOL_TYPE,
  REGISTER_STAT_TYPE,
} from "../../Enum/ADMIN_ACTION_TYPE"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChartBar,
  faCircleCheck,
  faCircleXmark,
  faRectangleAd,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { timeStampToDisplayTimeString } from "../../Utils/Converter"
import { useHistory } from "react-router-dom"

const Register = (props: { auth: any }) => {
  let history = useHistory()
  const [dailyRegisteredDatesLength, setDailyRegisteredDatesLength] =
    useState<number>(0)
  const [weeklyRegisteredDatesLength, setWeeklyRegisteredDatesLength] =
    useState<number>(0)
  const [monthlyRegisteredDatesLength, setMonthlyRegisteredDatesLength] =
    useState<number>(0)

  interface registeredAccountInfo {
    registerTime: Date
    registerName: string
    registerEmail: string
    registerIsVerified: boolean
    registerID: string
    isKOL: boolean
  }

  const [registeredAccounts, setRegisteredAccounts] = useState<
    registeredAccountInfo[]
  >([])

  const [weeklyTimeslots, setWeeklyTimeslots] = useState<
    registeredAccountInfo[][]
  >([[]])
  const [monthlyTimeslots, setMonthlyTimeslots] = useState<
    registeredAccountInfo[][]
  >([[]])
  const [weeklyTimeslotsLength, setWeeklyTimeslotsLength] = useState<number[]>(
    []
  )
  const [monthlyTimeslotsLength, setMonthlyTimeslotsLength] = useState<
    number[]
  >([])

  const [filterKOLType, setFilterKOLType] = useState<REGISTER_KOL_TYPE>(
    REGISTER_KOL_TYPE.DEFAULT
  )

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [weekIsOpened, setWeekIsOpened] = useState<boolean>(false)
  const [monthIsOpened, setMonthIsOpened] = useState<boolean>(false)
  const [weekOpenedIndex, setWeekOpenedIndex] = useState<number>(0)
  const [monthOpenedIndex, setMonthOpenedIndex] = useState<number>(0)

  const [filterName, setFilterName] = useState<string>("Total")
  const [filterRegisterDes, setFilterRegisterDes] =
    useState<string>("Registers in Total")

  useEffect(() => {
    switch (filterKOLType) {
      case REGISTER_KOL_TYPE.DEFAULT:
        setFilterName("All")
        setFilterRegisterDes("Registers in Total")
        break
      case REGISTER_KOL_TYPE.KOL_ONLY:
        setFilterName("KOL Only")
        setFilterRegisterDes("KOL Registers")
        break
      case REGISTER_KOL_TYPE.VERIFIED_KOL:
        setFilterName("Verified KOL Only")
        setFilterRegisterDes("Verified KOL Registers")
        break
      default:
        break
    }
  }, [filterKOLType])

  useEffect(() => {
    if (isAuth(props.auth) && !isLoaded) {
      // reset registerDates
      setRegisteredAccounts([])
      setMonthlyRegisteredDatesLength(0)
      setDailyRegisteredDatesLength(0)
      setWeeklyRegisteredDatesLength(0)
      setWeeklyTimeslots([])
      setMonthlyTimeslots([])

      // update register stats when initialised or clicked for changing time period
      getRegisterStats(filterKOLType).then((result) => {
        if (result.success) {
          let accounts: registeredAccountInfo[] = []
          result.accounts.forEach((acc) => {
            accounts.push(acc)
          })
          setRegisteredAccounts(accounts)
          setMonthlyRegisteredDatesLength(accounts.length)

          // split Timeslots as 24 hours or 7 days period

          setDailyRegisteredDatesLength(monthToDaily(accounts).length)
          setWeeklyRegisteredDatesLength(monthToWeek(accounts).length)

          setWeeklyTimeslots(
            registrationHelper(monthToWeek(accounts), REGISTER_STAT_TYPE.WEEKLY)
          )
          setMonthlyTimeslots(
            registrationHelper(accounts, REGISTER_STAT_TYPE.MONTHLY)
          )

          let slots: number[] = []
          registrationHelper(
            monthToWeek(accounts),
            REGISTER_STAT_TYPE.WEEKLY
          ).forEach((slot) => {
            slots.push(slot.length)
          })
          setWeeklyTimeslotsLength(slots)
          slots = []
          registrationHelper(accounts, REGISTER_STAT_TYPE.MONTHLY).forEach(
            (slot) => {
              slots.push(slot.length)
            }
          )
          setMonthlyTimeslotsLength(slots)

          setIsLoaded(true)
        } else {
          console.log("No register data!")
          setIsLoaded(true)
        }
      })
    } else {
      console.log("User plan not valid")
    }
  }, [props.auth, isLoaded])

  const weeklyTemplate: string[] = dateGetter(REGISTER_STAT_TYPE.WEEKLY)
  const monthlyTemplate: string[] = dateGetter(REGISTER_STAT_TYPE.MONTHLY)

  const maximumLimit = (registeredArr: registeredAccountInfo[][]) => {
    let maxArrList: number[] = []
    registeredArr.forEach((acc) => {
      maxArrList.push(acc.length)
    })
    if (Math.max(...maxArrList) < 10) {
      return 10
    }
    return (parseInt(String(Math.max(...maxArrList) / (4 / 5) / 5)) + 1) * 5
  }

  const weeklyOptions = {
    title: "7-Days " + filterRegisterDes,
    hAxis: { title: "Date", viewWindow: { min: 0, max: 7 } },
    vAxis: {
      title: "New Register",
      viewWindowMode: "explicit",
      viewWindow: { min: 0, max: maximumLimit(weeklyTimeslots) },
      baseline: {
        color: "#F6F6F6",
      },
    },
    legend: "none",
  }

  const weeklyData = [
    ["Date", "New Register"],
    [weeklyTemplate[0], weeklyTimeslotsLength[0]],
    [weeklyTemplate[1], weeklyTimeslotsLength[1]],
    [weeklyTemplate[2], weeklyTimeslotsLength[2]],
    [weeklyTemplate[3], weeklyTimeslotsLength[3]],
    [weeklyTemplate[4], weeklyTimeslotsLength[4]],
    [weeklyTemplate[5], weeklyTimeslotsLength[5]],
    ["Today", weeklyTimeslotsLength[6]],
  ]

  const monthlyOptions = {
    title: "30-Days " + filterRegisterDes,
    hAxis: {
      title: "Date",
      viewWindow: { min: 0, max: 30 },
      textStyle: { fontSize: 10 },
    },
    vAxis: {
      title: "New Register",
      viewWindowMode: "explicit",
      viewWindow: { min: 0, max: maximumLimit(monthlyTimeslots) },
      baseline: {
        color: "#F6F6F6",
      },
    },
    legend: "none",
  }

  const monthlyData = [
    ["Hour", "New Register"],
    [monthlyTemplate[0], monthlyTimeslotsLength[0]],
    [monthlyTemplate[1], monthlyTimeslotsLength[1]],
    [monthlyTemplate[2], monthlyTimeslotsLength[2]],
    [monthlyTemplate[3], monthlyTimeslotsLength[3]],
    [monthlyTemplate[4], monthlyTimeslotsLength[4]],
    [monthlyTemplate[5], monthlyTimeslotsLength[5]],
    [monthlyTemplate[6], monthlyTimeslotsLength[6]],
    [monthlyTemplate[7], monthlyTimeslotsLength[7]],
    [monthlyTemplate[8], monthlyTimeslotsLength[8]],
    [monthlyTemplate[9], monthlyTimeslotsLength[9]],
    [monthlyTemplate[10], monthlyTimeslotsLength[10]],
    [monthlyTemplate[11], monthlyTimeslotsLength[11]],
    [monthlyTemplate[12], monthlyTimeslotsLength[12]],
    [monthlyTemplate[13], monthlyTimeslotsLength[13]],
    [monthlyTemplate[14], monthlyTimeslotsLength[14]],
    [monthlyTemplate[15], monthlyTimeslotsLength[15]],
    [monthlyTemplate[16], monthlyTimeslotsLength[16]],
    [monthlyTemplate[17], monthlyTimeslotsLength[17]],
    [monthlyTemplate[18], monthlyTimeslotsLength[18]],
    [monthlyTemplate[19], monthlyTimeslotsLength[19]],
    [monthlyTemplate[20], monthlyTimeslotsLength[20]],
    [monthlyTemplate[21], monthlyTimeslotsLength[21]],
    [monthlyTemplate[22], monthlyTimeslotsLength[22]],
    [monthlyTemplate[23], monthlyTimeslotsLength[23]],
    [monthlyTemplate[24], monthlyTimeslotsLength[24]],
    [monthlyTemplate[25], monthlyTimeslotsLength[25]],
    [monthlyTemplate[26], monthlyTimeslotsLength[26]],
    [monthlyTemplate[27], monthlyTimeslotsLength[27]],
    [monthlyTemplate[28], monthlyTimeslotsLength[28]],
    [monthlyTemplate[29], monthlyTimeslotsLength[29]],
  ]

  const weekChartEvents = [
    {
      eventName: "select",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      callback({ chartWrapper }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (chartWrapper.getChart().getSelection() !== []) {
          setWeekOpenedIndex(chartWrapper.getChart().getSelection()[0].row)
          setWeekIsOpened(true)
        } else setWeekIsOpened(false)
      },
    },
  ]
  const monthChartEvents = [
    {
      eventName: "select",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      callback({ chartWrapper }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (chartWrapper.getChart().getSelection() !== []) {
          setMonthOpenedIndex(chartWrapper.getChart().getSelection()[0].row)
          setMonthIsOpened(true)
        } else setMonthIsOpened(false)
      },
    },
  ]

  return (
    <div>
      <h1 className="text-2xl flex justify-center">Registration Stats</h1>

      <div className="">
        <div className="dropdown relative dropdown-end flex justify-end my-6 ">
          <label tabIndex={0} className="btn m-1">
            <FontAwesomeIcon icon={faChartBar} className="mr-1" /> Filter:{" "}
            {filterName}
          </label>
          <div
            tabIndex={0}
            className="dropdown-content menu fixed top-14 p-2 border border-gray-200 bg-white shadow-lg rounded-box w-52 grid justify-items-stretch">
            <button
              className="btn btn-primary mb-4 w-full no-animation"
              onClick={() => {
                setFilterKOLType(REGISTER_KOL_TYPE.DEFAULT)
                setIsLoaded(false)
                setWeekIsOpened(false)
                setMonthIsOpened(false)
              }}>
              <div>All</div>
            </button>

            <button
              className="btn btn-primary mb-4 w-full no-animation"
              onClick={() => {
                setFilterKOLType(REGISTER_KOL_TYPE.KOL_ONLY)
                setIsLoaded(false)
                setWeekIsOpened(false)
                setMonthIsOpened(false)
              }}>
              <div>KOL Only</div>
            </button>

            <button
              className="btn btn-primary w-full no-animation"
              onClick={() => {
                setFilterKOLType(REGISTER_KOL_TYPE.VERIFIED_KOL)
                setIsLoaded(false)
                setWeekIsOpened(false)
                setMonthIsOpened(false)
              }}>
              <div>Verified KOL Only</div>
            </button>
          </div>
        </div>
      </div>
      <div className="">
        <div className="stats shadow w-full">
          <div className="stat ">
            <div className="stat-title text-primary">
              Today {filterRegisterDes}
            </div>
            <div className="stat-value text-primary">
              {dailyRegisteredDatesLength}
            </div>
          </div>
          <div className="stat ">
            <div className="stat-title">7-Days {filterRegisterDes}</div>
            <div className="stat-value">{weeklyRegisteredDatesLength}</div>
          </div>
          <div className="stat ">
            <div className="stat-title">30-Days {filterRegisterDes}</div>
            <div className="stat-value">{monthlyRegisteredDatesLength}</div>
          </div>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-2 lg:gap-2">
        {/* First Chart */}
        <section className="col-span-1 my-6">
          <div
            className={[
              "card flex w-full bg-base-100 shadow-xl ",
              weekIsOpened ? "h-160" : "h-96",
            ].join(" ")}>
            <div className={[weekIsOpened ? "h-48" : "h-full"].join(" ")}>
              <Chart
                chartType="LineChart"
                data={weeklyData}
                options={weeklyOptions}
                width="100%"
                height="100%"
                legendToggle
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                chartEvents={weekChartEvents}
              />
            </div>
            {weekIsOpened && (
              <div className="h-full">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-semibold p-4">
                    Registered List
                  </h2>
                  <div>
                    <button
                      className="btn btn-circle btn-ghost btn-sm text-lg m-4"
                      onClick={() => {
                        setWeekIsOpened(false)
                      }}>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                {weeklyTimeslots[weekOpenedIndex].length === 0 && (
                  <div className="p-4 text-center text-gray-500 font-bold">
                    Timeslot is empty...
                  </div>
                )}
                {weeklyTimeslots[weekOpenedIndex].length !== 0 && (
                  <div className="h-85 overflow-x-auto overflow-y-scroll p-4">
                    <table className="table w-full">
                      {/*head*/}
                      <thead>
                        <tr>
                          <th />
                          <th>Name</th>
                          <th>Email</th>
                          <th>Registered Date</th>
                          <th className="flex justify-center">Verified</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*rows*/}
                        {weeklyTimeslots[weekOpenedIndex].map(
                          (account: registeredAccountInfo, index: number) => {
                            if (index >= 0)
                              return (
                                <tr
                                  className={
                                    account.isKOL ? "cursor-pointer" : ""
                                  }
                                  onClick={() => {
                                    if (account.isKOL)
                                      window.open(
                                        process.env.REACT_APP_HOST_NAME +
                                          "/" +
                                          (localStorage.getItem("i18n-lang") ===
                                          "en"
                                            ? "en"
                                            : "hk") +
                                          "/p/" +
                                          account.registerID
                                      )
                                  }}>
                                  <th>{index + 1}</th>
                                  <td>{account.registerName}</td>
                                  <td>{account.registerEmail}</td>
                                  <td>
                                    {timeStampToDisplayTimeString(
                                      account.registerTime.getTime() / 1000
                                    )}
                                  </td>
                                  <td
                                    className={[
                                      "flex justify-center",
                                      account.registerIsVerified
                                        ? "text-primary"
                                        : "text-gray-500",
                                      account.isKOL ? "" : "text-sky-700",
                                    ].join(" ")}>
                                    {account.registerIsVerified ? (
                                      <FontAwesomeIcon icon={faCircleCheck} />
                                    ) : (
                                      <div>
                                        {account.isKOL ? (
                                          <FontAwesomeIcon
                                            icon={faCircleXmark}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faRectangleAd}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              )
                            return <div />
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>

        {/* Second Chart */}
        <section className="col-span-1 my-6">
          <div
            className={[
              "card flex w-full bg-base-100 shadow-xl ",
              monthIsOpened ? "h-160" : "h-96",
            ].join(" ")}>
            <div className={[monthIsOpened ? "h-48" : "h-full"].join(" ")}>
              <Chart
                chartType="LineChart"
                data={monthlyData}
                options={monthlyOptions}
                width="100%"
                height="100%"
                legendToggle
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                chartEvents={monthChartEvents}
              />
            </div>
            {monthIsOpened && (
              <div className="h-full">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-semibold p-4">
                    Registered List
                  </h2>
                  <div>
                    <button
                      className="btn btn-circle btn-ghost btn-sm text-lg m-4"
                      onClick={() => {
                        setMonthIsOpened(false)
                      }}>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                {monthlyTimeslots[monthOpenedIndex].length === 0 && (
                  <div className="p-4 text-center text-gray-500 font-bold">
                    Timeslot is empty...
                  </div>
                )}
                {monthlyTimeslots[monthOpenedIndex].length !== 0 && (
                  <div className="h-85 overflow-x-auto overflow-y-scroll p-4">
                    <table className="table w-full">
                      {/*head*/}
                      <thead>
                        <tr>
                          <th />
                          <th>Name</th>
                          <th>Email</th>
                          <th>Registered Date</th>
                          <th className="flex justify-center">Verified</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*rows*/}
                        {monthlyTimeslots[monthOpenedIndex].map(
                          (account: registeredAccountInfo, index: number) => {
                            if (index >= 0)
                              return (
                                <tr
                                  className={[
                                    account.registerIsVerified
                                      ? "text-primary"
                                      : "text-gray-500",
                                    account.isKOL
                                      ? "cursor-pointer"
                                      : "text-sky-700",
                                  ].join(" ")}
                                  onClick={() => {
                                    if (account.isKOL)
                                      window.open(
                                        "https://look4kol.com/" +
                                          (localStorage.getItem("i18n-lang") ===
                                          "en"
                                            ? "en"
                                            : "hk") +
                                          "/p/" +
                                          account.registerID
                                      )
                                  }}>
                                  <th>{index + 1}</th>
                                  <td>{account.registerName}</td>
                                  <td>{account.registerEmail}</td>
                                  <td>
                                    {timeStampToDisplayTimeString(
                                      account.registerTime.getTime() / 1000
                                    )}
                                  </td>
                                  <td className="flex justify-center">
                                    {account.registerIsVerified ? (
                                      <FontAwesomeIcon icon={faCircleCheck} />
                                    ) : (
                                      <div>
                                        {account.isKOL ? (
                                          <FontAwesomeIcon
                                            icon={faCircleXmark}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faRectangleAd}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              )
                            return <div />
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  )
}
export default Register
