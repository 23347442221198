export const SUPPORTED_REDUX_FUNCTIONS = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",

  SET_SCREEN_WIDTH: "SET_SCREEN_WIDTH",
  SET_SCREEN_HEIGHT: "SET_SCREEN_HEIGHT",

  // AdminControlManger
  SET_PROMOTED_KOL_LIST: "SET_PROMOTED_KOL_LIST",
  ADD_TO_PROMOTED_KOL_LIST: "ADD_TO_PROMOTED_KOL_LIST",
  REMOVE_FROM_PROMOTED_KOL_LIST: "REMOVE_FROM_PROMOTED_KOL_LIST",
  SET_REQUEST_STATUS_CHANGED: "SET_REQUEST_STATUS_CHANGED",

  // LoginManager
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",

  // HelpDocManager
  DIRECT_TO_HELP_DOC: "DIRECT_TO_HELP_DOC",
  HELP_DOC_RESET: "HELP_DOC_RESET",

  // ToolsManager
  SET_SEARCH_KOL_QUERY: "SET_SEARCH_KOL_QUERY",
  SET_SEARCH_KOL_QUERIES: "SET_SEARCH_KOL_QUERIES",
  RESET_SEARCH_KOL: "RESET_SEARCH_KOL",
  SET_SEARCH_KOL_STEP: "SET_SEARCH_KOL_STEP",

  // ToolsManager　> compare group
  SET_COMPARE_GROUP: "SET_COMPARE_GROUP",
  ADD_KOL_TO_COMPARE_GROUP: "ADD_KOL_TO_COMPARE_GROUP",
  REMOVE_KOL_FROM_COMPARE_GROUP: "REMOVE_KOL_FROM_COMPARE_GROUP",
  CREATE_NEW_COMPARE_GROUP: "CREATE_NEW_COMPARE_GROUP",
  COMPARE_GROUP_NEED_UPDATE: "COMPARE_GROUP_NEED_UPDATE",
  REMOVE_COMPARE_GROUP: "REMOVE_COMPARE_GROUP",
  SHOW_HELP_TEXT: "SHOW_HELP_TEXT",
  TOGGLE_CONDITION_DROPDOWN: "TOGGLE_CONDITION_DROPDOWN",

  //MemberManager
  SET_BOOKMARK_LIST: "SET_BOOKMARK_LIST",
  SET_ALL_BOOKMARK_LIST: "SET_ALL_BOOKMARK_LIST",

  // ErrorManager
  SET_ERROR_MSG: "SET_ERROR_MSG",

  // ArticleManager
  SET_ARTICLE: "SET_ARTICLE",
  UPDATE_ARTICLE: "UPDATE_ARTICLE",

  // QueryManager
  SET_VIEW_ID: "SET_VIEW_ID",
  TOGGLE_FORCE_UPDATE: "TOGGLE_FORCE_UPDATE",
}
