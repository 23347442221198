export interface CustomerType {
  objectID?: string
  uid?: string
  contactEmail: string
  avatarUrl: string
  companyName: string
  companySize: string
  roleInCompany: string
  companyType: string | ""
  firstName: string
  lastName: string
  contactNumber: string
  password?: string
  isPublish: boolean
  isVerified: boolean
  lastModified: Date
  createDate: Date
  coverImages: string
  description: string
  website: string
}

export const CustomerDefault: CustomerType = {
  uid: "",
  contactEmail: "",
  avatarUrl: "",
  companyName: "",
  companySize: "",
  roleInCompany: "",
  companyType: "",
  firstName: "",
  lastName: "",
  contactNumber: "",
  isPublish: false,
  isVerified: false,
  lastModified: new Date(),
  createDate: new Date(),
  coverImages: "",
  description: "",
  website: "",
}

class Customer {
  objectID?: string
  uid?: string
  contactEmail: string
  avatarUrl: string
  companyName: string
  companySize: string
  roleInCompany: string
  companyType: string | ""
  firstName: string
  lastName: string
  contactNumber: string
  password?: string
  isPublish: boolean
  isVerified: boolean
  lastModified: Date
  createDate: Date
  coverImages: string
  description: string
  website: string

  constructor(data: CustomerType) {
    this.uid = data.uid
    this.contactEmail = data.contactEmail
    this.avatarUrl = data.avatarUrl
    this.companyName = data.companyName
    this.companySize = data.companySize
    this.roleInCompany = data.roleInCompany
    this.companyType = data.companyType
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.contactNumber = data.contactNumber
    this.password = data.password
    this.isPublish = data.isPublish
    this.isVerified = data.isVerified
    this.lastModified = data.lastModified
    this.createDate = data.createDate
    this.coverImages = data.coverImages
    this.description = data.description
    this.website = data.website
  }
}

export interface updateCustomerBody {
  firstName: string
  lastName: string
  contactNumber: string
  companyName: string
  website: string
  roleInCompany: string
  companySize: string
  description: string
  coverImages: string
}

export interface initCustomerDefaultType {
  isPublish: boolean
  isVerified: boolean
  lastModified: Date
  createDate: Date
  description: ""
  website: ""
}

export const initCustomerDefault: initCustomerDefaultType = {
  isPublish: false,
  isVerified: false,
  lastModified: new Date(),
  createDate: new Date(),
  description: "",
  website: "",
}

export default Customer
