import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"
import { Article } from "../../Model/Article"

interface RegexRule {
  title: string
  rule: RegExp
  containAtLeast?: number
}

const keywordsAppearTime = 5

const allRules: RegexRule[] = [
  {
    title: "at least two <h2> tag in the article",
    rule: new RegExp(/<h2>.*?<\/h2>/g),
    containAtLeast: 2,
  },
  {
    title: "at least 1 image should be inserted to the article",
    rule: new RegExp(/(<img.*?>)/g),
    containAtLeast: 1,
  },
]

export const SEOAnalyze: React.FC = () => {
  const [data, setData] = useState<string>("")
  const [keywords, setKeywords] = useState<string[]>([])

  const article: Article = useSelector((state: any) => {
    return state.ArticleManager.article
  })

  useEffect(() => {
    setData(article.content)
    setKeywords(article.keywords)
  }, [article])

  const isContainsKeywords = (kws: string[], content: string, limit: number) =>
    kws.every(
      (word) => (content.match(new RegExp(word, "gi")) || []).length >= limit
    ) && kws.length > 0

  const atLeastOneKeywordAppearInH2 = (kws: string[], content: string) =>
    content.match(new RegExp("<h2>.*?(" + kws.join("|") + ").*?</h2>", "gi"))

  const keywordInPTag = () => {
    const regexPattern =
      "<p>[^<]*\\b(" + article.keywords[0] + ")\\b[^<]*<\\/p>"

    const regex = new RegExp(regexPattern, "i")

    const match = article.content.match(regex)

    return match
  }

  return (
    <section className="rounded-2xl p-4 shadow-md my-4 border border-gray-200">
      <h2 className="text-lg text-left font-medium  text-gray-700">
        <Trans>NewArticle.SEO-tools</Trans>
      </h2>

      <ul className="menu bg-base-100 w-56 rounded-box mt-4">
        <li
          className={classNames("grid my-2", {
            "text-green-700": article.keywords.length > 0,
            "text-red-700": article.keywords.length <= 0,
          })}
          style={{ gridTemplateColumns: "40px auto" }}>
          <div>
            <FontAwesomeIcon
              icon={article.keywords.length > 0 ? faCheckCircle : faTimesCircle}
            />
          </div>
          <div>Add a focus keyword for this blog</div>
        </li>

        <li
          className={classNames("grid my-2", {
            "text-green-700": article.title.includes(article.keywords[0]),
            "text-red-700": !article.title.includes(article.keywords[0]),
          })}
          style={{ gridTemplateColumns: "40px auto" }}>
          <div>
            <FontAwesomeIcon
              icon={
                article.title.includes(article.keywords[0])
                  ? faCheckCircle
                  : faTimesCircle
              }
            />
          </div>
          <div>Focus Keyword is present in title</div>
        </li>
        <li
          className={classNames("grid my-2", {
            "text-green-700": keywordInPTag(),
            "text-red-700": !keywordInPTag(),
          })}
          style={{ gridTemplateColumns: "40px auto" }}>
          <div>
            <FontAwesomeIcon
              icon={keywordInPTag() ? faCheckCircle : faTimesCircle}
            />
          </div>
          <div>Focus Keyword is present in the first paragraph</div>
        </li>
        {allRules.map((rule, index) => {
          const result = data.match(rule.rule)
          let pass = false

          if (result && rule.containAtLeast) {
            pass = result.length >= rule.containAtLeast
          }

          return (
            <li
              key={index}
              className={classNames("grid my-2", {
                "text-green-700": pass,
                "text-red-700": !pass,
              })}
              style={{ gridTemplateColumns: "40px auto" }}>
              <div>
                <FontAwesomeIcon icon={pass ? faCheckCircle : faTimesCircle} />
              </div>
              <div>{rule.title}</div>
            </li>
          )
        })}

        <li
          className={classNames("grid my-2", {
            "text-green-700": isContainsKeywords(
              keywords,
              data,
              keywordsAppearTime
            ),
            "text-red-700": !isContainsKeywords(
              keywords,
              data,
              keywordsAppearTime
            ),
          })}
          style={{ gridTemplateColumns: "40px auto" }}>
          <div>
            <FontAwesomeIcon
              icon={
                isContainsKeywords(keywords, data, keywordsAppearTime)
                  ? faCheckCircle
                  : faTimesCircle
              }
            />
          </div>
          <div>
            All your target keywords appear in the article at least{" "}
            {keywordsAppearTime} times
          </div>
        </li>

        <li
          className={classNames("grid my-2", {
            "text-green-700": atLeastOneKeywordAppearInH2(keywords, data),
            "text-red-700": !atLeastOneKeywordAppearInH2(keywords, data),
          })}
          style={{ gridTemplateColumns: "40px auto" }}>
          <div>
            <FontAwesomeIcon
              icon={
                atLeastOneKeywordAppearInH2(keywords, data)
                  ? faCheckCircle
                  : faTimesCircle
              }
            />
          </div>
          <div>At least one keyword appear in h2 tag</div>
        </li>
      </ul>
    </section>
  )
}
