import {
  faChartPie,
  faCheckCircle,
  faDollarSign,
  faFire,
  faMapMarkedAlt,
  faPercent,
  faSmileBeam,
  faThLarge,
  faUserCircle,
  faUsers,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { KOLSearchType } from "../redux/reducer/ToolsManager"
import { returnKOLGender } from "../Utils/KOLDefaultData"
import { getCountry } from "../Utils/Countries"
import { t } from "i18next"
import { DETAIL_TYPE, generalType } from "../Utils/TypeList"
import { getUserLang } from "../Utils/utiltyHelper"
import { DEFAULT_MAX_CONDITION } from "../Enum/KOL_SEARCH_TYPE"

export const returnConditionBadgesType = (): {
  type: CONDITION_BADGES_TYPE
  icon: IconDefinition
  label: string
}[] => {
  return [
    {
      type: CONDITION_BADGES_TYPE.USER,
      icon: faUserCircle,
      label: t("SearchKOLConditionSetting.name"),
    },
    {
      type: CONDITION_BADGES_TYPE.GENDER,
      icon: faVenusMars,
      label: t("SearchKOLConditionSetting.gender"),
    },
    {
      type: CONDITION_BADGES_TYPE.NATIONALITY,
      icon: faMapMarkedAlt,
      label: t("SearchKOLConditionSetting.area"),
    },
    // {
    //   type: CONDITION_BADGES_TYPE.IS_FACE,
    //   icon: faSmileBeam,
    //   label: t("SearchKOLConditionSetting.is-face"),
    // },
    {
      type: CONDITION_BADGES_TYPE.IS_LOOK4KOL_REG,
      icon: faSmileBeam,
      label: t("SearchKOLConditionSetting.is-look4kol-reg"),
    },
    {
      type: CONDITION_BADGES_TYPE.TYPE,
      icon: faThLarge,
      label: t("SearchKOLConditionSetting.category-and-genre"),
    },
    {
      type: CONDITION_BADGES_TYPE.HAS_DATA,
      icon: faChartPie,
      label: t("SearchKOLConditionSetting.has-data"),
    },
    {
      type: CONDITION_BADGES_TYPE.FOCUS_PERCENTAGE,
      icon: faPercent,
      label: t("SearchKOLConditionSetting.focus-percentage"),
    },
    {
      type: CONDITION_BADGES_TYPE.PRICE_RANGE,
      icon: faDollarSign,
      label: t("SearchKOLConditionSetting.price-range"),
    },
    {
      type: CONDITION_BADGES_TYPE.FOLLOWER_COUNT,
      icon: faUsers,
      label: t("SearchKOLConditionSetting.no-of-follower"),
    },
  ]
}

export enum CONDITION_BADGES_TYPE {
  USER = "userName",
  GENDER = "gender",
  NATIONALITY = "nationality",
  IS_FACE = "isFace",
  IS_LOOK4KOL_REG = "isLook4kolReg",
  TYPE = "genreCategory",
  GENRE = "genre",
  PRICE_RANGE = "price",
  HAS_DATA = "hasData",
  FOCUS_PERCENTAGE = "focusPercentage",
  FOLLOWER_COUNT = "follower",
}

const categoryBadgeTitle = (condition: KOLSearchType): string => {
  if (condition.genreCategory) {
    const title = generalType[parseInt(condition.genreCategory) - 1]
    return getUserLang() === "en" ? title.label : title.labelCHI
  }
  return "null"
}

const genreBadgeTitle = (condition: KOLSearchType): string => {
  if (condition.genre) {
    const obj = DETAIL_TYPE.find((e) => e.value === condition.genre)
    if (obj) {
      return getUserLang() === "en" ? obj.label : obj.labelCHI
    }
    return "null"
  }
  return "null"
}

export const returnBadges = (condition: KOLSearchType): ConditionBadge[] => {
  return [
    {
      type: CONDITION_BADGES_TYPE.USER,
      value: condition.userName,
      icon: faUserCircle,
      hidden: condition.userName === "",
    },
    {
      type: CONDITION_BADGES_TYPE.GENDER,
      value: returnKOLGender(condition.gender),
      icon: faVenusMars,
      hidden: condition.gender === "",
    },
    {
      type: CONDITION_BADGES_TYPE.NATIONALITY,
      value: getCountry(condition.nationality).label,
      icon: faMapMarkedAlt,
      hidden: condition.nationality === "",
    },
    // {
    //   type: CONDITION_BADGES_TYPE.IS_FACE,
    //   value:
    //     condition.isFace === true
    //       ? t("SearchKOLConditionSetting.is-face")
    //       : t("SearchKOLConditionSetting.is-not-face"),
    //   icon: faSmileBeam,
    //   hidden: condition.isFace === "",
    // },
    {
      type: CONDITION_BADGES_TYPE.IS_LOOK4KOL_REG,
      value: condition.isLook4kolReg
        ? t("SearchKOLConditionSetting.is-look4kol-reg")
        : t("SearchKOLConditionSetting.is-not-look4kol-reg"),
      icon: faCheckCircle,
      hidden: condition.isLook4kolReg === "",
    },
    {
      type: CONDITION_BADGES_TYPE.TYPE,
      value: categoryBadgeTitle(condition),
      icon: faThLarge,
      hidden: condition.genreCategory === "",
    },
    {
      type: CONDITION_BADGES_TYPE.GENRE,
      value: genreBadgeTitle(condition),
      icon: faFire,
      hidden: condition.genre === "",
    },
    {
      type: CONDITION_BADGES_TYPE.HAS_DATA,
      value: condition.hasData ? t("SearchKOLConditionSetting.has-data") : "",
      icon: faChartPie,
      hidden: condition.hasData === "",
    },
    {
      type: CONDITION_BADGES_TYPE.FOCUS_PERCENTAGE,
      value: condition.focusPercentage,
      icon: faPercent,
      hidden: condition.focusPercentage === "",
    },
    {
      type: CONDITION_BADGES_TYPE.PRICE_RANGE,
      value: {
        minPrice: condition.priceMin,
        maxPrice: condition.priceMax,
      },
      icon: faDollarSign,
      hidden: condition.priceMin === 0 && condition.priceMax === 0,
    },
    {
      type: CONDITION_BADGES_TYPE.FOLLOWER_COUNT,
      value: {
        minPrice: condition.followerMin,
        maxPrice: condition.followerMax,
      },
      icon: faUsers,
      hidden:
        condition.followerMin === 0 &&
        condition.followerMax === DEFAULT_MAX_CONDITION.FOLLOWER,
    },
  ]
}

export type ConditionBadge =
  | UserBadge
  | GenderBadge
  | NationalityBadge
  | IsFaceBadge
  | isLook4kolRegBadge
  | PriceRangeBadge
  | TypeBadge
  | GenreBadge
  | HasDataBadge
  | ForcePercentageBadge
  | FollowerCountBadge

export interface UserBadge {
  type: CONDITION_BADGES_TYPE.USER
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface GenderBadge {
  type: CONDITION_BADGES_TYPE.GENDER
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface NationalityBadge {
  type: CONDITION_BADGES_TYPE.NATIONALITY
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface IsFaceBadge {
  type: CONDITION_BADGES_TYPE.IS_FACE
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface isLook4kolRegBadge {
  type: CONDITION_BADGES_TYPE.IS_LOOK4KOL_REG
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface TypeBadge {
  type: CONDITION_BADGES_TYPE.TYPE
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface GenreBadge {
  type: CONDITION_BADGES_TYPE.GENRE
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface HasDataBadge {
  type: CONDITION_BADGES_TYPE.HAS_DATA
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface ForcePercentageBadge {
  type: CONDITION_BADGES_TYPE.FOCUS_PERCENTAGE
  value: string
  icon: IconDefinition
  hidden: boolean
}

export interface PriceRangeBadge {
  type: CONDITION_BADGES_TYPE.PRICE_RANGE
  value: {
    minPrice: number
    maxPrice: number
  }
  icon: IconDefinition
  hidden: boolean
}

export interface FollowerCountBadge {
  type: CONDITION_BADGES_TYPE.FOLLOWER_COUNT
  value: {
    minPrice: number
    maxPrice: number
  }
  icon: IconDefinition
  hidden: boolean
}
