import React, { Suspense, useEffect, useLayoutEffect, useRef } from "react"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { AnimatePresence } from "framer-motion"
import { getMessaging, onMessage } from "firebase/messaging"
import { getAuth, onAuthStateChanged } from "firebase/auth"

import ModalController from "../../Component/ModalController/ModalController"

import { isAuth } from "../../Utils/FirebaseUtils"
import * as cloudMessagingRegistration from "../../Utils/CloudMessaging"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"

import firebaseApp from "../../config/firebase"

import { ROLE } from "../../Enum/APP_TYPE"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import Login from "../../pages/login"
import classNames from "classnames"
import { checkViewAsQuery, getUserLang, isIOS } from "../../Utils/utiltyHelper"
import { Capacitor } from "@capacitor/core"

import AdminRouter from "../../pages/admin"
import { Header } from "../../Component/Header"
import { getAdminRole } from "../../Model/Role"
import { useQuery } from "../../Utils/QueryHelper"

const fbAuth = getAuth()

const MyApp: React.FC = (props: any) => {
  let history = useHistory()
  const { i18n } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const updateQuery = (id: URLSearchParams) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_VIEW_ID,
      data: id,
    })
  }

  useEffect(() => {
    if (
      location.pathname.split("/").length > 0 &&
      location.pathname.split("/")[1] !== ""
    ) {
      const langCode = location.pathname.split("/")[1]
      i18n.changeLanguage(langCode)
      localStorage.setItem("i18n-lang", langCode)
    } else {
      //set Default language to hk
      localStorage.setItem("i18n-lang", "hk")
      history.push("/hk")
    }
  }, [])

  useEffect(() => {
    if (isAuth(auth)) {
      // history.push("/" + getUserLang() + "/admin")
      cloudMessagingRegistration.register(auth)
      localStorage.setItem("look4kol-auth-uid", auth.uid)
    }
  }, [auth])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let data: any = JSON.parse(localStorage.getItem("look4kol-auth"))

    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.LOGIN,
      data: data,
    })
  }, [])

  useEffect(() => {
    try {
      const messaging = getMessaging(firebaseApp)
      onMessage(messaging, (payload) => {
        if (payload.data) {
          // eslint-disable-next-line no-new
          new Notification(
            payload.data.title ? payload.data.title : "look4kol",
            {
              body: payload.data.body,
              icon: payload.data.icon,
              image: payload.data.image,
              data: payload.data.click_action,
            }
          )
        }
      })
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    onAuthStateChanged(fbAuth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          console.log(idTokenResult.token)
          dispatch({
            type: SUPPORTED_REDUX_FUNCTIONS.LOGIN,
            data: {
              email: user.email,
              uid: user.uid,
              photoURL: user.photoURL,
              displayName: user.displayName,
              token: idTokenResult.token,
              role: getAdminRole(idTokenResult.claims.role as ROLE),
              expirationTime: idTokenResult.expirationTime,
              custID: idTokenResult.claims.custID
                ? idTokenResult.claims.custID
                : null,
            },
          })
        })
      } else {
        // logout()
      }
    })
  }, [fbAuth])

  let query = useQuery()
  // update query
  useEffect(() => {
    updateQuery(query)
  }, [query.toString()])
  // Override the history.push method

  const prevLocation = useRef({
    pathname: location.pathname,
    search: location.search,
  })

  const originalPush = history.push
  history.push = (path, state) => {
    let newPath = path
    if (typeof path === "string" && path.includes("?")) {
      //fix url if search query is inside pathname
      newPath = { pathname: path.split("?")[0], search: path.split("?")[1] }
    }
    let modifiedPath = checkViewAsQuery(prevLocation.current.search, newPath)
    prevLocation.current = modifiedPath

    if (
      modifiedPath.search.includes("viewAs") ||
      newPath.search?.toString().includes("returnToCustomer")
    ) {
      updateQuery(new URLSearchParams(modifiedPath.search))
      // Use history.replace function on paths that have viewAs or returnToCustomer params
      // since modifying query in middleware will cause unwanted looping if we use history.push
      history.replace(modifiedPath, state)
    } else {
      // Call the original history.push with the modified path and state
      originalPush.call(history, modifiedPath, state)
    }
  }
  useLayoutEffect(() => {
    function updateSize() {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_WIDTH,
        screenWidth: window.innerWidth,
      })
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_HEIGHT,
        screenHeight: window.innerHeight,
      })
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  const isInUrl = (path: string) => {
    return location.pathname.includes("/" + getUserLang() + "/" + path)
  }

  return (
    <>
      <ModalController />
      <header className="z-20 fixed top-0 w-screen bg-white ">
        <Header />
      </header>
      <div className="safe-are-detection md:mb-0 md:grid overflow-hidden">
        <div
          className={classNames(
            "md:overflow-y-scroll overflow-x-hidden mt-16"
          )}>
          <main
            id="mainContainer"
            className={classNames("h-full mt-0.5 md:mb-0 ", {
              "md:mb-0 mb-20": Capacitor.getPlatform() !== "ios",
              "mb-2 pb-28": Capacitor.getPlatform() === "ios" || isIOS(),
            })}>
            <Suspense fallback={<div></div>}>
              <AnimatePresence exitBeforeEnter initial={screenWidth < 768}>
                <ErrorBoundary>
                  <Switch location={location} key={location.pathname}>
                    <Route path={"/:lang/admin"} component={AdminRouter} />
                    <Route path={"/:lang"} exact component={Login} />
                    <Route path={"/:lang/login"} exact component={Login} />
                  </Switch>
                </ErrorBoundary>
              </AnimatePresence>
            </Suspense>
          </main>
        </div>
      </div>
    </>
  )
}

export default MyApp
