import { ADMIN_REQUEST_TYPE } from "../../Utils/TypeList"
import AdminAppeal from "../ModalController/AdminEditRequestModal/AdminRequestDisplay/AdminAppeal"
import BankCheckoutRequest from "../ModalController/AdminEditRequestModal/AdminRequestDisplay/BankCheckoutRequest"
import RecommendedRequest from "../ModalController/AdminEditRequestModal/AdminRequestDisplay/RecommendRequest"
import PayoutRequest from "../ModalController/AdminEditRequestModal/AdminRequestDisplay/PayoutRequest"
import React from "react"
import { AdminEditRequestModalData } from "../../Model/Modal"
import { useHistory } from "react-router-dom"
import { PayLaterRequest } from "../ModalController/AdminEditRequestModal/AdminRequestDisplay/PayLaterRequest"
import { ADMIN_REQUEST } from "../../Model/AdminRequest"
import KOLMissionRequest from "../ModalController/AdminEditRequestModal/KOLMissionRequest"

const AdminEditRequestModalComponent = (props: AdminEditRequestModalData) => {
  const history = useHistory()
  const closeModal = () => {
    document.getElementsByTagName("body")[0].style.overflow = "scroll"
    history.push("/hk/admin/request")
    props.closeModal()
  }

  return (
    <div className="overflow-y-auto max-w-full">
      {typeof props.metaData.request !== "undefined" && (
        <>
          {props.metaData.request.type === ADMIN_REQUEST_TYPE.APPEAL && (
            <AdminAppeal
              request={props.metaData.request}
              closeModal={closeModal}
              updateRequest={props.returnFn}
            />
          )}

          {props.metaData.request.type === ADMIN_REQUEST_TYPE.BANK_CHECKOUT && (
            <BankCheckoutRequest
              request={props.metaData.request}
              closeModal={closeModal}
            />
          )}

          {props.metaData.request.type ===
            ADMIN_REQUEST_TYPE.RECOMMEND_REQUEST && (
            <RecommendedRequest
              request={props.metaData.request}
              closeModal={closeModal}
            />
          )}

          {props.metaData.request.type ===
            ADMIN_REQUEST_TYPE.PAYOUT_REQUEST && (
            <PayoutRequest
              request={props.metaData.request}
              closeModal={closeModal}
            />
          )}

          {props.metaData.request.type ===
            ADMIN_REQUEST_TYPE.PAY_LATER_REQUEST && (
            <PayLaterRequest
              request={props.metaData.request as ADMIN_REQUEST}
              closeModal={closeModal}
            />
          )}
          {props.metaData.request.type ===
            ADMIN_REQUEST_TYPE.KOL_REFERRAL_MISSION && (
            <KOLMissionRequest
              request={props.metaData.request as ADMIN_REQUEST}
              closeModal={closeModal}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdminEditRequestModalComponent
