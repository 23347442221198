import React, { useEffect, useState } from "react"
import CSVReader from "react-csv-reader"
import Spinner from "../../Component/Spinner"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.replace(/\W/g, "_"),
}

const AddFollower = (props) => {
  const [followersLength, setFollowersLengths] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState("")

  const [isLoading, setIsLoading] = useState(false)

  const onFileUpload = (data, fileInfo) => {
    setFollowersLengths(data.length)
  }

  const uploadCSVToNeo4j = () => {
    setIsLoading(true)
    const formData = new FormData()
    const fileField = document.querySelector('input[type="file"]')
    // const httpsAgent = new https.Agent({
    //     rejectUnauthorized: false
    // })

    formData.append("fol_csv", fileField.files[0])

    returnIdTokenResult()
      .then(async (res) => {
        formData.append("tokenID", res.token)
        formData.append("UID", props.auth.uid)

        await fetch("https://neo4j.look4kol.com/follower/add/upload", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false)
          })
          .catch((error) => {
            console.error("Error:", error)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="my-4 p-4">
      <div className="py-2 px-4 rounded bg-gray-50">
        number of Followers: {followersLength}
      </div>

      <CSVReader
        disabled={isLoading}
        cssClass="react-csv-input"
        label={"upload KOL CSV"}
        onFileLoaded={onFileUpload}
        parserOptions={papaparseOptions}
      />

      <button
        className="px-4 py-2 bg-gray-200 rounded-2xl"
        disabled={followersLength === 0 || isLoading}
        onClick={() => {
          uploadCSVToNeo4j()
        }}>
        {isLoading ? <Spinner /> : "upload"}
      </button>

      {isLoading && <Spinner />}

      {isLoaded && <div>Done</div>}
      {error !== "" && { error }}
    </div>
  )
}
export default AddFollower
