import React, { useImperativeHandle } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const NewFileCard = (props, ref) => {
  useImperativeHandle(ref, () => ({
    returnFile() {
      return props.data
    },
  }))

  return (
    <div
      className="m-2 w-full rounded-2xl p-5 inline-grid bg-gray-100"
      style={{ gridTemplateColumns: "auto 50px" }}>
      <div>{props.data.name}</div>
      <div>
        <button
          className="m-2 w-12 h-12 rounded-2xl bg-red-700 text-white text-md"
          onClick={() => props.removeFileFromList(props.data.name)}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  )
}
export default React.forwardRef(NewFileCard)
