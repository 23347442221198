import { DateObj } from "./Utilities"
import { BADGE_TYPE } from "../Enum/TABLE_SYSTEM"
import { PAGINATION_DIRECTION } from "../Utils/TableHelperFunction"
// import { PAGINATION_DIRECTION } from "../Utils/TableHelperFunction"

export const IGCrawlerOption = [
  { label: "Crawler_neo4j.all", value: "" },
  { label: "Crawler_neo4j.priority", value: "-1" },
  { label: "Crawler_neo4j.ready", value: "0" },
  { label: "Crawler_neo4j.running", value: "1" },
  { label: "Crawler_neo4j.finished", value: "2" },
  { label: "Crawler_neo4j.error", value: "3" },
  { label: "Crawler_neo4j.unknown", value: "4" },
]

export const returnIGCrawlerOnSelection = (
  state: IGCrawlerState | ""
): IGCrawlerCondition => {
  if (state === "") {
    return IGCrawlerConditionDefault
  }
  return {
    ...IGCrawlerConditionDefault,
    status: state,
  }
}

export const returnIGCrawlerStateBadge = (
  state: IGCrawlerState
): {
  type: BADGE_TYPE
  label: string
  textColor: string
  bgColor: string
} => {
  switch (state) {
    case IGCrawlerState.PRIORITY:
      return {
        type: BADGE_TYPE.FAIL,
        label: "Crawler_neo4j.priority",
        textColor: "text-gray-900",
        bgColor: "bg-gray-200",
      }
      break
    case IGCrawlerState.READY:
      return {
        type: BADGE_TYPE.QUOTATION,
        label: "Crawler_neo4j.ready",
        textColor: "text-blue-900",
        bgColor: "bg-blue-200",
      }
      break
    case IGCrawlerState.RUNNING:
      return {
        type: BADGE_TYPE.JOB_ACCEPTED,
        label: "Crawler_neo4j.running",
        textColor: "text-yellow-900",
        bgColor: "bg-yellow-200",
      }
      break
    case IGCrawlerState.FINISHED:
      return {
        type: BADGE_TYPE.JOB_DONE,
        label: "Crawler_neo4j.finished",
        textColor: "text-green-900",
        bgColor: "bg-green-200",
      }
      break
    case IGCrawlerState.ERROR:
      return {
        type: BADGE_TYPE.FAIL,
        label: "Crawler_neo4j.error",
        textColor: "text-red-900",
        bgColor: "bg-red-200",
      }
      break
    default:
      return {
        type: BADGE_TYPE.FAIL,
        label: "unknown",
        textColor: "text-gray-900",
        bgColor: "bg-gray-200",
      }
  }
}

export enum IGCrawlerState {
  PRIORITY = -1,
  READY = 0,
  RUNNING = 1,
  FINISHED = 2,
  ERROR = 3,
}

export interface IGCrawlerCondition {
  status?: IGCrawlerState | ""
  userName?: string | ""
  noOfFollowers?: number | ""
  isLook4kolReg?: boolean | ""
  lastUpdate?: Date | DateObj | ""
  paginationData?: any | ""
  paginationDirection: PAGINATION_DIRECTION | ""
}

export const IGCrawlerConditionDefault: IGCrawlerCondition = {
  status: "",
  userName: "",
  noOfFollowers: "",
  isLook4kolReg: "",
  lastUpdate: "",
  paginationData: "",
  paginationDirection: "",
}

export interface IGCrawler {
  id: string
  createDate: Date | DateObj
  ig_id: string
  isLook4kolReg: boolean
  lastCursorId: string
  lastUpdate: Date | DateObj
  noOfCrawled: number
  noOfFollowers: number
  noOfFollowersCrawled: number
  status: IGCrawlerState
  uid: string
  userName: string
}
