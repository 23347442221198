import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { v4 as uuidv4 } from "uuid"
import { Reducer } from "react"
import { CONDITION_BADGES_TYPE } from "../../Model/ConditionBadge"
import { DEFAULT_MAX_CONDITION } from "../../Enum/KOL_SEARCH_TYPE"

export interface CompareItem {
  data: string[]
  id: string
  title: string
  type: "ig"
}

export const KOLSearchDefault: KOLSearchType = {
  uid: "",
  searchStr: "",
  nationality: "",
  isLook4kolReg: true,
  userName: "",
  focusPercentage: "",
  hasData: "",
  isFace: "",
  gender: "",
  type: "",
  genreCategory: "",
  genre: "",
  priceMin: 0,
  priceMax: 0,
  followerMin: 0,
  followerMax: 0,
  orderBy: "",
  page: 0,
  score: 0,
  isVerified: true,
  isPublish: true,
  limit: 10,
  basicScoreMin: 0,
  basicScoreMax: 0,
  topCateID: "",
  topCateMin: 0,
  topCateMax: 100,
}

export interface KOLSearchType {
  uid: string
  searchStr: string
  nationality: string
  isLook4kolReg: boolean | ""
  // typeID: string
  userName: string
  focusPercentage: string
  hasData: boolean | ""
  isFace: boolean | ""
  gender: "" | "1" | "2" | "3"
  type: string
  genreCategory: string
  genre: string
  priceMin: number
  priceMax: number
  followerMin: number
  followerMax: number
  orderBy:
    | "desc(userName)"
    | "asc(userName)"
    | "desc(noOfFollowers)"
    | "asc(noOfFollowers)"
    | ""

  page: number
  score: number
  isVerified: boolean | ""
  isPublish: boolean | ""
  limit: number | ""
  basicScoreMin: number
  basicScoreMax: number
  topCateID: string
  topCateMin: number
  topCateMax: number
}

interface actionTypes {
  type: string
  data: any
  selectedKOLID: string
}

export interface initStateType {
  step: CONDITION_BADGES_TYPE | ""
  customerToolsSideBar: boolean
  searchKOLCondition: KOLSearchType
  //add to compare group
  compareGroup: CompareItem[]
  isCompareGroupChange: boolean
  isLoadedCompareGroup: boolean
  showHelpText: boolean
  isConditionDropDown: boolean
}

const initialState: initStateType = {
  step: "",
  customerToolsSideBar: false,
  searchKOLCondition: {
    ...KOLSearchDefault,
    followerMax: DEFAULT_MAX_CONDITION.FOLLOWER,
    basicScoreMax: DEFAULT_MAX_CONDITION.BASIC_SCORE,
  },
  //add to compare group
  compareGroup: [],
  isCompareGroupChange: false,
  isLoadedCompareGroup: false,
  showHelpText: false,
  isConditionDropDown: false,
}

const addKOLToCompareGroup = (
  compareGroup: CompareItem[],
  groupID: string,
  newKOLID: string
): CompareItem[] => {
  // need to use dot operator, react redux rule
  let newCompareGroup: CompareItem[] = [...compareGroup]
  let index = newCompareGroup.findIndex((e) => e.id === groupID)
  if (index !== -1) {
    newCompareGroup[index].data = newCompareGroup[index].data.concat(newKOLID)
  }

  return newCompareGroup
}

const removeCompareGroup = (
  compareGroup: CompareItem[],
  selectedGroupID: string
): CompareItem[] => {
  // need to use dot operator, react redux rule
  let newCompareGroup = [...compareGroup]
  newCompareGroup = newCompareGroup.filter((e) => e.id !== selectedGroupID)

  return newCompareGroup
}

const removeKOLFromCompareGroup = (
  compareGroup: CompareItem[],
  selectedGroupID: string
) => {
  // need to use dot operator, react redux rule
  let newCompareGroup = [...compareGroup]
  newCompareGroup.map((e, index) => {
    e.data.map((el, indexEl) => {
      if (el === selectedGroupID) {
        newCompareGroup[index].data = newCompareGroup[index].data.filter(
          (k) => k !== selectedGroupID
        )
      }
    })
  })
  return newCompareGroup
}

const createNewCompareGroup = (
  compareGroup: CompareItem[],
  groupName: string,
  newKOLID: string
): CompareItem[] => {
  let newGroup: CompareItem = {
    data: [newKOLID],
    id: uuidv4(),
    title: groupName,
    type: "ig",
  }

  return [...compareGroup].concat(newGroup)
}

const ToolsManager: Reducer<initStateType, actionTypes> = (
  state = initialState,
  action
) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY) {
    return {
      ...state,
      searchKOLCondition: {
        ...state.searchKOLCondition,
        [action.data.key]: action.data.value,
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERIES) {
    return {
      ...state,
      searchKOLCondition: {
        ...state.searchKOLCondition,
        ...action.data,
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.RESET_SEARCH_KOL) {
    return {
      ...state,
      searchKOLCondition: {
        ...KOLSearchDefault,
        followerMax: DEFAULT_MAX_CONDITION.FOLLOWER,
        basicScoreMax: DEFAULT_MAX_CONDITION.BASIC_SCORE,
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_STEP) {
    return {
      ...state,
      step: action.data,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_COMPARE_GROUP) {
    return {
      ...state,
      isLoadedCompareGroup: true,
      compareGroup: action.data,
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.ADD_KOL_TO_COMPARE_GROUP
  ) {
    /**
     *  @param string  compareGroupID - selected group ID
     */
    return {
      ...state,
      compareGroup: addKOLToCompareGroup(
        state.compareGroup,
        action.data,
        action.selectedKOLID
      ),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.CREATE_NEW_COMPARE_GROUP
  ) {
    /**
     *  @param string groupName - the title of the new group
     */

    return {
      ...state,
      compareGroup: createNewCompareGroup(
        state.compareGroup,
        action.data,
        action.selectedKOLID
      ),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_KOL_FROM_COMPARE_GROUP
  ) {
    /**
     *  @param string  selectedGroupID - selected group ID
     */

    return {
      ...state,
      compareGroup: removeKOLFromCompareGroup(state.compareGroup, action.data),
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_COMPARE_GROUP) {
    /**
     *  @param string  compareGroupID - selected group ID
     */
    return {
      ...state,
      compareGroup: removeCompareGroup(state.compareGroup, action.data),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.COMPARE_GROUP_NEED_UPDATE
  ) {
    return {
      ...state,
      //let AddToCompareGroup detect change
      isCompareGroupChange: !state.isCompareGroupChange,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SHOW_HELP_TEXT) {
    return {
      ...state,
      //let AddToCompareGroup detect change
      showHelpText: action.data,
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.TOGGLE_CONDITION_DROPDOWN
  ) {
    return {
      ...state,
      //let AddToCompareGroup detect change
      isConditionDropDown: action.data,
    }
  }

  return state
}

export default ToolsManager
