import React, { useEffect, useCallback, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUsers } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./Slider.module.css"
import { useDispatch, useSelector } from "react-redux"

type PropsType = {
  followersMin: string | null
  followersMax: string | null
  queryPushFn: (query: { followersMin?: string; followersMax?: string }) => void
  setCondition: (state: any) => void
}
const FollowersCountSlider: React.FC<PropsType> = ({
  followersMin,
  followersMax,
  queryPushFn,
  setCondition,
}) => {
  const dispatch = useDispatch()

  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })

  const [range, setRange] = useState({
    min: 0,
    max: 100000,
  })

  const [minVal, setMinVal] = useState<number>(
    typeof range.min === "number" ? range.min : 0
  )
  const [maxVal, setMaxVal] = useState<number>(
    typeof range.max === "number" ? range.max : Infinity
  )
  const [isFirstRefresh, setIsFirstRefresh] = useState<boolean>(true)
  const minValRef = useRef(range.min)
  const maxValRef = useRef(range.max)
  const myRanger = useRef(null)

  // Convert to percentage
  const getPercent = useCallback(
    (value) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Math.round(((value - range.min) / (range.max - range.min)) * 100)
    },
    [range.min, range.max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (myRanger.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      myRanger.current.style.left = `${minPercent}%`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      myRanger.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the ranger to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (myRanger.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      myRanger.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  useEffect(() => {
    if (
      followersMin &&
      searchKOLCondition.followerMin !== parseInt(followersMin)
    ) {
      updateSearchResult(parseInt(followersMin), searchKOLCondition.followerMax)
    }
    if (
      followersMax &&
      searchKOLCondition.followerMax !== parseInt(followersMax)
    ) {
      updateSearchResult(searchKOLCondition.followerMin, parseInt(followersMax))
    }
  }, [followersMax, followersMin, searchKOLCondition])

  const followersUpdateDetector = (value: string) => {
    return value && parseInt(value) >= range.min && parseInt(value) <= range.max
  }

  useEffect(() => {
    const minData = followersMin ? followersMin : range.min.toString()
    const maxData = followersMax ? followersMax : range.max.toString()
    if (minData && followersUpdateDetector(minData)) {
      setIsFirstRefresh(false)
      minValRef.current = parseInt(minData)
      setMinVal(parseInt(minData))
      updateSearchResult(parseInt(minData), maxVal)
    }
    if (maxData && followersUpdateDetector(maxData)) {
      setIsFirstRefresh(false)
      maxValRef.current = parseInt(maxData)
      setMaxVal(parseInt(maxData))
      updateSearchResult(minVal, parseInt(maxData))
    }
  }, [followersMax, followersMin, isFirstRefresh])

  const updateSearchResult = (minVal: number, maxVal: number) => {
    setCondition({
      key: "followerMin",
      value: minVal,
    })

    setCondition({
      key: "followerMax",
      value: maxVal,
    })
  }

  return (
    <>
      <div className="md:mx-4 mb-10">
        <div
          className="xl:grid gap-4"
          style={{ gridTemplateColumns: "50px auto" }}>
          <div className="hidden xl:inline bg-red-default w-10 h-10 text-white p-2 text-center text-lg rounded-2xl">
            <FontAwesomeIcon icon={faUsers} />
          </div>
          <div>
            <div className="grid" style={{ gridTemplateColumns: "auto 40px" }}>
              <span className="font-bold text-lg mb-4">
                <Trans>FollowersCountSlider.title</Trans>:
              </span>
              <div></div>
            </div>
            <div>
              <div className="container relative">
                <input
                  type="range"
                  min={range.min}
                  max={range.max}
                  step={500}
                  value={minVal}
                  onChange={(event) => {
                    const value = Math.min(
                      Number(event.target.value),
                      maxVal - 1
                    )
                    setMinVal(value)
                    minValRef.current = value
                  }}
                  onMouseUp={() => {
                    updateSearchResult(minVal, maxVal)
                    queryPushFn({ followersMin: minVal.toString() })
                  }}
                  onTouchEnd={() => {
                    updateSearchResult(minVal, maxVal)
                    queryPushFn({ followersMin: minVal.toString() })
                  }}
                  className={[classes.thumb, classes.thumbLeft].join(" ")}
                  style={{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    zIndex: ((minVal > range.max) as number) - 100 ? "2" : "5",
                  }}
                />
                <input
                  type="range"
                  min={range.min}
                  max={range.max}
                  step={100}
                  value={maxVal}
                  onChange={(event) => {
                    const value = Math.max(
                      Number(event.target.value),
                      minVal + 1
                    )
                    setMaxVal(value)
                    maxValRef.current = value
                  }}
                  onMouseUp={() => {
                    updateSearchResult(minVal, maxVal)
                    queryPushFn({ followersMax: maxVal.toString() })
                  }}
                  onTouchEnd={() => {
                    updateSearchResult(minVal, maxVal)
                    queryPushFn({ followersMax: maxVal.toString() })
                  }}
                  className={[classes.thumb, classes.thumbRight].join(" ")}
                />

                <div className={classes.slider}>
                  <div />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/*// @ts-ignore*/}
                  <div ref={myRanger} className={classes.sliderRange} />
                  <div className={classes.sliderLeftValue}>{minVal}</div>
                  <div className={classes.sliderRightValue}>
                    {maxVal === 100000 ? "100000+" : maxVal}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mb-4 border-gray-200 w-11/12 mx-auto" />
    </>
  )
}
export default FollowersCountSlider
