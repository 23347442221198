import { isCentralizedGenre } from "./KOLDefaultData"
import { KOLCombinePercentageType } from "../Model/KOLCombine"

const returnKOLRecomText = (
  percentages: KOLCombinePercentageType[]
): string => {
  if (Array.isArray(percentages)) {
    // if an array
    //type 1: centralized (75%) interest group
    if (
      percentages.length > 0 &&
      typeof percentages.find((e) => e.percentages > 0.75) !== "undefined"
    ) {
      return "KOLItemRecommendation.interest-group-very-centralized"
    } else if (
      //type 2: centralized (40%) interest group
      percentages.length > 0 &&
      typeof percentages.find((e) => e.percentages > 0.4) !== "undefined"
    ) {
      return "KOLItemRecommendation.interest-group-centralized"
    } else if (
      //type 3: centralized interest group
      isCentralizedGenre(percentages)
    ) {
      return "KOLItemRecommendation.genre-centralized"
    }
  }

  return ""
}

export { returnKOLRecomText }
