export function returnKOLGender(value) {
  const res = KOL_GENDER.find((e) => e.value === value)
  return res !== null && typeof res !== "undefined" ? res.label : "/"
}

export const KOL_GENDER = [
  {
    label: "SELECT",
    value: "",
  },
  {
    label: "Male",
    value: "1",
  },
  {
    label: "Female",
    value: "2",
  },
  {
    label: "Uni sex",
    value: "3",
  },
]

export function isCentralizedGenre(arr) {
  let total = 0

  let counts = [
    { label: "Sports", count: 0 },
    { label: "Music", count: 0 },
    { label: "Art", count: 0 },
    { label: "Politics", count: 0 },
    { label: "BoyAndGirlThings", count: 0 },
    { label: "Fashion", count: 0 },
    { label: "Entertainment", count: 0 },
    { label: "PersonalCare", count: 0 },
    { label: "Education", count: 0 },
  ]

  if (Array.isArray(arr)) {
    arr.map((e) => {
      KOL_GENRE.map((c) => {
        //skip the first useless item
        if (c.label !== "") {
          if (c.options.filter((g) => g.label === e.item).length > 0) {
            total += e.count
            let cIndex = counts.map((el) => el.label).indexOf(c.label)
            counts[cIndex].count = counts[cIndex].count + parseFloat(e.count)
          }
        }
      })
    })
  }
  return typeof counts.find((item) => item.count / total > 0.4) !== "undefined"
}

export function genrePercentageToCSV(userID, percentagesArr) {
  let resultArr = [userID]

  if (Array.isArray(percentagesArr)) {
    KOL_GENRE.map((i) => {
      if (Array.isArray(i.options) && i.options.length > 0) {
        i.options.map((genre) => {
          resultArr.push(genre.label)
          resultArr.push(
            typeof percentagesArr.find((pObj) => pObj.item === genre.label) !==
              "undefined"
              ? percentagesArr.find((pObj) => pObj.item === genre.label).count
              : "0"
          )
          resultArr.push(
            typeof percentagesArr.find((pObj) => pObj.item === genre.label) !==
              "undefined"
              ? percentagesArr.find((pObj) => pObj.item === genre.label)
                  .percentages
              : "0"
          )
        })
      }
    })
  }

  return resultArr
}

export function categoryPercentageToCSV(userID, percentagesArr) {
  let resultArr = [userID]
  let totalCount = 0

  let counts = [
    { label: "Sports", count: 0 },
    { label: "Music", count: 0 },
    { label: "Art", count: 0 },
    { label: "Politics", count: 0 },
    { label: "BoyAndGirlThings", count: 0 },
    { label: "Fashion", count: 0 },
    { label: "Entertainment", count: 0 },
    { label: "PersonalCare", count: 0 },
    { label: "Education", count: 0 },
  ]

  if (Array.isArray(percentagesArr)) {
    percentagesArr.map((e) => {
      KOL_GENRE.map((c) => {
        //skip the first useless item
        if (c.label !== "") {
          if (c.options.filter((g) => g.label === e.item).length > 0) {
            let cIndex = counts.map((el) => el.label).indexOf(c.label)
            counts[cIndex].count = counts[cIndex].count + parseFloat(e.count)
            totalCount += parseFloat(e.count)
          }
        }
      })
    })
  }

  counts.map((i) => {
    resultArr.push(i.label)
    resultArr.push(i.count)
    resultArr.push(totalCount !== 0 ? i.count / totalCount : 0)
  })

  return resultArr
}

export const KOL_GENRE = [
  {
    label: "",
    options: [],
  },
  {
    label: "Sports",
    options: [
      { label: "Football", labelCHI: "足球", value: "1.1" },
      { label: "Basketball", labelCHI: "籃球", value: "1.2" },
      { label: "Tennis", labelCHI: "網球", value: "1.3" },
      { label: "Volleyball", labelCHI: "排球", value: "1.4" },
      { label: "Badminton", labelCHI: "羽毛球", value: "1.5" },
      { label: "Gym", labelCHI: "健身", value: "1.6" },
      { label: "Equipment", labelCHI: "健身設備", value: "1.7" },

      { label: "SportsOthers", labelCHI: "其他體育", value: "1.0" },
    ],
  },
  {
    label: "Music",
    options: [
      { label: "ClassicalMusic", labelCHI: "古典音樂", value: "2.1" },
      { label: "Piano", labelCHI: "鋼琴", value: "2.2" },
      { label: "Guitar", labelCHI: "吉他", value: "2.3" },
      { label: "Drumset", labelCHI: "鼓組", value: "2.4" },
      { label: "PopMusic", labelCHI: "流行音樂", value: "2.5" },
      { label: "Bands", labelCHI: "樂隊", value: "2.6" },
      { label: "Instrument", labelCHI: "樂器", value: "2.7" },

      { label: "MusicOthers", labelCHI: "其他音樂", value: "2.0" },
    ],
  },
  {
    label: "Art",
    options: [
      { label: "FineArt", labelCHI: "美術", value: "3.1" },
      { label: "ElectronicArt", labelCHI: "電子藝術", value: "3.2" },
      { label: "Illustration", labelCHI: "插畫", value: "3.3" },
      { label: "Photography", labelCHI: "攝影", value: "3.4" },
      { label: "Movie", labelCHI: "電影", value: "3.5" },
      { label: "Handcraft", labelCHI: "手工", value: "3.6" },

      { label: "ArtOthers", labelCHI: "其他藝術", value: "3.0" },
    ],
  },
  {
    label: "Politics",
    options: [
      { label: "Yellow", labelCHI: "黃", value: "4.1" },
      { label: "Blue", labelCHI: "藍", value: "4.2" },

      { label: "PoliticsOthers", labelCHI: "其他政治", value: "4.0" },
    ],
  },
  {
    label: "BoyAndGirlThings",
    options: [
      { label: "Car", labelCHI: "車", value: "5.1" },
      { label: "Figure", labelCHI: "模型", value: "5.2" },
      { label: "Gaming", labelCHI: "遊戲", value: "5.3" },
      { label: "Shopping", labelCHI: "購物", value: "5.4" },
      { label: "Beauty", labelCHI: "化妝", value: "5.5" },

      { label: "BoyAndGirlThingsOthers", labelCHI: "其他", value: "5.0" },
    ],
  },
  {
    label: "Fashion",
    options: [
      { label: "Styling", labelCHI: "造型", value: "6.1" },
      { label: "Tattoo", labelCHI: "紋身", value: "6.2" },
      { label: "Clothing", labelCHI: "衣服", value: "6.3" },
      { label: "Shoes", labelCHI: "鞋", value: "6.4" },
      { label: "Watch", labelCHI: "手錶", value: "6.5" },
      { label: "Hat", labelCHI: "帽子", value: "6.6" },
      { label: "Glasses ", labelCHI: "眼鏡", value: "6.7" },
      { label: "Bags", labelCHI: "包", value: "6.8" },
      { label: "Accessories", labelCHI: "配件", value: "6.9" },

      { label: "FashionOther", labelCHI: "其他時尚", value: "6.0" },
    ],
  },
  {
    label: "Entertainment",
    options: [
      { label: "Comedian", labelCHI: "喜劇", value: "7.1" },
      { label: "Magic", labelCHI: "魔術", value: "7.2" },
      { label: "Dailylife", labelCHI: "日常", value: "7.3" },
      { label: "Memes", labelCHI: "Memes", value: "7.4" },
      { label: "Travel", labelCHI: "旅遊", value: "7.5" },
      { label: "Food", labelCHI: "食物", value: "7.6" },
      { label: "Pet", labelCHI: "寵物", value: "7.7" },

      { label: "EntertainmentOther", labelCHI: "其他娛樂", value: "7.0" },
    ],
  },
  {
    label: "PersonalCare",
    options: [
      { label: "PhysicalHealth", labelCHI: "身體", value: "8.1" },
      { label: "MentalHealth", labelCHI: "心理健康", value: "8.2" },
      { label: "Dating", labelCHI: "約會", value: "8.3" },
      { label: "Hygiene", labelCHI: "衛生", value: "8.4" },
      { label: "CareerPlanning", labelCHI: "職業規劃", value: "8.5" },
      { label: "Minimalismac", labelCHI: "極簡主義", value: "8.6" },
      { label: "Household", labelCHI: "家庭", value: "8.7" },

      { label: "PersonalCareOther", labelCHI: "其他個人護理", value: "8.0" },
    ],
  },
  {
    label: "Education",
    options: [
      { label: "Study", labelCHI: "學習", value: "9.1" },
      { label: "Business", labelCHI: "商業", value: "9.2" },
      { label: "Computing", labelCHI: "計算", value: "9.3" },
      { label: "Books", labelCHI: "書本", value: "9.4" },
      { label: "News", labelCHI: "新聞", value: "9.5" },
      { label: "Green", labelCHI: "環保", value: "9.6" },

      { label: "EducationOther", labelCHI: "其他教育", value: "9.0" },
    ],
  },
]
