import React, { useState, useEffect, useRef, useMemo } from "react"
import ReactQuill, { Quill } from "react-quill"

import "../../../Styles/Blog.css"

import { Trans } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import {
  getArticle,
  updateArticle,
} from "../../../HelpingFunction/Article/ArticleDBHelper"
import { Article, articleDefault } from "../../../Model/Article"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillEditorCss from "../../../Utils/ReactQuillEditor.module.css"
import classNames from "classnames"
import { SEOAnalyze } from "../../../page-components/admin/SEOAnalyze"
import { KeywordsTools } from "../../../page-components/admin/KeywordsTools"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../redux/SUPPORTED_REDUX_FUNCTION"
import { useDispatch, useSelector } from "react-redux"
import {
  getUserLang,
  IMGUR_THUMBNAIL_TYPE,
  returnThumbnailUrl,
  uploadImageFileToImgur,
} from "../../../Utils/utiltyHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleXmark,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons"
import BlogSidePanel from "../../../page-components/admin/BlogSidePanel"

const Aid: React.FC = (props) => {
  const quillRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{
    lang: "hk" | "en"
    aid: string
  }>()

  const article: Article = useSelector((state: any) => {
    return state.ArticleManager.article
  })

  const [hasFeatureImage, setHasFeatureImage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [saved, setSaved] = useState(false)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [isUploadingEmbedImage, setIsUploadingEmbedImage] = useState(false)
  const [alert, setAlert] = useState<{ visible: boolean; message: string }>({
    visible: false,
    message: "",
  })
  const [isLoadedArticle, setIsLoadedArticle] = useState<boolean>(false)

  useEffect(() => {
    if (params.aid) {
      getArticle(params.aid).then((result) => {
        if (result.success) {
          setArticle(result.data)
          setIsLoadedArticle(true)
        } else {
          console.log("cannot get article")
        }
      })

      setHasFeatureImage(false)
      setTimeout(() => setHasFeatureImage(true), 200)
    } else {
      setArticle(articleDefault)
      setIsLoadedArticle(true)
    }
  }, [params.aid])

  const setArticle = (a: Article) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_ARTICLE,
      data: a,
    })
  }

  const updateReduxArticle = (data: Article) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_ARTICLE,
      data: data,
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
          ["code-block"],
        ],
        handlers: {
          image: () => quillImageHandler(),
        },
        clipboard: {
          matchVisual: false,
        },
      },
    }),
    []
  )

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ]

  const saveArticle = async () => {
    setIsSubmitting(true)

    let id = article.id
    let a: Article = { ...article }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete a.id

    // set publish date
    await updateArticle(id, a).then((result) => {
      if (result.success) {
        setIsSubmitting(false)
        setSaved(true)
        history.push("/" + getUserLang() + "/admin/list-article")
        setTimeout(() => setSaved(false), 2000)
      } else {
        setIsSubmitting(false)
        setAlert({ visible: true, message: result.message })

        setTimeout(() => setAlert({ visible: false, message: "" }), 2000)
      }
    })
  }

  const updateKeywords = (newKeywords: string[]) => {
    updateReduxArticle({
      ...article,
      keywords: newKeywords,
    })
  }

  const onUploadImage = (
    e: React.ChangeEvent<HTMLInputElement> | null
  ): Promise<
    | {
        success: true
        data: string[]
      }
    | {
        success: false
      }
  > => {
    return new Promise((resolve) => {
      setIsUploadingImage(true)
      if (e && e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0]

        uploadImageFileToImgur(file).then((result) => {
          return resolve(result)
        })
      } else {
        setIsUploadingImage(false)
      }
    })
  }

  const ImageBlot = Quill.import("formats/image")

  class CustomImageBlot extends ImageBlot {
    static create(value: any) {
      const node = super.create(value)
      node.setAttribute("alt", value.alt)
      node.setAttribute("src", value.url)
      return node
    }

    static value(node: any) {
      return {
        alt: node.getAttribute("alt"),
        url: node.getAttribute("src"),
      }
    }
  }

  Quill.register(CustomImageBlot, true)

  const quillImageHandler = () => {
    setIsUploadingEmbedImage(true)
    const input = document.createElement("input")

    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    input.click()

    input.onchange = async () => {
      if (input && input.files && input.files.length > 0) {
        const file = input.files[0]
        // eslint-disable-next-line no-alert
        const alt = window.prompt("Enter alternative text:")
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let quillObj = quillRef?.current.getEditor()

        await uploadImageFileToImgur(file).then((result) => {
          if (result.success) {
            const img = result.data[0]
            const hImg = returnThumbnailUrl(img, IMGUR_THUMBNAIL_TYPE.h)

            try {
              quillObj.insertEmbed(quillObj.getSelection().index, "image", {
                url: hImg,
                alt: alt,
              })
              setIsUploadingEmbedImage(false)
            } catch (err) {
              console.log("upload err:", err)
            }
          }
        })
      }
    }
  }
  const handleOnBlur = () => {
    updateReduxArticle({
      ...article,
      content: article.content,
    })
  }

  if (isLoadedArticle) {
    return (
      <div className="max-w-7xl mx-auto pt-4 pb-36 relative px-4">
        {/* main content*/}
        <div className="relative max-w-7xl mx-auto pt-4 pb-36 relative px-4">
          <h1 className="text-grey-800 text-3xl font-bold leading-tight  ">
            <Trans>NewArticle.new-article</Trans>
          </h1>
          <hr className="mb-4" />

          <div className="grid gap-4 grid-cols-1 md:grid-cols-3 xl:grid-cols-4">
            {/* Text editor*/}
            <div className="col-span-1 md:col-span-2 xl:col-span-3">
              <div className="mb-4 md:mb-0 w-full  mx-auto relative h-full bg-white rounded-2xl">
                <div className="my-4 relative group ">
                  <label htmlFor="upload" className="cursor-pointer">
                    <div
                      className={classNames(
                        "absolute text-center w-full h-full top-0",
                        {
                          "group-hover:block hidden":
                            article.featureImage !== "" && !isUploadingImage,
                        }
                      )}>
                      {!isUploadingImage ? (
                        <div className="mt-24">
                          <p className="text-4xl">
                            <FontAwesomeIcon icon={faCloudArrowUp} />
                          </p>
                          <p className="text-xl font-medium text-gray-600 w-36 mx-auto">
                            <Trans>NewArticle.browse-image</Trans>
                          </p>
                        </div>
                      ) : (
                        <div className="mt-24">
                          <p>
                            <Trans>NewArticle.please-wait-image</Trans>
                          </p>
                        </div>
                      )}
                    </div>
                    {hasFeatureImage && !isUploadingImage ? (
                      <img
                        src={article.featureImage}
                        className="w-full rounded-2xl object-cover h-64 group-hover:opacity-25"
                        alt=""
                        style={{ height: "24em" }}
                      />
                    ) : (
                      <div
                        className="w-full rounded-2xl object-cover bg-gray-100"
                        style={{ height: "24em" }}></div>
                    )}
                  </label>
                </div>
                <div className="">
                  <input
                    disabled={isUploadingImage}
                    className="hidden"
                    type="file"
                    accept="/image/*"
                    id="upload"
                    onChange={(e) => {
                      onUploadImage(e).then((result) => {
                        if (result.success) {
                          setHasFeatureImage(false)

                          updateReduxArticle({
                            ...article,
                            featureImage: returnThumbnailUrl(
                              result.data[0],
                              IMGUR_THUMBNAIL_TYPE.h
                            ),
                          })

                          setTimeout(() => {
                            setIsUploadingImage(false)
                            setHasFeatureImage(true)
                          }, 200)
                        }
                      })
                    }}
                  />
                </div>

                <div
                  className={[
                    "my-4 blog",
                    ReactQuillEditorCss.ReactQuillEditor,
                  ].join(" ")}
                  style={{
                    minHeight: "400px",
                    maxHeight: "600px",
                    overflow: "hidden",
                  }}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/*@ts-ignore*/}
                  <ReactQuill
                    ref={quillRef}
                    defaultValue={article.content}
                    value={article.content}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                      updateReduxArticle({
                        ...article,
                        content: e,
                      })
                    }}
                    className={isUploadingEmbedImage ? "cursor-progress" : ""}
                    readOnly={isUploadingEmbedImage}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                  />
                </div>

                <div className="my-4">
                  <button
                    className={[
                      "btn",
                      isSubmitting
                        ? "bg-green-200 text-green-900"
                        : "bg-red-200 text-red-900",
                    ].join(" ")}
                    disabled={isSubmitting}
                    onClick={() => {
                      saveArticle()
                    }}>
                    {isSubmitting ? (
                      <Trans>NewArticle.is-submitting</Trans>
                    ) : saved ? (
                      <Trans>NewArticle.saved</Trans>
                    ) : (
                      <Trans>NewArticle.save</Trans>
                    )}
                  </button>
                </div>

                {alert.visible && (
                  <div className="alert alert-error shadow-lg">
                    <div>
                      <span className="mx-2">
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </span>
                      <span>{alert.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* option bar*/}
            <div className="col-span-1 md:col-span-1 xl:col-span-1">
              <BlogSidePanel />
              <SEOAnalyze />

              <KeywordsTools updateKeywords={updateKeywords} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <div>Loading ...</div>
}
export default Aid
