import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import NewAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { useDispatch, useSelector } from "react-redux"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getNeo4jServer } from "../../Utils/HelpingFunction"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamation, faSearch } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import FollowerPercentageDisplay from "../../Component/FollowerPercentageDisplay"
import SimilarKOL from "../../Component/SimilarKOL"

export interface PercentageObj {
  item: string
  percentage: number
}

type FollowerPercentageResponse =
  | {
      success: boolean
      data: PercentageObj[]
    }
  | { success: false }

const defaultData = [
  {
    item: "12.7",
    percentage: 0.2,
  },
  {
    item: "12.1",
    percentage: 0.08,
  },
  {
    item: "5.1",
    percentage: 0.04,
  },
  {
    item: "4.2",
    percentage: 0.04,
  },
  {
    item: "10.3",
    percentage: 0.08,
  },
  {
    item: "10.4",
    percentage: 0.04,
  },
  {
    item: "10.7",
    percentage: 0.04,
  },
  {
    item: "1.2",
    percentage: 0.16,
  },
  {
    item: "1.3",
    percentage: 0.08,
  },
  {
    item: "4.4",
    percentage: 0.04,
  },
  {
    item: "12.2",
    percentage: 0.04,
  },
  {
    item: "6.3",
    percentage: 0.04,
  },
  {
    item: "10.2",
    percentage: 0.04,
  },
  {
    item: "4.1",
    percentage: 0.04,
  },
  {
    item: "12.5",
    percentage: 0.04,
  },
]
const defaultUserID = "momo.wong.hk"

const SearchFollower = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [data, setData] = useState<PercentageObj[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isNoRecord, setNoRecord] = useState<boolean>(false)
  const [userID, setUserID] = useState<string>("")

  const [isServerOnline, setIsServerOnline] = useState<"" | boolean>("")

  useEffect(() => {
    checkServerState()
  }, [])

  const checkServerState = () => {
    returnIdTokenResult().then(async (res) => {
      fetch("https://neo4j.look4kol.com", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
          UID: auth.uid,
        },
      })
        .then((res) => {
          console.log(res)
          setIsServerOnline(res.status === 200)
        })
        .catch((err) => console.log(err))
    })
  }

  const onSearchStart = () => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true)
      setIsLoaded(false)
      setNoRecord(false)

      returnIdTokenResult()
        .then(async (res) => {
          await fetch(
            getNeo4jServer(`/v1/followers/${userID}/percentage/genres`),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                idToken: res.token,
                UID: auth.uid,
                key: process.env.REACT_APP_NEO4j_API_KEY as string,
              },
            }
          )
            .then((res) => res.json())
            .then((response) => {
              setIsLoading(false)

              if (response.success) {
                setData(response.data)
                setIsLoaded(true)
              } else {
                setData([])
                setIsLoaded(true)
                setNoRecord(true)
              }
            })
        })
        .catch((err) => console.log(err))
    })
  }

  const clear = () => {
    setData([])
    setIsLoading(false)
    setIsLoaded(false)
    setNoRecord(false)
    setUserID("")
  }

  const setDefault = () => {
    setData(defaultData)
    setUserID(defaultUserID)
    setIsLoaded(true)
  }

  const imageUrl =
    "https://images.unsplash.com/photo-1607346705432-b3047f8c20bf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&h=468&q=60"

  return (
    <div className="relative">
      {/* main content*/}
      <div className="relative max-w-7xl mx-auto pt-4 pb-36 relative px-4">
        <h1 className="text-grey-800 text-3xl font-bold leading-tight  ">
          <Trans>SearchFollower.title</Trans>
          <div className="badge badge-lg badge-primary mx-2 my-auto">BETA</div>
        </h1>
        <hr className="mb-4" />

        <NewAlert
          title={t("SearchFollower.warning-title")}
          alertType={ALERT_TYPE.ERROR}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          visible={true}
        />
        {isServerOnline && (
          <NewAlert
            title={t("SearchFollower.server-on")}
            alertType={ALERT_TYPE.INFO}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            visible={true}
          />
        )}

        {isServerOnline === false && (
          <>
            <NewAlert
              title={t("SearchFollower.server-off")}
              alertType={ALERT_TYPE.ERROR}
              buttonType={{
                type: BUTTON_TYPE.TEXT_ONLY,
                buttonText: t("SearchFollower.use-default"),
                buttonIcon: faExclamation,
              }}
              buttonFn={() => setDefault()}
              visible={true}
            />
          </>
        )}

        {/* Search container*/}
        <div className="relative h-72">
          <div
            className="absolute w-full h-full px-8 py-20 rounded-xl"
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              backgroundImage: `url(${imageUrl})`,
              filter: "blur(1px)",
            }}></div>

          <div className="absolute form-control mt-20 w-full">
            <h2 className="text-white text-5xl mb-4 font-bold leading-tight text-center ">
              <Trans>SearchFollower.input-ig-username</Trans>
            </h2>
            <div className="input-group w-96 mx-auto rounded-2xl overflow-hidden">
              <input
                type="text"
                placeholder="Search…"
                className="input input-bordered w-96"
                disabled={isLoading}
                value={userID}
                onChange={(e) => setUserID(e.target.value)}
              />
              <button
                className={classNames(
                  "btn border-gray-400 text-gray-400 bg-white",
                  {
                    loading: isLoading,
                  }
                )}
                disabled={isLoading}
                onClick={() => clear()}>
                <Trans>SimilarKOL.clear</Trans>
              </button>
              <button
                className={classNames("btn btn-primary btn-square", {
                  loading: isLoading,
                })}
                disabled={isLoading}
                onClick={() => onSearchStart()}>
                {!isLoading && <FontAwesomeIcon icon={faSearch} />}
              </button>
            </div>
          </div>
        </div>

        {/*Search result*/}
        <div className="mt-10">
          {isLoaded && !isNoRecord && (
            <FollowerPercentageDisplay userID={userID} data={data} />
          )}

          {isLoaded && isNoRecord && <div>No Record</div>}

          {isLoaded && !isNoRecord && <SimilarKOL data={data} />}
        </div>
      </div>
    </div>
  )
}
export default SearchFollower
