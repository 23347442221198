import React, { useEffect, useState } from "react"
import { isAuth } from "../../Utils/FirebaseUtils"
import {
  getPromotedKOL,
  setPromotedKOLIDs,
} from "../../HelpingFunction/Admin/AdminDBHelper"
import { useDispatch, useSelector } from "react-redux"
import KOL, { KOLDefault } from "../../Model/KOL"
import KOLSearchCard from "../../Component/KOLSearchCard"
import KOLSmallCard from "../../Component/KOLSmallCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"

const PromotedKOLSetting: React.FC = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [promotedKOLs, setPromotedKOLs] = useState<KOL[]>([])

  const [isLoadedPromotedKOLs, setIsLoadedPromotedKOLs] =
    useState<boolean>(false)

  const promotedKOLsID = useSelector((state: any) => {
    return state.AdminControlManager.promotedKOLsID
  })

  useEffect(() => {
    if (isAuth(auth)) {
      getPromotedKOL().then((result) => {
        if (result.success) {
          setPromotedKOLs(result.data)
          setIsLoadedPromotedKOLs(true)
        }
      })
    }
  }, [auth])

  const onPromoteKOL = (uid: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.REMOVE_FROM_PROMOTED_KOL_LIST,
      data: uid,
    })

    const newPromotedKOLs = promotedKOLs.filter((e) => e.id !== uid)
    // fake remove
    setPromotedKOLs(newPromotedKOLs)

    const newPromotedKOLsID = newPromotedKOLs.map((e) => e.id)
    // remove from db
    setPromotedKOLIDs(newPromotedKOLsID).then((result) => {
      console.log("done")
    })
  }

  if (isLoadedPromotedKOLs) {
    return (
      <>
        <div className="block gap-2 grid sm:grid-cols-2 lg:grid-cols-3 ">
          {promotedKOLs.map((kol) => {
            return (
              <div
                key={kol.id}
                className="relative bg-white p-4 rounded-lg shadow">
                <button
                  className="z-10 w-10 h-10 px-2 absolute right-2 top-2 rounded-full hover:bg-gray-200"
                  onClick={() => onPromoteKOL(kol.id as string)}>
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="text-red-default"
                  />
                </button>
                <KOLSmallCard data={kol} />
              </div>
            )
          })}
        </div>

        {promotedKOLs.length === 0 && (
          <div className="alert alert-warning shadow-lg w-full">
            <div>
              <span>You don't have any select kol yet</span>
            </div>
          </div>
        )}
      </>
    )
  }
  return (
    <>
      <div className="block gap-2 grid sm:grid-cols-2 lg:grid-cols-3 ">
        <KOLSearchCard
          key={"c1"}
          isContentLoader={true}
          isHomePage={true}
          data={KOLDefault}
        />
        <KOLSearchCard
          key={"c2"}
          isContentLoader={true}
          isHomePage={true}
          data={KOLDefault}
        />
        <KOLSearchCard
          key={"c3"}
          isContentLoader={true}
          isHomePage={true}
          data={KOLDefault}
        />
        <KOLSearchCard
          key={"c4"}
          isContentLoader={true}
          isHomePage={true}
          data={KOLDefault}
        />
      </div>
    </>
  )
}
export default PromotedKOLSetting
