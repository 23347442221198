import KOL from "../../Model/KOL"
import { getKOL } from "../KOL/kolDBHelper"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../config/firebase"
import { REGISTER_KOL_TYPE } from "../../Enum/ADMIN_ACTION_TYPE"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath } from "../../Utils/HelpingFunction"
import { IGCrawlerState } from "../../Model/IGCrawler"

const db = getFirestore(firebaseApp)

/**
 * set promoted kol in the homepage
 * @param {string} data - list of kol in firebase uid
 */

const setPromotedKOLIDs = (
  data: string[]
): Promise<{
  success: boolean
}> => {
  let refDoc = doc(db, "Utils", "Homepage")
  return new Promise(async (resolve) => {
    await updateDoc(refDoc, {
      promotedKOLList: data,
      lastUpdate: new Date(),
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * get promoted kol in the homepage
 */

const getPromotedKOLIDs = (): Promise<
  | {
      success: true
      data: string[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let docRef = doc(db, "Utils", "Homepage")

    await getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: doc.data().promotedKOLList as string[],
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * get promoted kol in the homepage
 */

const getPromotedKOL = (): Promise<
  | {
      success: true
      data: KOL[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let docRef = doc(db, "Utils", "Homepage")

    await getDoc(docRef)
      .then((doc) => {
        let kols: KOL[] = []
        let allPromise: Promise<any>[] = []
        if (doc.exists()) {
          const kolList: string[] = doc.data().promotedKOLList
          if (Array.isArray(kolList)) {
            kolList.map((kolID: string) => {
              // console.log("KOL ID:" + kolID)
              allPromise.push(
                new Promise(async (resolve1) => {
                  await getKOL(kolID)
                    .then((result) => {
                      if (result.success) {
                        kols.push(result.data)
                        return resolve1(null)
                      }
                      return resolve1(null)
                    })
                    .catch((err) => console.log(err))
                })
              )
            })

            Promise.all(allPromise).then((result) => {
              return resolve({
                success: true,
                data: kols,
              })
            })
          } else {
            //empty promotion
            return resolve({
              success: true,
              data: [],
            })
          }
        }
      })
      .catch((err) => console.log(err))
  })
}

/**
 * Get register number from members
 */
const getRegisterStats = (
  filterKOLType: REGISTER_KOL_TYPE
): Promise<{
  success: boolean
  accounts: {
    registerTime: Date
    registerName: string
    registerEmail: string
    registerIsVerified: boolean
    registerID: string
    isKOL: boolean
  }[]
}> => {
  return new Promise(async (resolve) => {
    const monthlyLimit = new Date(new Date().setHours(0, 0, 0, 0))
    monthlyLimit.setTime(new Date().getTime() - 29 * 24 * 60 * 60 * 1000)

    let docRef = collection(db, "KOL")
    let mothlyDB = query(
      docRef,
      orderBy("createDate", "desc"),
      where("createDate", ">=", monthlyLimit)
    )

    switch (filterKOLType) {
      case REGISTER_KOL_TYPE.KOL_ONLY:
        docRef = collection(db, "KOL")
        mothlyDB = query(
          docRef,
          orderBy("createDate", "desc"),
          where("createDate", ">=", monthlyLimit)
        )
        break
      case REGISTER_KOL_TYPE.VERIFIED_KOL:
        docRef = collection(db, "KOL")
        mothlyDB = query(
          docRef,
          orderBy("createDate", "desc"),
          where("createDate", ">=", monthlyLimit),
          where("isVerified", "==", true)
        )
        break
      default:
        break
    }

    let registeredAccount: {
      registerTime: Date
      registerName: string
      registerEmail: string
      registerIsVerified: boolean
      registerID: string
      isKOL: boolean
    }[] = []

    await getDocs(mothlyDB)
      .then(async (kolDataSet) => {
        if (!kolDataSet.empty) {
          // let num = 0
          kolDataSet.forEach((memberData) => {
            registeredAccount.push({
              registerTime: memberData.data().createDate.toDate(),
              registerName:
                memberData.data().firstName + " " + memberData.data().lastName,
              registerEmail: memberData.data().email,
              registerIsVerified: memberData.data().isVerified,
              registerID: memberData.id,
              isKOL: true,
            })
            // console.log("createDate Date is... " + monthlyRegisterTime[num])
            // num++
          })

          if (filterKOLType === REGISTER_KOL_TYPE.DEFAULT) {
            let docRef = collection(db, "Customer")
            let customerDB = query(
              docRef,
              orderBy("createDate", "desc"),
              where("createDate", ">=", monthlyLimit)
            )
            await getDocs(customerDB)
              .then((customerDataSet) => {
                if (!customerDataSet.empty) {
                  customerDataSet.forEach((memberData) => {
                    registeredAccount.push({
                      registerTime: memberData.data().createDate.toDate(),
                      registerName: memberData.data().companyName,
                      registerEmail: memberData.data().contactEmail,
                      registerIsVerified: memberData.data().isVerified,
                      registerID: memberData.id,
                      isKOL: false,
                    })
                  })
                }
                return resolve({
                  success: true,
                  accounts: registeredAccount,
                })
              })
              .catch((err) => console.error(err))
          }

          return resolve({
            success: true,
            accounts: registeredAccount,
          })
        }
        //if no registration found
        // console.log("no data passed filter!")
        return resolve({
          success: true,
          accounts: [],
        })
      })
      .catch((err) => console.error(err))
  })
}

/**
 * submit kol to neo4j and create crawler record
 * @param {
 *   name: string
 *   isLook4kolReg: boolean
 *   status: IGCrawlerState
 * } data
 */

const addNeo4jJKOLRecord = (data: {
  name: string
  type: string
  isLook4kolReg: boolean
  status: IGCrawlerState
}): Promise<
  | {
      success: true
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/neo4j/kol"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          return resolve(finalResponse)
        })
    })
  })
}

export {
  getPromotedKOLIDs,
  setPromotedKOLIDs,
  getPromotedKOL,
  getRegisterStats,
  addNeo4jJKOLRecord,
}
