import React from "react"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import {
  CONDITION_BADGES_TYPE,
  ConditionBadge,
} from "../../Model/ConditionBadge"
import { queryType } from "../../Utils/QueryHelper"
import { compareConditionType } from "../../pages/admin/tools/kol"
import { DEFAULT_MAX_CONDITION } from "../../Enum/KOL_SEARCH_TYPE"

interface SearchConditionBadgeType {
  data: ConditionBadge
  queryPushFn: (query: compareConditionType) => void
}

export const SearchConditionBadge: React.FC<SearchConditionBadgeType> = ({
  data,
  queryPushFn,
}) => {
  const dispatch = useDispatch()

  const toggleConditionDropDown = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_CONDITION_DROPDOWN,
      data: state,
    })

  const setStep = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_STEP,
      data: state,
    })

  const setCondition = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: state.key,
        value: state.value,
      },
    })

  if (
    data.type === CONDITION_BADGES_TYPE.PRICE_RANGE ||
    data.type === CONDITION_BADGES_TYPE.FOLLOWER_COUNT
  ) {
    return (
      <div
        className="inline-grid my-auto mx-1 px-1 text-base rounded-full btn btn-primary btn-sm"
        style={{ gridTemplateColumns: "auto 30px" }}>
        <button
          className="inline-grid  px-2 gap-2"
          style={{ gridTemplateColumns: "30px auto " }}
          onClick={() => {
            setStep(data.type)
            toggleConditionDropDown(true)
          }}>
          <div slot="avatar" className="text-center">
            <FontAwesomeIcon icon={data.icon} />
          </div>
          <div>
            {data.value.minPrice} - {data.value.maxPrice}
          </div>
        </button>
        <button
          className="text-center  rounded-full hover:bg-gray-100 hover:text-gray-700"
          onClick={() => {
            setCondition({
              key: data.type + "Min",
              value: 0,
            })
            setCondition({
              key: data.type + "Max",
              value:
                data.type === CONDITION_BADGES_TYPE.FOLLOWER_COUNT
                  ? DEFAULT_MAX_CONDITION.FOLLOWER
                  : 0,
            })
            queryPushFn({ [data.type + "Min"]: "", [data.type + "Max"]: "" })
          }}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    )
  }
  return (
    <div
      className="inline-grid my-auto mx-1 px-1 text-base rounded-full btn btn-primary btn-sm"
      style={{ gridTemplateColumns: "auto 30px" }}>
      <button
        className="inline-grid px-2 gap-2"
        style={{ gridTemplateColumns: "30px auto " }}
        onClick={() => {
          setStep(data.type)
          toggleConditionDropDown(true)
        }}>
        <div slot="avatar" className="text-center">
          <FontAwesomeIcon icon={data.icon} />
        </div>
        <div>{data.value}</div>
      </button>
      <button
        className="text-center  rounded-full hover:bg-gray-100 hover:text-gray-700"
        onClick={() => {
          setCondition({
            key: data.type,
            value: "",
          })
          queryPushFn({ [data.type]: "" })
        }}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  )
}
