import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { Reducer } from "react"

interface actionTypes {
  type: string
  data: URLSearchParams | boolean
}

export interface initStateType {
  params: URLSearchParams
  forceUpdate: boolean
}

const initialState: initStateType = {
  params: new URLSearchParams(""),
  forceUpdate: false,
}

const QueryManager: Reducer<initStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_VIEW_ID:
      return {
        ...state,
        params: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.TOGGLE_FORCE_UPDATE:
      return {
        ...state,
        forceUpdate: action.data,
      }
    default:
      return state
  }
}

export default QueryManager
