import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts"
import { AdminViewKOLPercentageModalData } from "../Model/Modal"
import {
  returnCatePercentage,
  returnSelectedItem,
  returnCategoryName,
  stringToColour,
} from "../Utils/TypeList"
import classNames from "classnames"
import CustomTable from "./NewTable"
import { t } from "i18next"
import { Column } from "../Model/Table"
import {
  defaultIGPercentageTableSchema,
  getIGFollowerPercentageTableSchema,
} from "../Utils/TableHelperFunction"
import { KOLCombine } from "../Model/KOLCombine"
import { PercentageObj } from "../pages/admin/search-follower"
import { Trans } from "react-i18next"
import { faCheck, faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

enum ViewType {
  ALL,
  CATE,
}

interface PropsType {
  userID: string
  data: PercentageObj[]
}

const FollowerPercentageDisplay: React.FC<PropsType> = ({ userID, data }) => {
  const catePer = data && Array.isArray(returnCatePercentage(data))

  const [type, setType] = useState<ViewType>(ViewType.ALL)
  const [tableCol, setTableCol] = useState<Column[][]>([
    defaultIGPercentageTableSchema(),
  ])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    let tableSets: Column[][] = []
    if (type === ViewType.ALL) {
      data.map((i, index) => {
        tableSets.push(getIGFollowerPercentageTableSchema(i))
      })
    }

    setTableCol(tableSets)
    setIsLoaded(true)
  }, [type])

  if (type === ViewType.ALL) {
    return (
      <div className="bg-gray-50 rounded-xl p-4 shadow-sm  mx-auto  overflow-y-hidden">
        <h2 className="text-3xl font-bold my-8 text-center">
          {userID}
          <Trans>SearchFollower.interest</Trans>
        </h2>
        <div className=" mx-auto w-96 my-4">
          <div className="btn-group grid grid-cols-2">
            <button
              className={classNames(
                "btn-active w-full btn btn-ghost bg-white hover:text-gray-600 hover:bg-red-100"
              )}>
              <Trans>SearchFollower.all</Trans>
            </button>
            <button
              className={classNames(
                "w-full btn btn-ghost bg-white hover:text-gray-600 hover:bg-red-100"
              )}
              disabled={true}
              onClick={() => setType(ViewType.CATE)}>
              <FontAwesomeIcon icon={faLock} className="mr-2" />
              <Trans>SearchFollower.category</Trans>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1">
            <div className="rounded-xl overflow-hidden border border-gray-100 bg-white h-80">
              {Array.isArray(data) && (
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ["RecomGenre", "Percentage"],
                    ...data.map((i, index) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      const typeName = returnSelectedItem(i.item).label
                      return [typeName, i.percentage * 100]
                    }),
                  ]}
                  options={{
                    title: userID,
                    colors: [
                      ...data
                        .filter((e) => e.item !== "12.8" && e.item !== "12.7")
                        .map((i, index) => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          const typeName = returnSelectedItem(i.item).label
                          return stringToColour(typeName)
                        }),
                    ],
                  }}
                  rootProps={{ "data-testid": ViewType.ALL }}
                />
              )}
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="rounded-xl  border border-gray-100 bg-white h-80 overflow-y-scroll">
              <CustomTable
                cols={tableCol}
                // tableFn={tableFn}
                isFirstColStatic={false}
                notFoundDes={t("MyPayoutRequest.record-not-found")}
                isLoading={!isLoaded}
                rowIsLoading={!isLoaded}
                isLastRecord={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  } else if (type === ViewType.CATE) {
    return (
      <div className="container mx-auto  overflow-y-hidden">
        <div className="mx-auto w-96">
          <div className="btn-group grid grid-cols-2">
            <button
              className={classNames(
                "w-full btn btn-ghost bg-gray-100 hover:text-gray-600 hover:bg-red-100"
              )}
              onClick={() => setType(ViewType.ALL)}>
              All
            </button>
            <button
              className={classNames(
                "btn-active w-full btn btn-ghost bg-gray-100 hover:text-gray-600 hover:bg-red-100"
              )}>
              Category
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1">
            {catePer && (
              <Chart
                width={"100%"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Category", "Percentage"],
                  ...returnCatePercentage(data)
                    .filter((e) => e.label !== "12")
                    .map((i, index) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      const categoryName = returnCategoryName(i.label)
                      return [categoryName, i.count]
                    }),
                ]}
                options={{
                  title: userID,
                  colors: [
                    ...returnCatePercentage(data)
                      .filter((e) => e.label !== "12")
                      .map((i, index) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const categoryName = returnCategoryName(i.label)
                        return stringToColour(categoryName)
                      }),
                  ],
                }}
                rootProps={{ "data-testid": ViewType.ALL }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
  return <div />
}

export default FollowerPercentageDisplay
