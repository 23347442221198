/* eslint-disable max-statements */
import React, { useEffect, useState } from "react"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { useTranslation } from "react-i18next"
import {
  ADMIN_REQUEST_STATUS_TYPE,
  ADMIN_REQUEST_TYPE,
} from "../../../Enum/APP_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import {
  AdminRequestCondition,
  getAdminRequest,
  getAdminRequests,
} from "../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import { ADMIN_REQUEST } from "../../../Model/AdminRequest"
import CustomTable from "../../../Component/NewTable"
import { Column, tableFunctions } from "../../../Model/Table"
import {
  adminRequestTableSchema,
  defaultAdminRequestTableSchema,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../../../Utils/TableHelperFunction"
import {
  BUTTON_TYPE,
  TABLE_FUNCTIONS,
  WIDTH_SIZE,
} from "../../../Enum/TABLE_SYSTEM"
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons"

const Id: React.FC = (props) => {
  const dispatch = useDispatch()
  let history = useHistory()

  const { t } = useTranslation()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const requestStateChanged = useSelector((state: any) => {
    return state.AdminControlManager.statusChanged
  })

  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()

  const [adminRequests, setAdminRequests] = useState<ADMIN_REQUEST[]>([])
  const [isLoadedAdminRequests, setIsLoadedAdminRequests] = useState(false)
  const [condition, setCondition] = useState<AdminRequestCondition>({
    requestID: "",
    jobID: "",
    type: "",
    status: "",
    paginationData: "",
    paginationDirection: "",
  })

  const [stateSelectionValue, setStateSelectionValue] = useState<string>("0")
  const [typeSelectionValue, setTypeSelectionValue] = useState<string>("")
  const [hasMore, setHasMore] = useState(true)

  const [adminRequestList, setAdminRequestList] = useState<Column[][]>([
    defaultAdminRequestTableSchema(),
  ])
  const [isGettingMoreJobs, setIsGettingMoreJobs] = useState<boolean>(false)

  const [pageNum, setPageNum] = useState<number>(0)
  const [orderByOption, setOrderByOption] = useState<{
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }>({
    fieldName: "createDate",
    direction: ORDER_BY_DIRECTION.DESC,
  })

  useEffect(() => {
    if (isAuth(auth)) {
      getAdminRequestsList()
    }
  }, [auth, condition])

  useEffect(() => {
    if (isAuth(auth) && requestStateChanged) {
      getAdminRequestsList()
    }
  }, [requestStateChanged])

  useEffect(() => {
    //used query path to avoid page from refreshing
    let id = query.get("id")
    if (id !== null) {
      getAdminRequest(id).then((result) => {
        if (result.success) {
          editRequest({
            metaData: {
              request: result.data,
            },
          })
        }
      })
    }
  }, [query])

  const editRequest = (state: {
    metaData: {
      request: ADMIN_REQUEST
    }
  }) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.ADMIN_EDIT_REQUEST_MODAL,
      metaData: state.metaData,
    })

  const resetRequestStateChanged = (status: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_REQUEST_STATUS_CHANGED,
      data: status,
    })
  }

  const getAdminRequestsList = () => {
    getAdminRequests(condition, orderByOption).then((result) => {
      if (result.success) {
        let finalData = result.data

        //always return hasMore (for nextBtn) as true when loading prev page, otherwise nextBtn might not work
        if (condition.paginationDirection === PAGINATION_DIRECTION.PREV) {
          finalData = finalData.reverse()
          setHasMore(true)
        } else {
          setHasMore(result.hasMore)
        }
        setIsLoadedAdminRequests(true)
        setAdminRequests(finalData)
        resetRequestStateChanged(false)
      } else {
        setIsLoadedAdminRequests(true)
        setAdminRequests(adminRequests)
        resetRequestStateChanged(false)
        setHasMore(false)
      }
    })
  }

  const reset = () => {
    setIsLoadedAdminRequests(false)
    conditionStateControl(requestStateOption[0])
    setStateSelectionValue("0")
    setTypeSelectionValue("0")
    setPageNum(0)
    setHasMore(false)
    setCondition({
      requestID: "",
      jobID: "",
      status: "",
      type: "",
      paginationData: "",
      paginationDirection: "",
    })
  }

  useEffect(() => {
    if (Array.isArray(adminRequests) && isLoadedAdminRequests) {
      setIsGettingMoreJobs(true)
      let tableSets: Column[][] = []

      adminRequests.map(async (requestData, index) => {
        tableSets.push(adminRequestTableSchema(requestData))
      })
      setAdminRequestList(tableSets)
      setIsGettingMoreJobs(false)
    }
  }, [adminRequests])

  const requestStateOption = [
    { label: t("AdminRequest.all-state"), value: "0" },
    { label: t("AdminRequest.finished"), value: "1" },
    { label: t("AdminRequest.not-yet-finished"), value: "2" },
    { label: t("AdminRequest.reject"), value: "3" },
  ]

  const requestTypeOption = [
    { label: t("AdminRequest.all-type"), value: "0" },
    { label: t("AdminRequest.type-appeal"), value: "1" },
    { label: t("AdminRequest.type-bank"), value: "2" },
    { label: t("AdminRequest.type-recommend"), value: "3" },
    { label: t("AdminRequest.type-payout"), value: "4" },
    { label: t("AdminRequest.kol-mission"), value: "5" },
  ]

  const conditionStateControl = (e: any) => {
    setPageNum(0)
    setIsLoadedAdminRequests(false)
    setAdminRequests([])
    switch (e.value) {
      case "0":
        setCondition({
          requestID: "",
          jobID: "",
          status: "",
          type: condition.type,
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "1":
        setCondition({
          requestID: "",
          jobID: "",
          status: ADMIN_REQUEST_STATUS_TYPE.FINISHED,
          type: condition.type,
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "2":
        setCondition({
          requestID: "",
          jobID: "",
          status: ADMIN_REQUEST_STATUS_TYPE.PENDING,
          type: condition.type,
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "3":
        setCondition({
          requestID: "",
          jobID: "",
          status: ADMIN_REQUEST_STATUS_TYPE.REJECT,
          type: condition.type,
          paginationData: "",
          paginationDirection: "",
        })
        break
      default:
    }
  }
  const conditionTypeControl = (e: any) => {
    setIsLoadedAdminRequests(false)
    setAdminRequests([])
    switch (e.value) {
      case "0":
        setCondition({
          requestID: "",
          jobID: "",
          status: condition.status,
          type: "",
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "1":
        setCondition({
          requestID: "",
          jobID: "",
          status: condition.status,
          type: ADMIN_REQUEST_TYPE.APPEAL,
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "2":
        setCondition({
          requestID: "",
          jobID: "",
          status: condition.status,
          type: ADMIN_REQUEST_TYPE.BANK_CHECKOUT,
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "3":
        setCondition({
          requestID: "",
          jobID: "",
          status: condition.status,
          type: ADMIN_REQUEST_TYPE.RECOMMEND_REQUEST,
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "4":
        setCondition({
          requestID: "",
          jobID: "",
          status: condition.status,
          type: ADMIN_REQUEST_TYPE.PAYOUT_REQUEST,
          paginationData: "",
          paginationDirection: "",
        })
        break
      case "5":
        setCondition({
          requestID: "",
          jobID: "",
          status: condition.status,
          type: ADMIN_REQUEST_TYPE.KOL_REFERRAL_MISSION,
          paginationData: "",
          paginationDirection: "",
        })
        break
      default:
    }
  }

  const labelStateFunction = (event: { target: { selectedIndex: number } }) => {
    const ets: number = event.target.selectedIndex
    conditionStateControl(requestStateOption[ets - 1])
  }

  const labelTypeFunction = (event: { target: { selectedIndex: number } }) => {
    const ets: number = event.target.selectedIndex
    conditionTypeControl(requestTypeOption[ets - 1])
  }

  const previousPagAction = () => {
    if (pageNum >= 1 && adminRequests.length > 0) {
      setIsLoadedAdminRequests(false)
      setCondition({
        ...condition,
        paginationData: adminRequests[0].createDate,
        paginationDirection: PAGINATION_DIRECTION.PREV,
      })
      setPageNum(pageNum - 1)
    }
  }
  const nextPagAction = () => {
    if (hasMore && adminRequests.length > 0) {
      setIsLoadedAdminRequests(false)
      setCondition({
        ...condition,
        paginationData: adminRequests[adminRequests.length - 1].createDate,
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
      setPageNum(pageNum + 1)
    }
  }

  const tableFn: tableFunctions[] = [
    {
      title: t("AdminRequest.status"),
      data: {
        type: TABLE_FUNCTIONS.SELECT,
        onChange: (e) => {
          labelStateFunction(e)
          setStateSelectionValue((e.target.selectedIndex - 1).toString())
        },
        labeledValue: stateSelectionValue,
        selectData: requestStateOption,
        width: WIDTH_SIZE.XL,
      },
    },
    {
      title: t("AdminRequest.request-type"),
      data: {
        type: TABLE_FUNCTIONS.SELECT,
        onChange: (e) => {
          labelTypeFunction(e)
          setTypeSelectionValue((e.target.selectedIndex - 1).toString())
          setPageNum(0)
        },
        labeledValue: typeSelectionValue,
        selectData: requestTypeOption,
        width: WIDTH_SIZE.XL,
      },
    },
    {
      title: "AdminRequest.reset",
      data: {
        type: TABLE_FUNCTIONS.BUTTON,
        buttonType: BUTTON_TYPE.SUCCESS,
        icon: faRotateLeft,
        onClick: reset,
        text: "AdminRequest.reset",
        width: WIDTH_SIZE.MD,
      },
    },
    {
      title: "Request ID",
      data: {
        type: TABLE_FUNCTIONS.INPUT,
        placeHolder: "Request UID",
        textValue: condition.requestID ? condition.requestID : "",
        width: WIDTH_SIZE.XXL,
        onChange: (e) => {
          setStateSelectionValue("0")
          setPageNum(0)
          setIsLoadedAdminRequests(false)
          setCondition({
            ...condition,
            status: "",
            requestID: e.target.value,
          })
        },
      },
    },
    {
      title: "Job ID",
      data: {
        type: TABLE_FUNCTIONS.INPUT,
        placeHolder: "Job UID",
        textValue: condition.jobID ? condition.jobID : "",
        width: WIDTH_SIZE.XXL,
        onChange: (e) => {
          setStateSelectionValue("0")
          setPageNum(0)
          setIsLoadedAdminRequests(false)
          setCondition({
            ...condition,
            status: "",
            jobID: e.target.value,
          })
        },
      },
    },
    //  TODO LATER
    // {
    //   title: "AdminRequest.date-range",
    //   data: {
    //     type: TABLE_FUNCTIONS.DATE,
    //     buttonType: BUTTON_TYPE.GHOST,
    //     icon: faCalendarWeek,
    //     onClick: (date: Date | null) => {
    //       console.log("date", date)
    //     },
    //     text: "AdminRequest.date-range",
    //     width: WIDTH_SIZE.LG,
    //   },
    // },
  ]

  return (
    <div>
      <h2 className="font-bold text-xl p-1 m-2">Admin Request</h2>
      <label className="font-sm text-gray-500 p-1 mx-4">Request Filter</label>
      <div className="mb-24 mt-2 md:m-0">
        <CustomTable
          cols={adminRequestList}
          tableFn={tableFn}
          clickAction={(index) =>
            history.replace("/hk/admin/request?id=" + adminRequests[index].id)
          }
          isFirstColStatic={false}
          isLoading={!isLoadedAdminRequests}
          rowIsLoading={isGettingMoreJobs}
          prevBtn={{
            disable: false,
            onClick: previousPagAction,
          }}
          pageNum={pageNum}
          nextBtn={{
            disable: !hasMore,
            onClick: nextPagAction,
          }}
          notFoundDes={"Record Not Found"}
          isLastRecord={!hasMore}
        />
      </div>
    </div>
  )
}

export default Id
