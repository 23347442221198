import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faFilter,
  faMagnifyingGlass,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { CONDITION_BADGES_TYPE, returnBadges } from "../../Model/ConditionBadge"
import classNames from "classnames"
import { SearchSetting } from "./SearchSetting"
import { SearchConditionBadge } from "./SearchConditionBadge"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../Utils/utiltyHelper"
import { queryType } from "../../Utils/QueryHelper"
import { compareConditionType } from "../../pages/admin/tools/kol"

type PropsType = {
  queryPushFn: (query: compareConditionType) => void
}

export const SearchCondition: React.FC<PropsType> = ({ queryPushFn }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const step: CONDITION_BADGES_TYPE | "" = useSelector((state: any) => {
    return state.ToolsManager.step
  })

  const searchKOLCondition = useSelector((state: any) => {
    return state.ToolsManager.searchKOLCondition
  })

  const isConditionDropDown = useSelector((state: any) => {
    return state.ToolsManager.isConditionDropDown
  })

  const resetCondition = () =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.RESET_SEARCH_KOL,
    })

  const toggleConditionDropDown = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_CONDITION_DROPDOWN,
      data: state,
    })

  const setStep = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_STEP,
      data: state,
    })

  const setCondition = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY,
      data: {
        key: state.key,
        value: state.value,
      },
    })

  return (
    <div className="relative ">
      {/* Row one*/}
      <div className="hidden h-14 md:flex gap-2 px-2">
        <div className="my-auto">
          <h1 className="text-grey-800 text-3xl font-bold leading-tight  inline-block">
            <Trans>SearchKOL.search-kol-statistics</Trans>
          </h1>
        </div>
        <div className="hidden md:inline-block py-1.5">
          <div className="form-control border border-gray-300 rounded-lg overflow-hidden">
            <label className="input-group">
              <span className="bg-white ">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="text-lg text-gray-700"
                />
              </span>
              <input
                id={"SearchKOL_SearchInput"}
                className="block mx-auto w-full  text-md  py-2 px-2 border-l border-gray-300 rounded-lg text-gray-700"
                onChange={(e) => {
                  const regex = / /gi
                  setCondition({
                    key: "searchStr",
                    value: e.target.value.replaceAll(regex, ""),
                  })
                  queryPushFn({
                    searchID: e.target.value.replaceAll(regex, ""),
                  })
                }}
              />
            </label>
          </div>
        </div>

        <div className="grid gap-1 md:inline-block  my-auto"></div>
      </div>

      {/* Row two*/}
      <div className="h-14 flex items-center gap-2">
        <div
          className={classNames("dropdown", {
            "dropdown-open": isConditionDropDown,
          })}>
          <label
            tabIndex={0}
            className="col-span btn btn-md btn-ghost h-10 rounded-4xl"
            onClick={(e) => {
              setStep("")
              toggleConditionDropDown(true)
            }}>
            <FontAwesomeIcon
              icon={faFilter}
              className=" text-lg text-red-default"
            />
          </label>

          {/*
        Condition dropdown
          */}

          {isConditionDropDown && (
            <div
              tabIndex={0}
              className="dropdown-content w-72 p-4 shadow bg-white ">
              <div>
                <div
                  className="grid gap-2"
                  style={{ gridTemplateColumns: "20px auto 20px" }}>
                  <button
                    className={classNames(
                      " float-right rounded-full hover:bg-gray-50",
                      {
                        visible: step !== "",
                        invisible: step === "",
                      }
                    )}
                    onClick={(e) => setStep("")}>
                    <FontAwesomeIcon icon={faChevronLeft} className="mx-2" />
                  </button>

                  <Trans>SearchKOLConditionSetting.new-filter</Trans>

                  <button
                    className={classNames(
                      " float-right rounded-full hover:bg-gray-50",
                      {
                        visible: step === "",
                        invisible: step !== "",
                      }
                    )}
                    onClick={(e) => toggleConditionDropDown(false)}>
                    <FontAwesomeIcon icon={faTimes} className="mx-2" />
                  </button>
                </div>
                <hr className="border-gray-200 mt-1 " />
                <SearchSetting
                  toggleConditionDropDown={toggleConditionDropDown}
                  queryPushFn={queryPushFn}
                />
              </div>
            </div>
          )}
        </div>

        <button
          id={"SearchKOL_ResetFilter"}
          className="btn btn-sm my-2 btn-ghost text-red-default hover:text-red-800"
          onClick={(e) => {
            resetCondition()
            history.push({
              pathname: "/" + getUserLang() + "/admin/tools",
              search: "?" + new URLSearchParams("type=search"),
            })
          }}>
          <Trans>SearchKOL.reset</Trans>
        </button>

        <div className="flex items-center pt-1 h-14 overflow-y-hidden overflow-x-scroll whitespace-nowrap">
          {returnBadges(searchKOLCondition)
            .filter((e) => !e.hidden)
            .map((item, index) => {
              if (!item.hidden) {
                return (
                  <SearchConditionBadge
                    key={"scb" + index}
                    data={item}
                    queryPushFn={queryPushFn}
                  />
                )
              }
            })}
        </div>
      </div>
    </div>
  )
}
