import Compressor from "compressorjs"
import { v4 as uuidv4 } from "uuid"
import { SupportingImageType } from "../Utils/FileTypeImageConverter"
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../config/firebase"
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  list,
  deleteObject,
} from "firebase/storage"

const db = getFirestore(firebaseApp)

const fileCompress = (file, customSize) => {
  return new Promise(async (resolve, reject) => {
    new Compressor(file, {
      file: "blob",
      quality: 0.9,
      maxWidth: typeof customSize !== "undefined" ? customSize.width : 864,
      maxHeight: typeof customSize !== "undefined" ? customSize.height : 486,
      width: typeof customSize !== "undefined" ? customSize.width : 864,
      height: typeof customSize !== "undefined" ? customSize.height : 486,
      success(result) {
        return resolve({
          success: true,
          file: result,
        })
      },
      error(err) {
        return resolve({
          success: false,
          message: err.message,
        })
      },
    })
  })
}

const addFileRecord = (domain, files, uid) => {
  console.log(
    domain,
    "Chatroom/" +
      domain.split("/")[1] +
      (domain.split("/")[0] === "Job" ? "/Job" : "")
  )
  return new Promise((resolve, reject) => {
    if (Array.isArray(files)) {
      let allPromise = []
      files.map((file) => {
        let newFile = file
        newFile.createUserID = uid
        let docID = newFile.id
        delete newFile.id
        allPromise.push(
          new Promise(async (resolve1, reject1) => {
            // console.log(domain.split("/")[0], domain.split("/")[1])
            await setDoc(
              doc(
                db,
                "ChatRoom",
                domain.split("/")[1] +
                  (domain.split("/")[0] === "Job" ? "/Job" : ""),
                "Asset",
                docID
              ),
              newFile
            )
              .then((res) => {
                return resolve1()
              })
              .catch((err) => console.log(err))
          })
        )
      })

      Promise.all(allPromise).then((res) => {
        return resolve({
          success: true,
        })
      })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}
const storage = getStorage()

export async function createImageThumbnail(
  file,
  fileID,
  uid,
  currentPath,
  customSize
) {
  return new Promise(async (resolve, reject) => {
    const compressState = await fileCompress(file, customSize)
    if (compressState.success) {
      let imageRef = ref(storage, currentPath + "/ImageThumbnail/" + fileID)

      uploadBytes(imageRef, compressState.file)
        .then(async (snapshot) => {
          resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          resolve({
            success: true,
            message: err.message,
          })
        })
    }
  })
}

export async function uploadFileToStorage(file, fileID, uid, currentPath) {
  return new Promise(async (resolve, reject) => {
    console.log(file, fileID, uid, currentPath)
    let storageRef = ref(storage, currentPath + fileID)

    uploadBytes(storageRef, file, { customMetadata: { uid: uid } })
      .then(async (snapshot) => {
        resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        resolve({
          success: true,
          message: err.message,
        })
      })
  })
}

export async function onUploadFile(files, uid, currentPath, customSize) {
  return new Promise(async (resolve, reject) => {
    let allPromise = []
    console.log(files, uid, currentPath, customSize)
    files.map((item) => {
      allPromise.push(
        new Promise(async (resolve1, reject) => {
          // const file = file.file
          if (typeof item !== "undefined") {
            const fileKey = uuidv4()
            const fileName = item.name
            const fileType = fileName
              .split(".")
              [fileName.split(".").length - 1].toLowerCase()
            const fileID = fileKey + "." + fileType
            /**
             *  if type support Image Thumbnail
             */
            if (SupportingImageType.includes(fileType)) {
              const uploadThumbnailState = await createImageThumbnail(
                item,
                fileID,
                uid,
                currentPath,
                customSize
              )
              const uploadImageState = await uploadFileToStorage(
                item,
                fileID,
                uid,
                currentPath + "/"
              )
              console.log(uploadThumbnailState, uploadImageState)
              if (uploadThumbnailState.success && uploadImageState.success) {
                //get the download link
                const getThumbnailState = await getDownloadLinkAndMetadata(
                  currentPath + "/ImageThumbnail/" + fileID
                )
                const getImageState = await getDownloadLinkAndMetadata(
                  currentPath + "/" + fileID
                )
                console.log(currentPath, getThumbnailState, getImageState)
                if (getThumbnailState.success && getImageState.success) {
                  //filesize  = image size + thumbnail size
                  const fileSize =
                    getImageState.metaData.size +
                    getThumbnailState.metaData.size
                  // const addFileSizeState = await updateFileSize(uid, fileSize)
                  const fileID = fileKey
                  resolve1({
                    createDate: new Date(),
                    fileName: fileName,
                    fileSize: fileSize,
                    id: fileID,
                    fileKey: fileID,
                    path: currentPath,
                    type: "image",
                    thumbnailUrl: getThumbnailState.downloadLink,
                    url: getImageState.downloadLink,
                  })
                }
              } else {
                resolve1({
                  success: false,
                  message: "error in uploading",
                })
              }
            } else {
              const uploadFileState = await uploadFileToStorage(
                item,
                fileID,
                uid,
                currentPath + "/"
              )
              if (uploadFileState.success) {
                //get the download link
                const getImageState = await getDownloadLinkAndMetadata(
                  currentPath + "/" + fileID
                )
                if (getImageState.success) {
                  //filesize  = image size + thumbnail size
                  const fileSize = getImageState.metaData.size
                  // const addFileSizeState = await updateFileSize(uid, fileSize)
                  const fileID = fileKey
                  resolve1({
                    createDate: new Date(),
                    fileName: fileName,
                    fileSize: fileSize,
                    id: fileID,
                    fileKey: fileID,
                    path: currentPath,
                    type: "file",
                    url: getImageState.downloadLink,
                  })
                }
              } else {
                resolve1({
                  success: false,
                  message: "error in uploading",
                })
              }
            }
          } else {
            resolve1({
              success: false,
              message: "error in uploading",
            })
          }
        })
      )
    })

    Promise.all(allPromise).then((allNewFile) => {
      addFileRecord(currentPath, allNewFile, uid)

      resolve({
        success: true,
        data: allNewFile,
      })
    })
  })
}

export function getDownloadLinkAndMetadata(path) {
  return new Promise(async (resolve, reject) => {
    let file = {}
    const getFileDownloadLink = await getDownloadURL(ref(storage, path))
      .then((res) => {
        return {
          success: true,
          data: res,
        }
      })
      .catch((err) => {
        return {
          success: false,
          message: err,
        }
      })

    const getFileMetadata = await getMetadata(ref(storage, path))
      .then((res) => {
        return {
          success: true,
          data: res,
        }
      })
      .catch((err) => {
        return {
          success: false,
          message: err,
        }
      })

    if (
      (await getFileDownloadLink.success) &&
      (await getFileMetadata.success)
    ) {
      return resolve({
        success: true,
        downloadLink: getFileDownloadLink.data,
        metaData: getFileMetadata.data,
      })
    } else {
      return resolve({
        success: false,
        message: getFileDownloadLink.success
          ? getFileMetadata.message
          : getFileDownloadLink.message,
      })
    }
  })
}

export async function getFolderList(path) {
  console.log(path)
  return await list(ref(storage, path))
    .then(async (res) => {
      const numberOfFolder = res.prefixes.length
      let folders = []
      const setFolderState = await new Promise((resolve, reject) => {
        res.prefixes.forEach(function (folderRef) {
          const folder = {
            name: folderRef.name,
            path: folderRef.fullPath,
          }
          folders.push(folder)
        })
        resolve({ success: true })
      })

      if (setFolderState.success) {
        return {
          success: true,
          data: {
            numberOfFolder: numberOfFolder,
            folders: folders,
          },
        }
      }
    })
    .catch(function (error) {
      return {
        success: false,
        message: error,
      }
    })
}

export async function getFileListFromFirestore(initPath, path, type) {
  // console.log(initPath.split("/")[0] + "/" + initPath.split("/")[1])
  // console.log(initPath + path)
  // console.log(initPath)
  return new Promise(async (resolve, reject) => {
    const searchPath =
      "ChatRoom/" +
      initPath.split("/")[1] +
      (initPath.split("/")[0] === "Job" ? "/Job" : "")

    // console.log(searchPath + path)

    let dbRef = query(
      collection(db, "ChatRoom", initPath.split("/")[1], "Asset"),
      where("path", "==", searchPath + path)
    )

    if (
      typeof type !== "undefined" &&
      typeof type === "string" &&
      type !== ""
    ) {
      dbRef = dbRef.where("type", "==", type)
    }

    await getDocs(query(dbRef, orderBy("createDate", "desc")))
      .then((docs) => {
        if (!docs.empty) {
          let allFile = []
          docs.forEach((doc) => {
            allFile.push({
              id: doc.id,
              ...doc.data(),
            })
          })
          resolve({
            success: true,
            data: allFile,
          })
        } else {
          resolve({
            success: true,
            data: [],
          })
        }
      })
      .catch((err) => {
        console.log(err)
        resolve({
          success: true,
          data: [],
        })
      })
  })
}

export async function updateFileName(domain, fileUID, newFileName) {
  return new Promise(async (resolve, reject) => {
    await updateDoc(
      doc(
        db,
        "ChatRoom",
        domain.split("/")[1] + (domain.split("/")[0] === "Job" ? "/Job" : ""),
        "Asset",
        fileUID
      ),
      {
        fileName: newFileName,
      }
    )
      .then((res) => {
        resolve({ success: true })
      })
      .catch((err) => console.log(err))
  })
}

export async function removeFileFromStorage(path, fileName) {
  return new Promise(async (resolve, reject) => {
    const fileExt = fileName.split(".")[fileName.split(".").length - 1]

    //if file contain Thumbnail
    if (SupportingImageType.includes(fileExt)) {
      const MyImage = await new Promise(async (resolve1, reject) => {
        await deleteObject(ref(storage, path + "/" + fileName))
          .then(() => {
            return resolve1({ success: true })
          })
          .catch((err) => resolve1({ success: true }))
      })

      const Thumbnail = await new Promise(async (resolve1, reject) => {
        await deleteObject(ref(storage, path + "/ImageThumbnail/" + fileName))
          .then(() => {
            return resolve1({ success: true })
          })
          .catch((err) => resolve1({ success: true }))
      })

      if (MyImage.success) {
        resolve({ success: true })
      }
    } else {
      const MyFile = await new Promise(async (resolve1, reject) => {
        await deleteObject(ref(storage, path + "/" + fileName))
          .then(() => {
            return resolve1({ success: true })
          })
          .catch((err) => console.log(err))
      })

      if (MyFile.success) {
        resolve({ success: true })
      }
    }
  })
}

export async function removeMyUploadRecordFromFirestore(jobID, fileID) {
  return new Promise(async (resolve, reject) => {
    let dbRef = db

    //path example: /ChatRoom/5a011fe4-2dd4-4229-aa3f-ef7be40f2369/Asset/4f73873b-dfe3-4588-b80f-5cf3dffd405a
    await deleteDoc(doc(dbRef, "Job", jobID, "Asset", fileID))
      .then((res) => {
        resolve({ success: true })
      })
      .catch((err) => console.log(err))
  })
}
