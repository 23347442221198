import React, { lazy, useEffect, useState } from "react"
import { Kol } from "./kol"
import { CompareKOL } from "./compare"
import { useLocation } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../redux/SUPPORTED_REDUX_FUNCTION"
import { isAuth } from "../../../Utils/FirebaseUtils"
import {
  getKOLCompareGroup,
  updateCompareGroup,
} from "../../../HelpingFunction/Customer/customerDBHelper"

const Index: React.FC = (props) => {
  const dispatch = useDispatch()

  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const isLoadedCompareGroup = useSelector((state: any) => {
    return state.ToolsManager.isLoadedCompareGroup
  })

  const isCompareGroupChange = useSelector((state: any) => {
    return state.ToolsManager.isCompareGroupChange
  })

  const compareGroup = useSelector((state: any) => {
    return state.ToolsManager.compareGroup
  })

  const [type, setType] = useState<"search" | "compare" | null>(null)

  useEffect(() => {
    setType(query.get("type") as "search" | "compare" | null)
  }, [query])

  useEffect(() => {
    if (isAuth(auth)) {
      getKOLCompareGroup(auth.uid).then((result) => {
        setCompareGroup(result.success ? result.data : [])
      })
    }
  }, [auth])

  useEffect(() => {
    if (isAuth(auth) && isLoadedCompareGroup) {
      updateCompareGroup(auth.uid, compareGroup).then((result) => {
        //close popup
        closePopup()
      })
    }
  }, [compareGroup, auth])

  const closePopup = () =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.CLOSE_MODAL,
    })

  const setCompareGroup = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_COMPARE_GROUP,
      data: state,
    })

  if (screenWidth < 768) {
    return (
      <div className="w-full h-full">
        <div className=" overflow-y-scroll relative h-full ">
          {type === "search" && <Kol />}
          {type === "compare" && <CompareKOL />}
        </div>
      </div>
    )
  }
  return (
    <div className="w-full h-full">
      <div className=" w-full grid grid-col-1 md:grid-cols-5 lg:grid-cols-12 xl:grid-cols-12">
        <div
          className="md:col-span-3 lg:col-span-7 xl:col-span-8 overflow-y-scroll relative h-full "
          style={
            screenWidth < 768
              ? { maxHeight: " calc(100vh - 185px)" }
              : { maxHeight: "92vh" }
          }>
          <Kol />
        </div>

        <div className="bg-gray-100 border-l border-gray-200 md:col-span-2 lg:col-span-5 xl:col-span-4 md:block hidden">
          <CompareKOL />
        </div>
      </div>
    </div>
  )
}
export default Index
