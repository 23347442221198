import React, { useEffect, useImperativeHandle, useState } from "react"
import { IGCrawler, IGCrawlerOption } from "../../Model/IGCrawler"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { timeStampToDisplayTimeString } from "../../Utils/Converter"
import { useTranslation } from "react-i18next"
import NewAlert from "../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { getNeo4jFollowerCounts } from "../../HelpingFunction/crawlerDBHelper"

interface PropsType {
  metaData: {
    data: IGCrawler
  }
  closeModal: () => void
}

const EditCrawlerStateModal: React.FC<PropsType> = (
  { metaData: { data }, closeModal },
  ref: any
) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const [newIGCrawlerState, setNewIGCrawlerState] = useState<IGCrawler>(data)

  const [neo4jFollowers, setNeo4jFollowers] = useState<number>(0)

  useImperativeHandle(ref, () => ({
    returnData() {
      return newIGCrawlerState
    },
  }))

  useEffect(() => {
    getNeo4jFollowerCounts(newIGCrawlerState.userName).then((result) => {
      setNeo4jFollowers(result)
    })
  }, [newIGCrawlerState.userName])

  return (
    <div>
      <NewAlert
        title={t("Crawler_neo4j.warning-title")}
        alertType={ALERT_TYPE.ERROR}
        buttonType={{
          type: BUTTON_TYPE.NONE,
        }}
        visible={true}
      />

      <h2 className="font-bold text-2xl text-center my-4">{data.userName}</h2>
      <hr />

      <section>
        {/* status */}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">status</div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            <select
              className="select w-full max-w-xs"
              onChange={(e) => {
                setNewIGCrawlerState({
                  ...newIGCrawlerState,
                  status: e.target.selectedIndex,
                })
              }}>
              {IGCrawlerOption.map((e) => {
                // skip default item
                if (e.value !== "") {
                  return (
                    <option
                      selected={parseInt(e.value) === newIGCrawlerState.status}>
                      {t(e.label)}
                    </option>
                  )
                }
              })}
            </select>
          </div>
        </div>

        {/* ig id*/}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">ig id</div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            {data.ig_id}
          </div>
        </div>

        {/* neo4j follower*/}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">
            {t("Crawler_neo4j.neo4j-follower")}
          </div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            {neo4jFollowers}
          </div>
        </div>

        {/* isLook4kol reg */}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">isLook4kolReg</div>
          <div
            className="rounded-2xl p-4 bg-gray-50 border border-gray-100 grid gap-2"
            style={{ gridTemplateColumns: "auto 40px" }}>
            <select
              className="select select-bordered w-full max-w-xs"
              onChange={(e) => {
                setNewIGCrawlerState({
                  ...newIGCrawlerState,
                  isLook4kolReg: e.target.selectedIndex === 0,
                })
              }}>
              <option selected={newIGCrawlerState.isLook4kolReg}>True</option>
              <option selected={!newIGCrawlerState.isLook4kolReg}>False</option>
            </select>

            <div className="m-2">
              {newIGCrawlerState.isLook4kolReg ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-green-600 text-2xl"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="text-red-600 text-2xl"
                />
              )}
            </div>
          </div>
        </div>

        {/* create date*/}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">create date</div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            {timeStampToDisplayTimeString(data.createDate)}
          </div>
        </div>

        {/* lastupdate date*/}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">last update date</div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            {timeStampToDisplayTimeString(data.lastUpdate)}
          </div>
        </div>

        {/* noOfFollower */}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">No. of followers</div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            <input
              type="number"
              min={0}
              max={100000000}
              placeholder="No. of followers"
              className="input input-bordered w-full max-w-xs"
              value={newIGCrawlerState.noOfFollowers}
              onChange={(e) => {
                setNewIGCrawlerState({
                  ...newIGCrawlerState,
                  noOfFollowers: parseInt(e.target.value),
                })
              }}
            />
          </div>
        </div>

        {/* noOfCrawled */}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">No. of crawled</div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            <input
              type="number"
              min={0}
              max={100000000}
              placeholder="No. of crawled"
              className="input input-bordered w-full max-w-xs"
              value={newIGCrawlerState.noOfCrawled}
              onChange={(e) => {
                setNewIGCrawlerState({
                  ...newIGCrawlerState,
                  noOfCrawled: parseInt(e.target.value),
                })
              }}
            />
          </div>
        </div>

        {/* noOfFollowersCrawled */}
        <div className="my-4">
          <div className="text-md my-2 text-gray-600">
            No. of follower crawled
          </div>
          <div className="rounded-2xl p-4 bg-gray-50 border border-gray-100">
            <input
              type="number"
              min={0}
              max={100000000}
              placeholder="No. of crawled"
              className="input input-bordered w-full max-w-xs"
              value={newIGCrawlerState.noOfFollowersCrawled}
              onChange={(e) => {
                setNewIGCrawlerState({
                  ...newIGCrawlerState,
                  noOfFollowersCrawled: parseInt(e.target.value),
                })
              }}
            />
          </div>
        </div>
      </section>
    </div>
  )
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(EditCrawlerStateModal)
