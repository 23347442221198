import React from "react"
import { AdminCreateChatroomModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"

const AdminCreateChatroomModalComponent = (
  props: AdminCreateChatroomModalData
) => {
  return (
    <div className="container mx-auto  overflow-y-auto">
      <label>
        <Trans>AdminCreateChatroomModal.sub-title</Trans>
      </label>

      <p>
        <Trans>AdminCreateChatroomModal.des</Trans>
      </p>
    </div>
  )
}

export default AdminCreateChatroomModalComponent
