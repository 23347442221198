import React from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import KOLProfileInput from "./list-kol/[kolID]"
import CustomerProfileInput from "./list-customer/[customerID]"
import AdminPaymentSystem from "./stripe"
import AdminListJob from "./job"
import AdminRequest from "./request/[id]"
import ListArticle from "./list-article"
import NewArticle from "./new-article/[aid]"
import AddKol from "./add-kol"
import UploadFollowerCSV from "./add-follower"
import CustomerTools from "./tools/index"
import SearchFollower from "./search-follower"
import Recommendation from "../../Layout/Recommendation/Recommendation"
import AdminSetting from "./setting"
import { ChatGPT } from "./chatgpt"
import { ImageGenerate } from "./image-generate"
import RegistrationStats from "./register"
import { Crawler_neo4j } from "./crawler_neo4j"
import { ADMIN_ROLE, returnHomeFn } from "../../Model/Role"
import EmailSystem from "./email-system"
import PreviewArticle from "./PreviewArticle"

const Index: React.FC = (props) => {
  let history = useHistory()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const role: ADMIN_ROLE = useSelector((state: any) => {
    return state.firebase.auth ? state.firebase.auth.role : ADMIN_ROLE.UNKNOWN
  })

  return (
    <div
      className=" drawer drawer-mobile"
      style={{ height: "calc(100vh - 65px)" }}>
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div
        className="drawer-content flex flex-col items-center justify-center"
        style={{ display: "inline-block" }}>
        <label
          htmlFor="my-drawer-2"
          className="lg:hidden w-full btn btn-sm btn-primary drawer-button left-2 fixed top-4 w-40 z-20">
          Open drawer
        </label>
        <Switch>
          <Route path={"/:lang/admin/list-kol"}>
            <KOLProfileInput />
          </Route>

          <Route path={"/:lang/admin/list-customer/:customerID?/:type?"}>
            <CustomerProfileInput />
          </Route>

          <Route path={"/:lang/admin/job"}>
            <AdminListJob />
          </Route>

          <Route path={"/:lang/admin/email-system"}>
            <EmailSystem />
          </Route>

          <Route path={"/:lang/admin/stripe"}>
            <AdminPaymentSystem auth={auth} />
          </Route>

          <Route path={"/:lang/admin/list-article"} exact>
            <ListArticle />
          </Route>

          <Route path={"/:lang/admin/new-article/:aid?"}>
            <NewArticle />
          </Route>

          <Route path={"/:lang/admin/preview-article/:id"}>
            <PreviewArticle />
          </Route>

          <Route path={"/:lang/admin/add-kol"}>
            <AddKol />
          </Route>

          <Route path={"/:lang/admin/add-follower"}>
            <UploadFollowerCSV auth={auth} />
          </Route>

          {/*<Route path={"/:lang/admin/dashboard"}>*/}
          {/*  /!* <UploadFollowerCSV auth={auth}/> *!/*/}
          {/*  <Dashboard auth={auth} />*/}
          {/*</Route>*/}

          <Route path={"/:lang/admin/tools"}>
            <CustomerTools />
          </Route>

          <Route path={"/:lang/admin/search-follower"}>
            <SearchFollower />
          </Route>

          <Route path={"/:lang/admin/recommendation"}>
            <Recommendation />
          </Route>

          <Route path={"/:lang/admin/request"}>
            <AdminRequest />
          </Route>

          <Route path={"/:lang/admin/setting"}>
            <AdminSetting />
          </Route>

          <Route path={"/:lang/admin/register"}>
            <RegistrationStats auth={auth} />
          </Route>

          <Route path={"/:lang/admin/crawler"}>
            <Crawler_neo4j />
          </Route>

          <Route path={"/:lang/admin/chatgpt"}>
            <ChatGPT />
          </Route>

          <Route path={"/:lang/admin/image-generate"}>
            <ImageGenerate />
          </Route>
        </Switch>
      </div>
      <div className="drawer-side ">
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className="menu p-4 text-left overflow-y-auto w-80 bg-base-100 text-base-content border-l border-gray-200 bg-gray-100">
          {returnHomeFn(role).map((e, index) => {
            return (
              <li
                key={index}
                className="btn btn-ghost text-left w-full"
                onClick={() => history.push("/hk/admin" + e.url)}>
                {e.title}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Index
