import React, { useEffect, useState } from "react"
import { getAPIPath } from "../../Utils/HelpingFunction"
import { isAuth, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import DivContentLoader from "../../Component/DivContentLoader"
import ListStripeCharges from "../../page-components/admin/ListStripeCharges"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

const Stripe = (props) => {
  const [balance, setBalance] = useState({
    available: [],
    pending: [],
  })
  const [isLoadedBalance, setIsLoadedBalance] = useState(false)

  const [displayBalance, setDisplayBalance] = useState(false)

  useEffect(() => {
    if (isAuth(props.auth)) {
      getOurBalance()
    }
  }, [props.auth])

  const getOurBalance = async () => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/admin/stripe/balance"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            setBalance({
              available: finalResponse.data.available,
              pending: finalResponse.data.pending,
            })
            setIsLoadedBalance(true)
          }
        })
    })
  }

  return (
    <div className="w-full grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-4">
        <h2 className="font-bold text-xl">Stripe Control</h2>
        <div className="my-4"></div>

        {isLoadedBalance ? (
          <div className="relative mb-8 rounded-2xl bg-white shadow h-60 p-4 border-t-4 border-yellow-700">
            <button
              className="absolute right-5 top-5 bg-gray-100 p-1 rounded-full text-gray-700"
              onClick={() => setDisplayBalance(!displayBalance)}>
              {displayBalance ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button>
            {balance.available.map((e, index) => {
              return (
                <div key={"av" + index} className=" my-2 px-2">
                  <div className="block text-md">{e.currency} Balance</div>
                  <div className="block text-md text-2xl font-bold">
                    {e.currency.toUpperCase()}
                    <span className="ml-2">
                      {displayBalance
                        ? (e.amount / 100)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "**********"}
                    </span>
                  </div>
                </div>
              )
            })}

            {balance.pending.map((e, index) => {
              return (
                <div key={"av" + index} className=" my-2 px-2">
                  <div className="block text-md">{e.currency} Pending</div>
                  <div className="block text-md text-2xl font-bold">
                    {e.currency.toUpperCase()}
                    <span className="ml-2">
                      {displayBalance
                        ? (e.amount / 100)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "**********"}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <DivContentLoader w={"100%"} h={240} r={35} />
        )}
      </div>
      <div className="col-span-12 md:col-span-8">
        <ListStripeCharges />
      </div>
    </div>
  )
}
export default Stripe
