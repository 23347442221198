import { returnIdTokenResult } from "../Utils/FirebaseUtils"
import { getAPIPath, getNeo4jServer } from "../Utils/HelpingFunction"

/**
 * send question to chatgpt
 * @param {string} question - question
 */

const sendQuestionToChatgpt = (question: string): Promise<string> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getAPIPath("/api/openai/chatgpt"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          question: question,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          return resolve(finalResponse.data)
        })
    })
  })
}

/**
 * send description to openai
 * @param {string} description - description
 */

const generateImageByDescription = (
  description: string
): Promise<{ url: string }[]> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getAPIPath("/api/openai/image"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          description: description,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          return resolve(finalResponse.data)
        })
    })
  })
}

export { sendQuestionToChatgpt, generateImageByDescription }
