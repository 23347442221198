import { Job } from "../Model/Job"
import { BADGE_TYPE } from "../Enum/TABLE_SYSTEM"

const returnJobBadgeSetting = (
  job: Job
): {
  type: BADGE_TYPE
  label: string
  textColor: string
  bgColor: string
} => {
  const isWorking = job.accepted && !job.completePayout && job.paid

  const isJobDone = job.accepted && job.completePayout && job.paid

  const isJobAccepted = job.accepted && !job.completePayout && !job.paid

  const isQuotation = !job.accepted && job.quotationDetail !== ""

  const isWaitingForAccept = !job.accepted && job.quotationDetail === ""

  if (isWorking) {
    return {
      type: BADGE_TYPE.WORKING,
      label: "MyTransaction.working",
      textColor: "text-fuchsia-900",
      bgColor: "bg-fuchsia-200",
    }
  } else if (isJobDone) {
    return {
      type: BADGE_TYPE.JOB_DONE,
      label: "MyTransaction.fulfilled",
      textColor: "text-green-900",
      bgColor: "bg-green-200",
    }
  } else if (isJobAccepted) {
    return {
      type: BADGE_TYPE.JOB_ACCEPTED,
      label: "MyTransaction.accepted",
      textColor: "text-yellow-900",
      bgColor: "bg-yellow-200",
    }
  } else if (isQuotation) {
    return {
      type: BADGE_TYPE.QUOTATION,
      label: "MyTransaction.contract",
      textColor: "text-blue-900",
      bgColor: "bg-blue-200",
    }
  } else if (isWaitingForAccept) {
    return {
      type: BADGE_TYPE.PENDING,
      label: "MyTransaction.pending",
      textColor: "text-red-900",
      bgColor: "bg-red-200",
    }
  }
  return {
    type: BADGE_TYPE.FAIL,
    label: "unknown",
    textColor: "text-gray-900",
    bgColor: "bg-gray-200",
  }
}

export { returnJobBadgeSetting }
