import { Job, JOB_LIST_CONDITION_TYPE } from "../../Model/Job"
import { DateObj } from "../../Model/Utilities"
import { isChatroomExists } from "../Chatroom/chatroomDBHelper"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import firebaseApp from "../../config/firebase"
import { v4 as uuidv4 } from "uuid"
import { createNewJob } from "../../Utils/JobHelpingFunction"
import {
  getDirectionSign,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../../Utils/TableHelperFunction"

const db = getFirestore(firebaseApp)

/**
 * Get Job snapstop item by jobID
 * @param jobID - Job ID
 */

const getJobSnapshot = (
  jobID: string
): Promise<
  | {
      success: true
      job: Job
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    onSnapshot(doc(db, "Job", jobID), (doc) => {
      if (doc.exists()) {
        return resolve({
          success: true,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          job: {
            id: doc.id,
            ...doc.data(),
          } as Job,
        })
      }
      return resolve({
        success: false,
      })
    })
  })
}

/**
 * Get Job item by jobID
 * @param jobID - Job ID
 */

const getJob = (
  jobID: string
): Promise<
  | {
      success: true
      job: Job
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Job", jobID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            job: {
              id: doc.id,
              ...doc.data(),
            } as Job,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        return resolve({
          success: false,
        })
      })
  })
}

export interface jobListCondition {
  type?: JOB_LIST_CONDITION_TYPE
  uid: string | ""
  customerID: string | ""
  kolID: string | ""
  completePayout: boolean | ""
  accepted: boolean | ""
  paid: boolean | ""
  quotationDetail: boolean | string | ""
  paginationData?: any | ""
  paginationDirection: PAGINATION_DIRECTION | ""
  jobID?: string
  projectID?: string
}

const getJobList = (
  condition: jobListCondition,
  uid: string,
  orderByOption: {
    fieldName: string
    direction: ORDER_BY_DIRECTION
  },
  setLimit: number
): Promise<{
  success: true
  data: Job[]
  hasMore: boolean
}> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Job")

    if (condition.kolID !== "") {
      dbRef = query(
        dbRef,
        where("participateUID", "in", [[condition.customerID, condition.kolID]])
      )
    } else if (uid !== "" && uid) {
      if (condition.projectID !== undefined) {
        dbRef = query(dbRef, where("CustomerID", "==", uid))
      } else {
        dbRef = query(dbRef, where("participateUID", "array-contains", uid))
      }
    }

    if (condition.completePayout !== "") {
      dbRef = query(
        dbRef,
        where("completePayout", "==", condition.completePayout)
      )
    }

    if (condition.accepted !== "") {
      dbRef = query(dbRef, where("accepted", "==", condition.accepted))
    }

    if (condition.paginationData !== "") {
      dbRef = query(
        dbRef,
        where(
          orderByOption.fieldName,
          getDirectionSign(
            orderByOption.direction,
            condition.paginationDirection
          ),
          condition.paginationData
        )
      )
    }

    if (condition.projectID) {
      dbRef = query(
        dbRef,
        where("projectID", "array-contains", condition.projectID)
      )
    }

    //Previous page exclusive:
    //For reversing the order of data depending on the direction
    // (so that we can get the closest datasets)
    const reverseDirection =
      orderByOption.direction === ORDER_BY_DIRECTION.DESC
        ? ORDER_BY_DIRECTION.ASC
        : ORDER_BY_DIRECTION.DESC

    const orderByDirection =
      condition.paginationDirection === PAGINATION_DIRECTION.PREV
        ? reverseDirection
        : orderByOption.direction

    if (condition.quotationDetail) {
      dbRef = query(
        dbRef,
        // filtering will be done later to avoid inequality error with paginationData filter
        // where("quotationDetail", "!=", ""),
        orderBy(orderByOption.fieldName, orderByDirection),
        //orderBy quotationDetail ensures that all quotationDetail that are not empty will be queried first
        //(if without the orderBy, it will affect the hasMore var)
        orderBy("quotationDetail", "desc")
      )
    } else if (condition.quotationDetail === false) {
      dbRef = query(
        dbRef,
        where("quotationDetail", "==", ""),
        //orderBy orderByOption.fieldName will be done first before quotationDetail to avoid order error
        orderBy(orderByOption.fieldName, orderByDirection),
        orderBy("quotationDetail", "desc")
      )
    } else if (condition.paid) {
      dbRef = query(
        dbRef,
        orderBy(orderByOption.fieldName, orderByDirection),
        orderBy("paid", "desc")
      )
    } else if (condition.paid === false) {
      dbRef = query(
        dbRef,
        where("paid", "==", ""),
        orderBy(orderByOption.fieldName, orderByDirection),
        orderBy("paid", "desc")
      )
    } else {
      dbRef = query(dbRef, orderBy(orderByOption.fieldName, orderByDirection))
    }

    await getDocs(query(dbRef, limit(setLimit)))
      .then(async (docs: any) => {
        let allPromise: Promise<any>[] = []
        if (!docs.empty) {
          let filteredLimit = 0
          if (condition.kolID !== "") {
            //if getting same Chatroom Contract
            await isChatroomExists(
              docs.docs[0].data().CustomerID[0],
              docs.docs[0].data().KOLID[0]
            ).then((result) => {
              docs.forEach((doc: any) => {
                const quotationFilterCheck =
                  (condition.quotationDetail &&
                    doc.data().quotationDetail !== "") ||
                  !condition.quotationDetail

                if (filteredLimit < 10 && quotationFilterCheck) {
                  allPromise.push(
                    new Promise(async (resolve1) => {
                      //find job chatroom
                      if (result.success) {
                        resolve1({
                          id: doc.id,
                          chatroomID: result.chatroomID,
                          ...doc.data(),
                        })
                      } else {
                        resolve1({
                          id: doc.id,
                          ...doc.data(),
                        })
                      }
                    })
                  )
                }
                filteredLimit += 1
              })
            })

            Promise.all(allPromise).then((res) => {
              //docs.docs.length is not the same as filteredLimit when quotationDetail filter is on
              const hasMoreConvertor = condition.quotationDetail
                ? filteredLimit === setLimit - 1
                : docs.docs.length === setLimit

              return resolve({
                success: true,
                data: res,
                hasMore: hasMoreConvertor,
              })
            })
          } else {
            //Get all Contracts
            docs.forEach((doc: any) => {
              const quotationFilterCheck =
                (condition.quotationDetail &&
                  doc.data().quotationDetail !== "") ||
                !condition.quotationDetail

              if (filteredLimit < 10 && quotationFilterCheck) {
                allPromise.push(
                  new Promise(async (resolve1) => {
                    resolve1({
                      id: doc.id,
                      ...doc.data(),
                    })
                  })
                )
                filteredLimit += 1
              }
            })
            Promise.all(allPromise).then((res) => {
              //docs.docs.length is not the same as filteredLimit when quotationDetail filter is on
              const hasMoreConvertor = condition.quotationDetail
                ? filteredLimit === setLimit - 1
                : docs.docs.length === setLimit

              return resolve({
                success: true,
                data: res,
                hasMore: hasMoreConvertor,
              })
            })
          }
        } else {
          return resolve({
            success: true,
            data: [],
            hasMore: false,
          })
        }
      })
      .catch((err: any) => console.log(err))
  })
}

export interface QuotationObj {
  ServicePlanID: string
  //will be the job name
  ServicePlanName: string
  quotationDetail: string
}

export const QuotationObjDefault = {
  ServicePlanID: "",
  //will be the job name
  ServicePlanName: "",
  quotationDetail: "",
}

const createNewContract = (
  kolID: string,
  uid: string,
  quotationObj?: QuotationObj
): Promise<
  | {
      success: true
      jobID: string
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    // job id
    const projectID = uuidv4()

    const JobObject = {
      CustomerID: uid,
      KOLID: kolID,
      endDate: new Date(),
      isFinish: false,
      // the order cannot be changed !!
      participateUID: [uid, kolID],
      price: 0,
      startDate: new Date(),
      priceID: "",
      customTitle: [],
      isSubmitQuotation: true,
      ...(quotationObj ? quotationObj : QuotationObjDefault),
      //    more detail will be added by cloud functions
    }

    await createNewJob(projectID, JobObject).then((result) => {
      if (result.success) {
        return resolve({
          success: true,
          jobID: projectID,
        })
      }
      return resolve({
        success: false,
      })
    })
  })
}

export { getJob, getJobSnapshot, getJobList, createNewContract }
