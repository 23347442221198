export enum APP_TYPE {
  NONE,
  IMAGE,
}

export enum SYSTEM_MESSAGE_TYPE {
  SYSTEM = "system",
  CONTRACT = "contract",
  FILE_REF = "file-ref",
  FILE = "file",
  TEXT = "text",
  IMAGE = "image",
}

export enum UNREAD_MESSAGE_TYPE {
  JUST_CONTRACT = "contract",
  JUST_CHAT = "chat",
}

export enum ASSET_TYPE {
  FILE_REF = "file-ref",
  FILE = "file",
  LINK_TEXT = "link-text",
  TEXT = "text",
  IMAGE = "image",
  CHATGPT_TEXT = "chatgpt",
}

export enum ADMIN_REQUEST_STATUS_TYPE {
  FINISHED,
  PENDING,
  REJECT,
}

export enum ADMIN_REQUEST_TYPE {
  APPEAL = "APPEAL",
  BANK_CHECKOUT = "BANK_CHECKOUT",
  RECOMMEND_REQUEST = "RECOMMEND_REQUEST",
  PAYOUT_REQUEST = "PAYOUT_REQUEST",
  PAY_LATER_REQUEST = "PAY_LATER_REQUEST",
  KOL_REFERRAL_MISSION = "KOL_REFERRAL_MISSION",
}

export enum RECOMMEND_REQUEST_TYPE {
  PRODUCT,
  SERVICE,
  BRAND,
}

export enum RECOMMEND_REQUEST_GOAL_TYPE {
  REACH,
  CONVERSION,
  BRAND,
}

export enum CHATROOM_TYPE {
  JOB = "job",
  PRIVATE = "private",
  ADMIN = "admin",
  DEFAULT = "",
}

export enum JOB_STATUS_TYPE {
  STATUS_0 = "status-0",
  STATUS_1 = "status-1",
  STATUS_2 = "status-2",
  STATUS_3 = "status-3",
  STATUS_4 = "status-4",

  //suspend job
  STATUS_99 = "status-99",
}

export enum CHATROOM_PAGE_TYPE {
  LIST_CHAT,
  CHAT_TEXT,
  CONTRACT,
  FILE,
}

export enum ROLE {
  KOL = "kol",
  CUSTOMER = "customer",
  ADMIN = "admin",
  DEFAULT = "",
}

export enum COMPONENT_DISPLAY {
  LIST = "LIST",
  TABLE = "TABLE",
}
