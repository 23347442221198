/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import { ADMIN_REQUEST } from "../../../Model/AdminRequest"
import KOL, { KOLDefault } from "../../../Model/KOL"
import { getKOL } from "../../../HelpingFunction/KOL/kolDBHelper"
import { getUserLang } from "../../../Utils/utiltyHelper"
import { ADMIN_REQUEST_STATUS_TYPE } from "../../../Enum/APP_TYPE"
import { setAdminRequest } from "../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import classNames from "classnames"
import CustomAlert from "../../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import { faCircleCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"

interface Props {
  request: ADMIN_REQUEST
  closeModal: () => void
}

const KOLMissionRequest = (props: Props) => {
  const [req, setReq] = useState<ADMIN_REQUEST>(props.request)
  const [kol, setKOL] = useState<KOL>(KOLDefault)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isLoadingAccept, setIsLoadingAccept] = useState<boolean>(false)
  const [isLoadingReject, setIsLoadingReject] = useState<boolean>(false)

  useEffect(() => {
    getLocalKOL()
  }, [])

  const acceptAdminRequest = async () => {
    setIsLoadingAccept(true)
    const newReq: ADMIN_REQUEST = {
      ...req,
      status: ADMIN_REQUEST_STATUS_TYPE.FINISHED,
    }
    await setAdminRequest(req.id as string, newReq).then((res) => {
      if (res.success) {
        setIsLoadingAccept(false)
        setReq(newReq)
      } else {
        setIsLoadingAccept(false)
      }
    })
  }
  const rejectAdminRequest = async () => {
    setIsLoadingReject(true)
    const newReq: ADMIN_REQUEST = {
      ...req,
      status: ADMIN_REQUEST_STATUS_TYPE.REJECT,
    }
    await setAdminRequest(req.id as string, newReq).then((res) => {
      if (res.success) {
        setIsLoadingReject(false)
        setReq(newReq)
      } else {
        setIsLoadingReject(false)
      }
    })
  }

  const getLocalKOL = async () => {
    await getKOL(req.requestUID as string).then((res) => {
      if (res.success) {
        setKOL(res.data)
        setIsLoaded(true)
      }
    })
  }

  const requestTitle =
    req.status === ADMIN_REQUEST_STATUS_TYPE.FINISHED
      ? "Request Approved"
      : req.status === ADMIN_REQUEST_STATUS_TYPE.PENDING
      ? "Request Pending"
      : "Request Rejected"

  const alertType =
    req.status === ADMIN_REQUEST_STATUS_TYPE.FINISHED
      ? ALERT_TYPE.PAYMENT
      : req.status === ADMIN_REQUEST_STATUS_TYPE.PENDING
      ? ALERT_TYPE.WARNING
      : ALERT_TYPE.ERROR

  const buttonType = {
    type: BUTTON_TYPE.NONE,
  }

  const icon =
    req.status === ADMIN_REQUEST_STATUS_TYPE.FINISHED
      ? faCircleCheck
      : faCircleInfo

  if (isLoaded) {
    return (
      <div className="p-2">
        <CustomAlert
          title={requestTitle}
          alertType={alertType}
          buttonType={buttonType}
          icon={icon}
        />
        <section className="my-2">
          <p className="font-semibold my-4">
            <Trans>KOLMission.kol-details</Trans>
          </p>
          <div className="flex flex-row gap-2 items-center">
            <img className="w-8 h-8 rounded-full" src={kol.avatarUrl} />
            <a
              target="_blank"
              href={`https://www.look4kol.com/${getUserLang()}/p/${
                kol.userName
              }`}>
              {kol.userName}
            </a>
          </div>
        </section>
        <hr />
        <div>
          <p className="font-semibold my-4">
            <Trans>KOLMission.req-details</Trans>
          </p>
          {Array.isArray(props.request.data) &&
            props.request.data.map((file) => {
              return (
                <div>
                  <img className="w-72 mx-auto" src={file.url} />
                </div>
              )
            })}
        </div>
        {req.status === ADMIN_REQUEST_STATUS_TYPE.PENDING && (
          <div className="flex flex-row gap-6 justify-center items-center my-8">
            <button
              onClick={() => rejectAdminRequest()}
              className={classNames(
                "btn bg-red-400 text-white w-40 hover:bg-red-700",
                {
                  loading: isLoadingReject,
                  "btn-disabled": isLoadingAccept,
                }
              )}>
              <Trans>KOLMission.reject</Trans>
            </button>
            <button
              onClick={() => acceptAdminRequest()}
              className={classNames(
                "btn bg-green-400 text-white w-40 hover:bg-green-700",
                {
                  loading: isLoadingAccept,
                  "btn-disabled": isLoadingReject,
                }
              )}>
              <Trans>KOLMission.accept</Trans>
            </button>
          </div>
        )}
      </div>
    )
  }
  return (
    <div>
      <p>Loading...</p>
    </div>
  )
}

export default KOLMissionRequest
