import React from "react"
import { useSelector } from "react-redux"
import { isAuth, logout } from "../Utils/FirebaseUtils"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../Utils/utiltyHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

export const Header: React.FC = () => {
  let history = useHistory()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  return (
    <div className="navbar bg-[#e17e72]">
      <div className="flex-1">
        <label
          htmlFor="my-drawer-2"
          className="w-full btn btn-ghost drawer-button left-2 fixed text-white w-40 z-20">
          <FontAwesomeIcon className="lg:hidden " icon={faBars} />
          <span className="ml-2">Look4kol</span>
        </label>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal p-0">
          <li className="text-white">{isAuth(auth) && <a>{auth.email}</a>}</li>
          {isAuth(auth) ? (
            <button className="btn" onClick={() => logout()}>
              logout
            </button>
          ) : (
            <button
              className="btn"
              onClick={() => history.push("/" + getUserLang() + "/login")}>
              login
            </button>
          )}
        </ul>
      </div>
    </div>
  )
}
