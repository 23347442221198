import {
  ADMIN_REQUEST_STATUS_TYPE,
  ADMIN_REQUEST_TYPE,
} from "../../Enum/APP_TYPE"
import AdminRequest, { ADMIN_REQUEST } from "../../Model/AdminRequest"
import { DateObj } from "../../Model/Utilities"
import { v4 as uuidv4 } from "uuid"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../config/firebase"
import {
  getDirectionSign,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
} from "../../Utils/TableHelperFunction"

const db = getFirestore(firebaseApp)

const getBankCheckoutHistory = (
  jobID: string
): Promise<
  | {
      success: true
      data: AdminRequest[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let docRef = collection(db, "AdminRequest")
    const q1 = query(docRef, where("jobID", "==", jobID))
    const q2 = query(
      q1,
      where("type", "==", ADMIN_REQUEST_TYPE.BANK_CHECKOUT),
      orderBy("createDate", "desc")
    )
    await getDocs(q2)
      .then((docs) => {
        if (!docs.empty) {
          let arr: AdminRequest[] = []
          docs.forEach((doc) => {
            arr.push({
              id: doc.id,
              ...doc.data(),
            } as AdminRequest)
          })

          return resolve({
            success: true,
            data: arr,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getMyPayoutHistory = (
  condition: {
    type: string
    startAfter: "" | DateObj | Date
  },
  uid: string
): Promise<
  | {
      success: true
      hasMore: boolean
      data: any[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    let hasMore = false

    let colRef = collection(db, "AdminRequest")

    let dbRef = query(
      colRef,
      where("type", "==", ADMIN_REQUEST_TYPE.PAYOUT_REQUEST),
      where("requestUID", "==", uid)
    )

    if (condition.startAfter !== "") {
      dbRef = query(dbRef, where("lastUpdate", "<", condition.startAfter))
    }

    getDocs(query(dbRef, orderBy("lastUpdate", "desc"), limit(10)))
      .then((docs) => {
        if (!docs.empty) {
          let newPayoutHis: any = []
          hasMore = docs.docs.length === 10
          docs.forEach((doc) => {
            newPayoutHis.push({
              id: doc.id,
              ...doc.data(),
            })
          })

          return resolve({
            success: true,
            hasMore: hasMore,
            data: newPayoutHis,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

const createBankCheckout = (
  data: AdminRequest
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    const caseID = uuidv4()

    await setDoc(doc(db, "AdminRequest", caseID), data)
      .then((result) => {
        console.log(result)
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export interface AdminRequestCondition {
  requestID?: string
  jobID?: string
  status?: ADMIN_REQUEST_STATUS_TYPE | ""
  type?: ADMIN_REQUEST_TYPE | ""
  paginationData?: any | ""
  paginationDirection: PAGINATION_DIRECTION | ""
}

const getAdminRequests = (
  condition: AdminRequestCondition,
  orderByOption: {
    fieldName: string
    direction: ORDER_BY_DIRECTION
  }
): Promise<{
  success: boolean
  hasMore: boolean
  data: ADMIN_REQUEST[]
}> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "AdminRequest")
    if (condition.requestID === "") {
      if (condition.jobID !== "") {
        dbRef = query(dbRef, where("jobID", "==", condition.jobID))
      }

      if (condition.type !== "") {
        dbRef = query(dbRef, where("type", "==", condition.type))
      }

      if (condition.status !== "") {
        dbRef = query(dbRef, where("status", "==", condition.status))
      }

      if (condition.paginationData !== "") {
        dbRef = query(
          dbRef,
          where(
            orderByOption.fieldName,
            getDirectionSign(
              orderByOption.direction,
              condition.paginationDirection
            ),
            condition.paginationData
          )
        )
      }
      //Previous page exclusive:
      //For reversing the order of data depending on the direction
      // (so that we can get the closest datasets)
      const reverseDirection =
        orderByOption.direction === ORDER_BY_DIRECTION.DESC
          ? ORDER_BY_DIRECTION.ASC
          : ORDER_BY_DIRECTION.DESC

      const orderByDirection =
        condition.paginationDirection === PAGINATION_DIRECTION.PREV
          ? reverseDirection
          : orderByOption.direction

      dbRef = query(dbRef, orderBy(orderByOption.fieldName, orderByDirection))

      await getDocs(query(dbRef, limit(11)))
        .then((docs: any) => {
          let allAdminRequests: ADMIN_REQUEST[] = []
          let docLength = 0
          if (!docs.empty) {
            docs.forEach((doc: any) => {
              if (docLength < 10) {
                allAdminRequests.push({
                  id: doc.id,
                  ...doc.data(),
                })
              }
              docLength += 1
            })

            return resolve({
              success: true,
              hasMore: docs.docs.length === 11,
              data: allAdminRequests,
            })
          }
          return resolve({
            success: true,
            hasMore: false,
            data: [],
          })
        })
        .catch((err: any) => console.log(err))
    } else {
      await getDoc(doc(dbRef, condition.requestID))
        .then((doc: any) => {
          if (doc.exists()) {
            return resolve({
              success: true,
              hasMore: false,
              data: [
                {
                  id: doc.id,
                  ...doc.data(),
                },
              ],
            })
          }
          return resolve({
            success: true,
            hasMore: false,
            data: [],
          })
        })
        .catch((err: any) => console.log(err))
    }
  })
}

const getAdminRequest = (
  id: string
): Promise<{
  success: boolean
  data: ADMIN_REQUEST
}> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "AdminRequest", id))
      .then((doc: any) => {
        if (doc.exists) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            },
          })
        }
      })
      .catch((err: any) => console.log(err))
  })
}

const setAdminRequest = (
  id: string,
  data: ADMIN_REQUEST
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    console.log(data)
    await setDoc(doc(db, "AdminRequest", id), data)
      .then((doc: any) => {
        console.log("edited")
        return resolve({
          success: true,
        })
      })
      .catch((err: any) => {
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * Only Admin can have the permission to update AdminRequest
 * @param id
 */
const markAdminRequestAsFinished = (
  id: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "AdminRequest", id), {
      status: ADMIN_REQUEST_STATUS_TYPE.FINISHED,
      lastUpdate: new Date(),
    })
      .then((doc: any) => {
        return resolve({
          success: true,
        })
      })
      .catch((err: any) => console.log(err))
  })
}

const returnStatusString = (status: ADMIN_REQUEST_STATUS_TYPE) => {
  if (status === ADMIN_REQUEST_STATUS_TYPE.FINISHED) {
    return "Finished"
  } else if (status === ADMIN_REQUEST_STATUS_TYPE.PENDING) {
    return "Pending"
  }
  return "Rejected"
}

export {
  getBankCheckoutHistory,
  createBankCheckout,
  getMyPayoutHistory,
  getAdminRequests,
  getAdminRequest,
  setAdminRequest,
  markAdminRequestAsFinished,
  returnStatusString,
}
