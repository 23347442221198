export enum REGISTER_STAT_TYPE {
  WEEKLY,
  MONTHLY,
  CUSTOM,
}

export enum REGISTER_KOL_TYPE {
  DEFAULT,
  KOL_ONLY,
  VERIFIED_KOL,
}
