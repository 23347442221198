import { useLocation } from "react-router-dom"
import React, { useMemo } from "react"
import { KOLSearchType } from "../redux/reducer/ToolsManager"
import { compareConditionType } from "../pages/admin/tools/kol"

export const verifiedUpdateDetector = (
  value: string | null,
  searchCondition: number
) => {
  if (value && value !== "") {
    return parseInt(value) !== searchCondition
  }
  return false
}

export interface queryType {
  name: string
  data: string | null
}

export interface compareQueryType {
  pageNum: string | null
  searchID: string | null
  gender: string | null
  nationality: string | null
  isLook4kolReg: string | null
  genreCategory: string | null
  genre: string | null
  hasData: string | null
  focusPercentage: string | null
  priceMin: string | null
  priceMax: string | null
  followerMin: string | null
  followerMax: string | null
}

export const compareKOLQuery = (rawData: URLSearchParams): compareQueryType => {
  return {
    searchID: getQuery(rawData, "searchID"),
    pageNum: getQuery(rawData, "pageNum"),
    gender: getQuery(rawData, "gender"),
    nationality: getQuery(rawData, "nationality"),
    isLook4kolReg: getQuery(rawData, "isLook4kolReg"),
    genreCategory: getQuery(rawData, "genreCategory"),
    genre: getQuery(rawData, "genre"),
    hasData: getQuery(rawData, "hasData"),
    focusPercentage: getQuery(rawData, "focusPercentage"),
    priceMin: getQuery(rawData, "priceMin"),
    priceMax: getQuery(rawData, "priceMax"),
    followerMin: getQuery(rawData, "followerMin"),
    followerMax: getQuery(rawData, "followerMax"),
  }
}

const isQueryUpdated = (
  queryValue: string | null,
  paramValue: string | null
): string | false => {
  if (queryValue || queryValue === "") {
    return queryValue !== "" ? queryValue : false
  }
  return paramValue ? paramValue : false
}

export const queryGenerator = (
  query: queryType[],
  params: queryType[]
): URLSearchParams => {
  const requests: URLSearchParams[] = []
  params.forEach((original) => {
    const update = query.find((e) => e.name === original.name)
    const checkResult = isQueryUpdated(
      update ? update.data : null,
      original.data
    )
    if (checkResult) {
      requests.push(new URLSearchParams(original.name + "=" + checkResult))
    }
  })
  return new URLSearchParams(requests.join("&"))
}

export function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const getQuery = (reduxParam: URLSearchParams, name: string) => {
  return new URLSearchParams(reduxParam).get(name)
}

export const getAuthUID = (reduxParam: URLSearchParams): string => {
  const viewAsQuery = getQuery(reduxParam, "viewAs")
  const auth = localStorage.getItem("look4kol-auth-uid")
  const authID = auth ? auth : ""
  return viewAsQuery ? viewAsQuery : authID
}

export const queryChecker = (
  searchFromLocation: string,
  searchQuery: string
) => {
  const requiredParams = new URLSearchParams(searchQuery.split("?")[1])
  const urlParams = new URLSearchParams(searchFromLocation)
  const paramsArr: {
    key: string
    value: string
  }[] = []
  const arrChecker = searchQuery.includes("?") ? requiredParams : urlParams
  arrChecker.forEach((value, key) => {
    if (key !== "viewAs" && key !== "metaPath")
      paramsArr.push({ key: key, value: value })
  })
  if (paramsArr.length) {
    return paramsArr.every((e) =>
      searchQuery.includes("?")
        ? urlParams.get(e.key) === e.value
        : requiredParams.has(e.key)
    )
  }
  return !searchQuery.includes("?")
}

const paramToConditionName = (paramName: string) => {
  switch (paramName) {
    case "pageNum":
      return "page"
    case "followersMin":
      return "followerMin"
    case "followersMax":
      return "followerMax"
    case "engagementMin":
      return "basicScoreMin"
    case "engagementMax":
      return "basicScoreMax"
    case "verified":
      return "isVerified"
    case "searchID":
      return "searchStr"
    default:
      return paramName
  }
}

const paramValueToConditionValue = (paramValue: [string, any]) => {
  if (paramValue[0] === "pageNum") {
    return parseInt(paramValue[1]) - 1
  } else if (
    paramValue[0] === "isVerified" ||
    paramValue[0] === "isPublish" ||
    paramValue[0] === "hasData"
  ) {
    return (paramValue[1] === "true") as boolean
  } else if (paramValue[0] !== "searchID" && paramValue[0] !== "topCateID") {
    return parseInt(paramValue[1])
  }
  return paramValue[1]
}
export const searchParamsToCondition = (params: [string, any][]) => {
  return params
    .filter((p) => p[0] !== "kolID")
    .reduce(
      (a, v) => ({
        ...a,
        [paramToConditionName(v[0])]: paramValueToConditionValue(v),
      }),
      {}
    )
}

export interface searchQueryType {
  followersMin: string | null
  followersMax: string | null
  engagementMin: string | null
  engagementMax: string | null
  verified: string | null
  published: string | null
  kolID: string | null
  searchID: string | null
  pageNum: string | null
  type: string | null
}

export const searchPageQuery = (
  paramList: URLSearchParams
): searchQueryType => {
  const paramTmp = useMemo(
    () => ({
      followersMin: getQuery(paramList, "followersMin"),
      followersMax: getQuery(paramList, "followersMax"),
      engagementMin: getQuery(paramList, "engagementMin"),
      engagementMax: getQuery(paramList, "engagementMax"),
      verified: getQuery(paramList, "verified"),
      published: getQuery(paramList, "published"),
      kolID: getQuery(paramList, "kolID"),
      searchID: getQuery(paramList, "searchID"),
      pageNum: getQuery(paramList, "pageNum"),
      type: getQuery(paramList, "type"),
    }),
    [
      getQuery(paramList, "followersMin"),
      getQuery(paramList, "followersMax"),
      getQuery(paramList, "engagementMin"),
      getQuery(paramList, "engagementMax"),
      getQuery(paramList, "verified"),
      getQuery(paramList, "published"),
      getQuery(paramList, "kolID"),
      getQuery(paramList, "searchID"),
      getQuery(paramList, "pageNum"),
      getQuery(paramList, "type"),
    ]
  )
  return paramTmp
}

export interface searchConditionType {
  followersMin?: string
  followersMax?: string
  nationality?: string
  engagementMin?: string
  engagementMax?: string
  verified?: string
  published?: string
  limit?: string
  searchID?: string
  kolID?: string
  topCateID?: string
  topCateMin?: string
  topCateMax?: string
  pageNum?: string
  type?: string
}

export const returnSearchConditionParam = (
  query: searchConditionType | compareConditionType,
  paramList: URLSearchParams
) => {
  const newParam = new URLSearchParams(paramList)

  Object.entries(query)
    .filter(([key, value]) => {
      return value !== undefined
    })
    .forEach(([key, value]) => {
      // default if value is empty string, we remove the param in the query
      if (value === "") {
        newParam.delete(key)
      } else {
        newParam.set(key, value)
      }
    })

  // reset page number if param update does not from pageNum or kolID
  if (!query.pageNum && !("kolID" in query && query.kolID)) {
    newParam.set("pageNum", "1")
  }
  return newParam
}
