import React, { useEffect, useState } from "react"
import { searchKOLAlgolia } from "../HelpingFunction/KOL/kolDBHelper"
import { KOLSearchDefault } from "../redux/reducer/ToolsManager"
import { KOLCombine } from "../Model/KOLCombine"
import KOLRecommendationsProfile from "./KOLRecommendationProfile"
import { Trans } from "react-i18next"

interface PropsType {
  data: singlePercentageItem[]
}

interface singlePercentageItem {
  item: string
  percentage: number
}

const SimilarKOL: React.FC<PropsType> = ({ data }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [kols, setKOLs] = useState<KOLCombine[]>([])

  const getSearchResult = (topCateItem: singlePercentageItem) => {
    setIsLoading(true)
    setIsLoaded(false)

    searchKOLAlgolia({
      ...KOLSearchDefault,
      topCateID: topCateItem.item,
      topCateMin: topCateItem.percentage * 0.4,
      topCateMax: topCateItem.percentage * 1.6,
    })
      .then((response) => {
        if (response.success) {
          setKOLs(response.data as KOLCombine[])
        }
        setIsLoaded(true)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (data.length > 0) {
      getSearchResult(data[0])
    }
  }, [])

  useEffect(() => {
    if (data.length > 0) {
      getSearchResult(data[0])
    }
  }, [data])

  return (
    <div className="my-8">
      <h2 className="text-3xl font-bold">
        <Trans>SimilarKOL.title</Trans>
      </h2>
      <div className="bg-gray-50 rounded-2xl carousel carousel-center max-w-full px-4 space-x-4 rounded-box ">
        {isLoaded &&
          kols.map((kol, index) => {
            return (
              <div className="carousel-item" id={"rec-kol-" + index}>
                <KOLRecommendationsProfile data={kol} topCateItem={data} />
              </div>
            )
          })}
      </div>
    </div>
  )
}
export default SimilarKOL
