import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { Reducer } from "react"
import { Article, articleDefault } from "../../Model/Article"

interface actionTypes {
  type: string
  data: string | Article | any
}

export interface modalInitStateType {
  // a list of kol that promoted on the homepage
  article: Article
}

const initialState: modalInitStateType = {
  article: articleDefault,
}

const ArticleManager: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_ARTICLE:
      return {
        ...state,
        article: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.UPDATE_ARTICLE:
      return {
        ...state,
        article: {
          ...state.article,
          ...action.data,
        },
      }
    default:
      return state
  }
}

export default ArticleManager
