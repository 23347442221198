import React from "react"
import { Switch } from "react-router-dom"
import KOL from "../../Model/KOL"
import { Auth } from "firebase/auth"

import AdminEditKOLProfile from "./AdminEditKOLProfile"
import KOLJobProfiler from "./KOLJobProfiler"
import KOLWalletProfiler from "./KOLWalletProfiler"
import EditFollowerData from "./EditFollowerData"

enum selectedKOLEnum {
  PROFILE = "profile",
  FOLLOWER = "follower",
  JOB = "job",
  WALLET = "wallet",
}

interface Props {
  auth: Auth
  history: any
  selectedKOL: KOL
  type: selectedKOLEnum
}

const KOLProfiler = (props: Props) => {
  return (
    <>
      <div className="my-3 ">
        <span className="text-gray-400 text-lg font-medium">ID: </span>
        <span>{props.selectedKOL.id}</span>
      </div>

      <Switch>
        {props.type === selectedKOLEnum.PROFILE && (
          <AdminEditKOLProfile profile={props.selectedKOL} />
        )}

        {props.type === selectedKOLEnum.FOLLOWER && (
          <EditFollowerData profile={props.selectedKOL} />
        )}

        {props.type === selectedKOLEnum.JOB && (
          <KOLJobProfiler profile={props.selectedKOL} />
        )}
        {props.type === selectedKOLEnum.WALLET && (
          <KOLWalletProfiler profile={props.selectedKOL} />
        )}
      </Switch>
    </>
  )
}
export default KOLProfiler
