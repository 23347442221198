import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import DeleteArticleModal from "../../Layout/AdminRouter/ListArticle/DeleteArticleModal/DeleteArticleModal"
import {
  articleCondition,
  getArticles,
  removeArticle,
} from "../../HelpingFunction/Article/ArticleDBHelper"
import CustomTable from "../../Component/NewTable"
import { Column } from "../../Model/Table"
import {
  adminArticleTableSchema,
  adminProfileJobTableSchema,
  defaultAdminArticleTableSchema,
  defaultArticleTableSort,
  getMySort,
  onSort,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
  SORT_OPTION,
} from "../../Utils/TableHelperFunction"
import { Article } from "../../Model/Article"
import { getUserLang } from "../../Utils/utiltyHelper"

const ListArticle: React.FC = (props) => {
  let history = useHistory()
  const { t } = useTranslation()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [allArticle, setAllArticle] = useState<Article[]>([])
  const [removeArticleID, setRemoveArticleID] = useState<string>("")

  const [blogList, setBlogList] = useState<Column[][]>([
    defaultAdminArticleTableSchema(),
  ])
  const [isGettingMoreArticle, setIsGettingMoreArticle] =
    useState<boolean>(false)

  const [hasMore, setHasMore] = useState<boolean>(false)
  const [pageNum, setPageNum] = useState<number>(0)
  const [sortOptions, setSortOptions] = useState<SORT_OPTION[]>(
    defaultArticleTableSort
  )

  const [condition, setCondition] = useState<articleCondition>({
    paginationData: "",
    paginationDirection: "",
  })

  useEffect(() => {
    getArticles(condition, getMySort(sortOptions))
      .then((result) => {
        if (result.success) {
          let finalData = result.data

          //always return hasMore (for nextBtn) as true when loading prev page, otherwise nextBtn might not work
          if (condition.paginationDirection === PAGINATION_DIRECTION.PREV) {
            finalData = finalData.reverse()
            setHasMore(true)
          } else {
            setHasMore(result.hasMore)
          }

          setIsLoaded(true)
          setIsGettingMoreArticle(false)
          setAllArticle(finalData)
        } else {
          setIsLoaded(true)
          setIsGettingMoreArticle(false)
          setHasMore(false)
          setAllArticle([])
        }
      })
      .catch((err) => console.log(err))
  }, [condition])

  const delArticle = () => {
    if (removeArticleID !== "") {
      removeArticle(removeArticleID).then((result) => {
        if (result.success) {
          let newAllArticles = allArticle
          newAllArticles = newAllArticles.filter(
            (e) => e.id !== removeArticleID
          )
          setAllArticle(newAllArticles)
          setRemoveArticleID("")
        }
      })
    }
  }

  const editArticleFn = (articleID: string) => {
    const selectedArticle = allArticle.find(
      (article) => article.id === articleID
    )
    if (selectedArticle) {
      history.push({
        pathname:
          "/" + getUserLang() + "/admin/new-article/" + selectedArticle.id,
        state: { article: selectedArticle },
      })
    }
  }

  const previewArticleFn = (articleID: string) => {
    const selectedArticle = allArticle.find(
      (article) => article.id === articleID
    )
    if (selectedArticle) {
      history.push({
        pathname:
          "/" + getUserLang() + "/admin/preview-article/" + selectedArticle.id,
        state: { article: selectedArticle },
      })
    }
  }

  const toArticleFn = (article: Article) => {
    window.open(
      process.env.REACT_APP_HOST_NAME +
        "/" +
        getUserLang() +
        "/blog/" +
        article.urlTitle
    )
  }

  const deleteArticleFn = (articleID: string) => {
    const selectedArticle = allArticle.find(
      (article) => article.id === articleID
    )
    if (selectedArticle) {
      setRemoveArticleID(selectedArticle.id)
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById("deleteModal").showModal()
    }
  }

  const sortFn = (fieldName: string) => {
    setIsLoaded(false)
    let newSortOption = onSort(sortOptions, fieldName)

    setSortOptions(newSortOption)
    setPageNum(0)
    setTimeout(() => {
      setCondition({
        ...condition,
        paginationData: "",
        paginationDirection: "",
      })
    }, 1000)
  }
  useEffect(() => {
    if (Array.isArray(allArticle) && isLoaded) {
      setIsGettingMoreArticle(true)

      let allPromise: Promise<any>[] = []

      allArticle.map(async (article) => {
        allPromise.push(
          new Promise(async (resolve) => {
            resolve(
              adminArticleTableSchema(
                article,
                sortFn,
                sortOptions,
                () => {
                  editArticleFn(article.id)
                },
                () => {
                  previewArticleFn(article.id)
                },
                () => {
                  toArticleFn(article)
                },
                () => {
                  deleteArticleFn(article.id)
                }
              )
            )
          })
        )
      })

      Promise.all(allPromise).then((result) => {
        setBlogList(result)
        setIsGettingMoreArticle(false)
      })
    }
  }, [allArticle])

  const previousPagAction = () => {
    if (pageNum >= 1 && allArticle.length > 0) {
      setIsLoaded(false)
      setCondition({
        paginationData: allArticle[0].lastModified,
        paginationDirection: PAGINATION_DIRECTION.PREV,
      })
      setPageNum(pageNum - 1)
    }
  }
  const nextPagAction = () => {
    if (hasMore && allArticle.length > 0) {
      setIsLoaded(false)
      setCondition({
        paginationData: allArticle[9].lastModified,
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
      setPageNum(pageNum + 1)
    }
  }

  return (
    <div className="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative h-full  bg-white rounded-2xl p-4">
      <DeleteArticleModal removeArticle={delArticle} />
      <h1 className="text-2xl">
        <Trans>ListArticle.list-article</Trans>
      </h1>
      <div className="my-4">
        <CustomTable
          cols={blogList}
          isFirstColStatic={false}
          isLoading={!isLoaded}
          rowIsLoading={isGettingMoreArticle}
          notFoundDes={t("Article Not Found")}
          prevBtn={{
            disable: false,
            onClick: previousPagAction,
          }}
          pageNum={pageNum}
          nextBtn={{
            disable: !hasMore,
            onClick: nextPagAction,
          }}
          isLastRecord={!hasMore}
        />
      </div>
    </div>
  )
}
export default ListArticle
