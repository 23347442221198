import {
  ADMIN_REQUEST_STATUS_TYPE,
  ADMIN_REQUEST_TYPE,
  RECOMMEND_REQUEST_GOAL_TYPE,
  RECOMMEND_REQUEST_TYPE,
} from "../Enum/APP_TYPE"
import { FileType } from "./File"
import { DateObj } from "./Utilities"

export interface ADMIN_REQUEST {
  //optional, since using firebase/add won't contain "id"
  id?: string
  type: ADMIN_REQUEST_TYPE
  createDate: Date | DateObj
  status: ADMIN_REQUEST_STATUS_TYPE
  jobID?: string
  requestUID?: string
  data?:
    | RECOMMEND_REQUEST_DATA
    | PAYOUT_REQUEST_DATA
    | [FileType]
    | PAY_LATER_REQUEST_DATA
  lastUpdate?: Date | DateObj
  slack_ts?: string
}

export interface APPEAL_DATA {
  content: string
  reasonablePrice: number
  attachment: string[]
}

export interface RECOMMEND_REQUEST_DATA {
  title: string
  type: RECOMMEND_REQUEST_TYPE | ""
  categories: string[]
  interestPreference: string[]
  budget: number
  numberOfKOL: number
  kolRequirement: string[]
  numberOfFLsPreference: number | ""
  email: string
  goal: RECOMMEND_REQUEST_GOAL_TYPE | ""
}

export interface PAYOUT_REQUEST_DATA {
  id?: string
  bankDetail: {
    accNum: string
    bankID: string
    bankLocation: string
    cardHolder: string
    currency: string
  }
  handlingFee: number
  withdrawAmount: number
}

export interface PAY_LATER_REQUEST_DATA {
  id?: string
  content: string
}

class AdminRequest {
  id?: string
  type: ADMIN_REQUEST_TYPE
  createDate: DateObj | Date
  status: ADMIN_REQUEST_STATUS_TYPE
  jobID?: string
  requestUID?: string
  data?:
    | RECOMMEND_REQUEST_DATA
    | PAYOUT_REQUEST_DATA
    | APPEAL_DATA
    | FileType
    | PAY_LATER_REQUEST_DATA
  lastUpdate: DateObj | Date

  // eslint-disable-next-line max-params
  constructor(
    id: string,
    type: ADMIN_REQUEST_TYPE,
    createDate: DateObj,
    status: ADMIN_REQUEST_STATUS_TYPE,
    lastUpdate: DateObj,
    jobID?: string,
    requestUID?: string,
    data?:
      | RECOMMEND_REQUEST_DATA
      | PAYOUT_REQUEST_DATA
      | APPEAL_DATA
      | FileType
      | undefined
  ) {
    this.id = id
    this.type = type
    this.createDate = createDate
    this.status = status
    this.jobID = jobID
    this.requestUID = requestUID
    this.data = data
    this.lastUpdate = lastUpdate
  }
}

export default AdminRequest
