import React, { useImperativeHandle, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"

import { CompareItem } from "../../redux/reducer/ToolsManager"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"

interface PropsType {
  metaData: string
  closeModal: () => void
}

const AddToCompareGroup: React.FC<PropsType> = (
  { metaData, closeModal },
  ref: any
) => {
  const dispatch = useDispatch()

  const isLoadedCompareGroup: boolean = useSelector((state: any) => {
    return state.ToolsManager.isLoadedCompareGroup
  })

  const compareGroup: CompareItem[] = useSelector((state: any) => {
    return state.ToolsManager.compareGroup
  })

  const [newGroupName, setNewGroupName] = useState<string>("")
  const [selectedGroupID, setSelectedGroupID] = useState<string>("")

  const createNewCompareGroup = (groupName: string) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.CREATE_NEW_COMPARE_GROUP,
      data: groupName,
      selectedKOLID: metaData,
    })

  const removeKOLFromCompareGroup = (selectedKOLID: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.REMOVE_KOL_FROM_COMPARE_GROUP,
      data: selectedKOLID,
    })
  }

  const addKOLToCompareGroup = (
    selectedGroupID: string,
    selectedKOLID: string
  ) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.ADD_KOL_TO_COMPARE_GROUP,
      data: selectedGroupID,
      selectedKOLID: selectedKOLID,
    })
  }

  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        selectedKOLID: metaData,
        selectedGroupID: selectedGroupID,
      }
    },
  }))

  return (
    <div
      className="z-20 h-96 w-full p-2 grid overflow-hidden"
      style={{ gridTemplateRows: "160px auto" }}>
      <div className="border-b border-gray-200 pb-2 ">
        <div className="text-sm text-gray-500 font-bold mt-2">
          <Trans>AddToCompareGroup.new-group</Trans>
        </div>

        <input
          className="mb-2 py-2 px-4 w-full rounded-md bg-gray-100 border border-gray-200 my-2"
          value={newGroupName}
          onChange={(e) => {
            setNewGroupName(e.target.value)
          }}
        />

        <button
          className="relative float-right btn btn-ghost btn-md"
          onClick={(e) => {
            createNewCompareGroup(newGroupName)
            setNewGroupName("")
          }}>
          <Trans>AddToCompareGroup.confirm</Trans>
        </button>
      </div>
      <div className="overflow-y-scroll">
        <div className="text-sm text-gray-500 font-bold mt-8 mb-2">
          <Trans>AddToCompareGroup.existing-group</Trans>
        </div>

        <div>
          <ul className="menu bg-base-200 w-56 p-2 rounded-box">
            {isLoadedCompareGroup &&
              compareGroup
                .filter((el) => el.type === "ig")
                .map((g, index) => {
                  const isIncluded = g.data.includes(metaData)
                  return (
                    <li
                      key={"gp" + index}
                      className="cursor-pointer grid my-2"
                      style={{
                        gridTemplateColumns: "auto 40px 30px",
                      }}
                      onClick={(e) => {
                        e.preventDefault()

                        if (isIncluded) {
                          setSelectedGroupID("")
                          removeKOLFromCompareGroup(metaData)
                        } else {
                          setSelectedGroupID(g.id)
                          addKOLToCompareGroup(g.id, metaData)
                        }
                        closeModal()
                      }}>
                      <div className="my-auto mx-2">{g.title}</div>
                      <div className="badge text-white my-auto">
                        {g.data.length}
                      </div>

                      {(selectedGroupID === g.id || isIncluded) && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="my-auto mx-auto text-2xkl"
                        />
                      )}
                    </li>
                  )
                })}
          </ul>
        </div>
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(AddToCompareGroup)
