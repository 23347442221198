import React from "react"
import { Article } from "../../Model/Article"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../redux/SUPPORTED_REDUX_FUNCTION"
import { Trans } from "react-i18next"

const BlogSidePanel = () => {
  const dispatch = useDispatch()
  const article: Article = useSelector((state: any) => {
    return state.ArticleManager.article
  })

  const updateReduxArticle = (data: Article) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_ARTICLE,
      data: data,
    })
  }

  return (
    <div>
      <div className="my-8">
        <div className="flex flex-row gap-4 w-full ">
          <p className="font-medium">
            <Trans>NewArticle.publish-article</Trans>
          </p>
          <input
            type="checkbox"
            className="toggle"
            checked={article.isPublish}
            onChange={() =>
              updateReduxArticle({
                ...article,
                isPublish: !article.isPublish,
              })
            }
          />
        </div>
      </div>
      <hr />
      <div className="my-8">
        <p className="text-sm font-semibold my-2">
          <Trans>NewArticle.title-article</Trans>
        </p>
        <textarea
          placeholder="Enter a catchy title for your aticle."
          className="input input-bordered w-full h-24 resize-none"
          onChange={(e) => {
            updateReduxArticle({
              ...article,
              title: e.target.value,
            })
          }}
          value={article.title}
        />
      </div>
      <div className="my-8">
        <p className="text-sm font-semibold my-2">
          <Trans>NewArticle.author-article</Trans>
        </p>
        <input
          type="text"
          placeholder="Enter your name"
          className="input input-bordered w-full "
          onChange={(e) => {
            updateReduxArticle({
              ...article,
              authorName: e.target.value,
            })
          }}
          value={article.authorName}
        />
      </div>
      <div className="my-8">
        <p className="text-sm font-semibold my-2">
          <Trans>NewArticle.url-title</Trans>
        </p>
        <input
          type="text"
          placeholder="URL Title for your article"
          className="input input-bordered w-full "
          onChange={(e) => {
            let str = e.target.value
            str = str.replace(" ", "-")
            updateReduxArticle({
              ...article,
              urlTitle: str,
            })
          }}
          value={article.urlTitle}
        />
      </div>
      <div className="my-8">
        <p className="text-sm font-semibold my-2">
          <Trans>NewArticle.canonical</Trans>
        </p>
        <input
          type="text"
          placeholder="Enter a canonical link for this blog"
          className="input input-bordered w-full h-24 resize-none"
          onChange={(e) => {
            updateReduxArticle({
              ...article,
              canonicalLink: e.target.value,
            })
          }}
          value={article.canonicalLink}
        />
      </div>

      <div className="my-8">
        <p className="text-sm font-semibold my-2">
          <Trans>NewArticle.language</Trans>
        </p>
        <select
          className="select select-bordered w-full max-w-xs"
          value={article.language}
          onChange={(e) =>
            updateReduxArticle({ ...article, language: e.target.value })
          }>
          <option disabled selected>
            Choose Article Language
          </option>
          <option value={"EN"}>English</option>
          <option value={"CHI"}>Chinese</option>
        </select>
      </div>
    </div>
  )
}

export default BlogSidePanel
