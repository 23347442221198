import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { Reducer } from "react"

interface actionTypes {
  type: string
  data: string | string[] | boolean
}

export interface modalInitStateType {
  // a list of kol that promoted on the homepage
  promotedKOLsID: string[]
  isLoadedPromotedKOLsID: boolean
  statusChanged: boolean
}

const initialState: modalInitStateType = {
  promotedKOLsID: [],
  isLoadedPromotedKOLsID: false,
  statusChanged: false,
}

const AdminControlManager: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_PROMOTED_KOL_LIST:
      return {
        ...state,
        promotedKOLsID: action.data as string[],
        isLoadedPromotedKOLsID: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.ADD_TO_PROMOTED_KOL_LIST:
      return {
        ...state,
        promotedKOLsID: state.promotedKOLsID.concat(action.data as string),
      }
    case SUPPORTED_REDUX_FUNCTIONS.REMOVE_FROM_PROMOTED_KOL_LIST:
      return {
        ...state,
        promotedKOLsID: state.promotedKOLsID.filter(
          (e) => e !== (action.data as string)
        ),
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_REQUEST_STATUS_CHANGED:
      return {
        ...state,
        statusChanged: action.data as boolean,
      }
    default:
      return state
  }
}

export default AdminControlManager
