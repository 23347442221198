import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import NewAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { sendQuestionToChatgpt } from "../../HelpingFunction/chatgptHelper"
import classNames from "classnames"
import { faClock } from "@fortawesome/free-solid-svg-icons"

const example = [
  "generate 5 instagram post descriptions which inviting advertisers to register to the Look4KOL website. Look4KOL is a platform allowing advertisers to find suitable influencers based on the website unique recommendation and scoring algorithm. ",
  "Create a list of 8 questions for my interview with a full stack developer with react and nodejs",
]
export const ChatGPT: React.FC = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const [question, setQuestion] = useState<string>("")
  const [answer, setAnswer] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const send = () => {
    setIsLoading(true)
    sendQuestionToChatgpt(question).then((result) => {
      setIsLoading(false)
      setAnswer(result)
    })
  }

  return (
    <div className="relative">
      {/* main content*/}
      <div className="relative max-w-7xl mx-auto pt-4 pb-36 relative px-4">
        <h1 className="text-grey-800 text-3xl font-bold leading-tight  ">
          <Trans>OpenAI.chatgpt</Trans>
        </h1>
        <hr className="mb-4" />

        <NewAlert
          title={t("OpenAI.warning-title")}
          alertType={ALERT_TYPE.ERROR}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          visible={true}
        />

        {/*  ChatGPT example */}
        <section>
          <h2 className="text-grey-800 text-xl font-bold leading-tight  ">
            <Trans>OpenAI.example</Trans>
          </h2>
          <div className="my-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            {example.map((text, index) => {
              return (
                <div
                  key={index}
                  className="mx-2 rounded-lg bg-red-default text-white p-4 col-span-1">
                  <div className="h-36 overflow-y-scroll">{text}</div>
                  <button
                    className="mt-4 block btn btn-primary btn-outline bg-white w-full "
                    onClick={(e) => {
                      setQuestion(text)
                    }}>
                    <Trans>OpenAI.try-this</Trans>
                  </button>
                </div>
              )
            })}
          </div>
        </section>

        <hr className="my-4" />

        <section>
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-1">
              <textarea
                className="textarea textarea-bordered w-full h-96 overflow-y-scroll"
                placeholder="question"
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value)
                }}></textarea>

              <button
                className={classNames(
                  "mt-4 block btn btn-primary btn-outline bg-white w-full ",
                  { loading: isLoading }
                )}
                disabled={isLoading}
                onClick={(e) => {
                  send()
                }}>
                <Trans>OpenAI.send</Trans>
              </button>
            </div>

            <div className="col-span-1">
              <textarea
                className="textarea textarea-bordered w-full h-96 overflow-y-scroll"
                placeholder="answer"
                disabled={true}
                value={isLoading ? "loading" : answer}></textarea>

              {isLoading && (
                <NewAlert
                  title={t("OpenAI.require-time")}
                  icon={faClock}
                  alertType={ALERT_TYPE.INFO}
                  buttonType={{
                    type: BUTTON_TYPE.NONE,
                  }}
                  visible={true}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
