import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Trans } from "react-i18next"
import { validateEmail } from "../../Utils/FormValidate"
import Spinner from "../../../src/Component/Spinner"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowLeftLong,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"

const auth = getAuth()

const ResetPasswordForm = (props: any) => {
  const [resetPasswordEmail, setResetPasswordEmail] = useState("")
  const [isSendingResetEmail, setIsSendingResetEmail] = useState(false)
  const [isSentResetEmail, setIsSentResetEmail] = useState(false)

  const resetPassword = () => {
    setIsSendingResetEmail(true)
    const email = resetPasswordEmail
    // [START auth_send_password_reset]
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        setIsSendingResetEmail(false)
        setIsSentResetEmail(true)
      })
      .catch((error) => {
        let errorCode = error.code
        let errorMessage = error.message
        // ..
        console.log(error)
        setIsSendingResetEmail(false)
        setIsSentResetEmail(true)
      })
    // [END auth_send_password_reset]
  }

  return (
    <div className="py-24">
      <FontAwesomeIcon
        className="cursor-pointer"
        onClick={() => props.setForgotPassword(false)}
        icon={faArrowLeftLong}
      />
      <h3 className="pt-2 text-2xl text-gray-600 text-left text-semibold">
        <Trans>RegForm.reset-password</Trans>
      </h3>

      <div className="py-8 text-base leading-6 space-y-4 text-gray-600 text-md sm:text-lg sm:leading-7">
        <label className="leading-loose">
          <Trans>RegForm.email</Trans>
        </label>
        <input
          disabled={isSendingResetEmail || isSentResetEmail}
          className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
          onChange={(e) => {
            setResetPasswordEmail(e.target.value)
            if (validateEmail(e.target.value)) {
              props.setError("")
            } else {
              props.setError("ErrorList.reg-kol-email-not-valid")
            }
          }}
        />
      </div>

      <button
        onClick={() => resetPassword()}
        className={[
          "rounded-lg w-full py-2 px-4 text-center sm:text-sm border focus:border-gray-900 w-full border-gray-300 focus:outline-none transition duration-150 ease-in-out transform ",
          isSentResetEmail
            ? "bg-red-default text-white font-bold"
            : " bg-white hover:bg-gray-200",
        ].join(" ")}
        disabled={
          isSendingResetEmail ||
          isSentResetEmail ||
          !validateEmail(resetPasswordEmail)
        }>
        {isSendingResetEmail ? (
          <Spinner />
        ) : isSentResetEmail ? (
          <Trans>RegForm.sent</Trans>
        ) : (
          <Trans>RegForm.reset-password</Trans>
        )}
      </button>

      {!validateEmail(resetPasswordEmail) && (
        <p className="text-xs italic text-red-600">
          <Trans>RegForm.wrong-email-format</Trans>
        </p>
      )}
    </div>
  )
}

export default withRouter(ResetPasswordForm)
